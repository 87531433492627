import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useSpinner } from '../../context/SpinnerContext';
import HeaderEditCollection from './HeaderEditCollection';
import { AddImageIcon } from '../../assets';
import apiService from '../../services/apiService';
import { pinFileToIPFS, getIpfsUrl } from '../../services/ipfsService';
import DividersText from '../DividersText';
import Spacer from '../Spacer';
import { isFileTooLarge } from '../../utils/utils';

// TODO: Do the same as in CreateStory component, and only allow to enter this page if you are
// logged in (and the collection exists and you are the owner of the collection)
function EditCollection() {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinner();
  const { collectionId } = useParams();
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [uploadingCover, setUploadingCover] = useState(false);
  const [cover, setCover] = useState(null);
  const [coverUrl, setCoverUrl] = useState('');
  const [socialTwitter, setSocialTwitter] = useState('');
  const [socialInstagram, setSocialInstagram] = useState('');
  const [socialTiktok, setSocialTiktok] = useState('');
  const [socialDiscord, setSocialDiscord] = useState('');
  const [website, setWebsite] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);
  const logoRef = useRef(null);
  const coverRef = useRef(null);
  const nameRef = useRef(null);
  const socialTwitterRef = useRef(null);
  const socialInstagramRef = useRef(null);
  const socialTikTokRef = useRef(null);
  const socialDiscordRef = useRef(null);
  const socialWebsiteRef = useRef(null);

  const editCollection = async () => {
    try {
      showSpinner('editing collection');
      if (!name) {
        return setError(true);
      }

      const collectionData = {
        name,
        description,
        socialTwitter,
        socialInstagram,
        socialTiktok,
        socialDiscord,
        website,
        tags,
      };
      if (logo) {
        const logoHash = await pinFileToIPFS(logo);
        collectionData.logo = `ipfs://${logoHash.toString()}`;
      }
      if (cover) {
        const coverHash = await pinFileToIPFS(cover);
        collectionData.cover = `ipfs://${coverHash.toString()}`;
      }
      await apiService.post(`/collection/${collectionId}`, collectionData);
      toast.success('Successfully edited the collection');
      return navigate(-1);
    } catch (err) {
      console.log(err);
      return toast.error(err.message);
    } finally {
      hideSpinner();
    }
  };

  const cleanLogo = () => {
    if (logoUrl) {
      URL.revokeObjectURL(logoUrl);
      setLogoUrl('');
    }
    setUploadingLogo(false);
    setLogo(null);
  };

  const cleanCover = () => {
    if (coverUrl) {
      URL.revokeObjectURL(coverUrl);
      setCoverUrl('');
    }
    setUploadingCover(false);
    setCover(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        showSpinner('fetching data');
        const collection = await apiService.get(`/collection/${collectionId}`);
        if (collection.tags) {
          setTags(collection.tags);
        }
        if (collection.name) {
          setName(collection.name);
        }
        if (collection.description) {
          setDescription(collection.description);
        }
        if (collection.socialTwitter) {
          setSocialTwitter(collection.socialTwitter);
        }
        if (collection.socialInstagram) {
          setSocialInstagram(collection.socialInstagram);
        }
        if (collection.socialTiktok) {
          setSocialTiktok(collection.socialTiktok);
        }
        if (collection.socialTiktok) {
          setSocialTiktok(collection.socialTiktok);
        }
        if (collection.socialDiscord) {
          setSocialDiscord(collection.socialDiscord);
        }
        if (collection.website) {
          setWebsite(collection.website);
        }
        if (collection.logo) {
          const url = getIpfsUrl(collection.logo);
          const responseFile = await fetch(url);
          const file = await responseFile.blob();
          setLogo(file);
          setLogoUrl(URL.createObjectURL(file));
        }
        if (collection.cover) {
          const url = getIpfsUrl(collection.cover);
          const responseFile = await fetch(url);
          const file = await responseFile.blob();
          setCover(file);
          setCoverUrl(URL.createObjectURL(file));
        }
      } catch (err) {
        toast.error(err.message);
        console.log(err);
      } finally {
        hideSpinner();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const cleanDataUrlData = () => {
      if (logoUrl) {
        URL.revokeObjectURL(logoUrl);
      }
      if (coverUrl) {
        URL.revokeObjectURL(coverUrl);
      }
    };

    return cleanDataUrlData;
  }, []);

  useEffect(() => {
    if (error) {
      nameRef.current.focus();
    }
  }, [error]);

  return (
    <div>
      <HeaderEditCollection editCollection={editCollection} />
      <Spacer size="xs" />
      <section className="container mx-auto pt-8 pb-32 px-4">
        <div className="flex justify-center">
          <div className="space-y-12 max-w-md">
            {/* General Info */}
            <div>
              <DividersText>General Information</DividersText>
              <div className="mt-6">
                <label
                  htmlFor="max-copies"
                  className="block text-sm font-medium text-gray-700"
                >
                  {' '}
                  Collection Name{' '}
                </label>
                <div className="mt-1 relative">
                  <input
                    ref={nameRef}
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (error) {
                        setError(false);
                      }
                    }}
                    className={
                      !error
                        ? 'shadow-sm focus:ring-primary-500 focus:border-primary-500 border-black border rounded-md p-3 block w-full focus:outline-primary-500'
                        : 'block w-full p-3 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
                    }
                  />
                  {error && (
                    <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {error && (
                  <p className="mt-2 text-sm text-red-600">
                    You need to write a name.
                  </p>
                )}
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4">
                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    className="shadow-sm block w-full text-base border border-black rounded-md mt-2 focus:ring-primary-500 focus:border-primary-500 p-3 min-h-[150px] h-auto max-h-[700px] overflow-auto focus:outline-primary-500"
                    type="textarea"
                    id="description"
                    required=""
                    rows="7"
                    placeholder="You can use simple markdown to format your text:&#10;# Title&#10;## Subtitle&#10;**bold**&#10;*italic*&#10;> Blockquotes"
                    value={description}
                    onChange={(e) => {
                      const { value } = e.target;
                      setDescription(value);
                    }}
                  />
                  <div className="mt-2 text-sm text-gray-500">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    You can use{' '}
                    <a
                      href="https://www.markdownguide.org/basic-syntax/"
                      target="_blank"
                      className="group relative text-primary-600 hover:text-primary-400"
                      rel="noreferrer"
                    >
                      <div className="absolute bottom-2 flex flex-col items-center hidden mb-6 group-hover:flex -ml-4 -mr-4 left-0 right-0">
                        <span className="relative z-10 p-2 text-xs leading-tight text-white whitespace-no-wrap bg-black shadow-lg rounded-md">
                          Use simple markdown to format your text. Click to know
                          more.
                        </span>
                        <div className="w-3 h-3 -mt-2 rotate-45 bg-black" />
                      </div>
                      Markdown syntax
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
            {/* Upload Logo */}
            <div>
              <div>
                <DividersText>Upload Logo</DividersText>
              </div>
              {!logo ? (
                <div className="border-4 border-dashed border-gray-200 rounded-lg aspect-square text-center mt-4 flex justify-center items-center max-w-xs">
                  <div className="">
                    {!uploadingLogo ? (
                      <>
                        <figure className="flex justify-center">
                          <AddImageIcon />
                        </figure>
                        <div className="text-sm text-gray-600">
                          <label
                            htmlFor="upload-logo"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                          >
                            <span>Upload a file</span>
                            <input
                              ref={logoRef}
                              onInput={() => {
                                //  TODO: remove setTimeout
                                setUploadingLogo(true);
                                const temp = logoRef.current.files[0];
                                setTimeout(() => {
                                  if (isFileTooLarge(temp.size, 'image')) {
                                    return;
                                  }
                                  setUploadingLogo(false);
                                  setLogo(temp);
                                  setLogoUrl(URL.createObjectURL(temp));
                                }, 2000);
                              }}
                              id="upload-logo"
                              name="upload-logo"
                              type="file"
                              className="sr-only"
                              accept="image/png, image/jpeg, image/jpg, image/gif"
                            />
                          </label>
                          <span className="pl-1">or drag and drop</span>
                        </div>
                        <p className="text-xs leading-4 font-normal text-gray-500">
                          JPG, PNG or GIF up to 10MB
                        </p>
                      </>
                    ) : (
                      <div className="flex flex-col items-center px-2 border border-transparent text-lg font-medium rounded-lg text-gray-500">
                        {/* Icon Spinner */}
                        <svg
                          role="status"
                          className="w-10 h-10 mb-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span>Uploading Logo</span>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="aspect-square relative flex flex-col bg-gray-100 rounded-xl overflow-hidden mt-4 bg-cover bg-center max-w-xs">
                  <button
                    type="button"
                    className="inline-flex items-center z-10 px-2 py-3 border border-transparent text-lg font-medium rounded-full text-black w-10 h-10 absolute top-3 left-3 bg-white hover:text-black focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-transparent"
                    onClick={cleanLogo}
                  >
                    {/* Close */}
                    <svg
                      className="h-7 w-7"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M6 18L18 6M6 6L18 18"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <img
                    src={logoUrl}
                    alt="collection logo"
                    className="w-full h-full"
                  />
                </div>
              )}
              <p className="mt-4 text-sm text-gray-500">
                Recommended size 800x800px.
              </p>
            </div>

            {/* Upload Cover */}
            <div>
              <DividersText>Upload Cover</DividersText>
              {!cover ? (
                <div className="border-4 border-dashed border-gray-200 rounded-lg text-center mt-4 w-full aspect-[16/7] flex items-center justify-center max-h-80">
                  <div className="">
                    {!uploadingCover ? (
                      <>
                        <figure className="flex justify-center">
                          <AddImageIcon />
                        </figure>
                        <div className="text-sm text-gray-600">
                          <label
                            htmlFor="upload-cover"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                          >
                            <span>Upload a file</span>
                            <input
                              ref={coverRef}
                              onInput={() => {
                                //  TODO: remove setTimeout
                                const temp = coverRef.current.files[0];
                                setUploadingCover(true);
                                setTimeout(() => {
                                  if (isFileTooLarge(temp.size, 'image')) {
                                    return;
                                  }
                                  setUploadingCover(false);
                                  setCover(temp);
                                  setCoverUrl(URL.createObjectURL(temp));
                                }, 2000);
                              }}
                              id="upload-cover"
                              name="upload-cover"
                              type="file"
                              className="sr-only"
                              accept="image/png, image/jpeg"
                            />
                          </label>
                          <span className="pl-1">or drag and drop</span>
                        </div>
                        <p className="text-xs leading-4 font-normal text-gray-500">
                          JPG, PNG or GIF up to 10MB
                        </p>
                      </>
                    ) : (
                      <div className="flex flex-col items-center px-2 py-3 mb-6 border border-transparent text-lg font-medium rounded-lg text-gray-500">
                        {/* Icon Spinner */}
                        <svg
                          role="status"
                          className="w-10 h-10 mb-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span>Uploading Cover</span>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="relative flex flex-col bg-gray-100 rounded-xl overflow-hidden mt-4 w-full aspect-[16/7] bg-cover bg-center">
                  <button
                    type="button"
                    className="inline-flex items-center z-10 px-2 py-3 border border-transparent text-lg font-medium rounded-full text-black w-10 h-10 absolute top-3 left-3 bg-white hover:text-black focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-transparent"
                    onClick={cleanCover}
                  >
                    {/* Close */}
                    <svg
                      className="h-7 w-7"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M6 18L18 6M6 6L18 18"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <img src={coverUrl} alt="collection cover" />
                </div>
              )}
              <p className="mt-4 text-sm text-gray-500">
                Recommended size is 1500x700 px. Keep the most important info in
                the center to keep it visible in mobile.
              </p>
            </div>
            <div>
              <DividersText>Social</DividersText>
              <p className="text-sm mt-3 text-gray-500">
                Add your social networks
              </p>
              <div className="mt-3 flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  twitter.com/
                </span>
                <input
                  ref={socialTwitterRef}
                  type="text"
                  name="twitter"
                  id="twitter"
                  value={socialTwitter}
                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="username"
                  onChange={(e) => {
                    const { value } = e.target;
                    setSocialTwitter(value);
                  }}
                />
              </div>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  instagram.com/
                </span>
                <input
                  ref={socialInstagramRef}
                  type="text"
                  name="instagram"
                  id="instagram"
                  value={socialInstagram}
                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="username"
                  onChange={(e) => {
                    const { value } = e.target;
                    setSocialInstagram(value);
                  }}
                />
              </div>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  tiktok.com/@
                </span>
                <input
                  ref={socialTikTokRef}
                  type="text"
                  name="tiktok"
                  id="tiktok"
                  value={socialTiktok}
                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="username"
                  onChange={(e) => {
                    const { value } = e.target;
                    setSocialTiktok(value);
                  }}
                />
              </div>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  discord.gg/
                </span>
                <input
                  ref={socialDiscordRef}
                  type="text"
                  name="discord"
                  id="discord"
                  value={socialDiscord}
                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="user id"
                  onChange={(e) => {
                    const { value } = e.target;
                    setSocialDiscord(value);
                  }}
                />
              </div>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                  https://
                </span>
                <input
                  ref={socialWebsiteRef}
                  type="text"
                  name="website"
                  id="website"
                  value={website}
                  className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="website"
                  onChange={(e) => {
                    const { value } = e.target;
                    setWebsite(value);
                  }}
                />
              </div>
            </div>

            {/* About Content */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default EditCollection;
