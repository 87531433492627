import React from 'react';

function IconTablerDiscord({ className }) {
  return (
    <svg fill="none" viewBox="0 0 24 24" className={className}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.249 12.916a.917.917 0 100-1.833.917.917 0 000 1.833zM14.749 12.916a.917.917 0 100-1.833.917.917 0 000 1.833zM7.875 7.875c3.208-.917 5.042-.917 8.25 0M7.418 16.125c3.208.917 5.958.917 9.167 0"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.207 16.584c0 .916 1.375 2.75 1.833 2.75 1.375 0 2.597-1.528 3.209-2.75.611-1.528.458-5.347-1.375-10.542-1.336-.93-2.75-1.228-4.125-1.375l-.917 2.292M8.792 16.584c0 .916-1.243 2.75-1.68 2.75-1.31 0-2.473-1.528-3.055-2.75-.582-1.528-.436-5.347 1.31-10.542 1.272-.93 2.55-1.228 3.883-1.375l.917 2.292"
      />
    </svg>
  );
}

export default IconTablerDiscord;
