import React, { Fragment, useContext, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { motion, useReducedMotion } from 'framer-motion';
import {
  XMarkIcon,
  CreditCardIcon,
  WalletIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/24/outline';
import ReactGA from 'react-ga4';
import LoginContext from '../../context/LoginContext';
import Button from '../Button';
import AvatarWithInfo from '../AvatarWithInfo';
import TransakContext from '../../context/TransakContext';
import { LinkButton, ButtonIcon } from '../Button/Button';
import WalletInfo from '../WalletInfo';
import { getIpfsUrl } from '../../services/ipfsService';
import WithdrawModal from './WithdrawModal';

function SlideOverProfile() {
  const { address, userName, logout, userMetadata } = useContext(LoginContext);
  const { transak } = useContext(TransakContext);
  const [openPopup, setOpenPopup] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [withdrawButtonClicked, setWithdrawButtonClicked] = useState(false);
  const shouldReduceMotion = useReducedMotion();

  return address ? (
    <>
      <WithdrawModal open={openWithdraw} setOpen={setOpenWithdraw} />
      <div className=" md:flex-shrink-0 md:flex md:items-center">
        {/* Profile dropdown */}
        <div className=" relative">
          <ButtonIcon
            variant="link"
            icon={<WalletIcon />}
            rounded="full"
            size="sm"
            onClick={() => setOpenPopup(true)}
          />
          <Transition.Root
            show={openPopup}
            as={Fragment}
            afterLeave={() => {
              if (withdrawButtonClicked) {
                setOpenWithdraw(true);
                setWithdrawButtonClicked(false);
              }
            }}
          >
            <Dialog
              as="div"
              className="relative z-10"
              onClose={setOpenPopup}
              style={{ zIndex: '999999' }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-secondary-700 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <motion.div
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 100, opacity: 0 }}
                    transition={{
                      duration: shouldReduceMotion ? 0 : 0.25,
                      ease: 'linear',
                    }}
                    className="pointer-events-none fixed inset-y-0 right-8 top-12 flex max-w-full pl-10"
                  >
                    <Transition.Child as={Fragment}>
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-sm">
                        <div className="flex flex-col  bg-white dark:bg-dark-800 py-6 shadow-2xl  rounded-[14px] ">
                          <div className="px-4 sm:px-6">
                            {address.name}
                            <div className="flex items-start justify-between">
                              <Dialog.Title>
                                <AvatarWithInfo
                                  // TODO
                                  name={userName}
                                  // userName="@oyshiu"
                                  active
                                  avatarSize="md"
                                  img={
                                    userMetadata?.avatar &&
                                    getIpfsUrl(userMetadata.avatar)
                                  }
                                  link={`/${address}`}
                                />
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md z-40 bg-white dark:bg-dark-800 dark:hover:text-primary-500 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2"
                                  onClick={() => setOpenPopup(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="relative mt-6 flex-1 px-4 sm:px-6">
                            {/* Replace with your content */}
                            <h1 className="text-sm leading-5 font-medium text-secondary-400">
                              Connected to
                            </h1>

                            <WalletInfo />

                            <div className="flex justify-around mt-8">
                              <div
                                className="text-center flex flex-col items-center group cursor-pointer"
                                onClick={() => {
                                  setOpenPopup(false);
                                  ReactGA.event({
                                    category: 'funds',
                                    action: 'click_deposit',
                                  });
                                  transak.init();
                                }}
                                aria-hidden
                              >
                                <CreditCardIcon
                                  className="h-16 w-16 bg-gray-100 dark:bg-dark-600 dark:text-white p-5 rounded-full mb-2 group-hover:bg-gray-200 dark:group-hover:bg-dark-700 transition-all"
                                  aria-hidden="true"
                                />
                                <span className="text-sm text-gray-500 dark:text-white group-hover:text-black dark:group-hover:text-dark-400 transition-all">
                                  Deposit
                                </span>
                              </div>
                              <button
                                type="button"
                                onClick={() => {
                                  setOpenPopup(false);
                                  ReactGA.event({
                                    category: 'funds',
                                    action: 'click_withdraw',
                                  });
                                  setWithdrawButtonClicked(true);
                                }}
                                className="text-center flex flex-col items-center group"
                              >
                                <PaperAirplaneIcon
                                  className="h-16 w-16 bg-gray-100 dark:bg-dark-600 dark:text-white p-5 rounded-full mb-2 group-hover:bg-gray-200 dark:group-hover:bg-dark-700 transition-all"
                                  aria-hidden="true"
                                />
                                <span className="text-sm text-gray-500 dark:text-white group-hover:text-black dark:group-hover:text-dark-400 transition-all">
                                  Withdraw
                                </span>
                              </button>
                            </div>
                            {/* eslint-disable-next-line */}

                            <div className="lg:mt-60 mt-48">
                              {' '}
                              <Button
                                type="button"
                                width="full"
                                variant="secondary"
                                onClick={() => logout()}
                                className="w-full mt-4 "
                                flex
                              >
                                Sign Out
                              </Button>
                            </div>

                            {/* /End replace */}
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </motion.div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </>
  ) : (
    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
      <LinkButton to="/login" underlined>
        Login/Signup
      </LinkButton>
    </div>
  );
}

export default SlideOverProfile;
