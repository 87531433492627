import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SpinnerProvider } from './context/SpinnerContext';
import { LoginProvider } from './context/LoginContext';
import { TransakProvider } from './context/TransakContext';
import { ConvertProvider } from './context/ConvertContext';
import { ThemeProvider } from './context/ThemeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // TODO: StrictMode removed because it causes the double render in DEV
  // and its causing trouble with the editor. See if we can do
  // something about it.
  <BrowserRouter>
    <ThemeProvider>
      <SpinnerProvider>
        <LoginProvider>
          <ConvertProvider>
            <TransakProvider>
              <App />
            </TransakProvider>
          </ConvertProvider>
        </LoginProvider>
      </SpinnerProvider>
    </ThemeProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
