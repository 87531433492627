import React, { useEffect, useRef } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ButtonIcon } from '../Button/Button';
import Input from '../Forms/Forms';
import Toggle from '../Toggle';

function UnlockedLink({ editUnlockable, deleteUnlockable, content }) {
  const titleRef = useRef(null);
  const fileRef = useRef(null);

  useEffect(() => {
    if (content?.error.title) {
      titleRef.current.focus();
    }
    if (content?.error.file) {
      fileRef.current.focus();
    }
  }, [content]);

  return (
    <div className="bg-gray-50 dark:bg-dark-800 rounded-md mb-3">
      <div className="bg-gray-100 dark:bg-dark-700 flex px-6 py-4 rounded-md">
        <div className="flex items-center w-10">
          <ButtonIcon
            icon={<TrashIcon />}
            variant="link"
            size="sm"
            onClick={() => deleteUnlockable(content.id)}
          />
        </div>
        <div className="flex justify-between w-full items-center">
          <div className="flex items-center w-full">
            <Input
              ref={fileRef}
              placeholder="https://link.com"
              value={content.file || ''}
              onChange={(e) => {
                editUnlockable(
                  {
                    file: e.target.value,
                    error: {
                      file: false,
                    },
                  },
                  content.id,
                );
              }}
              error={content?.error.file}
              errorMessage="You need to write an url."
            />
          </div>
        </div>
      </div>
      <div className="p-6 space-y-6">
        <Input
          ref={titleRef}
          label="Title"
          placeholder="Your link title"
          value={content.title || ''}
          onChange={(e) => {
            editUnlockable(
              {
                title: e.target.value,
                error: {
                  title: false,
                },
              },
              content.id,
            );
          }}
          error={content?.error.title}
          errorMessage="You need to write a title."
        />
        <Toggle
          label="Gated"
          description="Only owners can access it."
          enabled={content.isPrivate}
          setEnabled={() =>
            editUnlockable({ isPrivate: !content.isPrivate }, content.id)
          }
        />
      </div>
    </div>
  );
}

export default UnlockedLink;
