import CardQuantity from '../Icons/CardQuantity';
import Link from '../Link';
import { getIpfsUrl } from '../../services/ipfsService';
import Avatar from '../Avatar';
import ItemInfo from '../ItemInfo/ItemInfo';
import { TYPE_STORY } from '../../utils/constants';
import { ItemCover } from './GridItem';
import Heading from '../Typography/Heading';
import MarkdownRenderer from '../MarkdownRenderer';

function GridItemExtended({ data }) {
  return (
    <Link
      to={`/${data?.type || TYPE_STORY}/${data.tokenId}`}
      key={data.tokenId}
    >
      <div className="aspect-[4/6] group relative rounded-2xl safari-mask-fix ">
        <div>
          <ItemCover image={getIpfsUrl(data.image)} type={data.type} />
        </div>
        <div>
          {/* Quantity and Creator */}
          <div className="absolute top-0 left-0 right-0 pt-2 px-3 flex justify-between w-full z-20 items-center">
            <ItemInfo
              type={data?.type || TYPE_STORY}
              gated={data.isPrivate}
              duration={data.readingTime}
              onCard
            />
            <div className="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
              <div className="h-8 w-8 text-white flex-col text-xs items-center hidden lg:flex">
                <CardQuantity className="h-6 w-6" />x{data.totalSupply}
              </div>
              <Link to={`/${data.createdBy}`} className="block hover:z-50">
                <Avatar
                  name={data.author}
                  img={data.avatar && getIpfsUrl(data.avatar)}
                />
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="absolute bottom-0 left-0 right-0 text-white p-10 z-10 ">
            <Heading size="5" className="line-clamp-2">
              {data.name}
            </Heading>
            <div className="max-h-[0px] group-hover:max-h-[300px] transition-all duration-[750ms] opacity-0 group-hover:opacity-100 ease-in-out">
              <div className="text-sm overflow-hidden max-h-[104px]">
                <MarkdownRenderer>{data.description}</MarkdownRenderer>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 overflow-hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="w-full h-[400px] scale-150 blur-xl z-0"
            >
              <path
                fill={data.color}
                d="M579.802 103.209C45.737 363.559-216.769 142.89-281.264.012l-57.143 29.072L170.461 1029.31l737.793-375.358c-39.494-99.104-160.475-348-328.452-550.743Z"
              />
            </svg>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default GridItemExtended;
