import { utils } from 'ethers';

export default function PriceFormatted({ price, fractionDigits = 5 }) {
  const priceFormatted = Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    style: 'decimal',
    maximumFractionDigits: fractionDigits,
  }).format(utils.formatEther(price));

  return `${priceFormatted} MATIC`;
}
