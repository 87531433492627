import React from 'react';

function DividersText({ children }) {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300 dark:border-dark-700" />
      </div>
      <div className="relative flex justify-start">
        <span className="bg-white dark:bg-dark-900 pr-3 text-lg font-medium text-gray-900 dark:text-dark-200">
          {children}
        </span>
      </div>
    </div>
  );
}

export default DividersText;
