import React from 'react';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import Avatar from '../Avatar/Avatar';
import ExternalLink from '../Icons/ExternalLink';
import Link from '../Link';

function AvatarWithInfo({
  img,
  name,
  userName,
  title,
  link,
  active,
  className,
  mintedDate,
  mintedLink,
  responsive,
  icon,
  avatarSize = 'lg',
  classes = 'flex items-center pl-1 py-1 pr-6 space-x-2 rounded-full',
}) {
  const children = (
    <div className={clsx('flex items-center', className)}>
      <div className="min-w-fit">
        {icon ? (
          <div
            className={clsx(
              'flex items-center justify-center rounded-full bg-gray-100 z-20 dark:bg-dark-700 dark:text-dark-300',
              { 'h-6 w-6': avatarSize === 'xs' },
              { 'h-8 w-8': avatarSize === 'sm' },
              { 'h-10 w-10': avatarSize === 'md' },
              { 'h-12 w-12': avatarSize === 'lg' },
              { 'h-16 w-16': avatarSize === 'xl' },
              { 'h-20 w-20 lg:h-32 lg:w-32': avatarSize === '2xl' },
            )}
          >
            {icon}
          </div>
        ) : (
          <Avatar
            img={img}
            name={name}
            size={avatarSize}
            className="rounded-full"
          />
        )}
      </div>
      <div
        className={clsx('flex flex-col justify-center ml-2 xs:ml-3', {
          'hidden md:flex': responsive === true,
        })}
      >
        <span className="uppercase font-semibold text-[10px] text-gray-600 dark:text-dark-500">
          {title}
        </span>
        <span
          className={clsx(
            'text-xs xs:text-sm text-black line-clamp-1 dark:text-dark-200',
            {
              'hidden md:flex': responsive === true,
            },
          )}
        >
          {name}
        </span>
        <span
          className={clsx(
            'text-[9px] xs:text-xs text-gray-600 dark:text-dark-500',
            {
              'hidden md:flex': responsive === true,
            },
          )}
        >
          {userName}
        </span>
        <span className="text-xs text-gray-600 dark:text-dark-500">
          {mintedDate && (
            <Link
              href={mintedLink}
              target="_blank"
              className="flex hover:text-primary-600"
            >
              Minted on {DateTime.fromISO(mintedDate).toFormat('dd/MM/yyyy')}
              {/* External link Icon */}
              <ExternalLink className="h-4 w-4 ml-2" />
            </Link>
          )}
        </span>
      </div>
    </div>
  );

  return link ? (
    <Link
      to={link}
      className={clsx(
        'hover:bg-gray-100 dark:bg-transparent dark:hover:bg-dark-800 transition-all flex max-w-fit pr-4 items-center rounded-full pl-1 py-1 space-x-2',
        {
          'pr-6': !responsive,
          'bg-gray-100': active === true,
          'pr-0 pl-0 !py-0 md:pr-6 md:!py-1 md:pl-1 space-x-0 md:space-x-2':
            responsive === true,
        },
      )}
    >
      {children}
    </Link>
  ) : (
    <div
      className={clsx(classes, {
        'pr-0 pl-0 py-0 md:pr-6': responsive === true,
      })}
    >
      {children}
    </div>
  );
}

export default AvatarWithInfo;
