import { useState, useEffect, useRef } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
// eslint-disable-next-line camelcase
import codes, { by639_1, by639_2T } from 'iso-language-codes';
import { toast } from 'react-hot-toast';
import { ButtonIcon } from '../Button/Button';
import Input from '../Forms/Forms';
import { CONTENT_TYPE_EPUB, MB_IN_B } from '../../utils/constants';
import { isFileTooLarge } from '../../utils/utils';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import { ListBox } from '../Select/Select';
import Toggle from '../Toggle';

function UnlockedEpub({ editUnlockable, deleteUnlockable, content }) {
  const { showSpinner, hideSpinner } = useSpinner();
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: '',
    text: '',
  });
  const [imageUrl, setImageUrl] = useState('');
  const element = useRef(null);
  const inputRef = useRef(null);
  const titleRef = useRef(null);
  const authorRef = useRef(null);

  const parseEpub = async (epub) => {
    try {
      showSpinner('Getting epub info...');
      if (isFileTooLarge(epub.size, 'book')) {
        hideSpinner();
        return;
      }
      const formData = new FormData();
      formData.append('epub', epub);
      const [responseMetadata, responseCover] = await Promise.all([
        apiService.post('/epub/get-metadata', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        apiService.post('/epub/get-cover', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob',
        }),
      ]);

      setImageUrl(URL.createObjectURL(responseCover));
      // eslint-disable-next-line camelcase
      const codeLanguage = by639_2T[responseMetadata.languages];
      const data = {
        file: epub,
        title: epub.name.replace('.epub', ''),
        author: responseMetadata.author || '',
        isbn: responseMetadata.isbn || '',
        image: responseCover,
        fileName: epub.name,
        fileType: epub.type,
        size: epub.size,
        error: {
          author: false,
          title: false,
          file: false,
        },
      };
      if (codeLanguage) {
        const { name: text, iso639_1: value } = codeLanguage;
        setSelectedLanguage({ text, value });
        // eslint-disable-next-line camelcase
        data.language = by639_1[value]?.iso639_1;
      }
      editUnlockable(data, content.id);
    } catch (err) {
      toast.error('Error uploading epub');
      console.error(err);
    } finally {
      hideSpinner();
    }
  };

  const addFile = () => {
    const typesToFunction = {
      [CONTENT_TYPE_EPUB]: parseEpub,
    };
    let userFile;
    if (inputRef.current?.files?.length) {
      // eslint-disable-next-line prefer-destructuring
      userFile = inputRef.current.files[0];
    } else {
      return deleteUnlockable(content.id);
    }
    const func = typesToFunction[userFile.type];
    if (!func) {
      return toast.error('File type not supported');
    }
    func(userFile);
    return userFile;
  };

  const clean = () => {
    setSelectedLanguage({
      value: '',
      text: '',
    });
    URL.revokeObjectURL(imageUrl);
    setImageUrl('');
    deleteUnlockable(content.id);
  };

  useEffect(() => {
    const languages = codes
      .map((code) => ({
        value: code.iso639_1,
        text: code.name,
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1));
    setLanguagesOptions(languages);
  }, []);

  useEffect(() => {
    if (content.error.title) {
      titleRef.current.focus();
    }
    if (content?.error.file) {
      titleRef.current.focus();
    }
    if (content.error.author) {
      authorRef.current.focus();
    }
  }, [content]);

  useEffect(() => {
    if (content.file) {
      element.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [content.file]);

  useEffect(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  return (
    <div ref={element} className="bg-gray-50 dark:bg-dark-800 rounded-md">
      {/* state: noFile */}
      {!content.file ? (
        <input
          ref={inputRef}
          onInput={addFile}
          id="epub-upload"
          name="epub-upload"
          type="file"
          className="sr-only"
          accept={CONTENT_TYPE_EPUB}
        />
      ) : (
        <div>
          <div className="bg-gray-100 dark:bg-dark-700 flex px-6 py-4 rounded-md dark:text-white">
            <div className="flex items-center w-10">
              <ButtonIcon
                icon={<TrashIcon />}
                variant="link"
                size="sm"
                onClick={clean}
              />
            </div>
            {/* File type section */}
            <div className="flex justify-between w-full items-center">
              <div className="flex items-center">
                <div className="h-[68px] w-[68px] flex justify-center items-center mr-4">
                  <img src={imageUrl} alt="" className="h-full rounded-md" />
                </div>
                <div className="flex flex-col">
                  <span className="text-md">{content.fileName}</span>
                  <span className="text-gray-600 dark:text-dark-400 text-xs">
                    {' '}
                    {Math.round(content.size / MB_IN_B)}MB
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="p-6 space-y-6">
            <Input
              ref={titleRef}
              label="Title"
              placeholder="File"
              value={content.title}
              onChange={(e) => {
                editUnlockable(
                  {
                    title: e.target.value,
                    error: {
                      title: false,
                    },
                  },
                  content.id,
                );
              }}
              error={content.error.title}
              errorMessage="You need to write a title."
            />
            <Input
              ref={authorRef}
              label="Author"
              placeholder="Author"
              value={content.author}
              onChange={(e) => {
                editUnlockable(
                  {
                    author: e.target.value,
                    error: {
                      author: false,
                    },
                  },
                  content.id,
                );
              }}
              error={content.error.author}
              errorMessage="You need to write an author name."
            />
            <ListBox
              label="Language"
              options={languagesOptions}
              selectedOption={selectedLanguage}
              setSelectedOption={(e) => {
                setSelectedLanguage(e);
                editUnlockable(
                  {
                    // eslint-disable-next-line camelcase
                    language: by639_1[e.value]?.iso639_1,
                  },
                  content.id,
                );
              }}
              placeholder="Language..."
            />
            <Input
              label="ISBN"
              placeholder="000-0-00-000000-0"
              value={content.isbn}
              onChange={(e) =>
                editUnlockable({ isbn: e.target.value }, content.id)
              }
            />
            <Toggle
              label="Gated"
              description="Only owners can access it."
              enabled={content.isPrivate}
              setEnabled={() =>
                editUnlockable({ isPrivate: !content.isPrivate }, content.id)
              }
            />
            <Toggle
              label="Allow download"
              description="Owners can download the file."
              enabled={content.allowDownload}
              setEnabled={() =>
                editUnlockable(
                  { allowDownload: !content.allowDownload },
                  content.id,
                )
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default UnlockedEpub;
