import { useContext, useEffect, useState } from 'react';
import LoginContext from '../../context/LoginContext';
import PolygonIcon from '../Icons/PolygonIcon';
import PriceConversion from '../PriceConversion';
import ButtonUserInfo from '../Header/ButtonUserInfo';
import PriceFormatted from '../PriceFormatted';

function WalletInfo() {
  const { ethersProvider, address } = useContext(LoginContext);
  const [balance, setBalance] = useState(0);

  const getBalance = async () => {
    const addressBalance = await ethersProvider.getBalance(address);
    setBalance(addressBalance);
  };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <div className="p-2 mt-3 rounded-lg border border-secondary-50 dark:border-dark-600 sm:p-3">
      <div className="flex items-center justify-between flex-wrap">
        <div className="w-0 flex-1 flex items-center">
          <span className="flex p-2 rounded-lg">
            <PolygonIcon className="w-6" />
          </span>
          <div className="ml-3">
            <h4 className="text-xs leading-4 font-normal text-secondary-400">
              Polygon
            </h4>
            <ButtonUserInfo accounts={address} text={address} />
          </div>
        </div>
        <div className="order-3 ">
          <p className="first-letter:text-lg first-letter:text-secondary-500 dark:first-letter:text-dark-400 text-2xl text-right text-secondary-800 dark:text-dark-100">
            <PriceConversion price={balance} />
          </p>
          <p className="text-gray-500 text-xs">
            <PriceFormatted price={balance} fractionDigits={2} />
          </p>
        </div>
      </div>
    </div>
  );
}

export default WalletInfo;
