import { Fragment } from 'react';
import { ShareIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { getIpfsUrl } from '../../services/ipfsService';
import Avatar from '../Avatar';
import CardQuantity from '../Icons/CardQuantity';
import Link from '../Link';
import { ItemCover } from '../GridStories/GridItem';
import { ButtonLink } from '../Button/Button';
import { capitalize } from '../../utils/utils';

function ModalBuyStorySuccessfully({
  openModalSuccess,
  setOpenModalSuccess,
  storyResponse,
}) {
  return (
    <Transition.Root show={openModalSuccess} as={Fragment}>
      <div
        className={clsx('relative z-50', {
          hidden: !openModalSuccess,
        })}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-90 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="min-h-full text-center sm:p-0 flex items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="min-h-full">
                <div className="flex justify-center flex-col mb-4">
                  {/* Card Image */}

                  <h1 className="text-3xl font-bold leading-10 text-center text-white mb-6">
                    🎉{' '}
                    <span className="capitalize">
                      {capitalize(storyResponse.type)}
                    </span>{' '}
                    collected!
                  </h1>
                  <Link
                    onClick={() => setOpenModalSuccess(false)}
                    to={`/${storyResponse.type}/${storyResponse.tokenId}`}
                  >
                    <section className="relative bg-no-repeat bg-contain rounded-xl overflow-hidden safari-mask-fix border-4 w-fit mx-auto hover:rotate-3 transition duration-500 hover:scale-95">
                      {/* Card Image */}

                      <ItemCover
                        image={getIpfsUrl(storyResponse.image)}
                        type={storyResponse.type}
                        className="max-h-[400px]"
                      />
                      <div className="absolute top-3 right-3 flex space-x-2 z-20 items-center">
                        {/* Total stories counter */}
                        <div className="h-8 w-8 text-white flex flex-col text-xs items-center">
                          <CardQuantity className="h-6 w-6" />x
                          {storyResponse.totalSupply}
                        </div>
                        {/* Avatar */}
                        <Avatar name={storyResponse.author} />
                      </div>
                      {/* Dark Gradient Overlay */}
                      <div className="bg-gradient-to-t from-black to-transparent h-48 w-full absolute bottom-0 z-10" />
                      {/* Title and Audio Preview */}
                      <div className="absolute bottom-0 w-full px-4 pb-6 md:px-6 md:pb-8 z-10 space-y-2">
                        <h2 className="text-xs sm:text-lg text-white font-semibold break-words text-left">
                          {storyResponse.name}
                        </h2>
                      </div>
                    </section>
                  </Link>
                </div>

                {/* TODO: Share button temporary hidden until we have the share functionality implemented */}
                <div className="flex flex-col space-y-4 mt-4 justify-center hidden">
                  {/* See story button */}
                  <Link
                    reload
                    to={`/${storyResponse.type}/${storyResponse.tokenId}`}
                    className="inline-flex items-center mx-16 px-4 py-4 border border-transparent shadow-sm text-[16px] font-medium rounded-lg text-black bg-primary-500 hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 justify-center"
                  >
                    <ShareIcon className="w-5 h-5 mr-3" /> Share
                  </Link>
                </div>

                <div className="flex mt-4 justify-center">
                  {/* See story button */}
                  <ButtonLink
                    size="md"
                    width="full"
                    onClick={() => setOpenModalSuccess(false)}
                    to={`/${storyResponse.type}/${storyResponse.tokenId}`}
                  >
                    See {storyResponse.type}
                  </ButtonLink>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition.Root>
  );
}

export default ModalBuyStorySuccessfully;
