/* eslint-disable */
import { utils, Signer, ethers, BigNumber } from 'ethers';

const recieve = '0xdFfbd32eaE63d140d955614a82FeF3A0d8E1F72c';
const BYTES4_ZERO = '0x00000000';
function getErc20Hash(tokenAddress, amount) {
  const bytes4 = '0xf47261b0'; // bytes4(keccak256("ERC20Token(address)"))
  const hash = ethers.utils.defaultAbiCoder.encode(
    ['address', 'uint256'],
    [tokenAddress, amount],
  );
  return bytes4 + hash.substring(2);
}

function getErc20Hast_zero(tokenAddress, amount) {
  const bytes4 = BYTES4_ZERO; // bytes4(keccak256("ERC20Token(address)"))
  const hast = ethers.utils.defaultAbiCoder.encode(
    ['address', 'uint256'],
    [tokenAddress, amount],
  );
  return bytes4 + hast.substring(2);
}

function getErc1155Hash(tokenAddress, ids, amount) {
  const bytes4 = '0xa7cb5fb7'; // bytes4(keccak256("ERC1155Assets(address,uint256[],uint256[],bytes)"));
  // console.log(tokenAddress,"tokenAddress");
  const hash = ethers.utils.defaultAbiCoder.encode(
    ['address', 'uint256[]', 'uint256[]', 'bytes'],
    [tokenAddress, ids, amount, ethers.utils.randomBytes(10)],
  );
  return bytes4 + hash.substring(2);
}

function getDomain(_chainId, _verifyingContract) {
  const domain = {
    name: 'exchange',
    version: '0.1.1',
    chainId: _chainId,
    verifyingContract: _verifyingContract,
  };
  return domain;
}

function getTypeSignature() {
  const type = {
    Order: [
      { name: 'makerAddress', type: 'address' },
      { name: 'takerAddress', type: 'address' },
      { name: 'feeRecipientAddress', type: 'address' },
      { name: 'senderAddress', type: 'address' },
      { name: 'makerAssetAmount', type: 'uint256' },
      { name: 'takerAssetAmount', type: 'uint256' },
      { name: 'makerFee', type: 'uint256' },
      { name: 'takerFee', type: 'uint256' },
      { name: 'expirationTimeSeconds', type: 'uint256' },
      { name: 'salt', type: 'uint256' },
      { name: 'makerAssetData', type: 'bytes' },
      { name: 'takerAssetData', type: 'bytes' },
      { name: 'makerFeeAssetData', type: 'bytes' },
      { name: 'takerFeeAssetData', type: 'bytes' },
      //  ;           // Address that created the order.
      // address takerAddress;           // Address that is allowed to fill the order. If set to 0, any address is allowed to fill the order.
      // address feeRecipientAddress;    // Address that will recieve fees when order is filled.
      // address senderAddress;          // Address that is allowed to call Exchange contract methods that affect this order. If set to 0, any address is allowed to call these methods.
      // uint256 makerAssetAmount;       // Amount of makerAsset being offered by maker. Must be greater than 0.
      // uint256 takerAssetAmount;       // Amount of takerAsset being bid on by maker. Must be greater than 0.
      // uint256 makerFee;               // Fee paid to feeRecipient by maker when order is filled.
      // uint256 takerFee;               // Fee paid to feeRecipient by taker when order is filled.
      // uint256 expirationTimeSeconds;  // Timestamp in seconds at which order expires.
      // uint256 salt;                   // Arbitrary number to facilitate uniqueness of the order's hash.
      // bytes makerAssetData;           // Encoded data that can be decoded by a specified proxy contract when transferring makerAsset. The leading bytes4 references the id of the asset proxy.
      // bytes takerAssetData;           // Encoded data that can be decoded by a specified proxy contract when transferring takerAsset. The leading bytes4 references the id of the asset proxy.
      // bytes makerFeeAssetData;        // Encoded data that can be decoded by a specified proxy contract when transferring makerFeeAsset. The leading bytes4 references the id of the asset proxy.
      // bytes takerFeeAssetData;
    ],
  };
  return type;
}

function leftOrder(
  makerAddress,
  makerAssetAmount,
  takerAssetAmount,
  expirationTimeSeconds,
  makerAssetData,
  ids,
  amount,
) {
  return {
    makerAddress,
    takerAddress: ethers.constants.AddressZero,
    feeRecipientAddress: recieve,
    senderAddress: ethers.constants.AddressZero,
    makerAssetAmount,
    takerAssetAmount: utils.parseEther(takerAssetAmount).toString(),
    makerFee: utils.parseEther('0.025').toString(),
    takerFee: '0',
    expirationTimeSeconds,
    salt: parseInt(Math.random() * 10 ** 3),
    makerAssetData: getErc1155Hash(makerAssetData, ids, amount),
    takerAssetData: getErc20Hast_zero(makerAssetData, makerAssetAmount),
    makerFeeAssetData: getErc20Hast_zero(makerAssetData, 0),
    takerFeeAssetData: getErc20Hast_zero(makerAssetData, 0),
  };
}

function rightOrder(
  makerAddress,
  makerAssetAmount,
  takerAssetAmount,
  expirationTimeSeconds,
  makerAssetData,
  ids,
  amount,
) {
  return {
    makerAddress,
    takerAddress: ethers.constants.AddressZero,
    feeRecipientAddress: recieve,
    senderAddress:  ethers.constants.AddressZero,
    makerAssetAmount: utils.parseEther(makerAssetAmount).toString(),
    takerAssetAmount,
    makerFee: '0',
    takerFee: '0',
    expirationTimeSeconds,
    salt: parseInt(Math.random() * 10 ** 3).toString(),
    makerAssetData: getErc20Hast_zero(makerAssetData, makerAssetAmount),
    takerAssetData: getErc1155Hash(makerAssetData, ids, amount),
    makerFeeAssetData: getErc20Hast_zero(makerAssetData, BigNumber.from(makerAssetAmount).mul(25).div(10000).toString()),
    takerFeeAssetData: getErc20Hast_zero(makerAssetData, 0),
  };
}

async function getSignsData(
  ethersProvider,
  makerAddress,  
  makerAssetAmount,
  takerAssetAmount,  
  expirationTimeSeconds,  
  makerAssetData,  
  ids,
  amount,
  isLeft,
) {
  //   {
  //     "name": "maticmum",
  //     "chainId": 80001,
  //     "ensAddress": null,
  //     "_defaultProvider": null
  // }
  // console.log(ethersProvider.);

  const chainId = ethersProvider._network.chainId;

  const domain = getDomain(
    chainId,
    process.env.REACT_APP_MATIC_CONTRACT_EXCHANGE,
  );
  const type = getTypeSignature();

  const order = orderType(
    makerAddress,    
    makerAssetAmount,
    takerAssetAmount,    
    expirationTimeSeconds,    
    process.env.REACT_APP_MATIC_CONTRACT_NFT,    
    ids,
    amount,
    isLeft,
  );

  const signer = ethersProvider.getSigner();

  const tx = await signer._signTypedData(domain, type, order);
  const order_ = order;
  return { domain, type, order_, tx };
}
function orderType(
  makerAddress,  
  makerAssetAmount,
  takerAssetAmount,
  expirationTimeSeconds,  
  makerAssetData,  
  ids,
  amount,
  isLeft,
) {
  if (isLeft) {
    return leftOrder(
      makerAddress,
      makerAssetAmount,
      takerAssetAmount,
      expirationTimeSeconds,
      makerAssetData,
      ids,
      amount,
    );
  } else {
    return rightOrder(
      makerAddress,
      makerAssetAmount,
      takerAssetAmount,
      expirationTimeSeconds,
      makerAssetData,
      ids,
      amount,
    );
  }
}

async function signsOrder(
  ethersProvider,
  order
  
) {
  const chainId = ethersProvider._network.chainId;

  const domain = getDomain(
    chainId,
    process.env.REACT_APP_MATIC_CONTRACT_EXCHANGE,
  );
  const type = getTypeSignature();

  const signer = ethersProvider.getSigner();

  const tx = await signer._signTypedData(domain, type, order);  
  console.log(tx,"tx");
  return { domain, type, tx };
}




export { getDomain, getTypeSignature, getSignsData,signsOrder };
