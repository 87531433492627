import axios from 'axios';
import {
  QUERY_PARAMETER_INVALID_TOKEN,
  LOCAL_STORAGE_JWT,
} from '../utils/constants';

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

apiService.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${localStorage.getItem(
      LOCAL_STORAGE_JWT,
    )}`;
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiService.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (
      error.response.status === 403 &&
      error.response.data?.error === 'Invalid token'
    ) {
      return window.location.assign(`/?${QUERY_PARAMETER_INVALID_TOKEN}`);
    }
    return Promise.reject(error);
  },
);

export default apiService;
