import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import { Tab } from '@headlessui/react';
import { useParams } from 'react-router-dom';
import { BookOpenIcon, SparklesIcon } from '@heroicons/react/24/outline';
import GridStories from '../../components/GridStories';
import apiService from '../../services/apiService';
import { useSpinner } from '../../context/SpinnerContext';
import GridCollections from '../../components/GridCollections/GridCollections';
import Profile from './Profile';
import Header from '../../components/Header/Header';
import LoginContext from '../../context/LoginContext';
import StoryIcon from '../../assets/icons/StoryIcon';
import TabsReadl from '../../components/Tab/Tab';
import CollectionIcon from '../../assets/icons/CollectionIcon';
import Container from '../../components/Containers';
import { TYPE_BOOK, TYPE_STORY } from '../../utils/constants';

function Bookshelf() {
  const FILTER_COLLECTED = 'collected';
  const FILTER_CREATED = 'created';
  const FILTER_ALL = 'all';

  const filtersData = [
    {
      filter: FILTER_ALL,
      text: 'All',
    },
    {
      filter: FILTER_COLLECTED,
      text: 'Collected',
    },
    {
      filter: FILTER_CREATED,
      text: 'Created',
    },
  ];

  const { showSpinner, hideSpinner } = useSpinner();
  const { address } = useParams();
  const { address: userAddress } = useContext(LoginContext);
  const [itemsAll, setItemsAll] = useState([]);
  const [itemsCollected, setItemsCollected] = useState([]);
  const [itemsCreated, setItemsCreated] = useState([]);
  const [storiesAll, setStoriesAll] = useState([]);
  const [storiesCollected, setStoriesCollected] = useState([]);
  const [storiesCreated, setStoriesCreated] = useState([]);
  const [booksAll, setBooksAll] = useState([]);
  const [booksCollected, setBooksCollected] = useState([]);
  const [booksCreated, setBooksCreated] = useState([]);
  const [collectionsAll, setCollectionsAll] = useState([]);
  const [collectionsCollected, setCollectionsCollected] = useState([]);
  const [collectionsCreated, setCollectionsCreated] = useState([]);
  const [listingsFilter, setListingsFilter] = useState(FILTER_ALL);
  const [dropdownActions, setDropdownActions] = useState([]);

  const getStoriesAll = async () => {
    try {
      showSpinner();
      const storiesResponse = await apiService.get(`/story/all/${address}`);
      setItemsAll(storiesResponse);
      setStoriesAll(storiesResponse.filter((item) => item.type === TYPE_STORY));
      setBooksAll(storiesResponse.filter((item) => item.type === TYPE_BOOK));
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const getStoriesCollected = async () => {
    try {
      showSpinner();
      const storiesResponse = await apiService.get(
        `/story/collected/${address}`,
      );
      setItemsCollected(storiesResponse);
      setStoriesCollected(
        storiesResponse.filter((item) => item.type === TYPE_STORY),
      );
      setBooksCollected(
        storiesResponse.filter((item) => item.type === TYPE_BOOK),
      );
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const getStoriesCreated = async () => {
    try {
      showSpinner();
      const storiesResponse = await apiService.get(`/story/created/${address}`);
      setItemsCreated(storiesResponse);
      setStoriesCreated(
        storiesResponse.filter((item) => item.type === TYPE_STORY),
      );
      setBooksCreated(
        storiesResponse.filter((item) => item.type === TYPE_BOOK),
      );
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const getCollectionsCreated = async () => {
    try {
      showSpinner();
      const collectionsResponse = await apiService.get(
        `/collection/created/${address}`,
      );
      setCollectionsCreated(collectionsResponse);
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const getCollectionsCollected = async () => {
    try {
      showSpinner();
      const collectionsResponse = await apiService.get(
        `/collection/collected/${address}`,
      );
      setCollectionsCollected(collectionsResponse);
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const getCollectionsAll = async () => {
    try {
      showSpinner();
      const collectionsResponse = await apiService.get(
        `/collection/all/${address}`,
      );
      setCollectionsAll(collectionsResponse);
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const checkDropdownActions = () => {
    if (address === userAddress) {
      setDropdownActions([
        {
          name: 'Edit Profile',
          href: `/edit-profile/${address}`,
        },
      ]);
    }
  };

  useEffect(() => {
    getStoriesAll();
    getStoriesCreated();
    getStoriesCollected();
    getCollectionsAll();
    getCollectionsCollected();
    getCollectionsCreated();
  }, [address]);

  useEffect(() => {
    checkDropdownActions();
  }, [address, userAddress]);

  const tabs = [
    {
      id: 'All',
      title: 'All',
      icon: <SparklesIcon className="w-6 h-6 mr-2" />,
    },
    {
      id: 'Stories',
      title: 'Stories',
      icon: <StoryIcon className="w-6 h-6 mr-2" />,
    },
    {
      id: 'Books',
      title: 'Books',
      icon: <BookOpenIcon className="w-6 h-6 mr-2" />,
    },
    {
      id: 'Collections',
      title: 'Collections',
      icon: <CollectionIcon className="w-6 h-6 mr-2" />,
    },
  ];

  return (
    <>
      <Header dropdownActions={dropdownActions} />
      <Container margin="flat-mobile" spacing="none" className="mb-8">
        <Profile address={address} />
      </Container>
      <Container className="pt-4">
        <Tab.Group>
          <Tab.List className="mb-6" aria-label="Tabs">
            <TabsReadl tabs={tabs} wrapperClasses="max-h-[44px] flex" />
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className="flex flex-initial flex-row mt-3 mb-8">
                {filtersData.map((data) => (
                  <div key={data.filter}>
                    <button
                      className={clsx(
                        'px-3 py-2 font-medium text-sm rounded-md',
                        data.filter === listingsFilter
                          ? 'bg-primary-500 text-secondary-900 px-3 py-2 font-medium text-sm rounded-md'
                          : 'text-gray-500 hover:text-gray-700',
                      )}
                      onClick={() => setListingsFilter(data.filter)}
                      type="button"
                    >
                      {data.text}
                    </button>
                  </div>
                ))}
              </div>
              {listingsFilter === FILTER_ALL && <GridStories view={itemsAll} />}
              {listingsFilter === FILTER_COLLECTED && (
                <GridStories view={itemsCollected} />
              )}
              {listingsFilter === FILTER_CREATED && (
                <GridStories view={itemsCreated} />
              )}
            </Tab.Panel>
            <Tab.Panel>
              <div className="flex flex-initial flex-row mt-3 mb-8">
                {filtersData.map((data) => (
                  <div className="flex items-center mx-2" key={data.filter}>
                    <button
                      className={clsx(
                        'px-3 py-2 font-medium text-sm rounded-md',
                        data.filter === listingsFilter
                          ? 'bg-primary-500 text-secondary-900 px-3 py-2 font-medium text-sm rounded-md'
                          : 'text-gray-500 hover:text-gray-700',
                      )}
                      onClick={() => setListingsFilter(data.filter)}
                      type="button"
                    >
                      {data.text}
                    </button>
                  </div>
                ))}
              </div>
              {listingsFilter === FILTER_ALL && (
                <GridStories view={storiesAll} />
              )}
              {listingsFilter === FILTER_COLLECTED && (
                <GridStories view={storiesCollected} />
              )}
              {listingsFilter === FILTER_CREATED && (
                <GridStories view={storiesCreated} />
              )}
            </Tab.Panel>
            <Tab.Panel>
              <div className="flex flex-initial flex-row mt-3 mb-8">
                {filtersData.map((data) => (
                  <div className="flex items-center mx-2" key={data.filter}>
                    <button
                      className={clsx(
                        'px-3 py-2 font-medium text-sm rounded-md',
                        data.filter === listingsFilter
                          ? 'bg-primary-500 text-secondary-900 px-3 py-2 font-medium text-sm rounded-md'
                          : 'text-gray-500 hover:text-gray-700',
                      )}
                      onClick={() => setListingsFilter(data.filter)}
                      type="button"
                    >
                      {data.text}
                    </button>
                  </div>
                ))}
              </div>
              {listingsFilter === FILTER_ALL && <GridStories view={booksAll} />}
              {listingsFilter === FILTER_COLLECTED && (
                <GridStories view={booksCollected} />
              )}
              {listingsFilter === FILTER_CREATED && (
                <GridStories view={booksCreated} />
              )}
            </Tab.Panel>
            <Tab.Panel>
              <div className="flex flex-initial flex-row mt-3 mb-2">
                {filtersData.map((data) => (
                  <div className="flex items-center mx-2" key={data.filter}>
                    <button
                      className={clsx(
                        'px-3 py-2 font-medium text-sm rounded-md',
                        data.filter === listingsFilter
                          ? 'bg-primary-500 text-secondary-900 px-3 py-2 font-medium text-sm rounded-md'
                          : 'text-gray-500 hover:text-gray-700',
                      )}
                      onClick={() => setListingsFilter(data.filter)}
                      type="button"
                    >
                      {data.text}
                    </button>
                  </div>
                ))}
              </div>
              {listingsFilter === FILTER_ALL && (
                <GridCollections view={collectionsAll} />
              )}
              {listingsFilter === FILTER_COLLECTED && (
                <GridCollections view={collectionsCollected} />
              )}
              {listingsFilter === FILTER_CREATED && (
                <GridCollections view={collectionsCreated} />
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Container>
    </>
  );
}

export default Bookshelf;
