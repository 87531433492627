import React, { useEffect, useState, useContext } from 'react';
import toast from 'react-hot-toast';
import { RWebShare } from 'react-web-share';
import { ShareIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';
import ButtonUserInfo from '../../components/Header/ButtonUserInfo';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import Avatar from '../../components/Avatar';
import { getIpfsUrl } from '../../services/ipfsService';
import PolygonIcon from '../../components/Icons/PolygonIcon';
import Link from '../../components/Link';
import LoginContext from '../../context/LoginContext';
import Metrics from '../../components/Metric/Metric';
import SocialLinks from '../../components/SocialLinks';
import CollapseText from '../../components/CollapseText';

export function AvatarProfile({ user, collection, className }) {
  return (
    <div className="w-20 h-20 lg:w-32 lg:h-32 rounded-full overflow-hidden flex items-center justify-center">
      {user?.avatar || user?.logo ? (
        <img
          className={`${className} object-cover`}
          src={collection ? getIpfsUrl(user?.logo) : getIpfsUrl(user?.avatar)}
          alt={user?.name}
        />
      ) : (
        <Avatar name={user?.name} className={className} size="2xl" />
      )}
    </div>
  );
}

function UserProfileInfo({ user, address }) {
  const { address: loginAddress } = useContext(LoginContext);

  return (
    <div>
      <div className="flex items-center md:items-center space-x-6 px-4">
        {/* Avatar */}
        <AvatarProfile user={user} className="w-full h-full" />
        {/* Info */}
        <div className="flex flex-col justify-center">
          <div className="flex gap-4">
            <SocialLinks items={user} />{' '}
            {address === loginAddress && (
              <div className="text-right hidden lg:flex">
                <Link
                  // eslint-disable-next-line no-underscore-dangle
                  to={`/edit-profile/${user.address}`}
                >
                  <div className="inline-flex space-x-2 items-center justify-center h-9 py-2 pl-2.5 pr-3 bg-gray-100 dark:bg-dark-700 shadow rounded-md group transition duration-300">
                    <PencilIcon className="w-4 h-full text-secondary-400 dark:text-dark-300 dark:group-hover:text-primary-500" />
                    <p className="text-sm font-medium leading-none text-gray-900 dark:text-dark-300 dark:group-hover:text-primary-500">
                      Edit Profile
                    </p>
                  </div>
                </Link>
              </div>
            )}
          </div>
          <h3 className="text-lg sm:text-2xl xl:text-3xl font-bold flex flex-col items-baseline mt-2">
            <span className="pr-1 leading-tight dark:text-white">
              {user?.name}
              <span className="hidden xs:inline-flex">&apos;s&nbsp;</span>
              <span className="text-gray-400 hidden xs:inline-flex">
                Bookshelf
              </span>
            </span>
          </h3>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-3">
            {user?.userName && (
              <span className="text-sm text-gray-500 font-semibold">
                @{user?.userName}
              </span>
            )}
            <div className="flex">
              <div className="cursor-pointer flex">
                <ButtonUserInfo accounts={user?.address} text={user?.address} />
              </div>
              <div>
                <Link
                  href={`https://polygonscan.com/address/${user?.address}`}
                  target="_blank"
                >
                  <PolygonIcon className="w-8 h-8 p-1.5 hover:bg-gray-100 dark:hover:bg-dark-700 rounded-md !text-gray-500 hover:!text-[#8247E5]" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CollapseText text={user.description} />
    </div>
  );
}

function Profile({ address }) {
  const { showSpinner, hideSpinner } = useSpinner();
  const [user, setUser] = useState();
  const [userMetrics, setUserMetrics] = useState([]);

  const getUser = async () => {
    try {
      showSpinner();
      const userResponse = await apiService.get(`/user/${address}`);
      setUser(userResponse);
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const getUserMetrics = async () => {
    try {
      const response = await apiService.get(`/user/metrics/${address}`);
      const metrics = Object.keys(response).map((key) => ({
        name: key.charAt(0).toUpperCase() + key.slice(1),
        value: response[key],
      }));
      setUserMetrics(metrics);
    } catch (err) {
      console.error(err);
      toast.error('Error retrieving user metrics');
    }
  };

  useEffect(() => {
    getUser();
    getUserMetrics();
  }, [address]);

  return (
    user && (
      <div>
        <div className="flex flex-col-reverse lg:flex-row justify-between w-full items-start">
          <div className="flex flex-col items-start px-4">
            <UserProfileInfo user={user} address={address} />
          </div>
          {/* Profile statistics */}
          <div className="flex flex-col justify-end w-full lg:w-auto">
            <div className='flex w-full lg:max-w-lg justify-between xl:justify-end flex-start mb-4 lg:mb-0 p-2 xs:p-4 bg-gray-50 dark:bg-dark-800 lg:dark:bg-transparent lg:bg-transparent rounded-md"'>
              <Metrics metrics={userMetrics} />
              <div>
                <RWebShare
                  data={{
                    text: `Check out ${user.name} profile on Readl Stories`,
                    url: `${window.location.origin}/${address}`,
                    title: 'Share this profile',
                  }}
                >
                  <span className="rounded-full overflow-hidden block shadow-lg hover:shadow-2xl transition-all">
                    <ShareIcon className="w-10 h-10 p-2 sm:w-12 sm:h-12 sm:p-2 lg:w-16 lg:h-16 lg:p-4 bg-white dark:bg-dark-700 dark:text-dark-100 dark:hover:bg-dark-500 dark:hover:text-white cursor-pointer transition duration-300" />
                  </span>
                </RWebShare>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default Profile;
