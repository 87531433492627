import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Contract, utils, constants } from 'ethers';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import ReactGA from 'react-ga4';
import { useSpinner } from '../../context/SpinnerContext';
import TransakContext from '../../context/TransakContext';
import LoginContext from '../../context/LoginContext';
import { MATIC_CONTRACT_NFT_ABI } from '../../utils/constants';
import { getIpfsUrl } from '../../services/ipfsService';
import WalletInfo from '../WalletInfo';
import PriceFormatted from '../PriceFormatted';
import PriceConversion from '../PriceConversion';
import CreatedOn from '../CreatedOn';
import ShowAddress from '../ShowAddress';
import { convertToHex, pathToPageName } from '../../utils/utils';
import apiService from '../../services/apiService';

function SendConfirmation({
  data,
  storyId,
  setOpen,
  story,
  callback,
  storyName,
}) {
  const { ethersProvider, address } = useContext(LoginContext);
  const { transak } = useContext(TransakContext);
  const { showSpinner, hideSpinner } = useSpinner();
  const location = useLocation();
  const { pathname } = location;
  const [userBalance, setUserBalance] = useState(constants.Zero);
  const [userHasEnoughFunds, setUserHasEnoughFunds] = useState(false);

  const getBalance = async () => {
    try {
      showSpinner();
      const balance = await ethersProvider.getSigner().getBalance();
      setUserBalance(balance);
      setUserHasEnoughFunds(balance.gt(utils.parseEther('0.001')));
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const transferNft = async () => {
    try {
      showSpinner('Please be patient.\nThis may take a couple of minutes.');
      const contract = new Contract(
        process.env.REACT_APP_MATIC_CONTRACT_NFT,
        MATIC_CONTRACT_NFT_ABI,
        ethersProvider.getSigner(),
      );
      const uriData = convertToHex(story.uri);
      const tx = await contract.safeTransferFrom(
        address,
        data.destinationWallet,
        storyId,
        data.quantity,
        uriData,
      );
      await tx.wait(2);
      await apiService.post('/user/mail/transfernft', {
        toAddress: data.destinationWallet,
        storyId,
        amount: data.quantity,
      });
      setOpen(false);
      // We don't put the hideSpinner in a finally block because the callback
      // function is displaying a spinner as well.
      hideSpinner();
      ReactGA.event({
        category: 'create',
        action: 'click_transfer',
        label: pathToPageName(pathname, storyName),
      });
      toast.success('Your story was sent successfully.');
      callback();
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
      hideSpinner();
    }
  };

  useEffect(() => {
    if (address) {
      getBalance();
    }
  }, [address]);

  return (
    <div>
      <div className="flex space-x-4 items-center">
        <div className="max-w-[64px] max-h-[100px] rounded-md overflow-hidden">
          <img alt="" src={getIpfsUrl(story.image)} />
        </div>
        <div>
          <h4>
            You are about to transfer{' '}
            <span className="font-semibold">{story.name}</span> by{' '}
            <span className="font-semibold">{story.author}</span>
          </h4>
          <div>
            <CreatedOn />
          </div>
        </div>
      </div>
      <div className="relative mt-6 flex-1">
        <h1 className="h-6 text-sm font-bold text-gray-900 leading-5">
          Connected to
        </h1>
        <WalletInfo />
        <h1 className="text-sm font-bold text-gray-900 leading-5 mt-3 mb-3">
          Transaction summary
        </h1>

        <ul className="text-sm mt-2 space-y-2">
          <li className="flex justify-between">
            <span className="text-secondary-400">Transfer to</span>
            <span>
              <ShowAddress address={data.destinationWallet} />
            </span>
          </li>
          <li className="flex justify-between">
            <span className="text-secondary-400">Quantity</span>
            <span>x1</span>
          </li>
          <li className="flex justify-between">
            <span className="text-gray-500">Your balance</span>
            <span
              className={clsx('', {
                'text-red-500': !userHasEnoughFunds,
                'text-primary-700': userHasEnoughFunds,
              })}
            >
              <span className="text-gray-500">
                {' '}
                <PriceFormatted price={userBalance} /> ·
              </span>{' '}
              <PriceConversion price={userBalance} />
            </span>
          </li>
          <li className="flex justify-between">
            <span className="text-gray-500">Transfer cost</span>
            <span>
              <span className="text-gray-500"> &gt;0.01 MATIC · </span>
              &gt;
              {Intl.NumberFormat('en-US', {
                notation: 'compact',
                compactDisplay: 'short',
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'narrowSymbol',
              }).format(0.01)}
            </span>
          </li>
        </ul>

        {/* eslint-disable-next-line */}
        {!userHasEnoughFunds ? (
          <>
            <div className="inline-flex items-center justify-center h-12 py-3 pl-3.5 pr-4 bg-red-50 rounded">
              <p className="text-xs leading-tight text-red-800">
                You don&apos;t have enough funds in your wallet
              </p>
            </div>
            <div className="flex flex-col space-y-4 mt-6 justify-center">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border-4 border-primary-500 shadow-sm text-base font-medium rounded-lg text-black bg-transparent-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 justify-center"
                onClick={() => transak.init()}
              >
                Fund my wallet
              </button>
            </div>
          </>
        ) : (
          <div className="flex flex-col space-y-4 mt-6 justify-center">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-lg text-black bg-primary-500 hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 justify-center"
              onClick={transferNft}
            >
              Transfer NFT
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SendConfirmation;
