import toast from 'react-hot-toast';
import { utils } from 'ethers';
import { MB_IN_B, TYPE_BOOK, TYPE_STORY } from './constants';

const BOOK_MAX_SIZE = 20;
const AUDIO_MAX_SIZE = 100;
const IMAGE_MAX_SIZE = 5;

export const isFileTooLarge = (size, type) => {
  const typeToSize = {
    image: IMAGE_MAX_SIZE,
    audio: AUDIO_MAX_SIZE,
    book: BOOK_MAX_SIZE,
  };
  if (size / MB_IN_B >= typeToSize[type]) {
    toast.error(
      'Your file is too large. Please compress it and upload it again',
    );
    return true;
  }
  return false;
};

export const capitalize = (text) =>
  text && text.charAt(0).toUpperCase() + text.slice(1);

export const pluralizeType = (type) => {
  switch (type) {
    case TYPE_STORY:
      return 'stories';
    case TYPE_BOOK:
      return 'books';
    default:
      return type;
  }
};

export function convertToHex(str) {
  let hex = '';
  for (let i = 0; i < str.length; i += 1) {
    hex += `${str.charCodeAt(i).toString(16)}`;
  }
  return `0x${hex}`;
}

export function pathToPageName(str, storyName) {
  const pathString = str.replace('/', '')?.split('/').join('_') || 'landing';
  let result = pathString;
  if (storyName) {
    const name = storyName.split(' ').join('_');
    result = `${pathString}_${name}`;
  }
  return result;
}

export function parseValue(valueInEther) {
  return Number(utils.formatEther(valueInEther));
}

export function formatReadingTime(num) {
  if (num > 540) {
    return '+10h';
  }
  if (num > 490) {
    return '9h - 10h';
  }
  if (num > 450) {
    return '8h - 9h';
  }
  if (num > 390) {
    return '7h - 8h';
  }
  if (num > 330) {
    return '6h - 7h';
  }
  if (num > 270) {
    return '5h - 6h';
  }
  if (num > 210) {
    return '4h - 5h';
  }
  if (num > 180) {
    return '3h - 4h';
  }
  if (num > 150) {
    return '2.5h';
  }
  if (num > 120) {
    return '2h';
  }
  if (num > 90) {
    return '1.5h';
  }

  if (num > 60) {
    return '1h';
  }

  if (num > 1) {
    return `${num} min`;
  }
  return '1 min';
}
