import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ButtonLink } from '../Button/Button';

function Modal({
  open,
  setOpen,
  background = true,
  allowClickToClose = false,
  children,
  size = 'md',
  flat,
  closeButton,
  className,
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={allowClickToClose ? setOpen : () => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-90 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-50 inset-0 overflow-y-auto">
          {closeButton && (
            <ButtonLink
              onClick={() => setOpen(false)}
              icon={<XMarkIcon />}
              variant="link-white"
              size="xs"
              rounded="full"
              className="fixed left-2 top-2 md:left-4 md:top-4 z-50"
            />
          )}
          <div className="flex items-center sm:items-center justify-center min-h-full text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  'inline-block align-center overflow-hidden rounded-lg text-left shadow-xl transform transition-all sm:align-middle',
                  {
                    'mt-12': closeButton,
                    'p-10 m-8': !flat,
                    'bg-white dark:bg-dark-900': background === true,
                    'max-w-md md:max-w-sm md:w-full': size === 'sm',
                    'max-w-md md:max-w-md md:w-full': size === 'md',
                    'max-w-md md:max-w-lg md:w-full': size === 'lg',
                    'max-w-md md:max-w-xl md:w-full': size === 'xl',
                    'max-w-md md:max-w-2xl md:w-full': size === '2xl',
                    'max-w-md md:max-w-3xl md:w-full': size === '3xl',
                  },
                  className,
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
