import React, { useEffect, useState, useRef } from 'react';
import { ReactReader, ReactReaderStyle } from 'react-reader';
import toast from 'react-hot-toast';
import WebFont from 'webfontloader';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  READER_THEMES,
  READER_FONTS,
  READER_DEFAULT_CONFIG,
} from '../../utils/constants';
import PopupMenu from './PopupMenu';
import MenuReaderSettings from '../../assets/icons/MenuReaderSettings';
import apiService from '../../services/apiService';

export default function Reader({
  tokenId,
  epub,
  title,
  // TODO: Check if we need this loadingCallback prop
  loadingCallback,
  onClose,
}) {
  const [bookLocation, setBookLocation] = useState();
  const [themes, setThemes] = useState();
  const [theme, setTheme] = useState();
  const [fontFamily, setFontFamily] = useState();
  const [fontSize, setFontSize] = useState();
  const [lineHeight, setLineHeight] = useState();
  const [initialSettingsLoaded, setInitialSettingsLoaded] = useState(false);
  const [showMenuSettings, setShowMenuSettings] = useState(false);
  const [showSettingsButton, setShowSettingsButton] = useState(true);
  const renditionRef = useRef(null);

  const saveSettings = async () => {
    if (tokenId) {
      try {
        await apiService.post('/reader-settings/reader', {
          theme,
          fontFamily,
          fontSize,
          lineHeight,
        });
      } catch (err) {
        console.error(err);
        toast.error(`Error saving settings: ${err.message}`);
      }
    }
  };

  const saveProgress = async (newLocation) => {
    if (tokenId) {
      try {
        await apiService.post('/reader-settings/progress', {
          tokenId,
          location: newLocation,
        });
      } catch (err) {
        console.error(err);
        toast.error(err.message);
      }
    }
  };

  const loadFonts = () => {
    WebFont.load({
      google: {
        families: READER_FONTS.filter((font) => font.provider === 'google').map(
          (font) => font.family,
        ),
      },
      custom: {
        families: READER_FONTS.filter((font) => font.provider === 'custom').map(
          (font) => font.family,
        ),
        urls: READER_FONTS.filter((font) => font.provider === 'custom').map(
          (font) => font.url,
        ),
      },
      context: document.querySelector('iframe[id^=epubjs-view-').contentWindow,
      active: loadingCallback,
    });
  };

  const loadThemes = () => {
    const readerThemes = {};
    Object.keys(READER_THEMES).forEach((readerTheme) => {
      readerThemes[readerTheme] = {
        readerStyles: {
          ...ReactReaderStyle,
          readerArea: {
            ...ReactReaderStyle.readerArea,
            backgroundColor: READER_THEMES[readerTheme].backgroundColor,
          },
          titleArea: {
            ...ReactReaderStyle.titleArea,
            color: READER_THEMES[readerTheme].secondaryColor,
          },
          tocButtonBar: {
            ...ReactReaderStyle.tocButtonBar,
            background: READER_THEMES[readerTheme].secondaryColor,
          },
          tocButtonExpanded: {
            ...ReactReaderStyle.tocButtonExpanded,
            backgroundColor: READER_THEMES[readerTheme].backgroundColor,
          },
          tocArea: {
            ...ReactReaderStyle.tocArea,
            backgroundColor: READER_THEMES[readerTheme].backgroundColor,
          },
          tocAreaButton: {
            ...ReactReaderStyle.tocAreaButton,
            color: READER_THEMES[readerTheme].secondaryColor,
          },
          arrow: {
            ...ReactReaderStyle.arrow,
            color: READER_THEMES[readerTheme].secondaryColor,
          },
        },
        renditionStyles: {
          background: READER_THEMES[readerTheme].backgroundColor,
          color: READER_THEMES[readerTheme].fontColor,
        },
      };
    });
    setThemes(readerThemes);
  };

  const locationChanged = (epubcifi) => {
    setBookLocation(epubcifi);
    saveProgress(epubcifi);
  };

  const handleClickMenuSettings = () => {
    setShowMenuSettings(!showMenuSettings);
  };

  const getInitialConfiguration = async () => {
    try {
      let hasPreviousSettings = false;
      if (tokenId) {
        const response = await apiService.get(`/reader-settings/${tokenId}`);
        if (response) {
          const { reader, progress, fixedSettings } = response;
          const bookProgress = progress.find(
            (item) => item.tokenId === tokenId,
          );
          setBookLocation(bookProgress?.location || null);

          setTheme(reader?.theme || READER_DEFAULT_CONFIG.theme);
          setFontFamily(reader?.fontFamily || READER_DEFAULT_CONFIG.fontFamily);
          setFontSize(reader?.fontSize || READER_DEFAULT_CONFIG.fontSize);
          setLineHeight(reader?.lineHeight || READER_DEFAULT_CONFIG.lineHeight);

          setShowSettingsButton(!fixedSettings);

          hasPreviousSettings = true;
        }
      }
      if (!hasPreviousSettings) {
        setTheme(READER_DEFAULT_CONFIG.theme);
        setFontFamily(READER_DEFAULT_CONFIG.fontFamily);
        setFontSize(READER_DEFAULT_CONFIG.fontSize);
        setLineHeight(READER_DEFAULT_CONFIG.lineHeight);
      }
      setInitialSettingsLoaded(true);
    } catch (err) {
      console.error(err);
      toast.error(`Error getting user configuration: ${err.message}`);
    }
  };

  useEffect(() => {
    loadThemes();
  }, []);

  useEffect(() => {
    if (renditionRef.current) {
      renditionRef.current.themes.override(
        'color',
        themes[theme].renditionStyles.color,
        true,
      );
      renditionRef.current.themes.override(
        'background',
        themes[theme].renditionStyles.background,
        true,
      );
    }
  }, [theme]);

  useEffect(() => {
    if (renditionRef.current) {
      renditionRef.current.themes.font(fontFamily);
    }
  }, [fontFamily]);

  useEffect(() => {
    if (renditionRef.current) {
      renditionRef.current.themes.fontSize(`${fontSize}%`);
    }
  }, [fontSize]);

  useEffect(() => {
    if (renditionRef.current) {
      renditionRef.current.themes.override(
        'line-height',
        `${lineHeight}%`,
        true,
      );
    }
  }, [lineHeight]);

  useEffect(() => {
    if (initialSettingsLoaded) {
      saveSettings();
    }
  }, [theme, fontFamily, fontSize, lineHeight]);

  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        style={{
          position: 'relative',
          height: '100vh',
        }}
        onClick={() => setShowMenuSettings(false)}
      >
        <ReactReader
          url={epub}
          title={title}
          location={bookLocation}
          locationChanged={locationChanged}
          styles={theme && themes[theme].readerStyles}
          epubOptions={{
            allowScriptedContent: true,
            allowPopups: true,
          }}
          getRendition={(rendition) => {
            renditionRef.current = rendition;

            getInitialConfiguration();

            rendition.hooks.content.register((contents) => {
              loadFonts();
              // TODO: Check a better way to have these styles
              contents.addStylesheetRules({
                '.wf-loading body': {
                  visibility: 'hidden',
                },
                h1: {
                  'line-height': '100%',
                },
                // TODO: These are styles for Didier video book, set them in DB
                '.ill-image': {
                  'max-width': 'none !important',
                  'max-height': 'none !important',
                },
              });

              contents.window.document.addEventListener('click', () => {
                setShowMenuSettings(false);
              });
            });
          }}
        />

        {showSettingsButton && (
          // eslint-disable-next-line
          <div
            className="absolute top-4 right-4 z-10"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              type="button"
              className="cursor-pointer"
              onClick={handleClickMenuSettings}
            >
              <MenuReaderSettings
                color={theme && READER_THEMES[theme].secondaryColor}
              />
            </button>
            {fontSize && lineHeight && (
              <PopupMenu
                show={showMenuSettings}
                theme={theme}
                setTheme={setTheme}
                fontFamily={fontFamily}
                setFontFamily={setFontFamily}
                fontSize={fontSize}
                setFontSize={setFontSize}
                lineHeight={lineHeight}
                setLineHeight={setLineHeight}
              />
            )}
            <button
              type="button"
              className="ml-4"
              style={{ color: theme && READER_THEMES[theme].secondaryColor }}
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-auto w-7" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    </>
  );
}
