import React from 'react';

function DiscordIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 19 14"
      fill="none"
    >
      <path
        d="M16.1673 1.99967C16.1673 1.99967 14.2569 0.504674 12.0006 0.333008L11.7973 0.739674C13.8373 1.23884 14.7731 1.95426 15.7506 2.83301C14.0652 1.97259 12.4011 1.16634 9.50065 1.16634C6.60023 1.16634 4.93607 1.97259 3.25065 2.83301C4.22815 1.95426 5.34148 1.16009 7.20398 0.739674L7.00065 0.333008C4.63357 0.556758 2.83398 1.99967 2.83398 1.99967C2.83398 1.99967 0.700234 5.09342 0.333984 11.1663C2.48482 13.6468 5.75065 13.6663 5.75065 13.6663L6.43357 12.7559C5.2744 12.353 3.96523 11.6334 2.83398 10.333C4.18315 11.3538 6.2194 12.4163 9.50065 12.4163C12.7819 12.4163 14.8181 11.3538 16.1673 10.333C15.0361 11.6334 13.7269 12.353 12.5677 12.7559L13.2506 13.6663C13.2506 13.6663 16.5165 13.6468 18.6673 11.1663C18.3011 5.09342 16.1673 1.99967 16.1673 1.99967ZM6.79232 9.49967C5.9869 9.49967 5.33398 8.75342 5.33398 7.83301C5.33398 6.91259 5.9869 6.16634 6.79232 6.16634C7.59773 6.16634 8.25065 6.91259 8.25065 7.83301C8.25065 8.75342 7.59773 9.49967 6.79232 9.49967ZM12.209 9.49967C11.4036 9.49967 10.7507 8.75342 10.7507 7.83301C10.7507 6.91259 11.4036 6.16634 12.209 6.16634C13.0144 6.16634 13.6673 6.91259 13.6673 7.83301C13.6673 8.75342 13.0144 9.49967 12.209 9.49967Z"
        fill="#8C9EFF"
      />
    </svg>
  );
}

export default DiscordIcon;
