import React from 'react';

function IconTablerTwitter({ className }) {
  return (
    <svg fill="none" viewBox="0 0 24 24" className={className}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.165 4.676c-.916.449-1.815.631-2.75.907-1.027-1.16-2.55-1.224-4.015-.675-1.464.548-2.422 1.888-2.401 3.425v.917c-2.975.076-5.624-1.279-7.334-3.667 0 0-3.833 6.814 3.667 10.083-1.716 1.143-3.427 1.914-5.5 1.834 3.032 1.652 6.337 2.22 9.198 1.39 3.282-.953 5.978-3.412 7.013-7.097a12.69 12.69 0 00.456-3.44c-.002-.228 1.384-2.54 1.666-3.678v0z"
      />
    </svg>
  );
}

export default IconTablerTwitter;
