import { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/24/solid';
import { ShareIcon } from '@heroicons/react/24/outline';
import { RWebShare } from 'react-web-share';
import { DateTime } from 'luxon';
import Countdown from 'react-countdown';
import clsx from 'clsx';
import Metrics from '../../components/Metric/Metric';
import Link from '../../components/Link';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import Header from '../../components/Header/Header';
import { getIpfsUrl } from '../../services/ipfsService';
import LoginContext from '../../context/LoginContext';
import CollapseText from '../../components/CollapseText';
import SocialLinks from '../../components/SocialLinks';
import { AvatarProfile } from '../Bookshelf/Profile';
import Container from '../../components/Containers';
import Footer from '../../components/Footer';
import UsersSlider from '../../components/UsersSlider';
import FlowerGirls from '../CustomCollections/FlowerGirls';
import MailchimpForm from '../../components/MailchimpForm/MailchimpForm';

const fakeBlurredStory = {
  name: 'Flower Girls Plot #%',
  image: '/images/flower-girls/card-%.jpg',
};

const countdownRenderer = ({ days, hours, minutes, seconds }) => (
  <span className="text-xl font-bold ml-4">
    {days}
    <span className="text-sm">D</span> {hours}
    <span className="text-sm">H</span> {minutes}
    <span className="text-sm">MIN</span> {seconds}
    <span className="text-sm">S</span>
  </span>
);

function Collection() {
  const { showSpinner, hideSpinner } = useSpinner();
  const { address } = useContext(LoginContext);
  const [collection, setCollection] = useState();
  const [stories, setStories] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [lastCollectors, setLastCollectors] = useState([]);
  const [isRevealed, setIsRevealed] = useState(false);
  const [revealDate, setRevealDate] = useState();
  const [collectionId, setCollectionId] = useState();
  const [twitterLink, setTwitterLink] = useState('');
  const [formLink, setFormLink] = useState('');
  const [formDateEnabled, setFormDateEnabled] = useState();

  const getData = async () => {
    try {
      showSpinner();

      const general = await apiService.get('/general/homepage');
      const { flowergirls } = general.extras;
      setRevealDate(flowergirls.revealDate);
      setCollectionId(flowergirls.collectionId);
      setTwitterLink(flowergirls.twitterLink);
      setFormLink(flowergirls.formLink);
      setFormDateEnabled(flowergirls.formDateEnabled);
    } catch (err) {
      toast.error('An error has been occurred');
    } finally {
      hideSpinner();
    }
  };

  const getCollectionInfo = async () => {
    const [collectionInfo, collectionMetrics] = await Promise.all([
      apiService.get(`/collection/${collectionId}`),
      apiService.get(`/collection/metrics/${collectionId}`),
    ]);

    setCollection(collectionInfo);

    setMetrics([
      { name: 'Sold', value: collectionMetrics.sold },
      { name: 'Collectors', value: collectionMetrics.collectors },
    ]);
    setLastCollectors(collectionMetrics.lastCollectors);
  };

  const getCollectionStories = async () => {
    const collectionStories = await apiService.get(
      `/story/collection/${collectionId}`,
    );
    setStories(collectionStories);
  };

  const getFakeBlurredStories = () => {
    const fakeBlurredStories = [];
    for (let i = 1; i <= 10; i += 1) {
      fakeBlurredStories.push({
        name: fakeBlurredStory.name.replace('%', i),
        image: fakeBlurredStory.image.replace('%', i),
      });
    }
    setStories(fakeBlurredStories);
  };

  const checkIsRevealed = async () => {
    const revealed = DateTime.now() > DateTime.fromISO(revealDate);
    if (revealed) {
      await getCollectionStories();
    } else {
      await getFakeBlurredStories();
    }
    setIsRevealed(revealed);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (collectionId) {
      getCollectionInfo();
    }
  }, [collectionId]);

  useEffect(() => {
    if (revealDate) {
      checkIsRevealed();
    }
  }, [revealDate]);

  const bannerStyle = {
    backgroundImage: `url(${
      collection?.cover && getIpfsUrl(collection.cover)
    })`,
    backgroundColor: !collection?.cover && '#B6B6B6',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };

  return (
    collection && (
      <>
        <Header />

        <Container margin="flat-mobile" spacing="none" className="relative">
          <div
            style={bannerStyle}
            className="bg-center h-[350px] md:h-[450px] lg:rounded-3xl"
          >
            {collection.createdBy === address && (
              <Link
                // eslint-disable-next-line no-underscore-dangle
                to={`/edit-collection/${collection._id}`}
              >
                <div className="hidden lg:inline-flex space-x-2 items-center justify-center h-9 py-2 pl-2.5 pr-3 bg-gray-100 shadow rounded-md absolute right-8 top-4">
                  <PencilIcon className="w-4 h-full text-secondary-400" />
                  <p className="text-sm font-medium leading-none text-gray-900">
                    Edit Collection
                  </p>
                </div>
              </Link>
            )}
            <div className="flex lg:hidden absolute top-4 right-4">
              <RWebShare
                data={{
                  text: `Check out ${collection.name} collection on Readl Stories`,
                  url: `${window.location.origin}/${collectionId}`,
                  title: 'Share this collection',
                }}
              >
                <span className="rounded-full overflow-hidden block shadow-lg hover:shadow-2xl transition-all">
                  <ShareIcon className="w-10 h-10 p-2 sm:w-12 sm:h-12 sm:p-2 lg:w-16 lg:h-16 lg:p-4 bg-white cursor-pointer" />
                </span>
              </RWebShare>
            </div>
            <div className="absolute bottom-6 right-6 z-10 max-w-[265px] hidden lg:block">
              <UsersSlider
                className="max-w-[100px] xs:max-w-[140px] sm:max-w-[300px]"
                users={lastCollectors}
              />
            </div>
          </div>

          <div className="bg-gradient-to-t from-white to-transparent h-48 w-full hidden absolute bottom-0 z-0" />
        </Container>

        <Container spacing="none" className="relative mt-4 z-10">
          <div
            className={clsx(
              'flex flex-col lg:flex-row justify-between w-full items-start lg:px-8',
              isRevealed ? 'flex-col-reverse' : 'flex-col',
            )}
          >
            <div className="flex flex-col items-start md:pr-8 w-full">
              <div className="flex items-center space-x-6 my-4">
                <AvatarProfile user={collection} collection />
                <div className="flex flex-col justify-center">
                  <div className="flex">
                    <SocialLinks items={collection} />
                  </div>
                  <h3 className="text-lg sm:text-2xl xl:text-3xl font-bold flex flex-col items-baseline mt-2">
                    <span className="pr-1 leading-tight">
                      {collection?.name}
                    </span>
                  </h3>
                  <div className="flex items-center">
                    <span className="text-sm text-gray-500 pr-0.5">by</span>
                    <Link
                      href={`/${collection?.createdBy}`}
                      className="text-sm text-gray-500 font-semibold hover:bg-gray-100 p-1 rounded-md"
                    >
                      @{collection?.author}
                    </Link>
                  </div>
                </div>
              </div>
              <CollapseText text={collection.description} />
            </div>
            {/* Collections statistics */}
            <div className="flex flex-col lg:px-0 text-center lg:justify-end w-full lg:w-fit lg:min-w-max lg:pt-10">
              {!isRevealed ? (
                <div className="py-20 lg:py-0 flex flex-col justify-items-end">
                  <div>
                    <span className="text-gray-500 font-bold uppercase text-sm pb-2">
                      Collection will be revealed in
                    </span>
                    <Countdown
                      date={revealDate}
                      renderer={countdownRenderer}
                      onComplete={() => {
                        setTimeout(checkIsRevealed, 1000);
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <div
                      id="mailchimpform"
                      className="flower-girls relative max-w-[405px] mx-auto lg:ml-auto lg:mr-0"
                    >
                      <MailchimpForm />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex w-full justify-between xl:justify-end flex-start mb-4 lg:mb-0 p-2 xs:p-4 bg-gray-50 lg:bg-transparent rounded-xl">
                  <Metrics metrics={metrics} />
                  <div className="min-w-fit block lg:hidden">
                    <UsersSlider
                      className="max-w-[100px] xs:max-w-[140px] sm:max-w-[300px]"
                      users={lastCollectors}
                    />
                  </div>
                  <div className="hidden lg:flex">
                    <RWebShare
                      data={{
                        text: `Check out ${collection.name} collection on Readl Stories`,
                        url: `${window.location.origin}/${collectionId}`,
                        title: 'Share this collection',
                      }}
                    >
                      <span className="rounded-full overflow-hidden block shadow-lg hover:shadow-2xl transition-all">
                        <ShareIcon className="w-10 h-10 p-2 sm:w-12 sm:h-12 sm:p-2 lg:w-16 lg:h-16 lg:p-4 bg-white cursor-pointer" />
                      </span>
                    </RWebShare>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>

        <FlowerGirls
          revealDate={revealDate}
          isRevealed={isRevealed}
          checkIsRevealed={checkIsRevealed}
          stories={stories}
          twitterLink={twitterLink}
          formLink={formLink}
          formDateEnabled={formDateEnabled}
        />

        <Footer />
      </>
    )
  );
}

export default Collection;
