import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import LoginContext from '../../context/LoginContext';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import Modal from '../Modal/Modal';
import Heading from '../Typography';
import Input from '../Forms/Forms';

function ModalNewCollection({
  open,
  setOpen,
  getCollections,
  setCollectionId,
}) {
  const { showSpinner, hideSpinner } = useSpinner();
  const { userName } = useContext(LoginContext);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(false);

  const createCollection = async () => {
    try {
      showSpinner('Creating collection...');
      if (!name) {
        return setError(true);
      }
      const item = await apiService.post('/collection', {
        name,
        description,
        author: userName,
      });
      setOpen(false);
      await getCollections();
      //  eslint-disable-next-line
      setCollectionId(item._id);
      // TODO: Replace it with toast once its merged in develop
      return toast.success('Collection created successfully');
    } catch (err) {
      console.error(err);
      // TODO: Replace it with toast once its merged in develop
      return toast.error('Error creating new collection');
    } finally {
      hideSpinner();
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Heading size="5">Create collection</Heading>
      <p className="text-gray-500 text-sm dark:text-dark-200 mt-2">
        Create a new collection for your NFT stories.
      </p>

      <div className="space-y-6 mt-8">
        {/* Collection name */}
        <div>
          <Input
            label="Collection name"
            type="text"
            name="name"
            id="name"
            size="md"
            autoComplete="given-name"
            placeholder="Collection name"
            className={
              !error
                ? 'shadow-sm focus:ring-primary-500 focus:border-primary-500 border-black border rounded-md p-3 block w-full focus:outline-primary-500'
                : 'block w-full p-3 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
            }
            onInput={(e) => {
              setName(e.target.value);
              if (error) {
                setError(false);
              }
            }}
          />
          <div className="mt-1 relative">
            {error && (
              <div className="absolute inset-y-3 right-0 pr-4 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {error && (
            <p className="mt-2 text-sm text-red-600">
              You need to write a name.
            </p>
          )}
        </div>

        {/* Description */}
        <div>
          <label
            htmlFor="tags"
            className="block text-sm font-medium text-gray-700 dark:text-dark-100"
          >
            Description
          </label>
          <textarea
            className="shadow-sm block w-full text-sm border border-black rounded-md mt-2 focus:ring-primary-500 focus:border-primary-500 p-3 min-h-[120px] h-auto max-h-[700px] overflow-auto focus:outline-primary-500 dark:bg-dark-700 dark:border-dark-600 dark:placeholder-dark-400"
            type="textarea"
            id="description"
            required=""
            onInput={(e) => setDescription(e.target.value)}
            rows="7"
            placeholder="You can use simple markdown to format your text:&#10;# Title&#10;## Subtitle&#10;**bold**&#10;*italic*&#10;> Blockquotes"
          />
          <p className="mt-2 text-sm text-gray-500">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            You can use{' '}
            <a
              href="https://www.markdownguide.org/basic-syntax/"
              target="_blank"
              className="group relative text-primary-600 hover:text-primary-400"
              rel="noreferrer"
            >
              <div className="absolute bottom-2 flex flex-col items-center hidden mb-6 group-hover:flex -ml-4 -mr-4 left-0 right-0">
                <span className="relative z-10 p-2 text-xs leading-tight text-white whitespace-no-wrap bg-black shadow-lg rounded-md">
                  Use simple markdown to format your text. Click to know more.
                </span>
                <div className="w-3 h-3 -mt-2 rotate-45 bg-black" />
              </div>
              Markdown syntax
            </a>
            .
          </p>
        </div>
      </div>

      <div className="flex flex-col space-y-4 mt-6 justify-center">
        {/* Create collection */}
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-lg text-black bg-primary-500 hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 justify-center"
          onClick={createCollection}
        >
          Create collection
        </button>

        {/* Cancel button */}
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-lg text-black text-black bg-gray-100 hover:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors justify-center"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default ModalNewCollection;
