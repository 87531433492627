import React from 'react';
import { Square2StackIcon } from '@heroicons/react/24/outline';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import ShowAddress from '../ShowAddress';

function ButtonUserInfo({ accounts, text }) {
  return (
    <div className="cursor-pointer flex">
      <CopyToClipboard
        text={text}
        onCopy={() => toast.success('Address copied to clipboard')}
      >
        <div className="flex hover:bg-gray-100 dark:hover:bg-dark-700 p-1 rounded-md cursor-pointer text-gray-500 hover:text-gray-800 dark:hover:text-dark-200 transition-all">
          <div className="flex items-center text-xs font-medium leading-none">
            <ShowAddress address={accounts} />
          </div>
          <Square2StackIcon className="ml-3 w-6" />
        </div>
      </CopyToClipboard>
    </div>
  );
}

export default ButtonUserInfo;
