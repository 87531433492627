import React from 'react';

function EmojiSad({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 22.9997C9.08261 22.9997 6.28476 21.8407 4.22189 19.7778C2.15902 17.7149 1 14.9172 1 11.9998C1 9.08241 2.15897 6.28456 4.22189 4.22169C6.28476 2.15882 9.08271 1 12 1C14.9172 1 17.7151 2.15882 19.778 4.22169C21.8409 6.28476 22.9997 9.08251 22.9997 11.9998C22.9968 14.9163 21.8368 17.7125 19.7745 19.7748C17.7124 21.8372 14.9163 22.9969 12 23V22.9997ZM12 2.15763C9.38963 2.15763 6.8862 3.19452 5.04068 5.04029C3.19492 6.88605 2.15783 9.38938 2.15783 11.9996C2.15783 14.6099 3.19487 17.1133 5.04068 18.9593C6.88625 20.8049 9.38978 21.842 12 21.842C14.6101 21.842 17.1137 20.8049 18.9592 18.9593C20.805 17.1136 21.8419 14.61 21.8419 11.9996C21.8388 9.39023 20.8009 6.88878 18.9557 5.04376C17.1107 3.19854 14.6092 2.16071 12 2.15763Z"
        fill="#221F20"
      />
      <path
        d="M15.8671 14.8244C15.0165 14.8116 14.2367 14.3489 13.8176 13.6087C13.6577 13.3305 13.7536 12.9754 14.0318 12.8157C14.31 12.6557 14.6651 12.7517 14.825 13.0299C15.0477 13.3953 15.4449 13.6181 15.8729 13.6181C16.3007 13.6181 16.6979 13.3952 16.9206 13.0299C17.0805 12.7517 17.4357 12.6557 17.7138 12.8157C17.992 12.9754 18.0879 13.3305 17.928 13.6087C17.722 13.975 17.4228 14.2803 17.0609 14.4937C16.699 14.7072 16.2871 14.8213 15.867 14.8244L15.8671 14.8244Z"
        fill="#221F20"
      />
      <path
        d="M7.76147 14.8244C6.91106 14.8116 6.13107 14.3489 5.71215 13.6087C5.55222 13.3305 5.64817 12.9754 5.92634 12.8157C6.20451 12.6557 6.55964 12.7517 6.71933 13.0299C6.94225 13.3953 7.33924 13.6181 7.76724 13.6181C8.19525 13.6181 8.59244 13.3952 8.81515 13.0299C8.97508 12.7517 9.3302 12.6557 9.60834 12.8157C9.88651 12.9754 9.98246 13.3305 9.82254 13.6087C9.61649 13.975 9.31737 14.2803 8.95546 14.4937C8.59355 14.7072 8.18162 14.8213 7.76141 14.8244L7.76147 14.8244Z"
        fill="#221F20"
      />
      <path
        d="M12.5786 19.3981H11.4208C11.1012 19.3981 10.8418 19.1389 10.8418 18.819C10.8418 18.4994 11.1012 18.2402 11.4208 18.2402H12.5786C12.8985 18.2402 13.1576 18.4994 13.1576 18.819C13.1576 19.1389 12.8985 19.3981 12.5786 19.3981Z"
        fill="#221F20"
      />
      <path
        d="M6.33846 9.54458C6.08104 9.54323 5.8246 9.51221 5.57435 9.45193C5.25451 9.37846 5.05504 9.05959 5.12851 8.73972C5.20198 8.42008 5.52085 8.22041 5.84071 8.29408C6.40092 8.42259 6.98941 8.32432 7.47751 8.02056C7.96561 7.71681 8.31377 7.23239 8.44597 6.67297C8.51944 6.35333 8.83831 6.15366 9.15798 6.22713C9.47782 6.30079 9.67729 6.61947 9.60382 6.93934C9.43227 7.66836 9.02014 8.31834 8.43418 8.78455C7.84817 9.25094 7.12222 9.50643 6.37325 9.50991L6.33846 9.54458Z"
        fill="#221F20"
      />
      <path
        d="M17.6037 9.54471C16.8576 9.5323 16.1373 9.26984 15.5579 8.79976C14.9785 8.32949 14.5735 7.67855 14.4078 6.95106C14.3343 6.63122 14.534 6.31235 14.8536 6.23886C15.1733 6.16539 15.4921 6.36486 15.5658 6.6847C15.698 7.24411 16.0462 7.72854 16.5343 8.03229C17.0224 8.33585 17.6107 8.43432 18.1711 8.30561C18.4907 8.23214 18.8096 8.4318 18.8831 8.75145C18.9568 9.07129 18.7571 9.38996 18.4373 9.46365C18.1639 9.52549 17.8839 9.55282 17.6037 9.54468V9.54471Z"
        fill="#221F20"
      />
    </svg>
  );
}

export default EmojiSad;
