import Container from '../../components/Containers';
import Slider from '../../components/Slider';
import Heading from '../../components/Typography';
import { Text } from '../../components/Typography/Heading';

const items = [
  {
    id: 1,
    title: 'Roadmap',
    subtitle: 'Building the pillars of web3 publishing',
    content:
      'We are working on new ways to set you free of centralized platforms and give you control over your readership.',
    bgColor: 'bg-black',
    textColor: 'text-white',
    steps: [
      {
        id: 1,
        title: '✅ Done',
        bgColor: 'bg-gray-900',
        textTitleColor: 'text-white',
        textColor: 'text-white',
        items: [
          'Marketplace ✅',
          'Customizable book reader ✅',
          'Decentralized and private storage system ✅',
          'Easily transfer and gift books ✅',
          'Customizable and easy content creator for stories ✅',
          'Add funds with credit card ✅',
          'Gated content ✅',
          'Unlimited royalty distribution rules for unlimited creators ✅',
          'Simplify login for web 2 users ✅',
        ],
      },
      {
        id: 2,
        title: 'Now',
        bgColor: 'holo-gradient',
        textTitleColor: 'text-black',
        textColor: 'text-white',
        items: [
          'Unlockable content ✅',
          'Transactional emails and reports ✅',
          'Sponsor transaction fees ✅',
          'Power Creators - customizable collection pages and profiles 👈',
          'Referral program 👈',
          'Comments and reactions 👈',
          'Verified reviews with rewards',
          'Reveals, allowlists and pre-mints',
          'Buy NFTs directly with credit card',
          'Sales and reading statistics dashboard',
          'Edit or freeze your NFT files metadata',
          'Collect and follow - email subscriptions',
          'NFT variants - add multiple covers with different rarities',
        ],
      },
      {
        id: 3,
        title: 'Next',
        bgColor: 'holo-gradient',
        textTitleColor: 'text-black',
        textColor: 'text-white',
        items: [
          'Discounts and bundle sales',
          'Improve audiobook integration',
          'Comics uploader and reader',
          'Multichain integration',
          'Collaborative tools and collections',
          'Custom smart contracts per collection',
          'Decentralized and open source reader',
          'Voting for readers',
          'Mobile app',
          'Multi-wallet connection',
          'Multi-language support',
          'SEO and customizable metadata for individual pages',
          'ISBN and NFT rights management',
          'Public Libraries',
          'Book and stories renting',
          'NFT to print',
        ],
      },
    ],
  },
];

function Roadmap() {
  return (
    <Container
      className="bg-black lg:rounded-2xl text-white py-28 px-4 lg:px-0"
      margin="flat-mobile"
    >
      <div className="text-center max-w-3xl mx-auto space-y-6 pb-8">
        <Heading size="4" className="text-gray-400 uppercase">
          Roadmap
        </Heading>
        <Heading size="1" className="text-holo">
          Building the pillars of web3 publishing
        </Heading>
        <Text size="xl" className="max-w-2xl mx-auto">
          We are working on new ways to set you free of centralized platforms
          and give you control over your readership.
        </Text>
      </div>
      <Slider
        className="w-full"
        classNameSlider=""
        slidesPerView={1}
        initialSlide={1}
        navigation
        items={items[0].steps.map((step) => (
          <div key={step.id} className="max-w-3xl mx-auto mt-10">
            <div className={`rounded-xl ${step.bgColor} relative p-2 lg:p-3`}>
              <div
                className={`absolute ${step.textTitleColor} ${step.bgColor} left-0 top-0 rounded-xl uppercase
              text-xl p-4 font-bold`}
              >
                {step.title}
              </div>
              <div className="bg-black pt-16 p-6 lg:p-12 rounded-lg mx-1">
                <ul
                  className={`list-disc pt-4 md:columns-2 prose text-sm lg:text-base ${step.textColor}`}
                >
                  {step.items.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      />
    </Container>
  );
}

export default Roadmap;
