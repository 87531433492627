import React from 'react';

function LogoReadlCircle({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 192 192"
    >
      <rect
        width="192"
        height="192"
        className="fill-black dark:fill-white"
        rx="96"
      />
      <path
        className="fill-white dark:fill-black"
        d="M132.769 81.128c-1.013-12.125-8.805-25.679-27.374-29.208-6.704-1.272-13.96-2.487-19.507-3.465a5.001 5.001 0 00-2.775.296l-20.265 7.982-.11.044-.073.03a6.428 6.428 0 00-1.778 1.105c-1.569 1.368-2.352 3.169-2.35 5.402v69.664l15.896 2.805v-30.238l17.027 3.013c4.332.765 7.319 2.113 8.96 4.045 1.492 1.905 2.389 5.347 2.691 10.328.105 5.435.244 13.265.43 17.787l16.493 3.087v-20.957c0-11.005-7.143-16.496-10.428-18.269 3.732-.389 6.643-2.041 8.733-4.954 1.942-2.647 2.913-6.432 2.913-11.358 0-7.616-2.016-13.57-6.048-17.865-4.33-4.349-10.676-7.26-19.037-8.733l-19.503-3.44 8.977-3.535 13.175 2.338c9.293 1.636 15.796 4.382 20.768 9.523 5.021 5.192 7.093 12.255 7.093 18.951 0 9.707-2.013 16.126-6.63 19.147 3.485 2.487 5.5 8.234 5.5 14.475v23.033l6.547-3.079c-.039-1.883 0-19.394 0-22.514 0-6.244-1.392-10.467-3.449-13.092 3.153-3.477 5.038-11.425 4.124-22.348zM102.443 78.9c1.793 1.957 2.69 4.653 2.69 8.09 0 3.437-.897 5.893-2.69 7.367-2.24 1.247-5.301 1.528-9.183.843l-18.815-3.318V71.277l19.71 3.476c3.73.658 6.49 2.04 8.281 4.147h.007z"
      />
    </svg>
  );
}

export default LogoReadlCircle;
