import { useWindowScroll } from 'react-use';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { ShareIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { RWebShare } from 'react-web-share';
import ReactGA from 'react-ga4';
import { ButtonLink } from '../Button/Button';
import ButtonBack from '../ButtonBack/ButtonBack';
import { pathToPageName } from '../../utils/utils';

export default function ProgressBar({ className }) {
  const { y } = useWindowScroll();
  const pageHeight = document.body.scrollHeight - window.innerHeight;

  useEffect(() => {
    const percentScrolled = (Math.abs(y) / pageHeight) * 100;
    const el = document.getElementById('scroll-progress');
    el?.style.setProperty(
      '--tw-gradient-stops',
      `var(--tw-ring-color) ${percentScrolled}%, transparent 0`,
    );
  }, [y, pageHeight]);

  return (
    <div
      id="scroll-progress"
      className={clsx(
        { className },
        'absolute bg-primary-50 left-0 bottom-0 z-50 h-1 w-full bg-gradient-to-r p-0 ring-primary-500 ring-opacity-75 dark:ring-palevioletred dark:ring-opacity-75 hidden',
      )}
    >
      <div className="absolute -bottom-3 bg-primary-500 rounded-full text-black p-2 left-[58%]">
        <LockClosedIcon className="w-3 h-3" />
      </div>
    </div>
  );
}

// updated import:
export function NavbarAlter({ storyInfo, shareAddress, display }) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  const { pathname } = location;

  // new function:
  const handleScroll = () => {
    // find current scroll position
    const currentScrollPos = window.pageYOffset;

    // set state based on location info (explained in more detail below)
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 72) ||
        currentScrollPos < 100,
    );

    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  };

  // new useEffect:
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    // updated styling:
    <div
      className={clsx(
        'fixed transition-all duration-700 h-0 w-full bg-white dark:bg-dark-800 shadow-lg z-50 flex items-center',
        {
          '-translate-y-40': !display,
          'top-[-72px]': visible,
          'top-[0] h-[72px]': !visible,
        },
      )}
    >
      <div className="container mx-auto relative flex justify-between">
        <div className="flex items-center space-x-4">
          <ButtonBack />
          <div>
            <img
              className="w-auto h-full max-h-[44px] max-w-[44px] rounded-sm"
              src={storyInfo.image}
              alt="cover"
            />
          </div>
          <h4 className="font-bold text-sm lg:text-lg line-clamp-2 leading-tight lg:leading-normal dark:text-dark-200">
            {storyInfo.name}
          </h4>
        </div>
        <div className="flex items-center space-x-2 w-fit">
          <RWebShare
            data={{
              text: `Check out ${storyInfo.name} profile on Readl Stories`,
              url: `${window.location.origin}/${shareAddress}`,
              title: 'Share this story',
            }}
            onClick={() => {
              ReactGA.event({
                category: 'share',
                action: 'click_share_header',
                label: pathToPageName(pathname, storyInfo.name),
              });
            }}
          >
            <ButtonLink
              icon={<ShareIcon />}
              variant="link"
              size="sm"
              rounded="full"
            />
          </RWebShare>
        </div>
      </div>
      {display && <ProgressBar className="hidden" />}
    </div>
  );
}
