import React from 'react';

function ShowAddress({ address }) {
  return (
    <p>
      {' '}
      {address?.substring(-1, 4)}...
      {address?.substring(100, 38)}
    </p>
  );
}

export default ShowAddress;
