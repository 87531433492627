import clsx from 'clsx';
import { motion } from 'framer-motion';
import React from 'react';

const ComponentHeading = React.forwardRef(
  (
    { size = '3', children, className, gradient, divider, dividerColor },
    ref,
  ) => {
    const CustomHeading = `h${size}`;

    return (
      <div>
        <CustomHeading
          ref={ref}
          className={clsx(
            className,
            'dark:text-white font-bold leading-tight',
            {
              'text-4xl sm:text-6xl': size === '1',
              'text-3xl sm:text-5xl': size === '2',
              'text-3xl sm:text-4xl': size === '3',
              'text-2xl': size === '4',
              'text-lg': size === '5',
              'text-md': size === '6',
              'text-holo': gradient,
              'before:inline-block before:w-8 before:bg-gray-300 before:h-1 before:my-1 before:mr-3':
                divider === 'before',
              dividerColor,
            },
          )}
        >
          {children}
        </CustomHeading>
      </div>
    );
  },
);

const ComponentText = React.forwardRef(
  ({ size = 'normal', children, className }, ref) => {
    return (
      <p
        ref={ref}
        className={clsx(className, 'dark:text-white', {
          'text-xs': size === 'xs',
          'text-sm': size === 'sm',
          'text-base': size === 'base',
          'text-lg': size === 'lg',
          'text-xl': size === 'xl',
          'text-2xl': size === '2xl',
        })}
      >
        {children}
      </p>
    );
  },
);

const Heading = motion(ComponentHeading);
const Text = motion(ComponentText);

export default Heading;
export { Text };
