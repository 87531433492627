import { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import CardQuantity from '../Icons/CardQuantity';
import PauseIcon from '../Icons/PauseIcon';
import PlayIcon from '../Icons/PlayIcon';
import Link from '../Link';
import { getIpfsUrl } from '../../services/ipfsService';
import Avatar from '../Avatar';
import PriceConversion from '../PriceConversion';
import PriceFormatted from '../PriceFormatted';
import ItemInfo from '../ItemInfo/ItemInfo';
import Badge from '../Badge';

export function ItemCover({ image, type, className }) {
  return (
    <div
      className={clsx(
        'aspect-[4/6] overflow-hidden safari-mask-fix flex items-center relative bg-black rounded-lg',
        className,
      )}
    >
      <img
        className="align-middle h-full min-w-full object-contain w-auto z-10 scale-100 group-hover:scale-110 transition-transform duration-700"
        src={image}
        alt=""
      />
      <div
        className="absolute w-full h-full opacity-40 rounded-lg "
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="backdrop-blur-md absolute w-full h-full rounded-lg " />
        {type}
      </div>
    </div>
  );
}

const childSequential = {
  initial: { opacity: 0, y: 25 },
  visible: { opacity: 1, y: [25, -15, 0], transition: { duration: 1 } },
};

function GridItem({ data }) {
  const [playing, setPlaying] = useState(false);

  const url = 'audio.mp3';

  const audioRef = useRef(new Audio(data.media ? getIpfsUrl(data.media) : url));

  const play = () => {
    setPlaying(true);
    audioRef.current.play();
  };

  const pause = () => {
    setPlaying(false);
    audioRef.current.pause();
  };

  return (
    <Link to={`/${data?.type}/${data.tokenId}`} key={data.tokenId}>
      <motion.div
        initial="initial"
        whileInView="visible"
        viewport={{ once: true }}
        variants={childSequential}
      >
        <section className="relative bg-no-repeat bg-contain rounded-lg group">
          <ItemCover image={getIpfsUrl(data.image)} type={data.type} />
          {/* Quantity and Creator */}
          <div className="absolute top-0 left-0 right-0 pt-2 px-3 flex justify-between w-full z-20 items-center">
            <ItemInfo
              type={data.type}
              gated={data.isPrivate}
              duration={data.readingTime}
              onCard
            />
            <div className="hidden sm:flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
              <div className="h-8 w-8 text-white flex-col text-xs items-center hidden lg:flex">
                <CardQuantity className="h-6 w-6" />x
                {data.totalSupply === 1000000 ? '1M' : data.totalSupply}
              </div>
              <Link to={`/${data.createdBy}`} className="block hover:z-50">
                <Avatar
                  name={data.author}
                  img={data.avatar && getIpfsUrl(data.avatar)}
                />
              </Link>
            </div>
          </div>
          <div className="bg-gradient-to-t from-black rounded-br-lg rounded-bl-lg to-transparent h-48 w-full absolute bottom-0 z-10 lg:opacity-0 group-hover:opacity-100 transition-all duration-500" />
          {/* Audio and Title */}
          <div className="absolute bottom-0 w-full px-4 pb-6 z-10 space-y-2 lg:opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-700">
            {Boolean(data.media) && (
              <div className="flex items-center">
                <button
                  type="button"
                  className="shadow-2xl rounded-full blob primary h-8 w-8 lg:h-10 lg:w-10"
                  onClick={playing ? pause : play}
                >
                  {playing ? <PauseIcon /> : <PlayIcon />}
                </button>
                <span className="pl-2 text-[10px] text-white">
                  {data.time} min
                </span>
              </div>
            )}
            {data.tags && (
              <div className="hidden lg:flex space-x-1 overflow-hidden rounded-xl">
                {data.tags
                  .filter((tag, index) => index < 2)
                  .map((tag) => (
                    <Badge key={tag} variant="onCard" size="sm">
                      {tag}
                    </Badge>
                  ))}
                {data.tags.length > 2 && (
                  <Badge variant="onCard" size="sm">
                    +{data.tags.length - 2}
                  </Badge>
                )}
              </div>
            )}
            <h2 className="text-base sm:text-lg text-white font-semibold break-words">
              {data.name}
            </h2>
          </div>
        </section>
        {data.quantity && (
          <div className="h-9 mt-2 flex items-center justify-between text-base font-medium text-gray-900 px-2">
            <p className="lg:text-sm text-xs text-gray-500">
              {data.quantity} available
            </p>
            <div className="inline-flex flex-col items-end justify-start">
              <div className="inline-flex space-x-0.5 items-center justify-start">
                {!data.uniqueOrder && (
                  <p className="text-xs text-gray-500 dark:text-dark-500">
                    from
                  </p>
                )}
                <p className="text-sm font-semibold text-right dark:text-dark-100">
                  {data.price === '0' ? (
                    'Free'
                  ) : (
                    <PriceConversion price={data.price} />
                  )}
                </p>
              </div>
              <p className="text-xs text-right dark:text-dark-100">
                {data.price !== '0' && <PriceFormatted price={data.price} />}
              </p>
            </div>
          </div>
        )}
      </motion.div>
    </Link>
  );
}

export default GridItem;
