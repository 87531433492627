import clsx from 'clsx';
import ScrollContainer from 'react-indiana-drag-scroll';
import { getIpfsUrl } from '../../services/ipfsService';
import Avatar from '../Avatar';
import Link from '../Link';

export default function UsersSlider({ users, className }) {
  return (
    <ScrollContainer
      className={clsx(
        'rounded-full overflow-hidden pl-4 max-h-[42px] whitespace-nowrap',
        className,
      )}
    >
      {users.map((user) => (
        <Link
          to={`/${user?.address}`}
          className="-translate-x-4 inline-block first:ml-0 -ml-4 hover:scale-105 transition duration-500"
          key={user.address}
        >
          <Avatar
            img={user?.avatar && getIpfsUrl(user?.avatar)}
            name={user?.name}
            size="md"
          />
        </Link>
      ))}
    </ScrollContainer>
  );
}
