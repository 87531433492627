import { motion } from 'framer-motion';
import clsx from 'clsx';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import Container from '../../components/Containers';
import Heading from '../../components/Typography';
import { Text } from '../../components/Typography/Heading';
import Tooltip from '../../components/Tooltip/Tooltip';
import {
  childVariants,
  containerMotion,
  childVariantsAlways,
  containerUp,
} from '../../components/MotionAnimations/motion-variants';
import Parallax from '../../components/parallax/parallax';

const readerFeatures = [
  {
    title: 'Onboarding made easy',
    text: `Readers can create their account with their email, social media, or Metamask.`,
  },
  {
    title: 'Read and collect anywhere',
    text: `Seamlessly switch between devices and enjoy your stories and books from your laptop or mobile devices.`,
  },
  {
    title: 'Customizable book reader',
    text: `Readers can adjust the font, background color and more to suit their preferences, making the content more accessible.`,
  },
  {
    title: 'Free transactions for free content',
    text: `Readers can collect free literary NFTs without needing to cover the gas fees with funds.`,
  },
  {
    title: 'Credit Card payments',
    text: `No need to possess cryptocurrency to collect your work. Collectors and readers can easily purchase your books and stories using their credit card.`,
  },
  {
    title: 'Resell their NFTs',
    text: `Readers can now resell their books and stories when they read it. Don't worry, you still keep your royalties.`,
  },
];

export function FeatureHighlight({ text, icon, dark, textSize = 'lg' }) {
  return (
    <div
      className={clsx(
        'py-4 px-6 rounded-lg inline-flex w-fit text-lg',
        dark ? 'bg-[#151515] text-gray-100' : 'bg-gray-50 text-gray-700',
        {
          'text-lg': textSize === 'lg',
          'text-base': textSize === 'base',
        },
      )}
    >
      <span className="inline-block mr-2">{icon}</span>
      {text}
    </div>
  );
}

function Features() {
  return (
    <div>
      <Container>
        <div className="max-w-xl mx-auto mb-32">
          <Heading size="1" className="text-center">
            Publish your literary NFTs
          </Heading>
          <Text size="xl" className="text-center">
            Bringing your stories to web3 has never been easier or faster.
            Publish your work with just a few clicks – no technical knowledge
            required.
          </Text>
        </div>
        <div className="space-y-6 bg-gray-100 rounded-2xl px-6 py-6 sm:px-12 sm:py-12 lg:p-20 lg:pt-12 mb-32 grid lg:grid-cols-2 gap-8 lg:gap-16 bg-[url('/public/images/landing/pattern-1.png')]">
          <motion.div
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.35 },
            }}
            viewport={{ once: true }}
            className="flex justify-center items-center"
          >
            <Parallax offset={-100} clampInitial={200}>
              <img
                src="/images/landing/stories-poems.png"
                alt="Readl for creators"
                className="mx-auto max-w-xs md:max-w-md mb-24 -mt-40 lg:max-w-md filter drop-shadow-2xl w-full"
              />
            </Parallax>
          </motion.div>
          <div className="space-y-4">
            <Heading size="2">
              <span className="dark:text-dark-800">Stories</span>
              <span className="text-gray-500"> and poems</span>
            </Heading>
            <Text size="xl">
              <span className="dark:text-secondary-600">
                Publish anything from a character side story, a poem or even an
                article.
              </span>
            </Text>
            <div className="flex flex-col space-y-2 pt-4">
              <FeatureHighlight
                icon="📝"
                text="Super simple text editor for writing your story, at home, or on the go."
              />
              <FeatureHighlight
                icon="🏞️"
                text="Add images, videos, and links to engage your audience and bring your words to life."
              />
              <FeatureHighlight
                icon="🔐"
                text="Add paywalls and monetize your stories in a way that works for you"
              />
            </div>
          </div>
        </div>
        <div className="space-y-6 bg-black rounded-2xl p-20 text-white px-6 py-6 sm:px-12 sm:py-12 lg:p-20 lg:pt-12 mb-24 grid lg:grid-cols-2 gap-8 lg:gap-16 bg-[url('/public/images/landing/pattern-2.png')]">
          <motion.div
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.35 },
            }}
            viewport={{ once: true }}
            className="flex justify-center items-center"
          >
            <div className="relative">
              <Parallax offset={-50} clampInitial={200}>
                <img
                  src="/images/landing/book-cover.png"
                  alt="Readl for creators"
                  className="mx-auto max-w-xs mb-24 -mt-40 sm:max-w-md lg:max-w-sm filter drop-shadow-2xl absolute z-0 lg:-right-20 top-20 hidden lg:flex"
                />
              </Parallax>
              <Parallax offset={-50} clampInitial={200} className="relative">
                <img
                  src="/images/landing/book-uploader.png"
                  alt="Readl for creators"
                  className="mx-auto max-w-xs mb-24 -mt-40 sm:max-w-md lg:max-w-md filter drop-shadow-2xl z-10 w-full"
                />
              </Parallax>
            </div>
          </motion.div>
          <div className="order-last lg:order-first space-y-4">
            <Heading size="2">
              Books <span className="text-gray-500">and comics</span>
            </Heading>
            <Text size="xl">
              We have it ready for all your books, comics and graphic novels.
            </Text>
            <div className="flex flex-col space-y-2 pt-4">
              <FeatureHighlight
                icon="📘"
                text="Upload your .epub file directly, and we'll take care of the rest."
                dark
              />
              <FeatureHighlight
                icon="🥸"
                text="Preview your work before publishing"
                dark
              />
              <FeatureHighlight
                icon="📲"
                text="Decide whether or not to allow your readers to download the ePub"
                dark
              />
            </div>
          </div>
        </div>
      </Container>
      <Container className="min-h-screen items-center" flex spacing="xl">
        <motion.div
          className="max-w-4xl mx-auto text-center space-y-6"
          variants={containerMotion}
          initial="initial"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <Heading size="1">
            Gating Options to Fit Any{' '}
            <span className="text-primary-500">Monetization Strategy</span>
          </Heading>
          <div className="max-w-2xl mx-auto">
            <Text size="lg">
              You choose how to monetize your work. You can offer ungated
              content and let your users support you by collecting your stories.
              Or if exclusive content is what you&apos;re after, then select
              exactly where the gated content starts.
            </Text>
          </div>
          <div className="relative !-mt-24 mb-8 min-h-[200px]">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 2, delayChildren: 2 }}
              viewport={{ once: true }}
              className="absolute bg-gradient-to-t from-white dark:from-dark-900 to-transparent h-[200px] bottom-4 left-0 right-0 flex items-end justify-center"
            >
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 1.5 }}
              >
                <Tooltip tooltipText="Collect to unlock">
                  <div className="bg-gray-100 p-5 rounded-full">
                    <LockClosedIcon className="h-5 w-5" />
                  </div>
                </Tooltip>
              </motion.div>
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="max-w-2xl mx-auto"
          >
            <img
              src="/images/landing/support-author.png"
              alt="Support the author"
            />
          </motion.div>
        </motion.div>
      </Container>
      <Container
        className="bg-gradient-green lg:rounded-2xl items-center text-white"
        variants={containerMotion}
        initial="initial"
        whileInView="visible"
        viewport={{ once: true }}
        flex
        margin="flat-mobile"
      >
        <div className="max-w-3xl mx-auto text-center py-32 space-y-12 px-4">
          <motion.div variants={childVariants}>
            <img src="/images/landing/unlockables.png" alt="Unlockables" />
          </motion.div>
          <motion.div variants={childVariants} className="space-y-4">
            <Heading size="1" className="!leading-none">
              Elevate the collector experience with{' '}
              <span className="text-black">unlockables</span>
            </Heading>
            <Text size="xl">
              Offer your readers exclusive access to extra content, such as
              alternative covers, audio narrations, and downloadable versions of
              the book. Get creative and think of more ways to provide value to
              your collectors.
            </Text>
          </motion.div>
        </div>
      </Container>
      {/* Collections */}
      <Container
        margin="flat-mobile"
        spacing="large"
        variants={containerMotion}
        initial="initial"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <div className="px-4">
          <div className="perspective-parent scale-95 max-w-3xl mx-auto">
            <motion.div className="perspective-child">
              <img src="/images/landing/collections.png" alt="Collection" />
            </motion.div>
          </div>
          <div className="text-center max-w-4xl mx-auto">
            <motion.div className="mt-8" variants={childVariants}>
              <Heading size="1" className="leading-none">
                Create collections with multi-format content
              </Heading>
              <Text size="xl">
                Collections can include both stories and books, allowing you to
                expand the universe of your stories from long-form literature to
                short-form content.
              </Text>
            </motion.div>
          </div>
        </div>
      </Container>
      {/* Sell it and report */}
      <Container spacing="large" margin="flat-mobile">
        <div className="grid lg:grid-cols-2 lg:gap-16 px-4 lg:px-0 justify-start items-start">
          <motion.div
            className="bg-gray-100 text-center rounded-2xl p-6 lg:p-12 py-24 shadow-2xl space-y-8 bg-[url('/public/images/landing/pattern-1.png')]"
            variants={containerUp}
            initial="initial"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <motion.div className=" max-h-96" variants={childVariants}>
              <img
                src="/images/landing/list-item.png"
                alt="List it in the marketplace"
                className="mx-auto"
              />
            </motion.div>
            <motion.div variants={childVariants} className="space-y-4 pb-8">
              <Heading size="2">
                <span className="dark:text-dark-800">
                  Sell your work directly on the bookstore
                </span>
              </Heading>
              <Text size="lg">
                <span className="dark:text-secondary-600">
                  Selling your stories is now easier than ever. Readers can
                  collect and read your work on Readl Bookstore. To make it
                  easier for web2 users to get started, Readl covers the gas
                  fees for any free content published.
                </span>
              </Text>
            </motion.div>
          </motion.div>
          <motion.div
            className="bg-black mt-16 lg:mt-64 translate-y-64 text-center text-white rounded-2xl p-6 lg:p-12 py-24 shadow-2xl space-y-8 bg-[url('/public/images/landing/pattern-2.png')]"
            variants={containerUp}
            initial="initial"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <motion.div className="max-h-96" variants={childVariants}>
              <img
                src="/images/landing/daily-digest.png"
                alt="Daily Digest"
                className="mx-auto"
              />
            </motion.div>
            <motion.div variants={childVariants} className="space-y-4 pb-8">
              <Heading size="2">Receive daily reports of your sales</Heading>
              <Text size="lg">
                Don&apos;t lose track of your sales. Receive a daily report to
                know exactly how many NFTs you sold and to whom. We also send a
                monthly report so you don’t need to do the math.
              </Text>
            </motion.div>
          </motion.div>
        </div>
      </Container>
      <Container
        variants={containerMotion}
        initial="initial"
        whileInView="visible"
        viewport={{ once: true }}
        margin="flat-mobile"
        className="px-4"
      >
        <motion.div
          className="mx-auto max-w-4xl mb-16"
          variants={childVariants}
        >
          <img
            src="/images/landing/reader.png"
            alt="Collection"
            className="w-full"
          />
        </motion.div>
        <motion.div
          className="max-w-3xl mx-auto text-center space-y-8"
          variants={childVariants}
        >
          <Heading size="1">
            The best of <span className="text-primary-500">web3</span> for your
            readers
          </Heading>
          <Text size="xl">
            We remember who you&apos;re writing for. Readl brings the simplicity
            of Web 2.0 to Web 3.0 and makes it easier to read and collect
            literary content.
          </Text>
        </motion.div>
      </Container>
      <Container
        variants={containerMotion}
        initial="initial"
        whileInView="visible"
        viewport={{ once: true }}
        margin="flat-mobile"
      >
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-y-4 md:gap-4 text-white mt-16 px-4">
          {readerFeatures.map((text) => (
            <motion.div
              className="space-y-4 bg-black rounded-xl p-10"
              variants={childVariantsAlways}
            >
              <Heading size="5" className="text-holo">
                {text.title}
              </Heading>
              <Text>{text.text}</Text>
            </motion.div>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default Features;
