import { useState, useEffect, useRef } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
// eslint-disable-next-line camelcase
import codes, { by639_1 } from 'iso-language-codes';
import { ButtonIcon } from '../Button/Button';
import Input from '../Forms/Forms';
import { MB_IN_B } from '../../utils/constants';
import Toggle from '../Toggle';
import { ListBox } from '../Select/Select';

function UnlockedAudio({ editUnlockable, deleteUnlockable, content }) {
  const [audio, setAudio] = useState();
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: '',
    text: '',
  });
  const element = useRef(null);
  const inputRef = useRef(null);
  const titleRef = useRef(null);

  const addFile = () => {
    let userFile;
    if (inputRef.current?.files?.length) {
      // eslint-disable-next-line prefer-destructuring
      userFile = inputRef.current.files[0];
    } else {
      return deleteUnlockable(content.id);
    }
    editUnlockable(
      {
        file: userFile,
        size: userFile.size,
        title: userFile.name
          .replace('.mp3', '')
          .replace('.aac', '')
          .replace('.gsm', '')
          .replace('.m4a', '')
          .replace('.m4p', '')
          .replace('.oog', '')
          .replace('.oga', '')
          .replace('.mogg', '')
          .replace('.voc', '')
          .replace('.vox', '')
          .replace('.wav', '')
          .replace('.webm', '')
          .replace('.flac', ''),
        fileName: userFile.name,
        fileType: userFile.type,
        error: {
          file: false,
          title: false,
        },
      },
      content.id,
    );

    setAudio(URL.createObjectURL(userFile));
    return true;
  };

  const clean = () => {
    setAudio();
    setLanguagesOptions([]);
    setSelectedLanguage({
      value: '',
      text: '',
    });
    deleteUnlockable(content.id);
  };

  useEffect(() => {
    const languages = codes
      .map((code) => ({
        value: code.iso639_1,
        text: code.name,
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1));
    setLanguagesOptions(languages);
  }, []);

  useEffect(() => {
    if (content?.error.title) {
      titleRef.current.focus();
    }
    if (content?.error.file) {
      titleRef.current.focus();
    }
  }, [content]);

  useEffect(() => {
    if (content.file) {
      element.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [content.file]);

  useEffect(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  return (
    <div ref={element} className="bg-gray-50 dark:bg-dark-800 rounded-md">
      {!content.file ? (
        <input
          ref={inputRef}
          onInput={addFile}
          id="audio-upload"
          name="audio-upload"
          type="file"
          className="sr-only"
          accept=".mp3,audio/*"
        />
      ) : (
        <>
          <div className="bg-gray-100 dark:bg-dark-700 dark:text-white flex px-6 py-4 rounded-md">
            <div className="flex items-center w-10">
              <ButtonIcon
                icon={<TrashIcon />}
                variant="secondary"
                size="sm"
                onClick={clean}
              />
            </div>
            {/* File type section */}
            <div className="ml-4 flex justify-between w-full items-center">
              <div className="flex items-center">
                <div className="flex flex-col">
                  <span className="text-md break-all">{content.fileName}</span>
                  <span className="text-gray-600 dark:text-dark-400 text-xs">
                    {Math.round((content.size / MB_IN_B) * 100) / 100}MB
                  </span>
                </div>
              </div>
            </div>
            <div className="flex w-full items-center">
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <audio
                controls
                controlsList="nodownload noplaybackrate"
                className="w-full"
              >
                <source src={audio} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>
          <div className="p-6 space-y-6">
            <Input
              ref={titleRef}
              label="Title"
              placeholder="File"
              value={content.title}
              onChange={(e) => {
                editUnlockable(
                  {
                    title: e.target.value,
                    error: {
                      title: false,
                    },
                  },
                  content.id,
                );
              }}
              error={content.error.title}
              errorMessage="You need to write a title."
            />
            <Input
              label="Author"
              placeholder="Author"
              value={content.author}
              onChange={(e) => {
                editUnlockable(
                  {
                    author: e.target.value,
                  },
                  content.id,
                );
              }}
            />
            <ListBox
              label="Language"
              options={languagesOptions}
              selectedOption={selectedLanguage}
              setSelectedOption={(e) => {
                setSelectedLanguage(e);
                editUnlockable(
                  {
                    // eslint-disable-next-line camelcase
                    language: by639_1[e.value]?.iso639_1,
                  },
                  content.id,
                );
              }}
              placeholder="Language..."
            />
            <Toggle
              label="Gated"
              description="Only owners can access it."
              enabled={content.isPrivate}
              setEnabled={() =>
                editUnlockable({ isPrivate: !content.isPrivate }, content.id)
              }
            />
            <Toggle
              label="Allow download"
              description="Owners can download the file."
              enabled={content.allowDownload}
              setEnabled={() =>
                editUnlockable(
                  { allowDownload: !content.allowDownload },
                  content.id,
                )
              }
            />
          </div>
        </>
      )}
    </div>
  );
}

export default UnlockedAudio;
