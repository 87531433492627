import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import GridStories from '../../components/GridStories';
import apiService from '../../services/apiService';
import { useSpinner } from '../../context/SpinnerContext';
import useUpdateQueryStringValueWithoutNavigation from '../../hooks/useUpdateQueryStringValueWithoutNavigation';

function Tags() {
  const { showSpinner, hideSpinner } = useSpinner();
  const [searchParams] = useSearchParams();
  const [stories, setStories] = useState([]);
  const [tags, setTags] = useState([]);
  const [queryValue, setQuery] = useState(() => {
    return searchParams.get('q') ?? '';
  });

  const query = queryValue.trim();
  useUpdateQueryStringValueWithoutNavigation('q', query);

  function toggleTag(tag) {
    setQuery((q) => {
      const expression = new RegExp(tag, 'ig');

      const newQuery = expression.test(q)
        ? q.replace(expression, '')
        : `${q} ${tag}`;

      return newQuery.replace(/\s+/g, ' ').trim();
    });
  }
  const regularQuery = query.replace('').trim();

  const getMostUsedTags = async () => {
    try {
      showSpinner();
      const TagsMostUsed = await apiService.get('/parameter?name=mostUsedTags');
      setTags(TagsMostUsed.map((tag) => tag.tag));
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const getTags = async () => {
    try {
      showSpinner();
      const tagsResponse = await apiService.get(`/story/tags/${query}`);
      setStories(tagsResponse);
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };
  useEffect(() => {
    getMostUsedTags();
    getTags();
  }, [query]);

  return (
    <>
      {tags.length > 0 ? (
        <div className="container mx-auto">
          <div className="col-span-full -mb-4 -mr-4 flex flex-wrap lg:col-span-10 px-4">
            {tags.map((tag) => {
              const selected = regularQuery.includes(tag);
              return (
                <span key={tag.tag} className="mb-4 mr-4">
                  <button
                    type="button"
                    onClick={() => toggleTag(tag)}
                    disabled={query && !selected}
                    className={clsx(
                      'inline-flex disabled:opacity-20 space-x-2 items-center justify-start px-3 py-0.5 bg-white bg-opacity-60 border rounded-full',
                      {
                        ' border-gray-100  transition duration-300 hover:ring-1 hover:ring-green-400':
                          !selected,
                        ' border-green-400': selected,
                      },
                    )}
                  >
                    <p className="text-xs font-medium leading-tight">{tag}</p>
                  </button>
                </span>
              );
            })}
          </div>
        </div>
      ) : null}
      <GridStories
        className="px-4"
        title={query && `#${query.trim()}`}
        view={stories}
      />
    </>
  );
}

export default Tags;
