import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Scrollbar,
  Autoplay,
  Mousewheel,
  Pagination,
  Navigation,
} from 'swiper';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';

/* eslint-disable import/no-unresolved */
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function Slider({
  items,
  spaceBetween = 12,
  slidesPerView = 4,
  slidesPerViewBreakpoints = null,
  infiniteLoop = false,
  scrollbar = false,
  autoplay = false,
  mousewheel = false,
  pagination = false,
  navigation = false,
  classNameSlider = '',
  initialSlide = 0,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [breakpoints, setBreakpoints] = useState({});

  useEffect(() => {
    if (slidesPerViewBreakpoints) {
      const fullConfig = resolveConfig(tailwindConfig);

      const breakpointsDetail = {};
      Object.keys(fullConfig.theme.screens).forEach((screenSize) => {
        if (slidesPerViewBreakpoints[screenSize]) {
          const size = fullConfig.theme.screens[screenSize].replace('px', '');
          breakpointsDetail[size] = {
            slidesPerView: slidesPerViewBreakpoints[screenSize],
          };
        }
      });

      setBreakpoints(breakpointsDetail);
    }

    setIsLoaded(true);
  }, [slidesPerViewBreakpoints]);

  return (
    isLoaded &&
    items?.length > 0 && (
      <Swiper
        modules={[Scrollbar, Autoplay, Mousewheel, Pagination, Navigation]}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        breakpoints={breakpoints}
        loop={infiniteLoop}
        scrollbar={scrollbar}
        autoplay={autoplay}
        mousewheel={mousewheel}
        pagination={pagination}
        navigation={navigation}
        className={classNameSlider}
        initialSlide={initialSlide}
      >
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide className="cursor-grab" key={index}>
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    )
  );
}

export default Slider;
