import React from 'react';
import clsx from 'clsx';
import { READER_THEMES, READER_FONTS } from '../../utils/constants';
import MenuReaderSettings from '../../assets/icons/MenuReaderSettings';
import './PopupMenu.css';

// TODO: Replace icons by standalone svg's (https://www.dropbox.com/sh/2zl74ahk7a2vyxn/AAB8_z88ksb9lfqvu30bjU24a?dl=0)
function PopupMenu({
  show,
  theme,
  setTheme,
  fontFamily,
  setFontFamily,
  fontSize,
  setFontSize,
  lineHeight,
  setLineHeight,
}) {
  return (
    <div
      className={clsx(
        'popup-options fixed w-full overflow-y-scroll scroll-box sm:absolute sm:overflow-y-hidden h-auto bottom-0 left-0 right-0 sm:bottom-auto sm:left-auto sm:top-10 px-4 pt-6 pb-8 bg-white sm:w-72 sm:rounded-md z-50',
        { hidden: !show },
      )}
    >
      {/* Theme */}
      <h6 className="uppercase font-semibold text-secondary-300 text-xxs mb-3">
        Theme
      </h6>
      <div className="grid grid-cols-4 justify-items-center">
        {Object.keys(READER_THEMES).map((item) => (
          // eslint-disable-next-line
          <div
            className={clsx(
              'w-12 h-12 bg-white border-secondary-50 text-center border align-middle rounded-full leading-9 flex items-center justify-center',
              {
                'cursor-auto border-2 shadow-xl': item === theme,
              },
              { 'cursor-pointer': item !== theme },
            )}
            style={{ backgroundColor: READER_THEMES[item].backgroundColor }}
            onClick={() => setTheme(item)}
            key={item}
          >
            <MenuReaderSettings color={READER_THEMES[item].fontColor} />
          </div>
        ))}
      </div>

      <div className="w-full h-7" />

      {/* Font Style */}
      <h6 className="uppercase font-semibold text-secondary-300 text-xxs mb-3">
        Font Style
      </h6>
      <div className="grid grid-cols-3 gap-y-4">
        {READER_FONTS.map((font) => (
          // eslint-disable-next-line
          <div
            className={clsx(
              'flex flex-col self-center justify-center text-center text-secondary-300 hover:text-secondary-900',
              {
                'cursor-default text-secondary-900': font.family === fontFamily,
              },
              { 'cursor-pointer': font.family !== fontFamily },
            )}
            onClick={() => setFontFamily(font.family)}
            key={font.family}
          >
            <span
              style={{ fontFamily: font.family }}
              className="text-3xl leading-10"
            >
              Aa
            </span>
            <span
              className={clsx('text-xxs text-secondary-400', {
                'text-secondary-900': font.family === fontFamily,
              })}
            >
              {font.family}
            </span>
          </div>
        ))}
      </div>

      <div className="w-full h-7" />

      {/* Font Size */}
      <h6 className="uppercase font-semibold text-secondary-300 text-xxs mb-3">
        Font Size
      </h6>
      <div className="flex flex-row justify-around items-center space-x-6">
        <div className="w-6">
          <svg
            width="24"
            height="24"
            fill="secondary-900"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6.2 11.6h1.6V18H6.2v-6.4z" fill="" />
            <path
              d="M3.8 11.2h6.4v1.6H3.8v-1.6zM13.8 6.4h1.6v11.601h-1.6v-11.6z"
              fill=""
            />
            <path d="M9 6h11.2v1.6H9V6z" fill="" />
          </svg>
        </div>

        <input
          className="
                rounded-lg
                overflow-hidden
                appearance-none
                bg-gray-400
                h-3
                w-full
              "
          type="range"
          min="70"
          max="200"
          step="10"
          value={fontSize}
          onChange={(e) => setFontSize(e.target.value)}
        />

        <div className="w-6">
          <svg
            width="24"
            height="24"
            fill="secondary-900"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.22 11.463h2.146v8.586H4.219v-8.586z" fill="" />
            <path
              d="M1 10.926h8.585v2.147H1v-2.147zM14.415 4.488h2.146V20.05h-2.146V4.49z"
              fill=""
            />
            <path d="M7.976 3.951H23v2.147H7.976V3.95z" fill="" />
          </svg>
        </div>
      </div>

      <div className="w-full h-7" />

      {/* Line Height */}
      <h6 className="uppercase font-semibold text-secondary-300 text-xxs mb-3">
        Line Height
      </h6>
      <div className="flex flex-row justify-around items-center space-x-6">
        <div className="w-6">
          <svg
            width="24"
            height="24"
            fill="secondary-900"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.333 7.417H1.667A.667.667 0 0 0 1 8.084v.5c0 .368.298.666.667.666h20.666A.667.667 0 0 0 23 8.584v-.5a.667.667 0 0 0-.667-.667zM22.333 11.084H1.667a.667.667 0 0 0-.667.667v.5c0 .368.298.666.667.666h20.666a.667.667 0 0 0 .667-.666v-.5a.667.667 0 0 0-.667-.667zM22.333 14.75H1.667a.667.667 0 0 0-.667.667v.5c0 .368.298.666.667.666h20.666a.667.667 0 0 0 .667-.666v-.5a.667.667 0 0 0-.667-.667z"
              fill=""
            />
          </svg>
        </div>

        <input
          className="
                rounded-lg
                overflow-hidden
                appearance-none
                bg-gray-400
                h-3
                w-full
              "
          type="range"
          min="100"
          max="200"
          step="10"
          value={lineHeight}
          onChange={(e) => setLineHeight(e.target.value)}
        />

        <div className="w-6">
          <svg
            width="24"
            height="24"
            fill="secondary-900"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.333 4.667H1.667A.667.667 0 0 0 1 5.334v.5c0 .368.298.666.667.666h20.666A.667.667 0 0 0 23 5.834v-.5a.667.667 0 0 0-.667-.667zM22.333 11.084H1.667a.667.667 0 0 0-.667.667v.5c0 .368.298.666.667.666h20.666a.667.667 0 0 0 .667-.666v-.5a.667.667 0 0 0-.667-.667zM22.333 17.5H1.667a.667.667 0 0 0-.667.666v.5c0 .368.298.667.667.667h20.666a.667.667 0 0 0 .667-.667v-.5a.667.667 0 0 0-.667-.666z"
              fill=""
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default PopupMenu;
