import PolygonIcon from '../Icons/PolygonIcon';
import PriceConversion from '../PriceConversion';
import PriceFormatted from '../PriceFormatted';

export default function ButtonCollect({ info, onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="inline-flex items-center px-4 py-3 border border-transparent shadow-sm text-base font-medium rounded-lg text-black bg-primary-500 hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 space-x-4 w-full justify-between"
    >
      <div className="flex space-x-4 items-center">
        <PolygonIcon className="h-6 w-6" />
        <div className="flex flex-col text-left">
          <div className="flex flex-col text-left">
            <span className="font-semibold text-base text-black">Collect</span>
          </div>
          <span className="font-semibold text-black text-xs">
            {info.copies} available
          </span>
        </div>
      </div>
      <div className="flex flex-col text-right">
        <div className="flex flex-col">
          <div>
            {info.prices.length > 1 && <span className="text-xs">from </span>}
            <span className="font-semibold text-base text-black">
              {info.prices[0] === '0' ? (
                'Free'
              ) : (
                <PriceConversion price={info.prices[0]} />
              )}
            </span>
          </div>
          <span className="text-xs text-primary-900">
            {info.prices[0] !== '0' && (
              <PriceFormatted price={info.prices[0]} />
            )}
          </span>
        </div>
      </div>
    </button>
  );
}
