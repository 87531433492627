import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') ||
      (window.matchMedia('(prefers-color-scheme: dark)').matches && 'dark') ||
      'light',
  );

  const changeTheme = (newTheme = '') => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const temp = () => {
      const userTheme = localStorage.getItem('theme');
      if (
        !userTheme &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        return changeTheme('dark');
      }
      if (
        !userTheme &&
        !window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        return changeTheme('light');
      }
      if (userTheme !== theme) {
        return setTheme(userTheme);
      }
      return theme;
    };
    temp();
  }, []);

  const data = useMemo(() => {
    return { theme, changeTheme };
  }, [theme, changeTheme]);

  return <ThemeContext.Provider value={data}>{children}</ThemeContext.Provider>;
}

export const useTheme = () => useContext(ThemeContext);
