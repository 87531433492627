import { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import { PencilIcon } from '@heroicons/react/24/solid';
import {
  BookOpenIcon,
  ShareIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { Tab } from '@headlessui/react';
import { useParams } from 'react-router-dom';
import { RWebShare } from 'react-web-share';
import { DateTime } from 'luxon';
import Metrics from '../../components/Metric/Metric';
import Link from '../../components/Link';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import Header from '../../components/Header/Header';
import { getIpfsUrl } from '../../services/ipfsService';
import EmojiSad from '../../assets/icons/EmojiSad';
import GridStories from '../../components/GridStories';
import LoginContext from '../../context/LoginContext';
import CollapseText from '../../components/CollapseText';
import SocialLinks from '../../components/SocialLinks';
import TabsReadl from '../../components/Tab/Tab';
import StoryIcon from '../../assets/icons/StoryIcon';
import { AvatarProfile } from '../Bookshelf/Profile';
import Container from '../../components/Containers';
import Footer from '../../components/Footer';
import UsersSlider from '../../components/UsersSlider';
import { TYPE_BOOK, TYPE_STORY } from '../../utils/constants';

function Collection() {
  const { collectionId } = useParams();
  const { showSpinner, hideSpinner } = useSpinner();
  const { address } = useContext(LoginContext);
  const [collection, setCollection] = useState();
  const [elements, setElements] = useState({
    all: [],
    books: [],
    stories: [],
  });
  const [dropdownActions, setDropdownActions] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [lastCollectors, setLastCollectors] = useState([]);

  const fillters = [
    {
      key: 'all',
      emptyMessage: 'This collection is empty.',
    },
    {
      key: 'books',
      emptyMessage: 'This collection has no books.',
    },
    {
      key: 'stories',
      emptyMessage: 'This collection has no stories.',
    },
  ];

  const getData = async () => {
    try {
      showSpinner();
      const response = await Promise.all([
        apiService.get(`/collection/${collectionId}`),
        apiService.get(`/story/collection/${collectionId}`),
      ]);
      const responseCollection = response[0];
      let responseStories = response[1];
      setCollection(responseCollection);

      responseStories = responseStories.sort((item, item2) => {
        const time1 = DateTime.fromISO(item.createdAt);
        const time2 = DateTime.fromISO(item2.createdAt);
        if (time1 > time2) {
          return -1;
        }
        if (time2 > time1) {
          return 1;
        }
        return 0;
      });
      const books = responseStories.filter((item) => item.type === TYPE_BOOK);
      const stories = responseStories.filter(
        (item) => item.type === TYPE_STORY,
      );
      setElements({
        all: responseStories,
        books,
        stories,
      });

      if (responseCollection.createdBy === address) {
        setDropdownActions([
          {
            name: 'Edit Collection',
            href: `/edit-collection/${collectionId}`,
          },
        ]);
      }
    } catch (err) {
      toast.error('An error has been occurred');
    } finally {
      hideSpinner();
    }
  };

  const getCollectionMetrics = async () => {
    const metricsResponse = await apiService.get(
      `/collection/metrics/${collectionId}`,
    );

    setMetrics([
      { name: 'Sold', value: metricsResponse.sold },
      { name: 'Collectors', value: metricsResponse.collectors },
    ]);
    setLastCollectors(metricsResponse.lastCollectors);
  };

  useEffect(() => {
    getData();
    getCollectionMetrics();
  }, []);

  const bannerStyle = {
    backgroundImage: `url(${
      collection?.cover && getIpfsUrl(collection.cover)
    })`,
    backgroundColor: !collection?.cover && '#B6B6B6',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };

  const tabs = [
    {
      id: 'All',
      title: 'All',
      icon: <SparklesIcon className="w-6 h-6 mr-2" />,
      component: 'All',
    },
    {
      id: 'Books',
      title: 'Books',
      icon: <BookOpenIcon className="w-6 h-6 mr-2" />,
      component: 'Books',
    },
    {
      id: 'Stories',
      title: 'Stories',
      icon: <StoryIcon className="w-6 h-6 mr-2" />,
      component: 'Stories',
    },
  ];

  return (
    collection && (
      <>
        <Header dropdownActions={dropdownActions} />

        <Container margin="flat-mobile" spacing="none" className="relative">
          <div
            style={bannerStyle}
            className="bg-center h-[350px] md:h-[450px] lg:rounded-lg"
          >
            {collection.createdBy === address && (
              <Link
                // eslint-disable-next-line no-underscore-dangle
                to={`/edit-collection/${collection._id}`}
              >
                <div className="hidden lg:inline-flex space-x-2 items-center justify-center h-9 py-2 pl-2.5 pr-3 bg-gray-100 shadow rounded-md absolute right-8 top-4">
                  <PencilIcon className="w-4 h-full text-secondary-400" />
                  <p className="text-sm font-medium leading-none text-gray-900">
                    Edit Collection
                  </p>
                </div>
              </Link>
            )}
            <div className="flex lg:hidden absolute top-4 right-4">
              <RWebShare
                data={{
                  text: `Check out ${collection.name} collection on Readl Stories`,
                  url: `${window.location.origin}/${collectionId}`,
                  title: 'Share this collection',
                }}
              >
                <span className="rounded-full overflow-hidden block shadow-lg hover:shadow-2xl transition-all">
                  <ShareIcon className="w-10 h-10 p-2 sm:w-12 sm:h-12 sm:p-2 lg:w-16 lg:h-16 lg:p-4 bg-white cursor-pointer" />
                </span>
              </RWebShare>
            </div>
            <div className="absolute bottom-6 right-6 z-10 max-w-[265px] hidden lg:block">
              <UsersSlider
                className="max-w-[100px] xs:max-w-[140px] sm:max-w-[300px]"
                users={lastCollectors}
              />
            </div>
          </div>

          <div className="bg-gradient-to-t from-white dark:from-dark-900 to-transparent h-48 w-full hidden lg:block absolute bottom-0 z-0" />
        </Container>
        <Container spacing="none" className="relative -mt-4 z-10">
          <div className="flex flex-col-reverse lg:flex-row justify-between w-full items-start lg:px-8">
            <div className="flex flex-col items-start md:pr-8 w-full">
              <div className="flex items-center space-x-6 my-4">
                <AvatarProfile user={collection} collection />
                <div className="flex flex-col justify-center">
                  <div className="flex">
                    <SocialLinks items={collection} />
                  </div>
                  <h3 className="text-lg sm:text-2xl xl:text-3xl font-bold flex flex-col items-baseline mt-2 dark:text-white">
                    <span className="pr-1 leading-tight">
                      {collection?.name}
                    </span>
                  </h3>
                  <div className="flex items-center">
                    <span className="text-sm text-gray-500 pr-0.5">by</span>
                    <Link
                      href={`/${collection?.createdBy}`}
                      className="text-sm text-gray-500 font-semibold hover:bg-gray-100 p-1 rounded-md"
                    >
                      @{collection?.author}
                    </Link>
                  </div>
                </div>
              </div>
              <CollapseText text={collection.description} />
            </div>
            {/* Collections statistics */}
            <div className="flex flex-col lg:px-0 justify-end w-full lg:w-fit lg:min-w-max lg:pt-10">
              <div className="flex w-full justify-between xl:justify-end flex-start mb-4 lg:mb-0 p-2 xs:p-4 bg-gray-50 dark:bg-dark-800 dark:lg:bg-transparent lg:bg-transparent rounded-xl">
                <Metrics metrics={metrics} />
                <div className="min-w-fit block lg:hidden">
                  <UsersSlider
                    className="max-w-[100px] xs:max-w-[140px] sm:max-w-[300px]"
                    users={lastCollectors}
                  />
                </div>
                <div className="hidden lg:flex">
                  <RWebShare
                    data={{
                      text: `Check out ${collection.name} collection on Readl Stories`,
                      url: `${window.location.origin}/${collectionId}`,
                      title: 'Share this collection',
                    }}
                  >
                    <span className="rounded-full overflow-hidden block shadow-lg hover:shadow-2xl transition-all">
                      <ShareIcon className="w-10 h-10 p-2 sm:w-12 sm:h-12 sm:p-2 lg:w-16 lg:h-16 lg:p-4 bg-white dark:bg-dark-700 dark:text-dark-100 dark:hover:bg-dark-500 dark:hover:text-white cursor-pointer transition duration-300" />
                    </span>
                  </RWebShare>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Container className="pt-16">
          <Tab.Group>
            <Tab.List className="mb-6" aria-label="Tabs">
              <TabsReadl tabs={tabs} wrapperClasses="max-h-[44px] flex" />
            </Tab.List>
            <Tab.Panels>
              {fillters.map(({ key, emptyMessage }) => (
                <Tab.Panel key={elements[key]}>
                  {elements[key].length ? (
                    <GridStories view={elements[key]} />
                  ) : (
                    <div className="flex text-gray-500">
                      <div className="mr-4 flex-shrink-0 self-center">
                        <EmojiSad className="w-6 h-6" />
                      </div>
                      <div>
                        <p className="mt-1 ">{emptyMessage}</p>
                      </div>
                    </div>
                  )}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </Container>
        <Footer />
      </>
    )
  );
}

export default Collection;
