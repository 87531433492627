import { PlusSmallIcon } from '@heroicons/react/24/outline';
import Button from '../Button';
import Container from '../Containers';
import Parallax from '../parallax/parallax';
import StartPublish from '../StartPublish';
import Heading from '../Typography';
import { Text } from '../Typography/Heading';

function FooterCTA() {
  return (
    <Container
      className="bg-black lg:rounded-2xl text-white bg-[url('/public/images/landing/pattern-2.png')]"
      margin="flat-mobile"
    >
      <div className="grid lg:grid-cols-2">
        <div className="-mt-32 xl:-mt-64 xl:-mb-64 lg:order-last">
          <Parallax offset={-100}>
            <img
              src="/images/landing/authors-house.png"
              alt="Authors house"
              className="max-w-2xl mx-auto w-full"
            />
          </Parallax>
        </div>
        <Parallax>
          <div className="flex flex-col justify-center space-y-4 px-6 pb-24 lg:p-20 pr-8 mt-20 lg:mt-16">
            <Heading size="2" className="text-holo">
              Publish your books and stories as NFTs for free
            </Heading>
            <Text size="xl">
              We`re reimagining the publishing model and helping authors grow
              their readership by making it easy to manage, distribute and
              monetize their content.
            </Text>
            <StartPublish>
              <Button
                variant="primary"
                size="lg"
                flex
                className="min-w-[130px]"
                iconLeft={<PlusSmallIcon />}
              >
                Get started
              </Button>
            </StartPublish>
          </div>
        </Parallax>
      </div>
    </Container>
  );
}

export default FooterCTA;
