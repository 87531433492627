import clsx from 'clsx';

function ItemCover({ image, type, className }) {
  return (
    <div
      className={clsx(
        'aspect-[4/6] overflow-hidden safari-mask-fix flex items-center bg-black bg-opacity-10 relative backdrop-blur-sm rounded-lg',
        className,
      )}
    >
      <img
        className="align-middle h-full min-w-full object-contain w-auto z-10 scale-100 group-hover:scale-110 transition-transform duration-700"
        src={image}
        alt=""
      />
      <div
        className="absolute w-full h-full opacity-40 rounded-lg "
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="backdrop-blur-md absolute w-full h-full rounded-lg " />
        {type}
      </div>
    </div>
  );
}

function GridItemReveal({ data }) {
  return (
    <div>
      <section className="relative bg-no-repeat bg-contain rounded-lg group">
        <ItemCover image={data.image} />
        <div className="absolute bottom-0 w-full px-4 pb-6 z-10 space-y-2 transition-all duration-700">
          <h2 className="text-base sm:text-lg text-white font-semibold break-words">
            {data.name}
          </h2>
        </div>
      </section>
    </div>
  );
}

export default GridItemReveal;
