import React from 'react';
import IconTablerDiscord from '../../assets/icons/IconTablerDiscord';
import { ButtonLink } from '../Button/Button';
import Heading from '../Typography';

function JoinDiscord() {
  return (
    <div className="bg-gray-50 dark:bg-dark-800 rounded-2xl px-4 md:px-8 py-8 md:py-12 flex flex-col text-center md:text-left space-y-6 md:space-y-0 md:flex-row justify-between items-center mt-12">
      <Heading size="5">Do you need additional support?</Heading>
      <ButtonLink
        variant="dark"
        iconRight={<IconTablerDiscord />}
        flex
        target="_blank"
        to="https://discord.com/invite/ykTZT3CZw8"
      >
        Join Discord
      </ButtonLink>
    </div>
  );
}

export default JoinDiscord;
