import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Tabs({ tabs, handleTabChange, currentTab }) {
  const onTabChange = (index) => {
    handleTabChange(index);
  };

  return (
    <nav className="-mb-px flex space-x-2 cursor-pointer" aria-label="Tabs">
      {tabs.map((tab, index) => (
        // eslint-disable-next-line
        <div
          key={tab}
          className={classNames(
            currentTab === index
              ? 'border-black dark:border-primary-500 text-black dark:text-white font-semibold text-sm md:text-lg'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            'whitespace-nowrap py-4 px-4 md:px-8 border-b-2 font-medium text-sm md:text-lg',
          )}
          onClick={() => onTabChange(index)}
        >
          {tab}
        </div>
      ))}
    </nav>
  );
}
