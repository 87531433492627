import { useState, useEffect } from 'react';
import clsx from 'clsx';
import Avatar from '../Avatar';
import PriceConversion from '../PriceConversion';
import PriceFormatted from '../PriceFormatted';

function ListingItem({ listing, creator, active, onClick }) {
  return (
    // eslint-disable-next-line
    <div
      className={clsx(
        'flex justify-between w-full py-4 items-center hover:bg-gray-100 rounded-xl px-4 transition-colors duration-200 cursor-pointer',
        {
          'bg-gray-100 border-2 border-primary-500': active,
        },
      )}
      onClick={onClick}
    >
      <div className="flex space-x-2 flex-1">
        <div>
          <Avatar name={listing.createdByName} size="md" />
        </div>
        <div className="flex flex-col w-full break-all">
          {creator && (
            <p className="text-xs font-semibold text-gray-500 uppercase">
              Creator
            </p>
          )}
          <p className="text-sm line-clamp-1">{listing.createdByName}</p>
          <p className="text-xs line-clamp-1 text-gray-500">@username</p>
        </div>
      </div>
      <div className="text-right">
        <p className="text-sm font-semibold">
          <PriceConversion price={listing.price} />
        </p>
        <p className="font-semibold text-gray-500 text-xs">
          <PriceFormatted price={listing.price} />
        </p>
      </div>
    </div>
  );
}

export default function StoryListings({
  authorListing,
  otherListings = [],
  selectListing,
}) {
  const [listingSelected, setListingSelected] = useState();

  useEffect(() => {
    if (authorListing) {
      setListingSelected(-1);
    } else {
      setListingSelected(0);
    }
  }, []);

  return (
    <div className="w-full md:w-1/2 py-12 px-8 space-y-6">
      <div>
        <h5 className="font-bold mb-2">Buy from the author</h5>
        {authorListing ? (
          <ListingItem
            listing={authorListing}
            active={listingSelected === -1}
            onClick={() => {
              setListingSelected(-1);
              selectListing(-1);
            }}
          />
        ) : (
          <div className="text-sm text-gray-600">No orders from the author</div>
        )}
      </div>
      <div>
        <h6 className="font-bold mb-2">Other collectors</h6>
        <div className="max-h-[230px] overflow-y-auto custom-scrollbar">
          {otherListings.length ? (
            <div className="flex flex-col space-y-1">
              {otherListings.map((listing, index) => (
                <ListingItem
                  listing={listing}
                  active={listingSelected === index}
                  onClick={() => {
                    setListingSelected(index);
                    selectListing(index);
                  }}
                  key={listing.orderHash}
                />
              ))}
            </div>
          ) : (
            <div className="text-sm text-gray-600">
              No orders from other collectors
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
