// import the component
import Mailchimp from 'react-mailchimp-form';

function MailchimpForm() {
  return (
    <Mailchimp
      action="https://readl.us14.list-manage.com/subscribe/post?u=a0c4f9e2a19a876d96c82f1b3&amp;id=b0fac8c39c&amp;f_id=00d5ece0f0"
      fields={[
        {
          name: 'EMAIL',
          placeholder: 'Email',
          type: 'email',
          required: true,
        },
        {
          name: '7159038',
          type: 'hidden',
          value: '7159038',
          required: true,
        },
      ]}
      messages={{
        sending: 'Sending...',
        success: 'We will keep you updated',
        error: 'An unexpected internal error has occurred.',
        empty: 'You must write an e-mail.',
        duplicate: 'Too many subscribe attempts for this email address',
        button: 'Get Notified',
      }}
    />
  );
}

export default MailchimpForm;
