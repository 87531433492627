import { createContext, useEffect, useContext, useMemo, useState } from 'react';
import apiService from '../services/apiService';

const ConvertContext = createContext();
const POLLING_INTERVAL = 60000;

export function ConvertProvider({ children }) {
  const [isConvertReady, setIsConvertReady] = useState(false);
  const [USDPrice, setUSDPrice] = useState();
  const [isMaintenance, setIsMaintenance] = useState(false);

  const getConversionData = async () => {
    try {
      const maticusd = await apiService.get('/parameter?name=maticusd');
      setUSDPrice(maticusd);

      setIsMaintenance(false);
    } catch (err) {
      if (err.response.status === 503) {
        setIsMaintenance(true);
      }
    }
  };

  const initialize = async () => {
    await getConversionData();
    setIsConvertReady(true);
    setInterval(getConversionData, POLLING_INTERVAL);
  };

  useEffect(() => {
    initialize();
  }, []);

  const data = useMemo(() => {
    return { isConvertReady, USDPrice, isMaintenance };
  });

  return (
    <ConvertContext.Provider value={data}>{children}</ConvertContext.Provider>
  );
}

export const useConvert = () => useContext(ConvertContext);
