import { motion } from 'framer-motion';
import IconTablerLinkedin from '../../assets/icons/IconTablerLinkedin';
import IconTablerTwitter from '../../assets/icons/IconTablerTwitter';
import Container from '../../components/Containers';
import Footer from '../../components/Footer';
import Heading from '../../components/Typography';
import { Text } from '../../components/Typography/Heading';

const teamText = [
  {
    image: 'Diego.jpg',
    name: 'Diego Torres',
    job: 'Cofounder & CEO',
    twitter: 'https://twitter.com/_DiegoTorres_',
    linkedin: 'https://www.linkedin.com/in/torreslopezdiego/',
  },
  {
    image: 'Nuno.jpg',
    name: 'Nuno Figueiredo',
    job: 'Cofounder & Product Owner',
    twitter: 'https://twitter.com/oyshiu',
    linkedin: 'https://linkedin.com/nunofigueiredodesign',
  },
  {
    image: 'Giulia.jpg',
    name: 'Giulia Motteran',
    job: 'Cofounder & Head of Community',
    twitter: 'https://twitter.com/giuliamotty',
    linkedin: 'https://www.linkedin.com/in/giuliamotteran/',
  },
  {
    image: 'Jose.jpg',
    name: 'José Juan',
    job: 'Cofounder & CTO',
    twitter: 'https://twitter.com/josejuansanz',
    linkedin: 'https://www.linkedin.com/in/josejuansanz/',
  },
  {
    image: 'Margarita.jpg',
    name: 'Margarita Guerrero',
    job: 'Head of Publishing',
    twitter: 'https://twitter.com/margarita_readl',
    linkedin: 'https://www.linkedin.com/in/margarita-guerrero-48410423/',
  },
  {
    image: 'Ricardo.jpg',
    name: 'Ricardo Castro',
    job: 'Marketing Specialist',
    twitter: 'https://twitter.com/ricardojcastro_',
    linkedin: 'https://www.linkedin.com/in/ricardojcastro/',
  },
  {
    image: 'Jhonathan.jpg',
    name: 'Jhonattan Ramírez',
    job: 'Blockchain Lead Dev',
    linkedin: 'https://www.linkedin.com/in/jhonattan-ramirez/',
  },
  {
    image: 'Jesus.jpg',
    name: 'Jesús Beltran',
    job: 'Blockchain Dev',
    linkedin: 'https://www.linkedin.com/in/jesusbeltran561/',
  },
  {
    image: 'Michael.jpg',
    name: 'Michael Chacón',
    job: 'Frontend Dev',
    linkedin: 'https://www.linkedin.com/in/mcljs/',
  },
  {
    image: 'Jhonny.jpg',
    name: 'Jhonny Varela',
    job: 'Full-stack Dev',
    linkedin: 'https://www.linkedin.com/in/jhonny-varela-visbal-172441209/',
  },
];

const partnersText = [
  {
    name: 'Outlier Ventures',
    image: 'outlierventures.png',
  },
  {
    name: 'Moonbeam',
    image: 'moonbeam.png',
  },
  {
    name: 'Transak',
    image: 'transak.png',
  },
  {
    name: 'Cambridge Blockchain Society',
    image: 'cambridgeassociation.png',
  },
  {
    name: 'Getronics',
    image: 'getronics.png',
  },
  {
    name: 'Automata',
    image: 'automata.png',
  },
  {
    name: 'Peninsula',
    image: 'peninsula.png',
  },
];

const containerMotion = {
  initial: { opacity: 0, transition: { duration: 1 } },
  visible: {
    opacity: 1,
    transition: { duration: 0.5, staggerChildren: 0.1 },
  },
};

const childSequential = {
  initial: { opacity: 0, y: 25 },
  visible: { opacity: 1, y: [25, -15, 0], transition: { duration: 1 } },
};

function TeamMember({ image, name, job, twitter, linkedin }) {
  return (
    <motion.div className="space-y-4 group" variants={childSequential}>
      <img
        alt={name}
        src={`/images/team/${image}`}
        className="group-hover:scale-105 transition-all duration-300 rounded-lg"
      />
      <div className="space-y-1">
        <Heading size="5">{name}</Heading>
        <Text size="sm" className="text-gray-500">
          {job}
        </Text>
        <div className="flex space-x-2 pt-2">
          {twitter && (
            <a href={twitter} target="_blank" rel="noreferrer">
              <IconTablerTwitter className="w-6 h-6 hover:text-primary-500 transition-all duration-200" />
            </a>
          )}
          {linkedin && (
            <a href={linkedin} target="_blank" rel="noreferrer">
              <IconTablerLinkedin className="w-6 h-6 hover:text-primary-500 transition-all duration-200" />
            </a>
          )}
        </div>
      </div>
    </motion.div>
  );
}

function Partner({ name, image }) {
  return (
    <div className="text-center min-h-[120px] p-8 flex items-center">
      <img
        alt={name}
        src={`/images/partners/${image}`}
        className="w-full max-w-[200px] max-h-24 p-3 mx-auto"
      />
    </div>
  );
}

export default function AboutUs() {
  return (
    <div>
      <Container
        initial="initial"
        whileInView="visible"
        variants={containerMotion}
        viewport={{ once: true }}
        className="py-16 md:py-36 lg:py-48 items-center flex flex-col md:flex-row"
      >
        <div className="md:w-7/12 md:pr-8 lg:pr-24">
          <Heading size="3" className="mb-6">
            We believe that technology is a tool of freedom and that it can be
            used to empower creators.
          </Heading>
          <Text size="lg">
            We see a broken system in the publishing industry—one where authors
            don&rsquo;t make enough money, publishers don&rsquo;t make enough
            money, and readers get none of the ownership they deserve. We want
            to change that.
          </Text>
        </div>
        <div className="md:w-5/12 md:px-6 lg:px-12">
          <Text size="lg">
            We&rsquo;ve built Readl as a platform for authors, publishers, and
            production houses to connect with their readers directly in new ways
            that offer both parties a fair deal. Authors will be able to make
            more money off their work; publishers will be able to keep more of
            their profits, and readers will be able to own digital content for
            themselves.
          </Text>
        </div>
      </Container>
      <Container
        initial="initial"
        whileInView="visible"
        variants={containerMotion}
        viewport={{ once: true }}
      >
        <Heading size="4" className="uppercase mb-8" variants={childSequential}>
          A real team behind a real product
        </Heading>
        <div className="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8 justify-center">
          {teamText.map((text) => (
            <TeamMember
              name={text.name}
              image={text.image}
              job={text.job}
              twitter={text.twitter}
              linkedin={text.linkedin}
              key={text.name}
            />
          ))}
        </div>
      </Container>
      <Container
        className="bg-black lg:rounded-3xl pt-24 pb-24 px-4"
        margin="flat-mobile"
        initial="initial"
        whileInView="visible"
        spacing="small"
        variants={containerMotion}
        viewport={{ once: true }}
      >
        <Heading
          variants={childSequential}
          size="3"
          className="uppercase text-center text-white"
        >
          Partners
        </Heading>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
          {partnersText.map((text) => (
            <motion.div variants={childSequential}>
              <Partner name={text.name} image={text.image} />
            </motion.div>
          ))}
        </div>
      </Container>

      <Footer />
    </div>
  );
}
