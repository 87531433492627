import React from 'react';

function IconTablerLinkedin({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <rect width="16" height="16" x="4" y="4" rx="2" />
      <path d="M8 11L8 16" />
      <path d="M8 8L8 8.01" />
      <path d="M12 16L12 11" />
      <path d="M16 16v-3a2 2 0 00-4 0" />
    </svg>
  );
}

export default IconTablerLinkedin;
