import React from 'react';

function StoryIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        fill="currentColor"
        d="M19 2H3v17.273c0 .723.316 1.417.879 1.928A3.159 3.159 0 006 22h12c.796 0 1.559-.287 2.121-.799.563-.511.879-1.205.879-1.928V2h-2zm0 17.273a.87.87 0 01-.293.643c-.187.17-.442.266-.707.266H6c-.265 0-.52-.096-.707-.266A.87.87 0 015 19.273V3.818h14v15.455zm-2-10.91H7V6.546h10v1.819zM17 12H7v-1.818h10V12zm-4 3.636H7v-1.818h6v1.818z"
      />
    </svg>
  );
}

export default StoryIcon;
