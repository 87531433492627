import React, { useContext, useState } from 'react';
import TransakContext from '../../context/TransakContext';
import PriceFormatted from '../../components/PriceFormatted';
import WalletInfo from '../../components/WalletInfo';
import Modal from '../../components/Modal';

function ModalAddFunds({ price }) {
  const { transak } = useContext(TransakContext);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  return (
    <div>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="py-3 px-4 w-full text-black box-border border-2 border-primary-400 hover:text-primary-600 rounded-lg focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors mt-5 dark:text-primary-500 dark:hover:bg-primary-500 dark:hover:text-black"
      >
        Fund my wallet
      </button>

      <Modal open={open} setOpen={setOpen}>
        <div className="flex space-x-1 items-center">
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="inline-flex items-center px-2 py-3 border border-transparent text-lg font-medium rounded-lg text-gray-500 hover:text-black focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-transparent dark:hover:text-primary-500"
          >
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 19L3 12M3 12L10 5M3 12L21 12"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <h5 className="font-semibold dark:text-white">Add funds</h5>
        </div>
        <div className="mt-3 space-y-2">
          <div
            aria-hidden="true"
            className="border border-gray-200 hover:bg-gray-50 cursor-pointer flex justify-between space-x-6 p-4 rounded-lg items-center group transition-all min-h-[88px] dark:text-white dark:border-dark-700 dark:hover:bg-dark-800"
            onClick={() => transak.init()}
          >
            <div>
              <p className="font-medium">Credit card or bank transfer</p>
              <p className="text-xs text-gray-500">
                Easy and quick for begginners
              </p>
            </div>
            <div className="w-6">
              <svg
                className="h-6 w-6 group-hover:text-primary-500 transition-all"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 5L16 12L9 19"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div
            aria-hidden="true"
            onClick={() => setOpen1(true) && setOpen(false)}
            className="border border-gray-200 hover:bg-gray-50 cursor-pointer flex justify-between space-x-6 p-4 rounded-lg items-center group transition-all min-h-[88px] dark:text-white dark:border-dark-700 dark:hover:bg-dark-800"
          >
            <div>
              <p className="font-medium">External wallet</p>
              <p className="text-xs text-gray-500">
                For everyone who already have a wallet and crypto
              </p>
            </div>
            <div className="w-6">
              <svg
                className="h-6 w-6 group-hover:text-primary-500 transition-all"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 5L16 12L9 19"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={open1} setOpen={setOpen1}>
        <div className="flex space-x-1 items-center">
          <button
            type="button"
            onClick={() => setOpen1(false) && setOpen(true)}
            className="inline-flex items-center px-2 py-3 border border-transparent text-lg font-medium rounded-lg text-gray-500 hover:text-black focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-transparent dark:hover:text-primary-500"
          >
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 19L3 12M3 12L10 5M3 12L21 12"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <h5 className="font-semibold dark:text-white">
            Add funds from external wallet
          </h5>
        </div>
        <div className="mt-2">
          <p className="text-gray-500 text-sm dark:text-dark-200">
            Copy address below and transfer at least{' '}
            <span className="font-medium">
              <PriceFormatted price={price} />
            </span>{' '}
            to collect your story.
          </p>
        </div>
        <WalletInfo />
        {/* <div className="mt-3 space-y-2">
                      <button
                        type="button"
                        className="py-3 px-4 w-full text-black border-2 border-gray-100 rounded-lg transition-colors inline-flex items-center justify-center mt-5 cursor-default"
                      >
                        <svg
                          role="status"
                          className="w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>{' '}
                        Awaiting deposit
                      </button>
                    </div> */}
      </Modal>
    </div>
  );
}

export default ModalAddFunds;
