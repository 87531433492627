import { Fragment, useContext, useState, useEffect } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { Dialog } from '@headlessui/react';
import { ethers, utils } from 'ethers';
import ReactGA from 'react-ga4';
import LoginContext from '../../context/LoginContext';
import { useSpinner } from '../../context/SpinnerContext';
import Modal from '../Modal/Modal';
import PriceFormatted from '../PriceFormatted';
import PriceConversion from '../PriceConversion';
import Heading from '../Typography';
import { Text } from '../Typography/Heading';
import Input from '../Forms/Forms';
import Button from '../Button';

function WithdrawModal({ open, setOpen }) {
  const [openCheck, setOpenCheck] = useState(false);
  const [balanceInWeis, setBalanceInWeis] = useState(0);
  const [balanceOf, setbalanceOf] = useState(0);
  const [amount, setAmount] = useState(0);
  const [wallet, setWallet] = useState('');
  const [checkedVerification, setCheckedVerification] = useState(true);
  const { showSpinner, hideSpinner } = useSpinner();
  const { ethersProvider, address } = useContext(LoginContext);

  // TODO: Calculate it
  const estimatedGas = 0.0001;

  const getBalance = async () => {
    const balance = await ethersProvider.getBalance(address);
    setBalanceInWeis(balance);
    setbalanceOf(ethers.utils.formatEther(balance));
  };

  useEffect(() => {
    if (open) {
      getBalance();
    }
  }, [open, balanceOf]);

  // eslint-disable-next-line consistent-return
  const onWithdraw = async () => {
    if (
      amount <= 0 ||
      amount > balanceOf ||
      !utils.isAddress(wallet) ||
      checkedVerification
    ) {
      return null;
    }
    showSpinner();
    try {
      const signer = await ethersProvider.getSigner();
      await signer.sendTransaction({
        to: wallet,
        value: utils.parseEther(amount),
      });
      setOpenCheck(true);
      setAmount(0);
      ReactGA.event({
        category: 'funds',
        action: 'view_withdraw_successfully',
        value: amount,
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      hideSpinner();
    }
  };

  return (
    address && (
      <>
        <Modal open={open} setOpen={setOpen} allowClickToClose>
          <div className="space-y-2">
            <Heading size="4">Withdraw funds</Heading>
            <Text className="dark:!text-dark-200">
              Send your funds to another wallet address.
            </Text>
          </div>
          <div className="mt-6">
            <p className="text-sm font-medium leading-tight text-gray-900 dark:text-dark-200">
              Quantity to withdraw
            </p>
            <p className="text-sm leading-tight text-gray-500 dark:text-dark-400 mt-0.5">
              <PriceFormatted price={balanceInWeis} /> available
            </p>

            <div className="inline-flex space-x-2 items-center justify-between w-full mt-1">
              <div className="flex space-x-2 items-center justify-start">
                <div className="relative mt-1 ">
                  <Input
                    type="number"
                    name="price"
                    id="price"
                    pattern="^[0-9]+"
                    min="0"
                    size="md"
                    placeholder="0"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    aria-describedby="price-currency"
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span
                      className="text-gray-500 dark:text-dark-400"
                      id="price-currency"
                    >
                      MATIC
                    </span>
                  </div>
                </div>

                <p className="text-base leading-normal text-gray-500 dark:text-dark-400 pt-1">
                  <PriceConversion price={balanceInWeis} />
                </p>
              </div>
              <button
                type="button"
                onClick={() => setAmount(`${balanceOf - estimatedGas}`)}
                className="inline-flex items-center rounded-md bg-gray-100 px-4 py-2 font-medium text-gray-700 shadow-sm  focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:text-sm"
              >
                All
              </button>
            </div>
          </div>
          <div>
            <div className="mt-6">
              <Input
                type="text"
                name="wallet"
                id="wallet"
                label="Destination wallet address"
                autoComplete="off"
                onChange={(e) => setWallet(e.target.value)}
                placeholder="0x00000000000000000000000000"
              />
            </div>
          </div>
          <div className="flex items-center mt-6">
            <input
              id="check-verification"
              name="check-verification"
              type="checkbox"
              onChange={() => setCheckedVerification(!checkedVerification)}
              className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
            />
            <label
              htmlFor="check-verifications"
              className="ml-2 block text-sm text-gray-700 dark:text-dark-300"
            >
              I verified that the destination address is MATIC compatible
            </label>
          </div>
          <Button
            width="full"
            className="mt-6"
            disabled={
              amount <= 0 ||
              amount > balanceOf ||
              !utils.isAddress(wallet) ||
              checkedVerification
            }
            onClick={onWithdraw}
          >
            Widthdraw
          </Button>
        </Modal>

        <Modal open={openCheck} setOpen={setOpenCheck}>
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 dark:bg-dark-700">
              <CheckIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-normal text-center text-gray-900 dark:text-dark-100"
              >
                Withdraw successful
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500 dark:text-dark-300">
                  We have sent your funds to the address {wallet}. It may take
                  from 1 to 10 minutes.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <Button
              width="full"
              onClick={() => {
                setOpen(false);
                setOpenCheck(false);
              }}
            >
              Done
            </Button>
          </div>
        </Modal>
      </>
    )
  );
}

export default WithdrawModal;
