import React from 'react';

function ReadlWhiteLogo({ className }) {
  return (
    <svg
      width="84"
      height="32"
      viewBox="0 0 84 32"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9718 12.1326C23.6788 8.62382 21.4241 4.70179 16.0506 3.68059C14.1108 3.31259 12.0111 2.96087 10.406 2.67779C10.1356 2.62952 9.857 2.65922 9.60281 2.76342L3.73887 5.07333L3.70703 5.08607L3.6858 5.09456C3.49797 5.17236 3.32417 5.28042 3.1713 5.41444C2.71744 5.81027 2.49074 6.33137 2.49121 6.97773V27.1363L7.09121 27.9481V19.1982L12.0182 20.07C13.2717 20.2913 14.1361 20.6815 14.6111 21.2406C15.0428 21.7916 15.3023 22.7878 15.3896 24.2291C15.42 25.8016 15.4604 28.0677 15.5142 29.3762L20.2868 30.2693V24.2051C20.2868 21.0205 18.2197 19.4317 17.2692 18.9186C18.3492 18.8059 19.1916 18.3279 19.7964 17.4848C20.3583 16.7191 20.6393 15.6236 20.6393 14.1983C20.6393 11.9946 20.0559 10.2713 18.8891 9.02862C17.6361 7.77034 15.7998 6.92795 13.3805 6.50145L7.73663 5.50644L10.3346 4.48311L14.1469 5.15967C16.8361 5.63311 18.7179 6.42785 20.1566 7.91542C21.6095 9.41785 22.2089 11.4617 22.2089 13.3993C22.2089 16.2082 21.6265 18.0659 20.2904 18.9399C21.2988 19.6596 21.882 21.3227 21.882 23.1287V29.7937L23.7765 28.9027C23.7651 28.3578 23.7765 23.2907 23.7765 22.3877C23.7765 20.581 23.3738 19.3588 22.7786 18.5995C23.6908 17.5931 24.2365 15.2931 23.9718 12.1326ZM15.1964 11.4879C15.7154 12.054 15.9749 12.8344 15.9749 13.8289C15.9749 14.8234 15.7154 15.534 15.1964 15.9605C14.5482 16.3214 13.6624 16.4028 12.539 16.2046L7.09475 15.2443V9.28197L12.798 10.2876C13.8775 10.4782 14.6763 10.8783 15.1943 11.4879H15.1964Z"
        fill="white"
      />
      <path
        d="M33.2857 13.7852C28.9688 13.7852 25.4551 17.3236 25.4551 21.671C25.4575 23.747 26.2833 25.7374 27.7513 27.2054C29.2193 28.6734 31.2096 29.4992 33.2857 29.5016C35.343 29.5016 37.1532 28.6842 38.5226 27.1372L38.6705 26.9695L35.0103 24.8867L34.919 24.9426C34.4281 25.2424 33.8615 25.395 33.2864 25.3821C31.9765 25.3821 30.7387 24.4487 30.1825 23.4713H40.4758L40.5091 23.3298C40.6245 22.8394 40.6782 22.0496 40.6782 21.671C40.6782 19.5479 39.943 17.577 38.609 16.1128C37.2403 14.6117 35.3479 13.7852 33.2857 13.7852ZM30.1209 19.8989C30.6559 18.5932 31.745 17.9046 33.2857 17.9046C34.5907 17.9046 35.6274 18.6449 36.1023 19.8989H30.1209Z"
        fill="white"
      />
      <path
        d="M50.2479 13.7852C48.1637 13.7852 46.165 14.6131 44.6913 16.0868C43.2176 17.5605 42.3896 19.5592 42.3896 21.6434C42.3896 23.7275 43.2176 25.7263 44.6913 27.2C46.165 28.6737 48.1637 29.5016 50.2479 29.5016C51.6208 29.5016 52.7842 29.2327 53.8033 28.6792H57.7225V14.6075H53.8033C52.7842 14.0548 51.6208 13.7852 50.2479 13.7852ZM50.2479 25.3821C48.2012 25.3821 46.5367 23.7049 46.5367 21.6434C46.5367 19.5819 48.2012 17.9046 50.2479 17.9046C51.9555 17.9046 53.6844 19.1891 53.6844 21.6434C53.6844 24.0976 51.9555 25.3821 50.2479 25.3821Z"
        fill="white"
      />
      <path
        d="M70.9062 14.5431C69.8623 14.0326 68.7136 13.7731 67.5517 13.7852C65.4577 13.7726 63.4426 14.5837 61.9411 16.0434C61.1999 16.7636 60.6115 17.6259 60.2112 18.5788C59.8109 19.5316 59.6069 20.5554 59.6114 21.5889C59.6114 25.9518 63.1732 29.5016 67.5517 29.5016C68.943 29.5016 70.1157 29.2327 71.1348 28.6793H75.0533V6.62402H70.9062V14.5431ZM67.5517 25.3545C67.0528 25.3614 66.5575 25.269 66.0946 25.0828C65.6317 24.8966 65.2104 24.6203 64.8552 24.2699C64.5 23.9195 64.2179 23.5021 64.0254 23.0418C63.8329 22.5815 63.7337 22.0875 63.7337 21.5885C63.7337 21.0896 63.8329 20.5956 64.0254 20.1353C64.2179 19.675 64.5 19.2575 64.8552 18.9071C65.2104 18.5568 65.6317 18.2804 66.0946 18.0943C66.5575 17.9081 67.0528 17.8157 67.5517 17.8225C69.2735 17.8225 71.0194 19.1162 71.0194 21.5889C71.0194 24.0616 69.2735 25.3545 67.5517 25.3545V25.3545Z"
        fill="white"
      />
      <path d="M81.5087 6.625H77.334V28.6788H81.5087V6.625Z" fill="white" />
    </svg>
  );
}

export default ReadlWhiteLogo;
