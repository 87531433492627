import { motion } from 'framer-motion';
import Container from '../../components/Containers';
import MailchimpForm from '../../components/MailchimpForm/MailchimpForm';
import Heading from '../../components/Typography';
import { Text } from '../../components/Typography/Heading';
import {
  childVariants,
  containerMotion,
} from '../../components/MotionAnimations/motion-variants';

function EmailCTA() {
  return (
    <Container
      className="min-h-[80vh] items-center"
      flex
      variants={containerMotion}
      initial="initial"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <motion.div
        className="text-center max-w-3xl mx-auto"
        variants={childVariants}
      >
        <div className="inline-block text-sm uppercase bg-primary-500 rounded-lg font-bold p-3">
          Very Soon
        </div>
        <Heading size="1">
          <span className="text-primary-500">Empowering</span> authors
        </Heading>
        <Text size="xl" className="mt-6 max-w-xl mx-auto">
          We are working on new ways to set you free of centralized platforms
          and give you control over your readership.
        </Text>
        <div
          id="mailchimpform"
          className="flower-girls relative max-w-[425px] mx-auto mt-10"
        >
          <MailchimpForm />
        </div>
      </motion.div>
    </Container>
  );
}

export default EmailCTA;
