/* eslint-disable */
import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';

export default function Badge({
  children = 'badge',
  dot,
  variant = 'yellow',
  size,
  rounded = 'full',
  remove,
  onClick,
  ...BageProps
}) {
  const [show] = useState(true);
  return (
    <div className="min-w-fit">
      <Transition
        show={show}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <span
          {...BageProps}
          className={clsx('inline-flex items-center font-medium', {
            'bg-gray-100 text-gray-700 dark:bg-dark-700 dark:text-dark-100':
              variant === 'gray',
            'bg-red-100 text-red-800': variant === 'red',
            'bg-yellow-100 text-yellow-800': variant === 'yellow',
            'bg-green-100 text-green-800': variant === 'green',
            'bg-blue-100 text-blue-800': variant === 'blue',
            'bg-indigo-100 text-indigo-800': variant === 'indigo',
            'bg-purple-100 text-purple-800': variant === 'purple',
            'bg-pink-100 text-pink-800': variant === 'pink',
            'border border-gray-100 text-gray-700 dark:border-dark-700 dark:text-dark-200 dark:hover:bg-dark-700':
              variant === 'outline',
            'border border-transparent bg-black bg-opacity-60 text-white':
              variant === 'onCard',
            'bg-yellow-500 text-white': variant === 'book',
            'bg-gradient-to-r from-[#24D4FB] to-[#04B7EF] blue text-white':
              variant === 'story',
            'rounded-xs': rounded === 'xs',
            'rounded-sm': rounded === 'sm',
            rounded: rounded === 'base',
            'rounded-lg': rounded === 'lg',
            'rounded-xl': rounded === 'xl',
            'rounded-full': rounded === 'full',
            'px-1.5 py-1 text-xs': size === 'sm',
            'px-2 py-1.5 text-xs': size === 'md',
            'px-3 py-2 text-xs': size === 'lg',
            'px-3 py-3 text-sm': size === 'xl',
            'pl-1 pr-1 py-0.5 text-xs': size === '',
          })}
        >
          {dot && (
            <svg
              className="-ml-0.5 mr-1.5 h-2 w-2 "
              fill="currentColor"
              viewBox="0 0 8 8"
            >
              <circle cx={4} cy={4} r={3} />
            </svg>
          )}
          {children}
          {remove && (
            <button
              type="button"
              onClick={onClick}
              className={clsx(
                'flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center ',
                {
                  'text-secondary-400 hover:bg-secondary-200 hover:text-secondary-500 focus:outline-none focus:bg-secondary-500 focus:text-white':
                    variant === 'gray',
                },
              )}
            >
              <span className="sr-only">Remove small option</span>
              <svg
                className="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          )}
        </span>
      </Transition>
    </div>
  );
}
