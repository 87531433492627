import * as React from 'react';
import IconTablerDiscord from '../../assets/icons/IconTablerDiscord';
import IconTablerTwitter from '../../assets/icons/IconTablerTwitter';
import IconTablerLinkedin from '../../assets/icons/IconTablerLinkedin';
import IconTablerMedium from '../../assets/icons/IconTablerMedium';
import IconTablerMail from '../../assets/icons/IconTablerMail';
import Link from '../Link';
import Container from '../Containers';
import { LinkMenu } from '../Header/Header';

function Footer() {
  return (
    <>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <Container
        spacing="small"
        className="bg-gray-50 px-8 py-5 rounded-xl flex flex-col sm:flex-row sm:justify-between space-y-4 sm:space-y-0 dark:bg-dark-800"
      >
        <div className="hidden md:flex items-center justify-start ml-6 gap-2 lg:gap-6">
          <LinkMenu title="Discover" to="/discover" />
          <LinkMenu title="Bookstore" to="/bookstore" />
          <LinkMenu title="About" to="/about-us" />
          <LinkMenu title="For Authors" to="/authors" />
        </div>
        <div className="flex space-x-2 justify-center sm:justify-start dark:text-dark-400">
          <a
            href="https://discord.com/invite/ykTZT3CZw8"
            target="_blank"
            rel="noreferrer"
          >
            <IconTablerDiscord className="w-7 h-7 dark:hover:text-primary-500 transition-all" />
          </a>
          <a
            href="https://twitter.com/readl_co"
            target="_blank"
            rel="noreferrer"
          >
            <IconTablerTwitter className="w-7 h-7 dark:hover:text-primary-500 transition-all" />
          </a>
          <a
            href="https://www.linkedin.com/company/79752592/admin/"
            target="_blank"
            rel="noreferrer"
          >
            <IconTablerLinkedin className="w-7 h-7 dark:hover:text-primary-500 transition-all" />
          </a>
          <a
            href="https://medium.com/@readlnetwork"
            target="_blank"
            rel="noreferrer"
          >
            <IconTablerMedium className="w-7 h-7 dark:hover:text-primary-500 transition-all" />
          </a>
          <a
            href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#109;&#97;&#114;&#107;&#101;&#116;&#105;&#110;&#103;&#64;&#114;&#101;&#97;&#100;&#108;&#46;&#99;&#111;"
            target="_blank"
            rel="noreferrer"
          >
            <IconTablerMail className="w-7 h-7 dark:hover:text-primary-500 transition" />
          </a>
        </div>
      </Container>
      <Container spacing="small">
        <div className="text-sm text-gray-600 dark:text-dark-500 text-center self-center">
          ©{' '}
          <Link to="/" className="text-black dark:text-dark-500">
            Readl
          </Link>{' '}
          -{' '}
          <Link
            to="/terms-conditions"
            className="text-black dark:text-dark-500"
          >
            Terms & Conditions
          </Link>{' '}
          ·{' '}
          <Link to="/privacy-policy" className="text-black dark:text-dark-500">
            Privacy Policy
          </Link>
        </div>
      </Container>
    </>
  );
}

export default Footer;
