import { LockClosedIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Tooltip from '../Tooltip/Tooltip';
import Badge from '../Badge';
import CardQuantity from '../Icons/CardQuantity';
import StoryIcon from '../../assets/icons/StoryIcon';
import { TYPE_BOOK, TYPE_STORY } from '../../utils/constants';
import { capitalize, formatReadingTime } from '../../utils/utils';

export default function ItemInfo({
  className,
  quantity,
  type,
  gated,
  duration,
  onCard,
}) {
  return (
    <div className={clsx('flex space-x-2', className)}>
      <div
        className={clsx(
          'inline-flex rounded-lg items-center text-xs font-semibold',
          {
            'bg-gray-100 dark:bg-dark-700 dark:text-dark-100': !onCard,
            'bg-black bg-opacity-60 text-white': onCard,
          },
        )}
      >
        <Tooltip tooltipText={gated ? 'Contains gated content' : ''}>
          <Badge variant={type} size={onCard ? 'md' : 'lg'} rounded="lg">
            {gated ? (
              <LockClosedIcon
                className={clsx({
                  'w-5 h-5 mr-1': !onCard,
                  'w-4 h-4 mr-1': onCard,
                })}
              />
            ) : (
              <div>
                {type === TYPE_STORY && (
                  <StoryIcon
                    className={clsx({
                      'w-5 h-5 mr-1': !onCard,
                      'w-4 h-4 mr-1': onCard,
                    })}
                  />
                )}
                {type === TYPE_BOOK && (
                  <BookOpenIcon
                    className={clsx({
                      'w-5 h-5 mr-1': !onCard,
                      'w-4 h-4 mr-1': onCard,
                    })}
                  />
                )}
              </div>
            )}
            <span
              className={clsx({
                '': !onCard,
                'text-xs': onCard,
              })}
            >
              {capitalize(type)}
            </span>
          </Badge>
        </Tooltip>
        <div
          className={clsx({
            'px-4': !onCard,
            'px-2 text-[10px]': onCard,
          })}
        >
          {formatReadingTime(Math.round(duration))}
        </div>
      </div>
      {quantity && (
        <Tooltip tooltipText="Total minted copies">
          <Badge variant="gray" size="lg" rounded="lg">
            <CardQuantity className="w-5 h-5 mr-1" />
            <span>x{quantity}</span>
          </Badge>
        </Tooltip>
      )}
    </div>
  );
}
