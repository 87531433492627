import React from 'react';
import GridItemCollection from '../GridItemCollection';

function GridCollections({ view, title, description, className = '' }) {
  return (
    <section className={className}>
      <div>
        {title && (
          <h1 className="text-left text-4xl font-bold leading-10">{title}</h1>
        )}
        {description && (
          <p className="text-left mt-4 text-base leading-relaxed">
            {description}
          </p>
        )}
        <div className="w-full overflow-x-auto mt-7 grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
          {view.map((data) => (
            <GridItemCollection data={data} key={JSON.stringify(data)} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default GridCollections;
