import { useEffect, useState } from 'react';
import apiService from '../../services/apiService';
import { ButtonLink } from '../Button/Button';
import Container from '../Containers';
import GridItem from '../GridStories/GridItem';
import Slider from '../Slider';
import Heading from '../Typography';
import { Text } from '../Typography/Heading';

export default function TheLibrary({ containerMotion, collectionId }) {
  const [stories, setStories] = useState([]);

  const getStories = async () => {
    const collectionStories = await apiService(
      `/story/collection/${collectionId}`,
    );
    setStories(collectionStories);
  };

  useEffect(() => {
    if (collectionId) {
      getStories();
    }
  }, [collectionId]);

  return (
    stories.length > 0 && (
      <Container
        className="bg-black bg-[url('https://p1.f0.n0.cdn.getcloudapp.com/items/RBuBYQZ4/39a2affc-cb05-4eee-a457-8b266055619e.jpg')] lg:rounded-3xl pt-24 pb-24 bg-cover bg-center"
        margin="flat-mobile"
        initial="initial"
        whileInView="visible"
        variants={containerMotion}
        viewport={{ once: true }}
      >
        <div className="space-y-3 text-white px-4 sm:px-12 pb-16 max-w-5xl">
          <Heading size="5" className="uppercase flex items-center">
            <span className="h-0.5 bg-white w-10 block mr-4" /> The Library
            Collection
          </Heading>
          <Heading size="1">
            &ldquo;There is no joy in
            <br /> possession without sharing&rdquo;
          </Heading>
          <Text size="xl" className="max-w-2xl">
            This is a public, decentralized, and perpetual library from Readl.
            All books in this collection are and always will be free.
          </Text>
        </div>
        <div className="px-4 sm:px-12">
          <Slider
            classNameSlider=""
            slidesPerView={1}
            navigation
            slidesPerViewBreakpoints={{
              xs: 1.4,
              sm: 2.5,
              xl: 4.2,
            }}
            items={stories.map((story) => (
              <GridItem data={story} />
            ))}
          />
        </div>
        <div className="text-center mt-12">
          <ButtonLink to={`/collection/${collectionId}`}>
            View full collection
          </ButtonLink>
        </div>
      </Container>
    )
  );
}
