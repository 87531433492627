import clsx from 'clsx';
import React from 'react';
import { motion } from 'framer-motion';
import {
  GRID_ITEM_SIZE_FULL,
  GRID_ITEM_SIZE_NARROW,
  GRID_ITEM_SIZE_MEDIUM,
} from '../../utils/constants';
import GridItem from './GridItem';

const containerMotion = {
  initial: { opacity: 0, transition: { duration: 0.5 } },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
};

function GridStories({
  view,
  title = '',
  className = '',
  size = GRID_ITEM_SIZE_FULL,
}) {
  return (
    <motion.section
      initial="initial"
      whileInView="visible"
      variants={containerMotion}
      viewport={{ once: true }}
      className={className}
    >
      <div>
        {title && <h3 className="font-bold text-3xl mb-4">{title}</h3>}
        <div
          className={clsx(
            'grid gap-x-4 gap-y-8 relative mb-20',
            {
              'grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-8':
                size === GRID_ITEM_SIZE_FULL,
            },
            {
              'grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4':
                size === GRID_ITEM_SIZE_MEDIUM,
            },
            {
              'grid-cols-1 xs:grid-cols-2 lg:grid-cols-3':
                size === GRID_ITEM_SIZE_NARROW,
            },
          )}
        >
          {view.map((data) => (
            <GridItem data={data} key={data.tokenId} />
          ))}
        </div>
      </div>
    </motion.section>
  );
}

export default GridStories;
