import React, { useState } from 'react';
import clsx from 'clsx';
import MarkdownRenderer from '../MarkdownRenderer';

export default function CollapseText({ text }) {
  const [showText, setShowText] = useState(false);
  const toggleText = () => setShowText(!showText);

  return (
    text && (
      <div
        className="text-base text-gray-600 transition-all cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-800 dark:text-dark-300 p-2 mt-2 rounded-md md:-ml-2 w-full md:max-w-xl"
        onClick={toggleText}
        aria-hidden
      >
        <div
          className={clsx('r-markdown', {
            'active line-clamp-2': !showText,
            'line-camp-3': showText,
          })}
        >
          <MarkdownRenderer>{text}</MarkdownRenderer>
        </div>
      </div>
    )
  );
}
