import clsx from 'clsx';
import * as React from 'react';
import Link from '../Link';

function getClassName({ className }) {
  return clsx(className);
}

function ButtonInner({
  children,
  variant,
  size = 'md',
  flex,
  width,
  fullHeight,
  iconLeft,
  iconRight,
  icon,
  rounded = 'md',
  classes,
  disabled,
  ...buttonProps
}) {
  return (
    <>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <button
        type="button"
        {...buttonProps}
        className={clsx(
          classes,
          'items-center font-semibold focus:ring-2 focus:ring-offset-0 focus:ring-primary-500 active:ring-transparent transition-colors dark:focus:border-transparent',
          {
            'group relative flex justify-center': flex === true,
            'bg-primary-200 border-primary-200':
              disabled && variant === 'primary',
            'text-black  bg-primary-500 border-primary-500 hover:bg-primary-300':
              variant === 'primary' && !disabled,
            'text-black bg-gray-100 hover:bg-gray-300': variant === 'secondary',
            'bg-black hover:bg-gray-900 dark:hover:bg-dark-800 text-white':
              variant === 'dark',
            'focus:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-primary-500 transition duration-300 disabled:opacity-50 disabled:ring-primary-500 inline-flex items-center border-2 border-transparent shadow-sm bg-primary-500 hover:bg-primary-200 font-semibold text-base px-6 py-3 text-secondary-900':
              variant === 'marketplace-primary',
            'ring-2 ring-primary-500 font-medium bg-transparent text-gray-900':
              variant === 'outline-primary',
            'text-black bg-transparent hover:bg-gray-100 dark:text-dark-200 dark:hover:bg-dark-800':
              variant === 'link',
            'bg-transparent text-white hover:bg-black':
              variant === 'link-white',
            'bg-[#404040] hover:bg-[#303030] text-white': variant === 'cancel',
            'rounded-full ': rounded === 'full',
            'rounded-sm': rounded === 'sm',
            'rounded-md': rounded === 'md',
            'rounded-lg': rounded === 'lg',
            'py-2 px-3 text-xs': size === 'xs',
            'py-3 px-4 text-sm': size === 'sm',
            'py-3 px-5 text-md': size === 'md',
            'py-5 px-7 text-lg': size === 'lg',
            'py-5 px-8 text-xl': size === 'xl',
            'w-full': width === 'full',
            'w-full sm:w-auto': width === 'alwaysfull',
            'h-full': fullHeight,
          },
        )}
      >
        {iconLeft && (
          <div
            className={clsx({
              'h-4 w-4 mr-2': size === 'xs',
              'h-5 w-5 mr-2': size === 'sm',
              'h-6 w-6 mr-2': size === 'md',
              'h-7 w-7 mr-2': size === 'lg',
              'h-8 w-8 mr-3': size === 'xl',
            })}
            aria-hidden="true"
          >
            {iconLeft}
          </div>
        )}
        {children}
        {iconRight && (
          <div
            className={clsx({
              'h-4 w-4 ml-2': size === 'xs',
              'h-5 w-5 ml-2': size === 'sm',
              'h-6 w-6 ml-2': size === 'md',
              'h-7 w-7 ml-2': size === 'lg',
              'h-8 w-8 ml-3': size === 'xl',
            })}
            aria-hidden="true"
          >
            {iconRight}
          </div>
        )}
      </button>
    </>
  );
}

function Button({
  children,
  variant = 'primary',
  className,
  flex,
  size = 'md',
  iconLeft,
  iconRight,
  disabled,
  width,
  ...buttonProps
}) {
  return (
    <div className={getClassName({ className })}>
      <ButtonInner
        {...buttonProps}
        variant={variant}
        disabled={disabled}
        flex={flex}
        iconLeft={iconLeft}
        iconRight={iconRight}
        width={width}
        size={size}
      >
        {children}
      </ButtonInner>
    </div>
  );
}

function ButtonIcon({
  children,
  variant = 'primary',
  className,
  flex,
  size = 'md',
  icon,
  width,
  fullHeight,
  ...buttonProps
}) {
  return (
    <div className={getClassName({ className })}>
      <ButtonInner
        {...buttonProps}
        variant={variant}
        flex={flex}
        width={width}
        size={size}
        fullHeight={fullHeight}
        classes={clsx({
          '!py-1 !px-1': size === 'xs',
          '!py-2 !px-2': size === 'sm',
          '!py-3 !px-3': size === 'md',
          '!py-5 !px-5': size === 'lg',
          '!py-6 !px-6': size === 'xl',
        })}
      >
        <div
          className={clsx({
            'h-6 w-6': size === 'xs',
            'h-7 w-7': size === 'sm',
            'h-6 w-7': size === 'md',
            'h-9 w-7': size === 'lg',
            'h-8 w-8': size === 'xl',
          })}
        >
          {icon}
        </div>
      </ButtonInner>
    </div>
  );
}

function LinkButton({ className, underlined, ...buttonProps }) {
  return (
    <button
      type="button"
      {...buttonProps}
      className={clsx(
        className,
        underlined
          ? 'underlined whitespace-nowrap focus:outline-none'
          : 'underline',
        'inline-block',
      )}
    />
  );
}

const ButtonLink = React.forwardRef(function ButtonLink(
  {
    children,
    variant = 'primary',
    className,
    flex,
    size = 'md',
    iconLeft,
    iconRight,
    icon,
    width,
    download,
    to,
    onClick,
    target,
    ...buttonProps
  },
  ref,
) {
  return (
    <Link
      ref={ref}
      href={to}
      onClick={onClick}
      download={download}
      target={target}
      className={getClassName({ className })}
    >
      {icon ? (
        <ButtonIcon
          {...buttonProps}
          variant={variant}
          flex={flex}
          icon={icon}
          width={width}
          size={size}
        />
      ) : (
        <ButtonInner
          {...buttonProps}
          variant={variant}
          flex={flex}
          iconLeft={iconLeft}
          iconRight={iconRight}
          width={width}
          size={size}
        >
          {children}
        </ButtonInner>
      )}
    </Link>
  );
});

export { Button, ButtonLink, LinkButton, ButtonIcon };
