import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { ButtonLink } from '../Button/Button';

export default function ButtonBack({ className }) {
  const navigate = useNavigate();

  return (
    <div className={className}>
      <ButtonLink
        icon={<ArrowLeftIcon />}
        variant="link"
        size="sm"
        rounded="full"
        onClick={() => navigate(-1)}
      />
    </div>
  );
}
