/* eslint-desable */
import axios from 'axios';
import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';

const gatewayTools = new IPFSGatewayTools();

const TIMEOUT = 3000;

export const getMetadataFromHash = async (hash) => {
  const response = await axios(`https://readl.mypinata.cloud/ipfs/${hash}`, {
    timeout: TIMEOUT,
  });
  return response.data;
};

export const pinFileToIPFS = async (file) => {
  const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

  const data = new FormData();
  data.append('file', file);

  const response = await axios.post(url, data, {
    maxBodyLength: 'Infinity',
    headers: {
      // 'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
    },
  });
  return response.data.IpfsHash;
};

export const pinJSONToIPFS = async (data) => {
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
  const response = await axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
    },
  });
  return response.data.IpfsHash;
};

export const getIpfsUrl = (sourceUrl) =>
  gatewayTools.convertToDesiredGateway(
    sourceUrl,
    process.env.REACT_APP_IPFS_GATEWAY,
  );

export const getIpfsCID = (url) => {
  const response = gatewayTools.containsCID(url);
  if (!response.containsCid) {
    return false;
  }
  return response.cid;
};

export const retrieveContentFromIPFS = async (sourceUrl) => {
  const url = gatewayTools.convertToDesiredGateway(
    sourceUrl,
    process.env.REACT_APP_IPFS_GATEWAY,
  );
  const response = await axios.get(url);
  return response.data;
};
