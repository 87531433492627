import React from 'react';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import ScrollContainer from 'react-indiana-drag-scroll';

function TabsReadl({
  tabs,
  vertical,
  align = 'center',
  tabClick,
  wrapperClasses,
  overlay,
  variant = 'normal',
}) {
  return (
    <ScrollContainer className={wrapperClasses}>
      {tabs.map((tab) => (
        <Tab
          className={({ selected }) =>
            clsx(
              'group focus:outline-none active:outline-none outline-none transition-all duration-300',
              {
                'font-semibold text-md md:text-lg': variant === 'normal',
                'font-bold text-2xl lg:text-[38px] !px-0 text-gray-400':
                  variant === 'modern',
                'font-bold text-2xl lg:text-[38px] !px-0 text-gray-500':
                  variant === 'modern-dark',
              },
              {
                '!text-black dark:!text-white':
                  selected && variant === 'modern',
                '!text-white': selected && variant === 'modern-dark',
              },
              selected && variant === 'normal'
                ? 'border-primary-500 text-black dark:text-white'
                : 'border-transparent text-gray-500 dark:text-dark-500 hover:text-gray-700 dark:text-dark-400 dark:hover:text-gray-200 hover:border-gray-300',
              vertical
                ? 'px-8 items-center flex py-3'
                : 'inline-flex items-center px-2 md:px-6 py-5',
              {
                'justify-center':
                  vertical && align === 'center' && variant === 'normal',
              },
              {
                'text-left border-l-2':
                  align === 'left' && variant === 'normal',
                'text-right border-r-2':
                  align === 'right' && variant === 'normal',
                'border-b-2': align === 'center' && variant === 'normal',
              },
            )
          }
          onClick={() => tabClick && tabClick(tab.id)}
          key={tab.id}
        >
          {tab.icon}
          {tab.title}
        </Tab>
      ))}
      {overlay && <div className="absolute" />}
    </ScrollContainer>
  );
}

export default TabsReadl;
