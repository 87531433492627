import React from 'react';
import Button from '../Button';
import Modal from '../Modal/Modal';
import Spacer from '../Spacer';

function ModalConfirmation({
  open,
  setOpen,
  Icon,
  title,
  description,
  ButtonOkText,
  ButtonOkOnClick,
  ButtonCancelText,
}) {
  return (
    <Modal open={open} setOpen={setOpen}>
      {Icon && (
        <div className="mx-auto flex items-center justify-center my-4">
          <Icon className="h-12 w-12 dark:text-dark-300" aria-hidden="true" />
        </div>
      )}
      <div className="mt-3 text-center sm:mt-3">
        <h3 className="text-lg font-bold leading-6 text-secondary-800 dark:text-dark-100">
          {title}
        </h3>
        <div className="mt-2">
          <p className="text-sm leading-tight text-center text-gray-500 dark:text-dark-300">
            {description}
          </p>
        </div>
        <div className="mt-5 sm:mt-6">
          <Button rounded="lg" width="full" onClick={ButtonOkOnClick}>
            {ButtonOkText}
          </Button>
          <Spacer className="mb-3" />
          <Button
            variant="secondary"
            rounded="lg"
            width="full"
            onClick={() => setOpen(false)}
          >
            {ButtonCancelText || 'Go back'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalConfirmation;
