import { useEffect, useState, useContext } from 'react';
import { Tab } from '@headlessui/react';
import toast from 'react-hot-toast';
import { motion, useReducedMotion } from 'framer-motion';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import Slider from '../../components/Slider';
import GridItem from '../../components/GridStories/GridItem';
import GridItemCollection from '../../components/GridItemCollection';
import Footer from '../../components/Footer';
import { ButtonLink } from '../../components/Button/Button';
import Container from '../../components/Containers';
import Heading from '../../components/Typography';
import { Text } from '../../components/Typography/Heading';
import TabsReadl from '../../components/Tab/Tab';
import GridItemExtended from '../../components/GridStories/GridItemExtended';
import UsersSlider from '../../components/UsersSlider';
import useBreakpoints from '../../hooks/useBreakpoints';
import TheLibrary from '../../components/TheLibrary';
import LoginContext from '../../context/LoginContext';
import MostRecent from '../../components/MostRecent/MostRecent';

function Discover() {
  const { showSpinner, hideSpinner } = useSpinner();
  const [content, setContent] = useState({
    mostRecentStories: [],
    collections: [],
    stories: [],
    knowMoreStories: [],
    lastCollected: [],
    hotTagsStories: {},
    extras: {},
  });
  const [selectedHotTag, setSelectedHotTag] = useState('');
  const [tagStories, setTagStories] = useState([]);
  const shouldReduceMotion = useReducedMotion();
  const { isXl } = useBreakpoints();
  const containerMotion = {
    initial: {
      opacity: 0,
      y: shouldReduceMotion ? 0 : 80,
      transition: { duration: 0 },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, staggerChildren: 0.5 },
    },
  };

  const getContent = async () => {
    try {
      showSpinner();
      const dataContent = await apiService.get('/general/homepage');
      setContent(dataContent);

      setSelectedHotTag(Object.keys(dataContent.hotTagsStories)[0]);
    } catch (err) {
      console.error(err);
      toast.error('Error retrieving home page');
    } finally {
      hideSpinner();
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  useEffect(() => {
    if (selectedHotTag) {
      setTagStories(content.hotTagsStories[selectedHotTag]);
    }
  }, [selectedHotTag]);

  const showCollections = content.collections.length > 0;

  const { isUserLoggedIn, userName } = useContext(LoginContext);

  return (
    <div>
      <Container className="mt-14">
        {isUserLoggedIn === true ? (
          <div className="flex text-center self-center">
            <Heading size="1">👋 Hello {userName}!</Heading>
          </div>
        ) : (
          <div>
            <Heading size="1">🔭 Discover</Heading>
          </div>
        )}
        <Text size="xl">Discover new and exciting stories</Text>
      </Container>
      <MostRecent
        lastCollected={content.lastCollected}
        lastListed={content.lastListed}
        containerMotion={containerMotion}
      />
      <Container
        flex
        className="mb-24 flex-col md:flex-row space-y-8 md:space-y-0 lg:px-8"
        initial="initial"
        whileInView="visible"
        variants={containerMotion}
        viewport={{ once: true }}
      >
        <div className="md:w-1/2">
          <Heading size="4" className="mb-4">
            Best selling authors last 7 days
          </Heading>
          <UsersSlider users={content.bestSellingAuthors || []} />
        </div>
        <div className="md:w-1/2">
          <Heading size="4" className="mb-4">
            Latest collectors
          </Heading>
          <UsersSlider users={content.lastCollectors || []} />
        </div>
      </Container>
      <Container
        className="bg-black lg:rounded-3xl text-white py-16 lg:py-28 bg-[url('https://p1.f0.n0.cdn.getcloudapp.com/items/NQuWO0pd/3ef3e9e0-febb-4a29-b761-4e6140706e76.png?source=viewer&v=727e943dc7dad4d90abd94c68f7fcea5')]"
        variants={containerMotion}
        initial="initial"
        animate="visible"
        margin="flat-mobile"
      >
        <div className="lg:px-12 px-4">
          <Heading
            size="5"
            className="uppercase text-gray-400 pb-3 ml-4"
            divider="before"
            dividerColors="bg-gray-400"
          >
            Most collected in the last 7 days
          </Heading>

          <motion.div
            className="lg:bg-[#141414] rounded-xl py-4"
            initial="initial"
            animate="visible"
            variants={containerMotion}
            viewport={{ once: true }}
          >
            <Slider
              className="w-full"
              classNameSlider="lg:!px-8 !px-0 lg:!py-4 !pr-4"
              slidesPerView={1.1}
              navigation
              slidesPerViewBreakpoints={{
                xs: 1.4,
                sm: 2.5,
                md: 2.5,
                lg: 3.5,
                xl: 4.5,
                '2xl': 4.5,
              }}
              items={content.mostCollected?.map((story) => (
                <GridItem data={story} />
              ))}
            />
          </motion.div>
        </div>
      </Container>
      <Container spacing="large">
        <div>
          <Heading
            size="5"
            className="uppercase text-gray-400 pb-3"
            divider="before"
            dividerColors="bg-gray-400"
          >
            Free to read and collect
          </Heading>

          <motion.div
            className="bg-gray-100 dark:bg-dark-800 rounded-xl py-4"
            initial="initial"
            whileInView="visible"
            variants={containerMotion}
            viewport={{ once: true }}
          >
            <Slider
              className="w-full"
              classNameSlider="!px-8 !py-4 !pr-4"
              slidesPerView={1.1}
              navigation
              slidesPerViewBreakpoints={{
                xs: 1.4,
                sm: 2.5,
                md: 2.5,
                lg: 3.5,
                xl: 4.5,
                '2xl': 4.5,
              }}
              items={content.freeToReadOrCollect?.map((story) => (
                <GridItem data={story} />
              ))}
            />
          </motion.div>
        </div>
      </Container>
      {/* Readl Top Picks */}
      <Container
        spacing="small"
        initial="initial"
        whileInView="visible"
        variants={containerMotion}
        viewport={{ once: true }}
      >
        <div className="mx-auto max-w-3xl text-center space-y-4">
          <Heading size="3" className="text-center pt-8">
            🖖 Readl Top Picks
          </Heading>
          <Text size="xl">Handpicked by our staff and the community.</Text>
        </div>
      </Container>
      <Container
        margin="flat-mobile"
        spacing="small"
        initial="initial"
        whileInView="visible"
        variants={containerMotion}
        viewport={{ once: true }}
      >
        <Slider
          className="w-full"
          classNameSlider="!px-8 !pt-8 !pb-12 !pr-4"
          slidesPerView={1}
          navigation
          slidesPerViewBreakpoints={{
            xs: 1.1,
            sm: 2.1,
            md: 2.2,
            lg: 2.6,
            xl: 3.5,
            '2xl': 3.6,
          }}
          items={content.stories.map((story) => (
            <GridItemExtended data={story} />
          ))}
        />
      </Container>
      <Container
        margin="flat-mobile"
        className="flex flex-col xl:items-center xl:flex-row bg-black lg:rounded-3xl px-4 md:px-12 py-16 mb-24 dark"
        initial="initial"
        whileInView="visible"
        variants={containerMotion}
        viewport={{ once: true }}
      >
        <Tab.Group>
          <div className="xl:w-2/12">
            <Heading size="2" className="text-white uppercase max-h-">
              <span className="xl:text-6xl font-extrabold">
                🔥
                <br /> Hot
              </span>{' '}
              Tags
            </Heading>
            <Tab.List className="my-6" aria-label="Tabs">
              <TabsReadl
                tabs={Object.keys(content.hotTagsStories).map((tag) => ({
                  id: tag,
                  title: tag,
                }))}
                wrapperClasses="max-h-[44px] flex xl:flex-col xl:max-h-fit"
                vertical={isXl}
                align={isXl ? 'left' : 'center'}
                tabClick={(tag) => setSelectedHotTag(tag)}
              />
            </Tab.List>
            <ButtonLink
              to={`/bookstore?tags=${selectedHotTag}`}
              width="full"
              className="hidden xl:block"
            >
              Discover
            </ButtonLink>
          </div>
          <div className="xl:w-10/12 xl:grow xl:pl-16">
            <Slider
              classNameSlider=""
              slidesPerView={1}
              navigation
              slidesPerViewBreakpoints={{
                xs: 1.4,
                sm: 2.5,
                xl: 3.2,
              }}
              items={tagStories.map((story) => (
                <GridItem data={story} />
              ))}
            />
            <ButtonLink
              to={`/bookstore?tags=${selectedHotTag}`}
              width="full"
              className="xl:hidden mt-12 block"
            >
              Discover
            </ButtonLink>
          </div>
        </Tab.Group>
      </Container>
      <Container
        margin="flat-mobile"
        initial="initial"
        whileInView="visible"
        variants={containerMotion}
        viewport={{ once: true }}
      >
        <div className="mb-6">
          <Heading
            size="5"
            className="uppercase text-gray-400 pb-3 ml-4 lg:ml-8"
            divider="before"
            dividerColors="bg-gray-400"
          >
            Most collected in the last 7 days
          </Heading>
          <Heading className="px-4 lg:px-8 mb-4 !text-2xl lg:!text-[38px]">
            Collections
          </Heading>
        </div>
        <div className="bg-gray-50 dark:bg-dark-800 pt-12 pb-4 lg:rounded-3xl">
          {showCollections && (
            <Slider
              className="w-full"
              classNameSlider="!px-8 !py-4 !pr-4"
              navigation
              slidesPerView={1}
              slidesPerViewBreakpoints={{
                xs: 1,
                sm: 1.5,
                md: 2,
                lg: 2.5,
                xl: 2.8,
                '2xl': 3.5,
              }}
              items={content.popularCollections?.map((collection) => (
                <GridItemCollection data={collection} />
              ))}
            />
          )}
        </div>
      </Container>
      <TheLibrary
        containerMotion={containerMotion}
        collectionId={content.extras.theLibraryCollection}
      />
      <Footer />
    </div>
  );
}

export default Discover;
