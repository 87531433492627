import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import UnlockedLink from './UnlockedLink';
import UnlockedEpub from './UnlockedEpub';
import UnlockedAudio from './UnlockedAudio';
import UnlockedFile from './UnlockedFile';
import UnlockedImage from './UnlockedImage';
import Heading from '../Typography';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export default function UnlockableExtraContent({
  generateEpub,
  unlockables,
  setUnlockables,
}) {
  const createNewUnlockable = (type) => {
    const defaultsByTypes = {
      link: {
        allowDownload: false,
      },
      book: {
        allowDownload: false,
      },
      audio: {
        allowDownload: false,
      },
      file: {
        allowDownload: true,
      },
      image: {
        allowDownload: true,
      },
    };
    setUnlockables((data) => [
      ...data,
      {
        type,
        id: `${type}${parseInt(Math.random() * 10000, 10)}`,
        error: {},
        isPrivate: false,
        ...defaultsByTypes[type],
      },
    ]);
  };

  const editUnlockable = (data, id) => {
    const temp = [...unlockables];
    const i = temp.findIndex((item) => item.id === id);
    if (i < 0) {
      return null;
    }
    const item = {
      ...temp[i],
      ...data,
    };
    temp[i] = item;
    setUnlockables(temp);
    return item;
  };

  const deleteUnlockable = (id) => {
    const temp = [...unlockables];
    setUnlockables(temp.filter((item) => item.id !== id));
  };

  const typeToComponents = {
    link: UnlockedLink,
    book: UnlockedEpub,
    audio: UnlockedAudio,
    file: UnlockedFile,
    image: UnlockedImage,
  };

  return (
    <>
      <div className="flex p-6 justify-between items-center">
        <div className="flex flex-col gap-1">
          <Heading size="5">Add unlockables</Heading>
        </div>
        <div>
          <Menu as="div" className="relative inline-block">
            <div>
              <Menu.Button className="fitems-center font-semibold focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors group relative flex justify-center text-black bg-gray-100 hover:bg-gray-300 rounded-md py-2 px-4 text-sm">
                Add New
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => createNewUnlockable('audio')}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'w-full text-left px-4 py-2 ',
                        )}
                      >
                        <p className="text-sm leading-tight text-gray-700">
                          Audio
                        </p>
                        <p className="text-xs leading-1 text-gray-500">
                          MP3 files
                        </p>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => createNewUnlockable('book')}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'w-full text-left px-4 py-2',
                        )}
                      >
                        <p className="text-sm leading-tight text-gray-700">
                          Book
                        </p>
                        <p className="text-xs leading-1 text-gray-500">
                          EPUB files
                        </p>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => createNewUnlockable('file')}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'w-full text-left px-4 py-2',
                        )}
                      >
                        <p className="text-sm leading-tight text-gray-700">
                          File
                        </p>
                        <p className="text-xs leading-1 text-gray-500">
                          ZIP files
                        </p>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => createNewUnlockable('image')}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'w-full text-left px-4 py-2',
                        )}
                      >
                        <p className="text-sm leading-tight text-gray-700">
                          Image
                        </p>
                        <p className="text-xs leading-1 text-gray-500">
                          PNG, JPG or GIF files
                        </p>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={() => {
                          createNewUnlockable('link');
                        }}
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'w-full text-left px-4 py-2',
                        )}
                      >
                        <p className="text-sm leading-tight text-gray-700">
                          Link
                        </p>
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      {unlockables?.map((item) => {
        const Component = typeToComponents[item.type];
        return (
          <div className="mb-4" key={item.id}>
            <Component
              editUnlockable={editUnlockable}
              deleteUnlockable={deleteUnlockable}
              content={item}
              generateEpub={generateEpub}
            />
          </div>
        );
      })}
    </>
  );
}
