import { utils } from 'ethers';
import { useConvert } from '../../context/ConvertContext';

export default function PriceConversion({ price }) {
  const { isConvertReady, USDPrice } = useConvert();

  let isLessThanCent = false;
  let convertedPrice = isConvertReady && USDPrice * utils.formatEther(price);
  if (convertedPrice > 0 && convertedPrice < 0.01) {
    convertedPrice = 0.01;
    isLessThanCent = true;
  }

  return (
    isConvertReady && (
      <>
        {isLessThanCent && '>'}
        {Intl.NumberFormat('en-US', {
          maximumFractionDigits: +convertedPrice > 1 * 10 ** 6 ? 1 : 2,
          notation: 'compact',
          compactDisplay: 'short',
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol',
        }).format(convertedPrice)}
      </>
    )
  );
}
