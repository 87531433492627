import { useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { utils } from 'ethers';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { toBlob } from 'html-to-image';
import ReactGA from 'react-ga4';
//  import resolveConfig from 'tailwindcss/resolveConfig';
//  import tailwindConfig from '../../tailwind.config';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import { pinFileToIPFS, getIpfsUrl } from '../../services/ipfsService';
import { ModalSellStory } from '../Story';
import StoryData from '../../components/StoryData';
import StoryEditor from '../../components/StoryEditor/StoryEditor';
import Tabs from '../../components/Tabs';
import LoginContext from '../../context/LoginContext';
import Header from '../../components/Header/Header';
import Spacer from '../../components/Spacer';
import ModalConfirmation from '../../components/ModalConfirmation';
import { TYPE_STORY, TYPE_BOOK } from '../../utils/constants';
import './CreateStory.css';
import Container from '../../components/Containers';
import useBreakpoints from '../../hooks/useBreakpoints';

function CreateStory() {
  const { type } = useParams();
  const { showSpinner, hideSpinner } = useSpinner();
  const [tabSelected, setTabSelected] = useState(0);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [openPublishConfirmationModal, setOpenPublishConfirmationModal] =
    useState(false);
  const [isPrivate, setIsPrivate] = useState(true);
  const [allowDownload, setAllowDownload] = useState(false);
  const [unlockables, setUnlockables] = useState([]);
  const [openLeavingPageModal, setOpenLeavingPageModal] = useState(false);
  const { isLoadingUserLogin, isUserLoggedIn, address, userName } =
    useContext(LoginContext);
  const navigate = useNavigate();
  const { isLg } = useBreakpoints();
  const tabs = ['Story', 'Details'];

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    setOpenLeavingPageModal(true);
    window.history.pushState(null, null, window.location.pathname);
  };

  const onBeforeUnload = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    e.returnValue = '';
  };

  const [request, setRequest] = useState({
    uploadingImage: null,
    imageFile: null,
    imageFileUrl: null,
    content: { blocks: [] },
    title: '',
    description: null,
    tags: [],
    collectionId: null,
    copies: '',
    royalties: '',
    royaltiesDistribution: null,
    isSensitiveContent: false,
    isPrivate: false,
    type: '',
    epub: null,
    epubInfo: null,
    allowDownload: true,
  });

  const [error, setError] = useState({
    title: false,
    collection: false,
    copies: false,
    content: false,
    author: false,
    royaltiesDistribution: false,
    royaltiesPercentage: false,
  });

  const [storyResponse, setStoryResponse] = useState(null);

  const selectTab = (tabIndex) => {
    setTabSelected(tabIndex);
  };

  const generateEpub = async () => {
    try {
      let cover;
      if (request.imageFile) {
        cover = request.imageFile;
      } else {
        const coverNode = document.getElementById('cover-maker');
        cover = await toBlob(coverNode, { style: { borderRadius: 0 } });
      }

      const formData = new FormData();
      formData.append('content', request.epub);
      formData.append('cover', cover);
      formData.append('title', request.epubInfo.title);
      formData.append('author', request.epubInfo.author);
      formData.append('language', request.epubInfo.language);
      formData.append('isbn', request.epubInfo.isbn);
      formData.append('description', request.epubInfo.description);
      const response = await apiService.post('/epub', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      });

      return response;
    } catch (err) {
      console.error(err);
      throw new Error('Error generating epub');
    }
  };

  const handleValidationCreateStory = async () => {
    try {
      if (!request.title) {
        return setError({
          ...error,
          title: true,
        });
      }
      if (!request.collectionId) {
        return setError({
          ...error,
          collection: true,
        });
      }
      if (!Number(request.copies)) {
        return setError({
          ...error,
          copies: true,
        });
      }
      if (request.type === TYPE_STORY) {
        if (
          !request.content.blocks.filter(
            (block) => block.type !== 'gatedContentDelimiter',
          ).length
        ) {
          return setError({
            ...error,
            content: true,
          });
        }
      }
      if (Array.isArray(request.royaltiesDistribution)) {
        for (let i = 0; i < request.royaltiesDistribution.length; i += 1) {
          if (
            utils.isAddress(request.royaltiesDistribution[i].address) === false
          ) {
            return setError({
              ...error,
              royaltiesDistribution: true,
            });
          }
          if (request.royaltiesDistribution[i].percentage === 0) {
            return setError({
              ...error,
              royaltiesPercentage: true,
            });
          }
        }
      }

      if (request.type === TYPE_BOOK) {
        if (!request.epub) {
          return toast.error('You need to upload an epub');
        }
      }

      if (unlockables.length) {
        const errors = [];
        const typeToValidation = {
          link: ['file', 'title'],
          book: ['file', 'title', 'author'],
          audio: ['file', 'title'],
          file: ['file', 'title'],
          image: ['file', 'title'],
        };
        unlockables.forEach((item, i) => {
          const vError = {};
          typeToValidation[item.type].forEach((validation) => {
            if (!item[validation]) {
              vError[validation] = true;
            }
          });
          if (Object.keys(vError).length > 0) {
            errors.push({ ...vError, i });
          }
        });
        if (errors.length) {
          const temp = [...unlockables];
          errors.forEach((e) => {
            const { i, ...data } = e;
            temp[i] = {
              ...temp[i],
              error: data,
            };
          });
          setUnlockables(temp);
          return hideSpinner();
        }
      }

      setOpenPublishConfirmationModal(true);
      ReactGA.event({
        category: 'create',
        action: 'click_publish',
        label: type,
      });
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const createStory = async () => {
    try {
      setOpenPublishConfirmationModal(false);
      showSpinner(`Publishing ${request.type}...`);
      const formData = new FormData();

      let isStoryPrivate = false;
      if (request.type === TYPE_STORY) {
        const delimiters = [];
        request.content.blocks.forEach((block, index) => {
          if (block.type === 'gatedContentDelimiter') {
            delimiters.push(index);
          }
        });
        isStoryPrivate = request.content.blocks.length > delimiters[0] + 1;
        // We delete duplicated content gated delimiters
        if (isStoryPrivate) {
          if (delimiters.length > 1) {
            delimiters.slice(1).forEach((index) => {
              delete request.content.blocks[index];
            });
            request.content.blocks = request.content.blocks.filter(
              (block) => block?.type,
            );
          }
        }
      } else if (request.type === TYPE_BOOK) {
        isStoryPrivate = request.isPrivate;
      }

      let imageHash;
      if (request.imageFile) {
        imageHash = await pinFileToIPFS(request.imageFile);
      } else {
        const coverNode = document.getElementById('cover-maker');
        const blob = await toBlob(coverNode, { style: { borderRadius: 0 } });
        imageHash = await pinFileToIPFS(blob);
      }

      const unlockablesUploaded = [];
      for (let i = 0; i < unlockables.length; i += 1) {
        const data = unlockables[i];
        if (!data.file) {
          return null;
        }
        const unlockableFile = new FormData();
        unlockableFile.append('isPrivate', data.isPrivate);
        if (data.type === 'link') {
          unlockableFile.append('link', data.file);
        } else {
          unlockableFile.append('file', data.file);
        }
        // eslint-disable-next-line no-await-in-loop
        const hash = await apiService.post(
          '/story/unlockable',
          unlockableFile,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        const response = {
          ...data,
          file: hash,
        };

        if (data.image) {
          // eslint-disable-next-line no-await-in-loop
          const cid = await pinFileToIPFS(data.image);
          response.image = `ipfs://${cid.toString()}`;
        }

        delete response.id;
        delete response.error;

        unlockablesUploaded.push(response);
      }

      const body = {
        name: request.title,
        author: userName,
        description: request.description,
        image: `ipfs://${imageHash.toString()}`,
        tags: request.tags,
        resaleComission: request.royalties || 0,
        isSensitiveContent: request.isSensitiveContent,
        collectionId: request.collectionId,
        totalSupply: Number(request.copies),
        royaltiesDistribution: request.royaltiesDistribution,
        isPrivate: isStoryPrivate,
        type: request.type,
        unlockableContent: unlockablesUploaded.filter((item) => {
          return item && Object.keys(item).length > 1;
        }),
      };

      if (request.type === TYPE_STORY) {
        body.content = request.content;
      }
      if (request.type === TYPE_BOOK) {
        body.allowDownload = request.allowDownload;
        body.epubInfo = request.epubInfo;
        const generatedEpub = await generateEpub();
        formData.append('epub', generatedEpub);
      }

      formData.append('data', JSON.stringify(body));

      const response = await apiService.post('/story', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setStoryResponse(response);
      ReactGA.event({
        category: 'create',
        action: 'published',
        label: `${type}_${body.name}`,
      });
      return true;
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
      return false;
    } finally {
      hideSpinner();
    }
  };

  const sellOrderCallBack = () =>
    navigate(`/${storyResponse.type}/${storyResponse.tokenId}`);

  useEffect(() => {
    showSpinner();
    if (!isLoadingUserLogin) {
      hideSpinner();
      if (!isUserLoggedIn) {
        navigate('/login');
      } else if (type !== TYPE_STORY && type !== TYPE_BOOK) {
        navigate('/');
      } else {
        setRequest({
          ...request,
          type,
        });
      }
    }
  }, [isLoadingUserLogin, isUserLoggedIn, storyResponse]);

  useEffect(() => {
    if (!isLg) {
      const { title, image, collection, copies, content, delimiter } = error;
      if (!tabSelected && (image || collection || copies)) {
        selectTab(1);
      }
      if (!!tabSelected && (title || content || delimiter)) {
        selectTab(0);
      }
    }
  }, [error]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isLg]);

  return (
    !isLoadingUserLogin &&
    isUserLoggedIn && (
      <>
        {/* TODO: Find a better way to pass the createStory function to the Header, and move it to the App.jsx component */}
        <Header createStory={handleValidationCreateStory} />
        <Spacer size="xs" />
        <Container className="pt-4">
          <div>
            <div className="flex flex-row items-start">
              {isLg ? (
                <>
                  <div
                    className="w-20 group open:lg:w-[400px] open:xl:w-[500px] items-center transition-all duration-500 px-4  py-6 bg-gray-50 dark:bg-dark-800 rounded-lg mr-16 relative hidden lg:flex"
                    open={openSidebar}
                  >
                    <div className="w-full">
                      <StoryData
                        storyHandle={setRequest}
                        storyData={request}
                        address={address}
                        userName={userName}
                        openSidebar={openSidebar}
                        error={error}
                        setError={setError}
                        className="flex flex-col"
                      />
                      <button
                        type="button"
                        className="absolute top-4 left-[100%] bg-gray-50 dark:bg-dark-800 dark:text-dark-100 hover:bg-dark-700 p-2 rounded-tr-lg rounded-br-lg hover:bg-gray-100 transition-all'"
                        onClick={() => setOpenSidebar((current) => !current)}
                        open={openSidebar}
                      >
                        <ChevronLeftIcon
                          className={clsx('w-5 h-5', {
                            '-scale-100': openSidebar === false,
                          })}
                        />
                      </button>
                    </div>
                  </div>
                  <div
                    className="flex w-full pr-[127px] open:pr-0 transition-all duration-500 justify-center"
                    open={openSidebar}
                  >
                    <div className="w-full max-w-xl mx-auto tab-editor">
                      <StoryEditor
                        storyHandle={setRequest}
                        storyData={request}
                        setError={setError}
                        error={error}
                        generateEpub={generateEpub}
                        unlockables={unlockables}
                        setUnlockables={setUnlockables}
                        isPrivate={isPrivate}
                        setIsPrivate={setIsPrivate}
                        allowDownload={allowDownload}
                        setAllowDownload={setAllowDownload}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="flex w-full pr-[127px] open:pr-0 transition-all duration-500 justify-center"
                  open={openSidebar}
                >
                  <div className="w-full max-w-xl mx-auto">
                    {/* Story Nav */}
                    <nav
                      className="-mb-px flex space-x-2 justify-center grow sticky top-16 bg-white dark:bg-dark-900 w-full z-10 lg:hidden"
                      aria-label="Tabs"
                    >
                      <Tabs
                        tabs={tabs}
                        handleTabChange={selectTab}
                        currentTab={tabSelected}
                      />
                    </nav>
                    <div
                      className={clsx('w-full', {
                        hidden: tabSelected !== 0,
                      })}
                    >
                      <div className="tab-editor w-full">
                        <StoryEditor
                          storyHandle={setRequest}
                          storyData={request}
                          setError={setError}
                          error={error}
                          generateEpub={generateEpub}
                          unlockables={unlockables}
                          setUnlockables={setUnlockables}
                          isPrivate={isPrivate}
                          setIsPrivate={setIsPrivate}
                          allowDownload={allowDownload}
                          setAllowDownload={setAllowDownload}
                        />
                      </div>
                    </div>

                    <div
                      className={clsx('', {
                        hidden: tabSelected !== 1,
                      })}
                    >
                      <div
                        className="tab-details max-w-md mx-auto group"
                        open={openSidebar}
                      >
                        <StoryData
                          storyHandle={setRequest}
                          storyData={request}
                          address={address}
                          userName={userName}
                          openSidebar={openSidebar}
                          error={error}
                          setError={setError}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <ModalConfirmation
              open={openPublishConfirmationModal}
              setOpen={setOpenPublishConfirmationModal}
              title="Ready to publish?"
              description="You won’t be able to edit your content after publishing. Make sure
        everything is ok."
              ButtonOkText="Publish"
              ButtonOkOnClick={createStory}
              Icon={CheckIcon}
            />
            <ModalConfirmation
              open={openLeavingPageModal}
              setOpen={setOpenLeavingPageModal}
              title="You will loose all your content if you leave this page"
              description="Your content won’t be stored and you will loose your progress."
              ButtonOkText="I understand"
              ButtonOkOnClick={() => navigate('/')}
              Icon={TrashIcon}
            />
            {/* Modal: Story Created */}
            {storyResponse !== null && (
              <ModalSellStory
                tokenId={storyResponse.tokenId}
                available={storyResponse.totalSupply}
                callback={sellOrderCallBack}
                story={{
                  image: getIpfsUrl(storyResponse.image),
                  name: storyResponse.name,
                  author: storyResponse.author,
                  type: storyResponse.type,
                }}
                isNewStory={!!storyResponse}
              />
            )}
          </div>
        </Container>
      </>
    )
  );
}

export default CreateStory;
