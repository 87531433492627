import React from 'react';

function PlayIcon(className = '') {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 23C18.5751 23 23.5 18.0751 23.5 12C23.5 5.92487 18.5751 1 12.5 1C6.42487 1 1.5 5.92487 1.5 12C1.5 18.0751 6.42487 23 12.5 23ZM11.8877 8.10593C11.4658 7.82465 10.9233 7.79842 10.4762 8.0377C10.0291 8.27698 9.75 8.74291 9.75 9.25V14.75C9.75 15.2571 10.0291 15.723 10.4762 15.9623C10.9233 16.2016 11.4658 16.1754 11.8877 15.8941L16.0127 13.1441C16.3952 12.8891 16.625 12.4597 16.625 12C16.625 11.5403 16.3952 11.1109 16.0127 10.8559L11.8877 8.10593Z"
        fill="white"
      />
      <path
        d="M11.8877 8.10593C11.4658 7.82465 10.9233 7.79842 10.4762 8.0377C10.0291 8.27698 9.75 8.74291 9.75 9.25V14.75C9.75 15.2571 10.0291 15.723 10.4762 15.9623C10.9233 16.2016 11.4658 16.1754 11.8877 15.8941L16.0127 13.1441C16.3952 12.8891 16.625 12.4597 16.625 12C16.625 11.5403 16.3952 11.1109 16.0127 10.8559L11.8877 8.10593Z"
        fill="black"
      />
    </svg>
  );
}

export default PlayIcon;
