import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import transakSDK from '@transak/transak-sdk';
import toast from 'react-hot-toast';
import ReactGA from 'react-ga4';
import LoginContext from './LoginContext';

const TransakContext = createContext();

function TransakProvider({ children }) {
  const { address } = useContext(LoginContext);
  const [account, setAccount] = useState();
  const [transak, setTransak] = useState();

  useEffect(() => {
    if (account && !transak) {
      // eslint-disable-next-line new-cap
      const transakInstance = new transakSDK({
        apiKey: process.env.REACT_APP_TRANSAK_API_KEY,
        environment: process.env.REACT_APP_TRANSAK_ENVIRONMENT,
        defaultCryptoCurrency: 'MATIC',
        walletAddress: account,
        themeColor: '18e8aa',
        email: '', // TODO: Add user email when register functionality is integrated
        hostURL: window.location.origin,
        widgetHeight: '550px',
        widgetWidth: '100%',
      });

      setTransak(transakInstance);
    }
  }, [account]);

  useEffect(() => {
    if (transak) {
      // This will trigger when the user marks payment is made.
      transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, () => {
        transak.close();
        ReactGA.event('earn_virtual_currency');
        toast.success('Funds have been send to your wallet');
      });
    }
  }, [transak]);

  useEffect(() => {
    if (address) {
      setAccount(address);
    }
  }, [address]);

  const data = useMemo(() => {
    return { transak };
  }, [transak]);

  return (
    <TransakContext.Provider value={data}>{children}</TransakContext.Provider>
  );
}

export { TransakProvider };
export default TransakContext;
