import { useState, useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import clsx from 'clsx';
import toast from 'react-hot-toast';
import EditorjsParser from 'editorjs-parser';
import { motion } from 'framer-motion';
import linkifyHtml from 'linkify-html';
import {
  ArrowPathRoundedSquareIcon,
  LockClosedIcon,
  ShareIcon,
} from '@heroicons/react/24/outline';
import { RWebShare } from 'react-web-share';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import Lightbox from 'yet-another-react-lightbox';
/* eslint-disable import/no-unresolved */
import 'yet-another-react-lightbox/styles.css';
import ReactGA from 'react-ga4';
import ScrollContainer from 'react-indiana-drag-scroll';
import Avatar from '../../components/Avatar';
import AvatarWithInfo from '../../components/AvatarWithInfo';
import LoginContext from '../../context/LoginContext';
import apiService from '../../services/apiService';
import { getIpfsUrl } from '../../services/ipfsService';
import { useSpinner } from '../../context/SpinnerContext';
import MarkdownRenderer from '../../components/MarkdownRenderer';
import Metrics from '../../components/Metric/Metric';
import Badge from '../../components/Badge';
import { ButtonLink } from '../../components/Button/Button';
import Footer from '../../components/Footer';
import { NavbarAlter } from '../../components/ProgressBar/ProgressBar';
import ItemInfo from '../../components/ItemInfo/ItemInfo';
import ButtonBack from '../../components/ButtonBack/ButtonBack';
import StoryActions from '../../components/StoryActions';
import Slider from '../../components/Slider';
import GridItem from '../../components/GridStories/GridItem';
import {
  STORY_STATUS_MINTED,
  TYPE_BOOK,
  TYPE_STORY,
} from '../../utils/constants';
import ModalBookReader from '../../components/ModalBookReader';
import UsersSlider from '../../components/UsersSlider';
import Container from '../../components/Containers';
import CollapseSection from '../../components/CollapseSection';
import Unlocks from '../../components/Unlocks';
import Header from '../../components/Header/Header';
import Link from '../../components/Link';
import Tooltip from '../../components/Tooltip/Tooltip';
import PolygonIcon from '../../components/Icons/PolygonIcon';
import { pathToPageName } from '../../utils/utils';
import Heading from '../../components/Typography';
import { Text } from '../../components/Typography/Heading';
import { FeatureHighlight } from '../ForAuthors/Features';
import Parallax from '../../components/parallax/parallax';
import { useTheme } from '../../context/ThemeContext';

const customParsers = {
  header: (data) => {
    return `<h${data.level + 1} id="h${data.level}">
        ${data.text}
      </h${data.level + 1}>`;
  },
  delimiter: () => {
    return '<div><div class="ce-delimiter" /></div>';
  },
};

const parser = new EditorjsParser(undefined, customParsers);

function Story() {
  const { tokenId } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const { address, isLoadingUserLogin } = useContext(LoginContext);
  const { showSpinner, hideSpinner } = useSpinner();
  const { theme } = useTheme();
  const [storyInfo, setStoryInfo] = useState();
  const [marketInfo, setMarketInfo] = useState([]);
  const [isReading, setIsReading] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [copiesOwned, setCopiesOwned] = useState(0);
  const [collectionStories, setCollectionStories] = useState([]);
  const [collectionNumbers, setCollectionNumbers] = useState();
  const [metrics, setMetrics] = useState([]);
  const [lastCollectors, setLastCollectors] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  const [displayBookReader, setDisplayBookReader] = useState(false);
  const [dropdownActions, setDropdownActions] = useState([]);
  const [openLightBox, setOpenLightBox] = useState(false);

  const toggleDescription = () => setShowDescription(!showDescription);

  const getOwnerData = async () => {
    if (address) {
      return apiService.get(`/owner/${tokenId}/${address}`);
    }

    return false;
  };

  const getMarketData = async () => apiService.get(`/market/id/${tokenId}`);

  const getContent = async (contentIpfs, contentType) => {
    if (contentIpfs) {
      const response = await axios.get(contentIpfs, {
        responseType: contentType === TYPE_BOOK ? 'blob' : 'json',
      });
      return response.data;
    }
    return '';
  };

  const getStoryData = async () => {
    try {
      const storyResponse = await apiService.get(`/story/id/${tokenId}`);

      let content = await getContent(storyResponse.content, storyResponse.type);

      // This is done to solve an issue with content preview of old stories
      if (storyResponse.hasToBeCut) {
        const previewBlocks = content.blocks.slice(0, 5);
        content = {
          ...content,
          blocks: previewBlocks,
        };
      }

      const data = {
        ...storyResponse,
        image: getIpfsUrl(storyResponse.image),
        content:
          storyResponse.type === TYPE_STORY ? parser.parse(content) : content,
      };
      setStoryInfo(data);

      return data;
    } catch (err) {
      if (err.response?.status === 400) {
        throw Error('This story does not exist');
      }
      throw err;
    }
  };

  const getStoryMetrics = async () => {
    const metricsResponse = await apiService.get(`/story/metrics/${tokenId}`);

    setMetrics([
      { name: 'Sold', value: metricsResponse.sold },
      { name: 'Collectors', value: metricsResponse.collectors },
    ]);
    setLastCollectors(metricsResponse.lastCollectors);
  };

  const getCollectionData = async (collectionId) => {
    const storiesResponse = await apiService.get(
      `/story/collection/${collectionId}`,
    );
    setCollectionStories(
      storiesResponse.filter((story) => story.tokenId !== tokenId).slice(0, 6),
    );
    const data = { stories: 0, books: 0 };
    storiesResponse.forEach((story) => {
      if (story.type === TYPE_BOOK) {
        data.books += 1;
      } else {
        data.stories += 1;
      }
    });
    setCollectionNumbers(`${data.stories} stories · ${data.books} books`);
  };

  const loadPageInfo = async () => {
    try {
      showSpinner();
      const [storyData, ownerData, marketData] = await Promise.all([
        getStoryData(),
        getOwnerData(),
        getMarketData(),
        getStoryMetrics(),
      ]);

      setMarketInfo(marketData);

      // eslint-disable-next-line no-underscore-dangle
      await getCollectionData(storyData.collectionId._id);

      if (ownerData) {
        const totalOwnerCopies = ownerData?.copies || 0;
        setIsOwner(totalOwnerCopies > 0);
        setCopiesOwned(totalOwnerCopies);
      }
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    } finally {
      hideSpinner();
    }
  };

  const onClickRead = (e, expand) => {
    e.preventDefault();
    setIsReading(true);
    if (storyInfo.type === TYPE_BOOK) {
      setDisplayBookReader(true);
    }
    if (expand) {
      ReactGA.event({
        category: 'read',
        action: 'click_expand',
        label: pathToPageName(pathname, storyInfo.name),
      });
    } else {
      ReactGA.event({
        category: 'read',
        action: 'click_read_visitor',
        label: pathToPageName(pathname, storyInfo.name),
      });
    }
  };

  useEffect(() => {
    if (storyInfo && storyInfo.type === TYPE_BOOK && !displayBookReader) {
      setIsReading(false);
    }
  }, [displayBookReader]);

  useEffect(() => {
    showSpinner();
    if (!isLoadingUserLogin) {
      loadPageInfo();
    }
  }, [isLoadingUserLogin, location.pathname]);
  return (
    storyInfo && (
      <div>
        <Header dropdownActions={dropdownActions} />
        <Lightbox
          open={openLightBox}
          close={() => setOpenLightBox(false)}
          slides={[{ src: storyInfo.image }]}
          carousel={{ finite: true }}
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
        />
        <NavbarAlter
          display={isReading}
          storyInfo={storyInfo}
          shareAddress={address}
        />
        {/* Mobile Info */}
        <Container
          flex
          margin="flat-mobile"
          spacing="none"
          className="bg-gray-50 dark:bg-dark-800 px-0.5 xs:px-2 lg:hidden py-2"
        >
          <div className="flex max-w-3xl mx-auto w-full items-center">
            <div className="w-1/2">
              <AvatarWithInfo
                title="Creator"
                name={storyInfo.creator.name}
                userName={storyInfo.creator.userName}
                avatarSize="md"
                className="w-full"
                img={
                  storyInfo.creator.avatar &&
                  getIpfsUrl(storyInfo.creator.avatar)
                }
                link={`/${storyInfo.createdBy}`}
              />
            </div>
            <div className="w-1/2">
              <AvatarWithInfo
                title="Collection"
                name={storyInfo.collectionId.name}
                className="w-full"
                avatarSize="md"
                img={
                  storyInfo.collectionId.logo &&
                  getIpfsUrl(storyInfo.collectionId.logo)
                }
                userName={collectionNumbers}
                // eslint-disable-next-line no-underscore-dangle
                link={`/collection/${storyInfo.collectionId._id}`}
              />
            </div>
          </div>
        </Container>
        {/* Top Actions */}
        <Container spacing="none" className="flex justify-between items-center">
          <div className="hidden lg:block">
            <ButtonBack />
          </div>
          <div className="hidden lg:flex justify-end">
            <RWebShare
              data={{
                text: `Check out ${storyInfo.name} profile on Readl Stories`,
                url: `${window.location.origin}/${address}`,
                title: 'Share this story',
              }}
              onClick={() => {
                ReactGA.event({
                  category: 'share',
                  action: 'click_share_top',
                  label: pathToPageName(pathname, storyInfo.name),
                });
              }}
            >
              <span className="rounded-full overflow-hidden block shadow-lg hover:shadow-2xl transition-all">
                <ShareIcon className="w-10 h-10 p-2 sm:w-12 sm:h-12 sm:p-2 lg:w-16 lg:h-16 lg:p-4 bg-white cursor-pointer dark:bg-dark-700 dark:text-white dark:hover:bg-dark-600" />
              </span>
            </RWebShare>
          </div>
        </Container>

        <Container className="flex flex-col lg:flex-row">
          {/* Cover */}
          <div className="w-full lg:w-3/12 pt-8 lg:pt-12">
            {storyInfo.type === 'book' ? (
              <div className="book-container">
                <button
                  className={clsx(
                    'book',
                    theme === 'dark' ? 'book-light' : 'book-dark',
                  )}
                  type="button"
                  onClick={() => setOpenLightBox(true)}
                >
                  <img alt={storyInfo.name} src={storyInfo.image} />
                </button>
              </div>
            ) : (
              <section
                className={clsx(
                  'bg-no-repeat bg-contain  top-32 p-4 lg:p-0 lg:pr-12 transition-all duration-500',
                )}
              >
                {/* Card Image */}
                <div className="flex overflow-hidden relative w-fit max-w-[80%] lg:max-w-none mx-auto">
                  <button type="button" onClick={() => setOpenLightBox(true)}>
                    <img
                      className="w-auto h-full max-h-[500px] rounded-xl cursor-pointer"
                      src={storyInfo.image}
                      alt="cover"
                    />
                  </button>
                </div>
              </section>
            )}
          </div>

          {/* Content */}
          <div className="w-full max-w-3xl mx-auto lg:w-6/12 lg:px-2 xl:px-6">
            <div>
              <ItemInfo
                type={storyInfo.type}
                quantity={storyInfo.totalSupply}
                gated={storyInfo.isPrivate}
                duration={storyInfo.readingTime}
              />
            </div>
            <div>
              {/* Title Preview */}
              <div className="space-y-2">
                {/* Title */}
                <h2 className="text-2xl lg:text-4xl text-black font-bold break-words leading-tight mt-5 mb-4 dark:text-white">
                  {storyInfo.name}
                </h2>
                <div className="relative">
                  <ScrollContainer
                    className="flex cursor-grab gap-2"
                    vertical={false}
                  >
                    {storyInfo?.tags &&
                      storyInfo.tags.map((tag) => (
                        <Badge key={tag} variant="outline" size="lg">
                          {tag}
                        </Badge>
                      ))}
                  </ScrollContainer>
                  <div className="bg-gradient-to-l from-white dark:from-dark-900 to-transparent absolute w-[5%] top-0 bottom-0 right-0" />
                </div>
              </div>
            </div>
            {/* Description */}
            {storyInfo.description && storyInfo.type === TYPE_STORY ? (
              <div
                className={clsx(
                  'transition-all duration-300 bg-gray-50 dark:bg-dark-800 cursor-auto hover:bg-gray-100 p-4 mt-4 rounded-lg -mx-2',
                  { '!cursor-pointer': TYPE_STORY },
                )}
                onClick={toggleDescription}
                aria-hidden
              >
                <span className="text-xs uppercase font-semibold text-gray-600 dark:text-dark-400">
                  Description
                </span>
                <MarkdownRenderer
                  className={clsx(
                    'leading-relaxed text-gray-600 dark:text-dark-200 transition-all r-markdown',
                    {
                      'line-clamp-2': !showDescription,
                    },
                  )}
                >
                  {storyInfo.description}
                </MarkdownRenderer>
              </div>
            ) : (
              <div>
                {storyInfo.description && (
                  <CollapseSection>
                    <MarkdownRenderer
                      id="editorjs"
                      className={clsx(
                        'prose max-w-none text-gray-600 dark:text-dark-200 r-markdown text-[1.1rem]',
                      )}
                    >
                      {storyInfo.description}
                    </MarkdownRenderer>
                  </CollapseSection>
                )}
              </div>
            )}
            {/* Content */}
            {storyInfo.type === TYPE_STORY && (
              <div
                className={clsx(
                  'text-lg text-gray-500 dark:text-dark-200 transition-all duration-1000 mt-2 rounded-md w-full overflow-hidden relative mb-8',
                  {
                    'max-h-72': !isReading,
                    'max-h-full': isReading,
                  },
                )}
                aria-hidden
              >
                <div
                  className={clsx(
                    'text-gray-900 dark:text-dark-200 relative prose max-w-none',
                  )}
                  id="editorjs"
                >
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(storyInfo.content, {
                        target: '_blank',
                      }),
                    }}
                  />
                </div>
                {!isReading && (
                  <div className="bg-gradient-to-t from-white dark:from-dark-900 via-[256, 256, 256, 0.5] w-full absolute left-0 right-0 bottom-0 h-24 flex items-end">
                    <div
                      className="mx-auto cursor-pointer hover:-translate-y-4 transition-all duration-500 hover:bg-gray-50 dark:hover:bg-dark-700 rounded-full p-2 -mb-4"
                      onClick={(e) => onClickRead(e, true)}
                      aria-hidden="true"
                    >
                      <ChevronDownIcon className="w-8 h-8" />
                    </div>
                  </div>
                )}
                {isReading && storyInfo.isPrivate && !isOwner && (
                  <motion.div
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 2, delayChildren: 2 }}
                    className="absolute bg-gradient-to-t from-white dark:from-dark-900 to-transparent h-[200px] bottom-4 left-0 right-0 flex items-end justify-center"
                  >
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 1, delay: 1.5 }}
                    >
                      <Tooltip tooltipText="Collect to unlock">
                        <div className="bg-gray-100 dark:bg-dark-700 p-5 rounded-full">
                          <LockClosedIcon className="h-5 w-5" />
                        </div>
                      </Tooltip>
                    </motion.div>
                  </motion.div>
                )}
              </div>
            )}
            {/* Actions */}
            <div className="mt-12">
              <StoryActions
                storyInfo={storyInfo}
                marketInfo={marketInfo}
                isOwner={isOwner}
                copiesOwned={copiesOwned}
                isReading={isReading}
                onClickRead={onClickRead}
                dropdownActions={dropdownActions}
                setDropdownActions={setDropdownActions}
                reload={loadPageInfo}
              />
            </div>
          </div>

          {/* Stats */}
          <div className="w-full lg:w-3/12" id="stats">
            <div
              className={clsx(
                'lg:top-24 p-4 lg:p-0 lg:pl-12 lg:mt-12 transition-all duration-500',
              )}
            >
              <div className="hidden lg:block space-y-4">
                <AvatarWithInfo
                  title="Creator"
                  name={storyInfo.creator.name}
                  userName={storyInfo.creator.userName}
                  className="w-full"
                  img={
                    storyInfo.creator.avatar &&
                    getIpfsUrl(storyInfo.creator.avatar)
                  }
                  link={`/${storyInfo.createdBy}`}
                />
                <AvatarWithInfo
                  title="Collection"
                  name={storyInfo.collectionId.name}
                  className="w-full"
                  img={
                    storyInfo.collectionId.logo &&
                    getIpfsUrl(storyInfo.collectionId.logo)
                  }
                  userName={collectionNumbers}
                  // eslint-disable-next-line no-underscore-dangle
                  link={`/collection/${storyInfo.collectionId._id}`}
                />
              </div>
              <div className="hidden lg:block mt-8">
                <Metrics
                  metrics={metrics}
                  direction="vertical"
                  align="left"
                  size="large"
                />
                <UsersSlider
                  className="max-w-[100px] xs:max-w-[140px] sm:max-w-[300px] mt-2"
                  users={lastCollectors}
                />
              </div>
            </div>
            <div className="max-w-3xl mx-auto flex w-full justify-between xl:justify-end p-2 xs:p-4 bg-gray-50 dark:bg-dark-800 lg:bg-transparent rounded-xl lg:hidden">
              <Metrics metrics={metrics} direction="horizontal" align="left" />
              <div className="min-w-fit ml-6 block lg:hidden max-w-[80px]">
                <UsersSlider
                  className="max-w-[100px] xs:max-w-[140px] sm:max-w-[300px]"
                  users={lastCollectors}
                />
              </div>
            </div>
          </div>
        </Container>
        {collectionStories.length > 0 && (
          <Container
            margin="flat-mobile"
            spacing="small"
            className="flex flex-col-reverse lg:flex-row bg-gray-50 dark:bg-dark-800 rounded-2xl py-14 my-24 items-center"
          >
            <div className="flex flex-col md:flex-row w-full min-w-[300px] max-w-3xl mx-auto lg:w-5/12 xl:w-4/12 space-y-4 lg:pr-8 lg:space-y-0 lg:space-x-4 mb-6 lg:mb-0 px-4 xs:px-8">
              <Link
                // eslint-disable-next-line no-underscore-dangle
                to={`/collection/${storyInfo.collectionId._id}`}
                className="block min-w-fit md:mr-8 md:mt-4"
              >
                <Avatar
                  img={
                    storyInfo.collectionId.logo &&
                    getIpfsUrl(storyInfo.collectionId.logo)
                  }
                  name={storyInfo.collectionId.name}
                  size="xl"
                />
              </Link>
              <div>
                <div className="mb-4">
                  <h6 className="uppercase font-semibold text-[10px] text-gray-600 dark:text-dark-400">
                    Collection
                  </h6>
                  <h4 className="text-xl font-bold dark:text-dark-100">
                    {storyInfo.collectionId.name}
                  </h4>
                  <span className="text-xs text-gray-500 dark:text-dark-400">
                    {collectionNumbers}
                  </span>
                </div>
                <div>
                  <MarkdownRenderer className="prose max-w-none text-sm text-gray-500 dark:text-dark-400 line-clamp-[8] r-markdown">
                    {storyInfo.collectionId.description}
                  </MarkdownRenderer>
                  <ButtonLink
                    variant="secondary"
                    size="sm"
                    className="mt-4 block"
                    // eslint-disable-next-line no-underscore-dangle
                    to={`/collection/${storyInfo.collectionId._id}`}
                  >
                    View Collection
                  </ButtonLink>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-7/12 xl:w-8/12 bg-white dark:bg-dark-900 py-5 rounded-xl mb-12 lg:mb-0">
              <Slider
                className="w-full"
                classNameSlider="!px-8 !py-4 !pr-4"
                slidesPerView={1}
                navigation
                slidesPerViewBreakpoints={{
                  xs: 1.4,
                  sm: 2.8,
                  md: 2.2,
                  lg: 2.2,
                  xl: 2.5,
                  '2xl': 2.5,
                }}
                items={collectionStories.map((story) => (
                  <GridItem data={story} />
                ))}
              />
            </div>
          </Container>
        )}
        {/* Content */}
        <Container className="py-8 relative flex flex-col-reverse lg:flex-row mt-12">
          <div className="w-full max-w-3xl mx-auto lg:mx-0 lg:w-6/12">
            <Heading size="4" className="mt-4 mb-8">
              Details
            </Heading>
            <div className="grid sm:grid-cols-2 gap-6 sm:pr-8">
              <AvatarWithInfo
                title="Creator"
                name={storyInfo.author}
                img={
                  storyInfo.creator.avatar &&
                  getIpfsUrl(storyInfo.creator.avatar)
                }
                link={`/${storyInfo.createdBy}`}
              />
              <AvatarWithInfo
                title="Collection"
                name={storyInfo.collectionId.name}
                img={
                  storyInfo.collectionId.logo &&
                  getIpfsUrl(storyInfo.collectionId.logo)
                }
                userName={collectionNumbers}
                // eslint-disable-next-line no-underscore-dangle
                link={`/collection/${storyInfo.collectionId._id}`}
              />
              <AvatarWithInfo
                title="Blockchain"
                name="Polygon"
                mintedDate={
                  storyInfo.status === STORY_STATUS_MINTED
                    ? storyInfo.updatedAt
                    : false
                }
                mintedLink={`${process.env.REACT_APP_NETWORK_BLOCK_EXPLORER}/token/${process.env.REACT_APP_MATIC_CONTRACT_NFT}/?a=${storyInfo.tokenId}`}
                icon={<PolygonIcon className="w-5 h-5" />}
              />
              <AvatarWithInfo
                title="Royalties Fee"
                name={`${storyInfo.resaleComission}% for the creator`}
                img="/sparkles.svg"
                icon={<ArrowPathRoundedSquareIcon className="w-5 h-5" />}
              />
            </div>
          </div>
          {(storyInfo.type === TYPE_BOOK ||
            storyInfo.unlockableContent.length > 0) && (
            <div className="w-full lg:w-6/12 mb-16 lg:mb-0 max-w-3xl mx-auto lg:mx-0">
              <Heading size="4" className="mt-4 mb-8">
                Unlocks
              </Heading>
              {storyInfo.type === TYPE_BOOK && (
                <Unlocks item={storyInfo} isOwner={isOwner} />
              )}
              <div className="mt-3">
                {storyInfo.unlockableContent.length > 0 &&
                  storyInfo.unlockableContent.map((item) => (
                    <Unlocks item={item} isOwner={isOwner} />
                  ))}
              </div>
            </div>
          )}
        </Container>
        <Container>
          <div className="space-y-6 bg-black rounded-2xl p-20 text-white px-6 py-6 sm:px-12 sm:py-12 lg:p-18 lg:pt-12 mb-24 grid lg:grid-cols-2 xl:grid-cols-5 gap-8 lg:gap-16 bg-[url('https://p1.f0.n0.cdn.getcloudapp.com/items/NQuWO0pd/3ef3e9e0-febb-4a29-b761-4e6140706e76.png?source=viewer&v=727e943dc7dad4d90abd94c68f7fcea5')] items-center">
            <div className="xl:col-span-2">
              <Heading size="2">📖 💎</Heading>
              <Heading size="2">Read and Collect</Heading>
              <Text size="xl">
                Unlock the power of Literary NFTs and build your collection
                today.
              </Text>
            </div>
            <div className="space-y-4 xl:col-span-3">
              <Parallax>
                <div className="flex flex-wrap items-start gap-2 pt-4">
                  <FeatureHighlight
                    icon="🔑"
                    text="Login with Metamask, Discord or email"
                    dark
                    textSize="base"
                  />
                  <FeatureHighlight
                    icon="🤝"
                    text="Resell your NFT when you’re done"
                    dark
                    textSize="base"
                  />
                  <FeatureHighlight
                    icon="📱"
                    text="Read and collect on any device"
                    dark
                    textSize="base"
                  />
                  <FeatureHighlight
                    icon="🤘"
                    text="Sponsored transactions on free items"
                    dark
                    textSize="base"
                  />
                  <FeatureHighlight
                    icon="💳"
                    text="Easily add funds to your wallet with credit card"
                    dark
                    textSize="base"
                  />
                </div>
              </Parallax>
            </div>
          </div>
        </Container>
        <ModalBookReader
          open={displayBookReader}
          setOpen={setDisplayBookReader}
          file={storyInfo.content}
          title={storyInfo.title}
          tokenId={storyInfo.tokenId}
        />
        <Footer />
      </div>
    )
  );
}

export default Story;
