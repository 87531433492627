import React from 'react';

function IconTablerMail({ className }) {
  return (
    <svg fill="none" viewBox="0 0 24 24" className={className}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.417 5.583H5.583c-1.012 0-1.833.82-1.833 1.833v9.167c0 1.013.82 1.833 1.833 1.833h12.834c1.012 0 1.833-.82 1.833-1.833V7.416c0-1.012-.82-1.833-1.833-1.833z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.75 7.417l8.25 5.5 8.25-5.5"
      />
    </svg>
  );
}

export default IconTablerMail;
