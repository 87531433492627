import clsx from 'clsx';

export default function TagButton({ name, count, selected, selectTag }) {
  return (
    <button
      className={clsx(
        'inline-flex disabled:opacity-20 space-x-2 text-xs items-center justify-start px-3 py-1 border rounded-full min-w-fit',
        {
          ' bg-gray-100 dark:bg-dark-800 border-gray-200 dark:border-dark-700 dark:text-dark-200 transition duration-300 hover:ring-1 hover:ring-primary-500':
            !selected,
          ' border-primary-500 bg-primary-500': selected,
        },
      )}
      type="button"
      onClick={() => selectTag(name)}
      key={name}
    >
      {name}{' '}
      {count && (
        <span className="ml-1 rounded-full bg-black bg-opacity-5 flex justify-center items-center min-w-4 h-4 aspect-square text-[10px]">
          {count}
        </span>
      )}
    </button>
  );
}
