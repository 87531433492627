import React from 'react';

function IconTablerGlobe({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      strokeWidth="2px"
      stroke="currentColor"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <circle cx="12" cy="12" r="9" />
      <path d="M3.6 9L20.4 9" />
      <path d="M3.6 15L20.4 15" />
      <path d="M11.5 3a17 17 0 000 18M12.5 3a17 17 0 010 18" />
    </svg>
  );
}

export default IconTablerGlobe;
