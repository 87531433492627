import { motion } from 'framer-motion';
import clsx from 'clsx';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
import Container from '../../components/Containers';
import Heading from '../../components/Typography';
import { Text } from '../../components/Typography/Heading';
import { Button } from '../../components/Button/Button';
import Link from '../../components/Link';
import Parallax from '../../components/parallax/parallax';
import StartPublish from '../../components/StartPublish';

const containerMotion = {
  initial: { opacity: 0, transition: { duration: 1 } },
  visible: {
    opacity: 1,
    transition: { duration: 0.5, staggerChildren: 0.3 },
  },
};
const childVariantsAlways = {
  initial: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

function UseCase({ useCase, className }) {
  return (
    <Container>
      <div
        className={clsx(
          'flex lg:flex-row flex-col lg:grid-cols-2 lg:gap-24 items-center border-t border-gray-200 pt-24 lg:border-none lg:pt-0',
          className,
        )}
      >
        <Parallax>
          <motion.div
            className="space-y-6 max-w-2xl"
            variants={containerMotion}
            initial="initial"
            whileInView="visible"
            viewport={{ once: true }}
            margin="flat-mobile"
          >
            <motion.div variants={childVariantsAlways}>
              <div>
                <Heading
                  size="6"
                  className="uppercase text-gray-500 dark:!text-dark-300"
                >
                  Use Case
                </Heading>
                <Heading size="2">{useCase.title}</Heading>
              </div>
            </motion.div>
            <motion.div variants={childVariantsAlways}>
              <Text size="lg">{useCase.description}</Text>
            </motion.div>
            <motion.ul
              variants={childVariantsAlways}
              className="prose text-lg text-black dark:text-white"
            >
              {useCase.useList.map((item) => (
                <li key={item.title}>{item.text}</li>
              ))}
            </motion.ul>
            <motion.div variants={childVariantsAlways}>
              <StartPublish>
                <Button variant="primary" flex iconLeft={<PlusSmallIcon />}>
                  Get started
                </Button>
              </StartPublish>
            </motion.div>
          </motion.div>
        </Parallax>
        {useCase.cover && (
          <Parallax>
            <motion.div
              variants={containerMotion}
              initial="initial"
              whileInView="visible"
              margin="flat-mobile"
              className="relative overflow-hidden rounded-2xl mt-6 max-w-2xl"
            >
              <div
                className={clsx(
                  'bg-black absolute -z-10 left-0 right-0 top-0 bottom-0 m-auto scale-150',
                )}
              >
                <img
                  className="align-middle h-full min-w-full object-contain w-auto z-10 scale-100 group-hover:scale-110 transition-transform duration-700 blur-xl"
                  src={useCase.cover}
                  alt=""
                />
                <div className="absolute bg-gradient-to-t from-black to-transparent h-[200px] bottom-4 left-0 right-0 flex items-end justify-center" />
              </div>
              <div className="p-4 lg:p-16 text-white lg:text-center space-y-4 flex flex-row lg:flex-col gap-4 lg:gap-0 items-center">
                <Link href={useCase.itemLink}>
                  <img
                    src={useCase.cover}
                    alt="Use case"
                    className="rounded-lg max-w-[80px] lg:max-w-[250px] mx-auto"
                  />
                </Link>
                <div className="space-y-2 text-sm lg:text-lg">
                  <Heading size="6">{useCase.itemTitle}</Heading>
                  <Text className="text-xs lg:text-base">
                    {useCase.itemDescription}
                  </Text>
                </div>
              </div>
            </motion.div>
          </Parallax>
        )}
      </div>
    </Container>
  );
}

export default UseCase;
