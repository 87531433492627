import clsx from 'clsx';
import React from 'react';
import { motion } from 'framer-motion';

const Component = React.forwardRef(
  (
    {
      className,
      id,
      children,
      width = 'xl',
      margin = 'normal',
      flex,
      wrapperClasses,
      breakpoint,
      spacing = 'normal',
    },
    ref,
  ) => {
    const breakpointFlex = `${breakpoint}:flex`;

    return (
      <div
        ref={ref}
        className={clsx(wrapperClasses, {
          'mx-4 lg:mx-12': margin === 'normal',
          'lg:mx-12': margin === 'flat-mobile',
        })}
      >
        <section
          id={id}
          className={clsx(className, breakpointFlex, 'mx-auto', {
            'max-w-sm': width === 'xs',
            'max-w-xl': width === 'sm',
            'max-w-3xl': width === 'md',
            'max-w-7xl': width === 'lg',
            'max-w-9xl': width === 'xl',
            'w-full': width === 'full',
            '': spacing === 'none',
            'mb-12': spacing === 'small',
            'mb-24': spacing === 'normal',
            'mb-36': spacing === 'large',
            'mb-48': spacing === 'xl',
            flex,
          })}
        >
          {children}
        </section>
      </div>
    );
  },
);

const Container = motion(Component);

export default Container;
