import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import LoginContext from '../../context/LoginContext';
import ModalStartPublish from '../ModalStartPublish';

export default function StartPublish({ children, gaLabel, className }) {
  const { address } = useContext(LoginContext);
  const navigate = useNavigate();
  const [openModalStartPublish, setOpenModalStartPublish] = useState(false);

  const clickStartPublish = () => {
    if (gaLabel) {
      ReactGA.event({
        category: 'create',
        action: 'click_create',
        label: gaLabel,
      });
    }
    if (!address) {
      navigate('/login');
    } else {
      setOpenModalStartPublish(true);
    }
  };

  return (
    <>
      <div onClick={clickStartPublish} aria-hidden="true" className={className}>
        {children}
      </div>
      <ModalStartPublish
        open={openModalStartPublish}
        setOpen={setOpenModalStartPublish}
      />
    </>
  );
}
