import clsx from 'clsx';
import React from 'react';
import Tooltip from '../Tooltip/Tooltip';

function Avatar({ img, name, size = 'md' }) {
  // In case there is no name, we have a fallback
  const nameArray = name ? name.split(' ') : 'A';
  const firstName = nameArray[0]?.charAt(0) || '';
  const lastName = nameArray[1]?.charAt(0) || '';
  const initials = firstName.toUpperCase() + lastName.toUpperCase();

  return (
    <Tooltip tooltipText={name}>
      {img ? (
        <img
          className={clsx(
            'inline-block rounded-full bg-gray-50 z-20 object-cover',
            { 'h-6 w-6': size === 'xs' },
            { 'h-8 w-8': size === 'sm' },
            { 'h-10 w-10': size === 'md' },
            { 'h-12 w-12': size === 'lg' },
            { 'h-16 w-16': size === 'xl' },
            { 'h-20 w-20 lg:h-32 lg:w-32': size === '2xl' },
          )}
          src={img}
          alt="avatar"
        />
      ) : (
        <span
          className={clsx(
            'inline-flex items-center justify-center rounded-full bg-gray-500 z-20',
            { 'h-6 w-6': size === 'xs' },
            { 'h-8 w-8': size === 'sm' },
            { 'h-10 w-10': size === 'md' },
            { 'h-12 w-12': size === 'lg' },
            { 'h-16 w-16': size === 'xl' },
            { 'h-20 w-20 lg:h-32 lg:w-32': size === '2xl' },
          )}
        >
          <span
            className={clsx(
              'font-medium leading-none text-white',
              {
                'text-xs': size === 'xs',
              },
              {
                'text-sm': size === 'sm',
              },
              {
                'text-lg': size === 'lg',
              },
              {
                'text-xl': size === 'xl',
              },
              {
                'text-2xl': size === '2xl',
              },
            )}
          >
            {initials}
          </span>
        </span>
      )}
    </Tooltip>
  );
}

export default Avatar;
