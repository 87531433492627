/* eslint-disable global-require */
/* https://uicolors.app/edit?sv1=dark:50-e8e8e8/100-dedede/200-bfbfbf/300-999999/400-757575/500-575757/600-404040/700-2e2e2e/800-212121/900-000000 */
/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,jsx}'],
  darkMode: 'class',
  variants: {
    opacity: ({ after }) => after(['disabled']),
    extend: {
      ringWidth: ['hover', 'active'],
      ringColor: ['hover', 'active'],
      opacity: ['disabled'],
    },
  },
  theme: {
    extend: {
      fontFamily: {
        body: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'],
      },
      boxShadow: {
        book: '0 20 20 rgba(0,0,0, .10)',
      },
      colors: {
        primary: {
          50: '#E3FCF5',
          100: '#BAF8E6',
          200: '#8CF4D5',
          300: '#5DEFC4',
          400: '#3BEBB7',
          500: '#18E8AA',
          600: '#1BD19B',
          700: '#10BE81',
          800: '#16AB73',
          900: '#127148',
        },
        secondary: {
          50: '#EBEFF1',
          100: '#D3D8DC',
          200: '#B2BAC1',
          300: '#8D98A2',
          400: '#647582',
          500: '#4D6274',
          600: '#324757',
          700: '#223748',
          800: '#132330',
          900: '#091824',
        },
        dark: {
          50: '#e8e8e8',
          100: '#dedede',
          200: '#bfbfbf',
          300: '#999999',
          400: '#757575',
          500: '#575757',
          600: '#404040',
          700: '#2e2e2e',
          800: '#1D1D1D',
          900: '#0C0C0C',
        },
      },
      zIndex: {
        '-10': '-10',
      },
      screens: {
        xs: '374px',
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px',
      },
      maxWidth: {
        '8xl': '1440px',
        '9xl': '1504px',
      },
      maxHeight: {
        '50vh': '50vh', // max height for medium size hero images
        '75vh': '75vh', // max height for giant size hero images
      },
      gridTemplateRows: {
        'max-content': 'max-content',
      },
      spacing: {
        '5vw': '5vw', // pull featured sections and navbar in the margin
        '8vw': '8vw', // positions hero img inside the margin
        '10vw': '10vw', // page margin
      },
      height: {
        hero: 'min(60rem, calc(100vh - 10rem))', // screen - navbar height (lg: only)
        fit: 'fit-content',
      },
      fontSize: {
        xxs: '0.625rem', // 10px
        xl: '1.375rem', // 22px
        '2xl': '1.5625rem', // 25px
        '3xl': '1.875rem', // 30px
        '4xl': '2.9rem', // 40px
        '5xl': '3.125rem', // 50px
        '6xl': '4rem', // 60px
        '7xl': '4.8rem', // 70px
      },
      rotate: {
        '-135': '-135deg',
        135: '135deg',
      },
      animation: {
        'fade-in': 'fade-in 0.5s linear forwards',
      },
      keyframes: {
        'fade-in': {
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        },
      },
    },
  },

  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/line-clamp'),
    require('tailwindcss/plugin'),
  ],
};
