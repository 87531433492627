import { Switch } from '@headlessui/react';
import clsx from 'clsx';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Toggle({
  label = '',
  description = '',
  enabled,
  setEnabled,
  children,
  className,
  disabled,
}) {
  return (
    <Switch.Group as="div" className={clsx('flex flex-col', className)}>
      <div className="flex items-center justify-between">
        {label && (
          <span className="flex flex-grow flex-col">
            <Switch.Label
              as="span"
              className={classNames(
                disabled
                  ? 'text-gray-400 dark:text-dark-500'
                  : 'text-gray-900 dark:text-dark-200',
                'text-sm font-medium',
              )}
              passive
            >
              {label}
            </Switch.Label>

            {description && (
              <Switch.Description
                as="span"
                className={classNames(
                  disabled
                    ? 'text-gray-300 dark:text-dark-500'
                    : 'text-gray-500 dark:text-dark-400',
                  'text-sm',
                )}
              >
                {description}
              </Switch.Description>
            )}
          </span>
        )}
        <Switch
          checked={enabled}
          onChange={setEnabled}
          disabled={disabled}
          className={classNames(
            // eslint-disable-next-line no-nested-ternary
            enabled
              ? disabled
                ? 'bg-primary-200 dark:bg-dark-700'
                : 'bg-primary-600'
              : 'bg-gray-200 dark:bg-dark-400',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              disabled ? 'bg-white dark:bg-dark-500' : 'bg-white',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
      </div>
      {enabled && <div>{children}</div>}
    </Switch.Group>
  );
}
