import React from 'react';

export default function PolygonIcon({ className }) {
  return (
    <svg
      className={`${className} text-[#8247E5]`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.658 8.23676C17.2559 8.007 16.7389 8.007 16.2794 8.23676L13.0627 10.1323L10.8799 11.3386L7.72063 13.2342C7.31854 13.4639 6.80157 13.4639 6.34204 13.2342L3.87206 11.7407C3.46997 11.5109 3.18277 11.0514 3.18277 10.5344V7.66235C3.18277 7.20282 3.41253 6.74329 3.87206 6.45608L6.34204 5.02005C6.74413 4.79029 7.2611 4.79029 7.72063 5.02005L10.1906 6.51352C10.5927 6.74329 10.8799 7.20282 10.8799 7.71979V9.61535L13.0627 8.35164V6.39864C13.0627 5.93911 12.8329 5.47958 12.3734 5.19238L7.77807 2.49264C7.37598 2.26287 6.85901 2.26287 6.39948 2.49264L1.6893 5.24982C1.22977 5.47958 1 5.93911 1 6.39864V11.7981C1 12.2576 1.22977 12.7172 1.6893 13.0044L6.34204 15.7041C6.74413 15.9339 7.2611 15.9339 7.72063 15.7041L10.8799 13.866L13.0627 12.6023L16.2219 10.7642C16.624 10.5344 17.141 10.5344 17.6005 10.7642L20.0705 12.2002C20.4726 12.43 20.7598 12.8895 20.7598 13.4065V16.2785C20.7598 16.7381 20.53 17.1976 20.0705 17.4848L17.658 18.9208C17.2559 19.1506 16.7389 19.1506 16.2794 18.9208L13.8094 17.4848C13.4073 17.255 13.1201 16.7955 13.1201 16.2785V14.4404L10.9373 15.7041V17.5997C10.9373 18.0592 11.1671 18.5187 11.6266 18.806L16.2794 21.5057C16.6815 21.7355 17.1984 21.7355 17.658 21.5057L22.3107 18.806C22.7128 18.5762 23 18.1167 23 17.5997V12.1428C23 11.6832 22.7702 11.2237 22.3107 10.9365L17.658 8.23676Z"
        fill="currentColor"
      />
    </svg>
  );
}
