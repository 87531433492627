import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { constants, BigNumber, Contract } from 'ethers';
import clsx from 'clsx';
import toast from 'react-hot-toast';
import ReactGA from 'react-ga4';
import ModalAddFunds from './ModalAddFunds';
import apiService from '../../services/apiService';
import LoginContext from '../../context/LoginContext';
import { MATIC_CONTRACT_EXCHANGE_ABI } from '../../utils/constants';
import { useSpinner } from '../../context/SpinnerContext';
import { getIpfsUrl } from '../../services/ipfsService';
import ModalBuyStorySuccessfully from '../../components/ModalBuyStory';
import PriceConversion from '../../components/PriceConversion';
import PriceFormatted from '../../components/PriceFormatted';
import Modal from '../../components/Modal';
import CreatedOn from '../../components/CreatedOn';
import StoryListings from '../../components/StoryListings';
import { pathToPageName, parseValue } from '../../utils/utils';
import { Text } from '../../components/Typography/Heading';

function OrderSummaryItem({ children, label }) {
  return (
    <li className="flex justify-between dark:text-dark-100">
      <span className="text-secondary-400 dark:text-dark-100">{label}</span>
      <div>{children}</div>
    </li>
  );
}

function ModalCollectStory({
  story,
  open = false,
  setOpen,
  authorListing,
  otherListings,
  callback,
}) {
  const { ethersProvider, address } = useContext(LoginContext);
  const { showSpinner, hideSpinner } = useSpinner();
  const location = useLocation();
  const { pathname } = location;
  const [userBalance, setUserBalance] = useState(constants.Zero);
  const [isUserBalanceLoaded, setIsUserBalanceLoaded] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [isGasFree, setIsGasFree] = useState(false);
  const [showListingSelector, setShowListingSelector] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);

  const balanceHandle = async () => {
    try {
      showSpinner();
      const balance = await ethersProvider.getSigner().getBalance();
      setUserBalance(balance);
      setIsUserBalanceLoaded(true);
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const orderParse = (order_) => {
    const {
      makerAddress,
      takerAddress,
      feeRecipientAddress,
      senderAddress,
      makerAssetAmount,
      takerAssetAmount,
      makerFee,
      takerFee,
      expirationTimeSeconds,
      salt,
      makerAssetData,
      takerAssetData,
      makerFeeAssetData,
      takerFeeAssetData,
    } = order_;

    return {
      makerAddress,
      takerAddress,
      feeRecipientAddress,
      senderAddress,
      makerAssetAmount,
      takerAssetAmount,
      makerFee,
      takerFee,
      expirationTimeSeconds,
      salt,
      makerAssetData,
      takerAssetData,
      makerFeeAssetData,
      takerFeeAssetData,
    };
  };

  const testHandle = async (sing, address_, Order_) => {
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(
      process.env.REACT_APP_MATIC_CONTRACT_EXCHANGE,
      MATIC_CONTRACT_EXCHANGE_ABI,
      signer,
    );

    const orderPrice = BigNumber.from(selectedOrder.price)
      .add(1000) // add 1000 wei in case the division is inexact
      .toString();

    const tx = await contract.matchOrders(
      orderParse(selectedOrder),
      Order_,
      selectedOrder.orderSing,
      sing,
      { value: orderPrice },
    );
    await tx.wait(2);
  };

  async function freeGas(isleft) {
    try {
      showSpinner();
      const block = await ethersProvider.getBlock('latest');
      const { timestamp } = block;

      const takerAssetAmount = 1;
      const payload = {
        orderHash: selectedOrder.orderHash,
        makerAddress: address,
        makerAssetAmount: selectedOrder.price,
        takerAssetAmount,
        expirationTimeSeconds: parseInt(timestamp + 60 * 60 * 24, 10),
        makerAssetData: process.env.REACT_APP_MATIC_CONTRACT_PROXY_1155,
        ids: [selectedOrder.tokenId],
        amount: [1],
        isleft,
      };

      await apiService.post(`/market/freegass`, { payload });
      setOpen(false);
      setOpenModalSuccess(true);
      ReactGA.event({
        category: 'collect',
        action: 'collected_successfully',
        label: pathToPageName(pathname, story.name),
        value: parseValue(selectedOrder.price),
      });
      // We don't put the hideSpinner in a finally block because the callback
      // function is displaying a spinner as well.
      hideSpinner();
      callback();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.error || 'An error has occurred');
      ReactGA.send({
        hitType: 'exception',
        exDescription: `Error buying nfts: ${err.message} in ${err.stack}`,
        exFatal: true,
      });
      hideSpinner();
    }
  }

  async function signDataHandle(isleft) {
    try {
      showSpinner('Please be patient.\nThis may take a couple of minutes.');
      const block = await ethersProvider.getBlock('latest');
      const { timestamp } = block;

      const takerAssetAmount = 1;
      const payload = {
        orderHash: selectedOrder.orderHash,
        makerAddress: address,
        makerAssetAmount: selectedOrder.price,
        takerAssetAmount,
        expirationTimeSeconds: parseInt(timestamp + 60 * 60 * 24, 10),
        makerAssetData: process.env.REACT_APP_MATIC_CONTRACT_PROXY_1155,
        ids: [selectedOrder.tokenId],
        amount: [1],
        isleft,
      };

      const { tx, order_ } = await apiService.post(`/market/sing`, payload);
      await testHandle(tx, address, orderParse(order_));
      setOpen(false);
      setOpenModalSuccess(true);
      ReactGA.event({
        category: 'collect',
        action: 'collected_successfully',
        label: pathToPageName(pathname, story.name),
        value: parseValue(selectedOrder.price),
      });
      // We don't put the hideSpinner in a finally block because the callback
      // function is displaying a spinner as well.
      hideSpinner();
      callback();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.error || 'An error has occurred');
      ReactGA.send({
        hitType: 'exception',
        exDescription: `Error buying nfts: ${err.message} in ${err.stack}`,
        exFatal: true,
      });
      hideSpinner();
    }
  }

  useEffect(() => {
    if (address) {
      balanceHandle();
    }
  }, [address]);

  useEffect(() => {
    if (authorListing) {
      setSelectedOrder(authorListing);
      setShowListingSelector(otherListings.length > 0);
    } else {
      setSelectedOrder(otherListings[0]);
      setShowListingSelector(otherListings.length > 1);
    }
  }, [authorListing, otherListings]);

  useEffect(() => {
    if (isUserBalanceLoaded && selectedOrder) {
      setIsGasFree(
        selectedOrder.price === '0' && userBalance.eq(constants.Zero),
      );

      const event = {
        category: 'collect',
        action: 'view_collect_modal',
        label: pathToPageName(pathname, story.name),
      };
      if (selectedOrder) {
        event.value = parseValue(selectedOrder.price);
      }
      ReactGA.event(event);
    }
  }, [isUserBalanceLoaded, selectedOrder]);

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        allowClickToClose
        size={otherListings.length ? '3xl' : 'sm'}
        flat
        closeButton
      >
        {selectedOrder && (
          <div className="flex flex-col-reverse md:flex-row">
            {showListingSelector && (
              <StoryListings
                authorListing={authorListing}
                otherListings={otherListings}
                selectListing={(orderId) =>
                  setSelectedOrder(
                    orderId === -1 ? authorListing : otherListings[orderId],
                  )
                }
              />
            )}

            <div className="w-full flex-1 bg-gray-100 dark:bg-dark-800 p-12">
              <div className="flex space-x-4 items-center">
                <div className="max-w-[64px] rounded-md overflow-hidden">
                  <img alt="" src={getIpfsUrl(story.image)} />
                </div>
                <div>
                  <Text>
                    You are about to collect{' '}
                    <span className="font-semibold">{story.name}</span> by{' '}
                    <span className="font-semibold">{story.author}</span>
                  </Text>
                  <div>
                    <CreatedOn />
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <Text size="sm" className="font-semibold dark:!text-dark-500">
                  Order summary
                </Text>
                <ul className="text-sm mt-2 space-y-2">
                  <OrderSummaryItem label="Quantity">x1</OrderSummaryItem>
                  <OrderSummaryItem label="Story Price">
                    <span className="text-gray-500">
                      <PriceFormatted
                        price={BigNumber.from(selectedOrder.takerAssetAmount)}
                      />{' '}
                      ·
                    </span>{' '}
                    <PriceConversion price={selectedOrder.takerAssetAmount} />
                  </OrderSummaryItem>
                  <OrderSummaryItem label="Transfer cost">
                    {isGasFree ? (
                      <>
                        <span className="text-gray-500 dark:text-dark-400">
                          <PriceFormatted price={0} /> ·{' '}
                        </span>
                        <PriceConversion price={0} />
                      </>
                    ) : (
                      <>
                        <span className="text-gray-500 dark:text-dark-400">
                          &gt;0.01 MATIC ·{' '}
                        </span>
                        &gt;
                        {Intl.NumberFormat('en-US', {
                          notation: 'compact',
                          compactDisplay: 'short',
                          style: 'currency',
                          currency: 'USD',
                          currencyDisplay: 'narrowSymbol',
                        }).format(0.01)}
                      </>
                    )}
                  </OrderSummaryItem>
                  <OrderSummaryItem label="Your balance">
                    <span
                      className={clsx('', {
                        'text-red-500': userBalance.lt(
                          BigNumber.from(selectedOrder.takerAssetAmount),
                        ),
                        'text-primary-700': !userBalance.lt(
                          BigNumber.from(selectedOrder.takerAssetAmount),
                        ),
                      })}
                    >
                      <span className="text-gray-500">
                        <PriceFormatted price={userBalance} /> ·
                      </span>{' '}
                      <PriceConversion price={userBalance} />
                    </span>
                  </OrderSummaryItem>
                  <span className="w-full border-t-2 border-black h-1 block" />
                  <li className="flex justify-between dark:text-dark-100">
                    <span>Total to pay</span>
                    <span className="font-semibold">
                      {+selectedOrder.takerAssetAmount === 0 &&
                      userBalance.gt(constants.Zero) ? (
                        <>
                          <span className="text-gray-500 font-normal">
                            {' '}
                            &gt;0.01 MATIC ·{' '}
                          </span>
                          &gt;
                          {Intl.NumberFormat('en-US', {
                            notation: 'compact',
                            compactDisplay: 'short',
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol',
                          }).format(0.01)}
                        </>
                      ) : (
                        <>
                          <span className="text-gray-500 font-normal">
                            {' '}
                            {!isGasFree && '~ '}
                            <PriceFormatted
                              price={selectedOrder.takerAssetAmount}
                            />{' '}
                            ·
                          </span>{' '}
                          <PriceConversion
                            price={selectedOrder.takerAssetAmount}
                          />
                        </>
                      )}
                    </span>
                  </li>
                </ul>
                {/* Show if no funds */}
                {/* Component: Warning */}
                {userBalance.lt(
                  BigNumber.from(selectedOrder.takerAssetAmount),
                ) ? (
                  <div className="bg-red-50 p-3 rounded text-xs text-red-800 mt-3">
                    You don&apos;t have enough funds in your wallet
                  </div>
                ) : null}
                {userBalance.lt(
                  BigNumber.from(selectedOrder.takerAssetAmount),
                ) && (
                  <ModalAddFunds
                    address={address}
                    price={BigNumber.from(selectedOrder.takerAssetAmount)}
                  />
                )}
                {userBalance.gte(
                  BigNumber.from(selectedOrder.takerAssetAmount),
                ) && (
                  <button
                    type="button"
                    onClick={() =>
                      isGasFree ? freeGas(false) : signDataHandle(false)
                    }
                    className="py-3.5 px-4 w-full text-black bg-primary-500 hover:bg-primary-300 rounded-lg focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors mt-5"
                  >
                    Collect {story.type}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal>
      <ModalBuyStorySuccessfully
        openModalSuccess={openModalSuccess}
        setOpenModalSuccess={setOpenModalSuccess}
        storyResponse={story}
      />
    </>
  );
}

export default ModalCollectStory;
