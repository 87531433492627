import clsx from 'clsx';
import React from 'react';

function Metrics({
  className,
  metrics,
  direction = 'horizontal',
  align,
  size = 'base',
}) {
  return (
    <div
      className={clsx('flex w-full', className, {
        'lg:justify-around lg:mr-2 items-center': direction === 'horizontal',
        'flex-col space-y-2': direction === 'vertical',
      })}
    >
      {metrics.map((metric) => (
        <div
          className={clsx('font-bold', {
            'px-2 lg:border-r md:px-6 last:pr-2 last:border-none border-gray-200 dark:border-dark-700 text-center':
              direction === 'horizontal',
            'text-sm sm:text-base xl:text-lg': size === 'base',
            'text-xl': size === 'large',
            'text-left': align === 'left',
          })}
          key={metric.name}
        >
          <span className="dark:text-white">{metric.value} </span>
          <span className="text-xs sm:text-base xl:text-lg text-gray-400">
            {metric.name}
          </span>
        </div>
      ))}
    </div>
  );
}

export default Metrics;
