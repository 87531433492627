import React from 'react';
import { getIpfsUrl } from '../../services/ipfsService';
import Avatar from '../Avatar';
import Link from '../Link';

function GridItemCollection({ data }) {
  return (
    <Link
      className="animate-fade-in item11"
      key={JSON.stringify(data)}
      // eslint-disable-next-line no-underscore-dangle
      to={`/collection/${data._id}`}
    >
      <section className="relative rounded-lg overflow-hidden">
        <div className="md:rounded-xl overflow-hidde flex aspect-[5/2.9]">
          {data.cover ? (
            <img
              className="relative w-full min-h-[250px] object-cover"
              src={getIpfsUrl(data.cover)}
              alt={data.name}
            />
          ) : (
            <div className="relative w-full min-h-[250px] object-cover" />
          )}
        </div>

        <div className="bg-gradient-to-t from-black to-transparent h-48 w-full absolute bottom-0 z-10" />

        <div className="absolute bottom-2 w-full pl-4 pr-12 pb-6 z-10 space-y-1">
          <h5 className="text-xs font-bold text-white uppercase text-left opacity-60">
            Collection
          </h5>
          <h2 className="text-lg text-white font-semibold text-left">
            {data.name}
          </h2>
          <div className="flex space-x-2">
            {data.tags.map((tag) => (
              <h2 className="text-xs leading-3 text-gray-50 text-left">
                #{tag}
              </h2>
            ))}
          </div>
        </div>
      </section>

      <div className="-mt-7 mr-8 z-10 relative flex justify-end">
        {data.logo ? (
          <Avatar img={getIpfsUrl(data.logo)} size="xl" />
        ) : (
          <Avatar name={data.author} size="xl" />
        )}
      </div>
    </Link>
  );
}

export default GridItemCollection;
