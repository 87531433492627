import React from 'react';

function IconTablerMedium({ className }) {
  return (
    <svg fill="none" viewBox="0 0 24 24" className={className}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.501 4.667h-11c-1.012 0-1.833.82-1.833 1.833v11c0 1.013.82 1.834 1.833 1.834h11c1.013 0 1.834-.821 1.834-1.834v-11c0-1.012-.821-1.833-1.834-1.833z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.332 9.25h.917l2.75 2.75 2.75-2.75h.916M8.332 14.75h1.833M13.832 14.75h1.833M9.25 9.25v5.5M14.75 9.25v5.5"
      />
    </svg>
  );
}

export default IconTablerMedium;
