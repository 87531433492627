import * as React from 'react';
import clsx from 'clsx';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

/* eslint-disable */
function Label({ className, ...labelProps }) {
  return (
    <label
      {...labelProps}
      className={clsx('block text-sm font-medium text-gray-700', className)}
    />
  );
}

const Input = React.forwardRef(function Input(props, ref) {
  const className = clsx(
    'shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md mt-2',
    props.className,
  );

  if (props.type === 'textarea') {
    return <textarea {...props} className={className} />;
  }

  return <input {...props} className={className} ref={ref} />;
});

function InputError({ children, id }) {
  if (!children) {
    return null;
  }

  return (
    <p role="alert" id={id} className="mt-2 text-sm text-red-600">
      {children}
    </p>
  );
}

const Field = React.forwardRef(function Field(
  {
    defaultValue,
    error = false,
    errorText,
    name,
    label,
    className,
    description,
    id,
    ...props
  },
  ref,
) {
  const prefix = 1;
  const inputId = id ?? `${prefix}-${name}`;
  const errorId = `${inputId}-error`;
  const descriptionId = `${inputId}-description`;

  return (
    <div className={clsx('mt-3', className)}>
      <div className="flex items-baseline justify-between ">
        <Label htmlFor={inputId}>{label}</Label>
      </div>
      <div className="relative">
        <Input
          className={clsx('', {
            'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500':
              error,
            'focus:ring-primary-500 focus:border-primary-500': !error,
          })}
          ref={ref}
          {...props}
          name={name}
          id={inputId}
          autoComplete={name}
          required
          defaultValue={defaultValue}
          aria-describedby={
            errorText ? errorId : description ? descriptionId : undefined
          }
        />
        {error ? (
          <div
            className={clsx(
              'absolute inset-y-0  right-0 pr-8 flex items-center pointer-events-none',
              {
                '-mt-7': errorText,
              },
            )}
          >
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
        {error ? (
          <InputError id={errorId}>{errorText}</InputError>
        ) : description ? (
          <div id={descriptionId} className="text-primary text-lg">
            {description}
          </div>
        ) : null}
      </div>
    </div>
  );
});

function ButtonGroup({ children }) {
  return (
    <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
      {children}
    </div>
  );
}

function ErrorPanel({ children, id }) {
  return (
    <div role="alert" className="relative mt-8 px-11 py-8" id={id}>
      <div className="absolute inset-0 bg-red-500 rounded-lg opacity-25" />
      <div className="text-primary-500 relative text-lg font-medium">
        {children}
      </div>
    </div>
  );
}

export { Label, Input, InputError, Field, ButtonGroup, ErrorPanel };
