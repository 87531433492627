import { useState } from 'react';
import Input, { Checkbox } from '../Forms/Forms';

function DestinationData({ setData, setStep, availableCopies }) {
  const [quantity, setQuantity] = useState('');
  const [wallet, setWallet] = useState('');
  const [check, setCheck] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [walletError, setWalletError] = useState(false);

  const confirmData = () => {
    if (!quantity) {
      return setQuantityError(true);
    }
    if (quantity > availableCopies || quantity < 0) {
      return setQuantityError(true);
    }
    if (!wallet) {
      return setWalletError(true);
    }
    if (!wallet.startsWith('0x') || wallet.length !== 42) {
      return setWalletError(true);
    }

    setData({
      quantity,
      destinationWallet: wallet,
      confirmationCheckBox: check,
    });

    return setStep(1);
  };

  return (
    <>
      <h3 className="text-lg font-semibold leading-normal text-center text-gray-900 dark:text-dark-100 mb-2">
        Transfer NFT
      </h3>

      <div className="space-y-6 mt-8">
        {/* Collection name */}
        <Input
          type="number"
          label="Quantity"
          helpTextTop={`${availableCopies} available`}
          placeholder="0"
          value={quantity}
          min={0}
          onChange={(e) => {
            setQuantity(e.target.value);
            if (quantityError) {
              setQuantityError(false);
            }
          }}
          onWheel={(event) => {
            event.target.blur();
          }}
          error={quantityError}
          errorMessage="You have to select a valid quantity."
        />
        <Input
          label="Destination wallet address"
          placeholder="0x0000000000000000000000000000000000000000"
          value={wallet}
          onChange={(e) => {
            setWallet(e.target.value);
            if (walletError) {
              setWalletError(false);
            }
          }}
          error={walletError}
          errorMessage="You have to select a valid wallet address."
        />
        <Checkbox
          label="I understand that after sending the NFT I won't have access to it anymore"
          value={check}
          onChange={(e) => {
            setCheck(e.target.checked);
          }}
        />
        <div className="flex flex-col space-y-4 mt-6 justify-center">
          {/* Create collection */}
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-lg text-black bg-primary-500 hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 justify-center disabled:opacity-75"
            onClick={confirmData}
            disabled={!check}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default DestinationData;
