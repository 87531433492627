import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DateTime, Interval } from 'luxon';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import ReactGA from 'react-ga4';
import { RWebShare } from 'react-web-share';
import { ShareIcon } from '@heroicons/react/24/outline';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import ModalSellStory from '../../views/Story/ModalSellStory';
import { Button, ButtonLink } from '../Button/Button';
import ButtonCollect from '../ButtonCollect';
import PolygonIcon from '../Icons/PolygonIcon';
import PriceConversion from '../PriceConversion';
import PriceFormatted from '../PriceFormatted';
import LoginContext from '../../context/LoginContext';
import ModalCollectStory from '../../views/Story/ModalCollectStory';
import Dropdown from '../Dropdown';
import { STORY_STATUS_MINTED, TYPE_STORY } from '../../utils/constants';
import { pathToPageName } from '../../utils/utils';
import ModalSendStory from '../ModalSendStory';
import ModalDelete from '../Header/ModalDelete';
import Heading from '../Typography';

export default function StoryActions({
  storyInfo,
  marketInfo,
  isOwner,
  copiesOwned,
  isReading,
  onClickRead,
  dropdownActions,
  setDropdownActions,
  reload,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { showSpinner, hideSpinner } = useSpinner();
  const { address } = useContext(LoginContext);
  const [isForSale, setIsForSale] = useState(false);
  const [authorListing, setAuthorListing] = useState();
  const [otherListings, setOtherListings] = useState([]);
  const [userActiveOrder, setUserActiveOrder] = useState();
  const [copiesAvailableToTransfer, setCopiesAvailableToTransfer] = useState(0);
  const [openCollectStoryModal, setOpenCollectStoryModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [collectButtonInfo, setCollectButtonInfo] = useState();

  const getExpirationDisplay = (epochSeconds) => {
    const expirationTime = DateTime.fromSeconds(epochSeconds);
    const currentTime = DateTime.now();

    if (expirationTime > currentTime.plus({ years: 1000 })) {
      return 'indefinitely';
    }

    const interval = Interval.fromDateTimes(currentTime, expirationTime);
    const duration = interval.toDuration('milliseconds', {
      conversionAccuracy: 'longterm',
    });

    if (expirationTime > currentTime.plus({ months: 1 })) {
      return `ends in ${duration.toFormat("M'm and 'd'd")}`;
    }
    if (expirationTime > currentTime.plus({ days: 1 })) {
      return `ends in ${duration.toFormat("d'd and 'h'h")}`;
    }
    if (expirationTime > currentTime.plus({ hours: 1 })) {
      return `ends in ${duration.toFormat("h'h and 'm'min")}`;
    }
    return `ends in ${duration.toFormat("m'min")}`;
  };

  const cancelListing = async (orderHash) => {
    try {
      showSpinner();
      await apiService.put(`/market/${orderHash}?status=CANCELLED`);
    } catch (err) {
      console.error(err);
      toast.error('Error cancelling order');
    } finally {
      hideSpinner();
    }
    reload();
  };

  const openCollectModal = () => {
    if (!address) {
      return navigate('/login');
    }
    const event = {
      category: 'collect',
      action: 'click_collect',
      label: pathToPageName(pathname, storyInfo.name),
    };
    ReactGA.event(event);
    setOpenCollectStoryModal(true);
    return true;
  };

  const checkAvailableDropdownActions = async () => {
    const dropData = [];

    if (isOwner) {
      dropData.push({
        name: 'Transfer NFT',
        callback: () => setOpenTransferModal(true),
      });
    }

    if (storyInfo.createdBy === address) {
      dropData.push({
        name: 'Delete',
        callback: () => setOpenDeleteModal(true),
      });
    }

    if (isForSale) {
      dropData.push({
        name: 'Collect',
        callback: () => openCollectModal(),
      });
    }

    setDropdownActions(dropData);
  };

  useEffect(() => {
    if (authorListing || otherListings.length) {
      setIsForSale(true);
    }
  }, [authorListing, otherListings]);

  useEffect(() => {
    if (storyInfo && marketInfo.length) {
      setAuthorListing(
        marketInfo.find(
          (order) =>
            order.createdBy === storyInfo.createdBy &&
            address !== storyInfo.createdBy,
        ),
      );
      setOtherListings(
        marketInfo.filter(
          (order) =>
            order.createdBy !== storyInfo.createdBy &&
            address !== order.createdBy,
        ),
      );
      setUserActiveOrder(
        marketInfo.find((order) => order.createdBy === address),
      );

      const collectInfo = { copies: 0, prices: [] };
      marketInfo.forEach((order) => {
        collectInfo.copies += +order.quantity;
        collectInfo.prices.push(order.price);
      });
      collectInfo.prices.sort();
      setCollectButtonInfo(collectInfo);

      const copiesNotOnSale = marketInfo
        ? copiesOwned -
          marketInfo.reduce((accumulator, order) => {
            if (order.makerAddress === address) {
              return accumulator + +order.quantity;
            }
            return accumulator;
          }, 0)
        : copiesOwned;
      setCopiesAvailableToTransfer(copiesNotOnSale);
    } else {
      setIsForSale(false);
      setAuthorListing(undefined);
      setOtherListings([]);
      setUserActiveOrder(undefined);
      setCollectButtonInfo(undefined);
      setCopiesAvailableToTransfer(copiesOwned);
    }

    if (address && storyInfo) {
      checkAvailableDropdownActions();
    }
  }, [
    storyInfo,
    marketInfo,
    isOwner,
    address,
    copiesAvailableToTransfer,
    copiesOwned,
  ]);

  return (
    <>
      <div className="text-center mt-4 mx-auto">
        {/* Owned, and listed for sale by the owner/creator */}
        {isOwner ? (
          <div className="bg-black z-10 relative rounded-2xl p-5 items-center text-left">
            <div className="flex flex-col xs:flex-row space-y-4 xs:space-y-0 items-center text-center xs:text-left justify-between">
              <div className="text-white">
                <h4 className="font-bold text-lg text-holo">Collected</h4>
                <span className="text-sm text-gray-400">
                  You own {copiesOwned} cop
                  {copiesOwned === 1 ? 'y' : 'ies'} of this {storyInfo.type}
                </span>
              </div>
              <div className="flex flex-col xs:flex-row space-y-2 xs:space-y-0 w-full xs:w-auto xs:space-x-2">
                {!isReading && (
                  <ButtonLink
                    variant="primary"
                    size="md"
                    onClick={onClickRead}
                    width="full"
                  >
                    Read
                  </ButtonLink>
                )}
                {!userActiveOrder && (
                  <ModalSellStory
                    tokenId={storyInfo.tokenId}
                    available={copiesOwned}
                    callback={reload}
                    story={storyInfo}
                  />
                )}
                {dropdownActions.length && (
                  <div className="hidden lg:block">
                    <Dropdown
                      variantButton="link-white"
                      items={dropdownActions}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* User active listing - owner is selling his item */}
            {userActiveOrder && (
              <div className="  pb-3 pt-12 xs:pb-5 -mt-6">
                <div className="text-left">
                  <div className="flex flex-col lg:flex-row sm:justify-between sm:items-center sm:space-x-4">
                    <div className="flex space-x-4 border border-gray-200 px-4 py-2 justify-between items-center rounded-md lg:w-80">
                      <div className="flex items-center w-full">
                        <div className="min-w-[36px]">
                          {/* Polygon Icon */}
                          <PolygonIcon className="h-6 w-6" />
                        </div>
                        <div className="grow">
                          <p className="text-xs xs:text-sm text-white">
                            {userActiveOrder.quantity} of {copiesOwned} uni. for
                            sale
                          </p>
                          <div className="flex flex-col space-y-1 items-start justify-start">
                            <p className="text-xs text-gray-400">on Polygon</p>
                            <p className="text-xs text-gray-400">
                              Listing{' '}
                              {getExpirationDisplay(
                                +userActiveOrder.expirationTimeSeconds,
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="text-right min-w-fit">
                        <p className="text-white font-semibold text-sm xs:text-lg -mb-1">
                          <PriceConversion price={userActiveOrder.price} />
                        </p>
                        <span className="text-gray-300 text-[10px] xs:text-xs">
                          <PriceFormatted price={userActiveOrder.price} />
                        </span>
                      </div>
                    </div>
                    {/* Cancel Button */}
                    <Button
                      className="lg:mt-0 mt-5"
                      width="full"
                      variant="cancel"
                      onClick={() => cancelListing(userActiveOrder.orderHash)}
                    >
                      Cancel listing
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {/* Not owned, gated and not for sale */}
            {storyInfo.isPrivate && !isForSale ? (
              <div className="bg-gray-50 text-center p-5 rounded-2xl text-black font-semibold mt-8 dark:bg-dark-800 dark:text-white">
                This {storyInfo.type} is not for sale.
              </div>
            ) : (
              // Not owned, gated and for sale
              <div className="rounded-2xl items-stretch sm:text-left flex flex-col sm:flex-row sm:space-x-2 pt-4 px-0 xs:p-5 bg-gray-50 dark:bg-dark-700">
                <div className="w-full flex self-stretch">
                  {!isReading && storyInfo.type === TYPE_STORY ? (
                    <ButtonLink
                      variant="dark"
                      width="full"
                      fullHeight
                      onClick={onClickRead}
                      className="w-full min-h-[66px] mb-2 sm:mb-0"
                    >
                      {storyInfo.isPrivate ? 'Preview' : 'Read'}
                    </ButtonLink>
                  ) : (
                    <div
                      className={clsx(
                        'flex flex-col self-center w-full py-4 sm:py-0 sm:pr-4',
                        {
                          'w-auto': !isReading && storyInfo.type === TYPE_STORY,
                        },
                      )}
                    >
                      <div>
                        {storyInfo.isPrivate ? (
                          <>
                            <h4 className="font-bold text-base dark:text-white">
                              Unlock the full {storyInfo.type}
                            </h4>
                            <p className="text-sm text-gray-600 dark:text-dark-200">
                              Collect this {storyInfo.type} to unlock the full
                              content.
                            </p>
                          </>
                        ) : (
                          <div>
                            <Heading size="5">Support the author</Heading>
                            <p className="text-sm text-gray-600 dark:text-dark-200 mt-2">
                              {isForSale
                                ? `This ${storyInfo.type} is free but you can support the author by collecting it.`
                                : `This ${storyInfo.type} is free but you can support the author by sharing it.`}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {/* Is for sale */}
                {isForSale && (
                  <ButtonCollect
                    info={collectButtonInfo}
                    onClick={openCollectModal}
                  />
                )}
                {isReading && !storyInfo.isPrivate && !isForSale && (
                  <RWebShare
                    data={{
                      text: `Check out ${storyInfo.name} profile on Readl Stories`,
                      url: `${window.location.origin}/${address}`,
                      title: 'Share this story',
                    }}
                    onClick={() => {
                      ReactGA.event({
                        category: 'share',
                        action: 'click_share_top',
                        label: pathToPageName(pathname, storyInfo.name),
                      });
                    }}
                  >
                    <div className="rounded-full overflow-hidden transition-all flex items-center justify-center dark:text-white">
                      <ShareIcon className="w-10 h-10 p-2 cursor-pointer" />
                    </div>
                  </RWebShare>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <ModalCollectStory
        story={storyInfo}
        open={openCollectStoryModal}
        setOpen={setOpenCollectStoryModal}
        callback={reload}
        authorListing={authorListing}
        otherListings={otherListings}
      />
      <ModalSendStory
        open={openTransferModal}
        setOpen={setOpenTransferModal}
        availableCopies={copiesAvailableToTransfer}
        story={storyInfo}
        callback={reload}
      />
      <ModalDelete
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        tokenId={storyInfo.tokenId}
        isMinted={storyInfo.status === STORY_STATUS_MINTED}
        type={storyInfo.type}
        storyName={storyInfo.name}
      />
    </>
  );
}
