import React from 'react';

function MenuReaderSettings({ color }) {
  return (
    <svg width="24" height="24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M21.167 8.24v.94a4.536 4.536 0 0 0-2.75-.94 4.583 4.583 0 0 0 0 9.166 4.535 4.535 0 0 0 2.75-.94v.94H23V8.24h-1.833Zm-2.75 7.333a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5ZM12 17.406h2.05L7.525 4.356 1 17.407h2.05l1.833-3.666h5.284L12 17.406Zm-6.2-5.5 1.725-3.45 1.725 3.45H5.8Z" />
    </svg>
  );
}
export default MenuReaderSettings;
