import { useContext, useEffect } from 'react';
import LoginContext from '../../context/LoginContext';

function OAuthCallback() {
  const { magic, oAuthCallbackFlow } = useContext(LoginContext);

  useEffect(() => {
    if (magic) {
      oAuthCallbackFlow();
    }
  }, [magic]);

  return null;
}

export default OAuthCallback;
