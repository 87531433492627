/* eslint-disable prettier/prettier */
export const containerMotion = {
    initial: { opacity: 0, transition: { duration: 1 } },
    visible: {
        opacity: 1,
        transition: { duration: 0.5, staggerChildren: 0.3 },
    },
};

export const childVariants = {
    initial: { opacity: 0, y: 25 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.5 } },
};

export const childVariantsAlways = {
    initial: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

export const containerUp = {
    initial: { opacity: 0, y: 25, transition: { duration: 1 } },
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 1, staggerChildren: 0.3 },
    },
}

export const containerUpY = {
    initial: { opacity: 0, y: 600, transition: { duration: 1 } },
    visible: {
        opacity: 1,
        y: 256,
        transition: { duration: 0.5, staggerChildren: 0.3 },
    },
}