import './GatedDelimiter.css';

/**
 * @typedef {Object} DelimiterData
 * @description Tool's input and output data format
 */
class GatedDelimiter {
  /**
   * Notify core that read-only mode is supported
   * @return {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow Tool to have no content
   * @return {boolean}
   */
  static get contentless() {
    return true;
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {{data: DelimiterData, config: object, api: object}}
   *   data — previously saved data
   *   config - user config for Tool
   *   api - Editor.js API
   */
  //  eslint-disable-next-line
  constructor({ data, config, api }) {
    this.api = api;
    //  eslint-disable-next-line
    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-submarine-delimiter',
    };
    //  eslint-disable-next-line
    this._data = {};
    //  eslint-disable-next-line
    this._element = this.drawView();
  }

  /**
   * Create Tool's view
   * @return {HTMLElement}
   * @private
   */
  drawView() {
    const div = document.createElement('DIV');

    //  eslint-disable-next-line
    div.innerHTML = `<div class="${this._CSS.wrapper} ${this._CSS.block}">
      <div class="inline-flex space-x-1 items-center justify-center text-center w-full cursor-default">
        <div class="w-1/4 h-0.5 border border-solid border-gray-200 dark:border-dark-700"/>
        </div>
        <div class="flex space-x-2 items-center justify-center w-2/4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" class="w-5 h-5 dark:!fill-dark-200">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
          </svg>
          <div class="flex space-x-1 items-center justify-center">
            <p class="text-sm leading-tight dark:text-dark-200">Gated content starts here</p>
          </div>
          <div class="tooltip">
              <svg xmlns="http://www.w3.org/2000/svg" class="tooltip" viewBox="0 0 24 24" stroke-width="1.5" class="w-5 h-5 dark:!fill-dark-200">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
              </svg>
              <span class="tooltiptext text-xs">
                Content below is only accessible to owners
              </span>
            </div>
        </div>
        <div class="w-1/4 h-0.5 border border-solid border-gray-200 dark:border-dark-700"/>
      </div>
    </div>`;

    return div;
  }

  /**
   * Return Tool's view
   * @returns {HTMLDivElement}
   * @public
   */
  render() {
    //  eslint-disable-next-line
    return this._element;
  }

  /**
   * Extract Tool's data from the view
   * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
   * @returns {DelimiterData} - saved data
   * @public
   */
  //  eslint-disable-next-line
  save() {
    return {};
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @return {{icon: string, title: string}}
   */

  static get toolbox() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" class="ce-submarine-delimiter-icon" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="black" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" /></svg>`,
      title: 'Gated Content',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  rendered() {
    const toolEl = document.querySelector(
      `.ce-toolbox .ce-popover__item[data-item-name="gatedContentDelimiter"]`,
    );
    toolEl.style.cursor = 'default';
    toolEl.style.color = 'grey';
    toolEl.style['pointer-events'] = 'none';
  }

  // eslint-disable-next-line class-methods-use-this
  removed() {
    const toolEl = document.querySelector(
      `.ce-toolbox .ce-popover__item[data-item-name="gatedContentDelimiter"]`,
    );
    toolEl.style.cursor = 'pointer';
    toolEl.style.color = 'black';
    toolEl.style['pointer-events'] = 'auto';
  }
}

export default GatedDelimiter;
