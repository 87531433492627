import PolygonIcon from '../Icons/PolygonIcon';

function CreatedOn() {
  return (
    <p className="text-xs leading-none text-gray-500 mt-1 flex items-center">
      Created on <PolygonIcon className="w-4 h-4 ml-1" />
      <span className="text-xs leading-none text-black dark:text-dark-300 ml-1">
        Polygon
      </span>
    </p>
  );
}

export default CreatedOn;
