import React from 'react';

function PauseIcon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM7.875 9.25C7.875 8.49061 8.49061 7.875 9.25 7.875C10.0094 7.875 10.625 8.49061 10.625 9.25V14.75C10.625 15.5094 10.0094 16.125 9.25 16.125C8.49061 16.125 7.875 15.5094 7.875 14.75V9.25ZM14.75 7.875C13.9906 7.875 13.375 8.49061 13.375 9.25V14.75C13.375 15.5094 13.9906 16.125 14.75 16.125C15.5094 16.125 16.125 15.5094 16.125 14.75V9.25C16.125 8.49061 15.5094 7.875 14.75 7.875Z"
        fill="white"
      />
      <path
        d="M7.875 9.25C7.875 8.49061 8.49061 7.875 9.25 7.875C10.0094 7.875 10.625 8.49061 10.625 9.25V14.75C10.625 15.5094 10.0094 16.125 9.25 16.125C8.49061 16.125 7.875 15.5094 7.875 14.75V9.25Z"
        fill="black"
      />
      <path
        d="M14.75 7.875C13.9906 7.875 13.375 8.49061 13.375 9.25V14.75C13.375 15.5094 13.9906 16.125 14.75 16.125C15.5094 16.125 16.125 15.5094 16.125 14.75V9.25C16.125 8.49061 15.5094 7.875 14.75 7.875Z"
        fill="black"
      />
    </svg>
  );
}

export default PauseIcon;
