import { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga4';
import Bookshelf from './views/Bookshelf';
import Collection from './views/Collection';
import CreateStory from './views/CreateStory';
import EditCollection from './components/EditCollection';
import Landing from './views/Landing';
import Bookstore from './views/Bookstore';
import Login from './components/Login';
import OAuthCallback from './components/OAuthCallback';
import OnBoarding from './components/OnBoarding';
import Story from './views/Story';
import { useSpinner } from './context/SpinnerContext';
import LoadingTea from './utils/LoadingTea/LoadingTea';
import Header from './components/Header/Header';
import Spacer from './components/Spacer';
import EditProfile from './components/EditProfile';
import Tags from './views/Tags';
import ForCreators from './views/ForAuthors';
import AboutUs from './views/AboutUs';
import PrivacyPolicy from './views/PrivacyPolicy';
import TermsConditions from './views/TermsConditions';
import Maintenance from './views/Maintenance';
import { useConvert } from './context/ConvertContext';
import CollectionFlowerGirls from './views/CollectionFlowerGirls';
import ModalMainGoal from './components/ModalMainGoal';
import LoginContext from './context/LoginContext';
import Discover from './views/Discover';

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

function App() {
  const { isSpinnerShown, spinnerMessage } = useSpinner();
  const { userMetadata, isUserLoggedIn } = useContext(LoginContext);
  const pathnamesWithoutHeader = [
    '/login',
    '/onboarding',
    '/create',
    '/edit-collection',
    '/edit-profile',
  ];

  const location = useLocation();
  const { isMaintenance } = useConvert();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKINGID, {
      gaOptions: { siteSpeedSampleRate: 10 },
    });
    ReactGA.set({
      anonymizeIp: true,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
    });
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, [location]);

  return isMaintenance ? (
    <Maintenance />
  ) : (
    <>
      <Toaster containerStyle={{ zIndex: 99999999 }} />
      <ScrollToTop />
      {isUserLoggedIn && !userMetadata.goal && <ModalMainGoal />}
      {isSpinnerShown && (
        <LoadingTea>
          <div className="mt-2 whitespace-pre-wrap">{spinnerMessage}</div>
        </LoadingTea>
      )}
      {!pathnamesWithoutHeader.filter((pathName) =>
        location.pathname.startsWith(pathName),
      ).length && (
        <>
          <Header />
          <Spacer size="xs" />
        </>
      )}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/tags" element={<Tags />} />
        <Route path="/bookstore" element={<Bookstore />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/collection/:collectionId" element={<Collection />} />
        <Route
          path="/flowergirls/flower-girls-stories"
          element={<CollectionFlowerGirls />}
        />
        <Route path="/story/:tokenId" exact element={<Story />} />
        <Route path="/book/:tokenId" exact element={<Story />} />
        <Route path="/create/:type" element={<CreateStory />} />
        <Route
          path="/edit-collection/:collectionId"
          element={<EditCollection />}
        />
        <Route path="/edit-profile/:address" element={<EditProfile />} />
        <Route path="/:address" element={<Bookshelf />} />
        <Route path="/login" element={<Login />} />
        <Route path="/oauth-callback" element={<OAuthCallback />} />
        <Route path="/onboarding" element={<OnBoarding />} />
        <Route path="/authors" element={<ForCreators />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
      </Routes>
    </>
  );
}

export default App;
