import { createContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Magic, RPCErrorCode } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ethers, utils } from 'ethers';
import ReactGA from 'react-ga4';
import {
  LOCAL_STORAGE_LOGIN_PREVIOUS_PAGE,
  LOCAL_STORAGE_LAST_PROVIDER,
  LOCAL_STORAGE_JWT,
  PROVIDER_MAGIC_EMAIL,
  PROVIDER_METAMASK,
  QUERY_PARAMETER_INVALID_TOKEN,
} from '../utils/constants';
import apiService from '../services/apiService';
import { useSpinner } from './SpinnerContext';

const LoginContext = createContext();

function LoginProvider({ children }) {
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinner();
  const [searchParams, setSearchParams] = useSearchParams();
  const [magic, setMagic] = useState();
  const [address, setAddress] = useState();
  const [userName, setUserName] = useState('');
  const [isLoadingUserLogin, setIsLoadingUserLogin] = useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userMetadata, setUserMetadata] = useState();
  const [ethersProvider, setEthersProvider] = useState();
  const [hasMetaMask, setHasMetamask] = useState(false);

  const switchEthereumChain = async (provider) => {
    const { chainId } = await provider.getNetwork();
    const expectedChainId = process.env.REACT_APP_NETWORK_CHAIN_ID;
    const expectedChainHex = utils.hexValue(Number(expectedChainId));
    if (chainId !== expectedChainId) {
      try {
        await provider.send('wallet_switchEthereumChain', [
          { chainId: expectedChainHex },
        ]);
      } catch (e) {
        if (e.code === 4902) {
          try {
            const network = {
              chainId: utils.hexValue(
                Number(process.env.REACT_APP_NETWORK_CHAIN_ID),
              ),
              chainName: process.env.REACT_APP_NETWORK_NAME,
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
              },
              rpcUrls: [process.env.REACT_APP_NETWORK_RPC_URL],
              blockExplorerUrls: [process.env.REACT_APP_NETWORK_BLOCK_EXPLORER],
            };
            await provider.send('wallet_addEthereumChain', [network]);
          } catch (addError) {
            console.error(addError);
          }
        }
      }
    }
  };

  const ethereumProvider = async () => {
    if (window.ethereum?.isMetaMask) {
      setHasMetamask(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setEthersProvider(provider);
      if (provider) {
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const userAddress = await signer.getAddress();
        await switchEthereumChain(provider);
        return userAddress;
      }
    }
    return undefined;
  };

  const extensionSetup = async () => {
    if (window.ethereum) {
      try {
        const userAddress = await ethereumProvider();
        return userAddress;
      } catch (err) {
        console.error(err);
        toast.error(err.message);
      }
    } else {
      setHasMetamask(false);
      return false;
    }
    return false;
  };

  const signMessage = async (userAddress, provider) => {
    try {
      const response = await apiService.get(`/auth/nonce/${userAddress}`);
      const { nonce } = response;
      const signer = provider.getSigner();
      return await signer.signMessage(
        `I am logging in to Readl and signing my one-time nonce: ${nonce}`,
      );
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const checkToken = async (userAddress, provider) => {
    try {
      const token = window.localStorage.getItem(LOCAL_STORAGE_JWT);
      await apiService.post('/auth/verify-jwt', { token });
    } catch (err) {
      console.error(err);
      // TODO: Control this specific error
      const signature = await signMessage(userAddress, provider);
      const response = await apiService.post('/auth/verify-signature', {
        address: userAddress,
        signature,
      });
      const { token } = response;
      window.localStorage.setItem(LOCAL_STORAGE_JWT, token);
    }
  };

  const getUser = async (userAddress) => apiService.get(`/user/${userAddress}`);

  const registerUser = async (registerData) =>
    apiService.post('/user', registerData);

  const logout = async (showMessage = false) => {
    try {
      window.localStorage.clear();
      await magic.user.logout();
      if (showMessage) {
        toast.error('Your user session has expired. Please, log in again');
      } else {
        window.location.assign('/');
      }
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    }
  };

  const checkLoginWithMagic = async () => {
    const isLoggedIn = await magic.user.isLoggedIn();
    if (isLoggedIn) {
      setHasMetamask(false);
      const user = await magic.user.getMetadata();
      const userAddress = user.publicAddress.toLowerCase();

      const userDB = await getUser(userAddress);

      if (!userDB.name) {
        return navigate('/onboarding', {
          state: { address: userAddress },
        });
      }

      try {
        await checkToken(
          userAddress,
          new ethers.providers.Web3Provider(magic.rpcProvider),
        );
      } catch (err) {
        console.error(err);
        return window.location.assign(`/?${QUERY_PARAMETER_INVALID_TOKEN}`);
      }

      setUserName(userDB.name);
      setUserMetadata(userDB);
      setAddress(userAddress);
      setIsUserLoggedIn(isLoggedIn);
    }
    return true;
  };

  const checkLoginWithMetamask = async () => {
    const userAddress = await extensionSetup();
    if (userAddress) {
      const userDB = await getUser(userAddress);
      setAddress(userAddress.toLowerCase());
      setHasMetamask(true);

      if (!userDB) {
        await registerUser({
          address: userAddress,
          provider: PROVIDER_METAMASK,
        });
      }

      if (!userDB.name) {
        return navigate('/onboarding', {
          state: { address: userAddress, isMetamask: true },
        });
      }

      try {
        await checkToken(
          userAddress.toLowerCase(),
          new ethers.providers.Web3Provider(magic.rpcProvider),
        );
      } catch (err) {
        console.error(err);
        return window.location.assign(`/?${QUERY_PARAMETER_INVALID_TOKEN}`);
      }

      setUserName(userDB.name);
      setUserMetadata(userDB);
      setAddress(userAddress.toLowerCase());
      setIsUserLoggedIn(true);
    }
    return true;
  };

  const checkLoginUser = async () => {
    try {
      const lastProvider = window.localStorage.getItem(
        LOCAL_STORAGE_LAST_PROVIDER,
      );
      if (lastProvider === PROVIDER_METAMASK) {
        await checkLoginWithMetamask();
      } else {
        await checkLoginWithMagic();
      }
    } catch (err) {
      console.error(err);
      setIsUserLoggedIn(false);
    } finally {
      setIsLoadingUserLogin(false);
    }
  };

  const loginEmail = async (email) => {
    try {
      showSpinner();
      setEthersProvider(new ethers.providers.Web3Provider(magic.rpcProvider));
      setHasMetamask(false);
      await magic.auth.loginWithMagicLink({
        email,
        redirectURI: new URL('/oauth-callback', window.location.origin).href,
      });
      window.location.href = '/';
    } catch (err) {
      console.error(err);
      switch (err.code) {
        case RPCErrorCode.MagicLinkFailedVerification:
          toast.error('Verification failed');
          break;
        case RPCErrorCode.MagicLinkExpired:
          toast.error('Verification link expired');
          break;
        case RPCErrorCode.MagicLinkRateLimited:
          ReactGA.send({
            hitType: 'exception',
            exDescription: `Hit Magic Verification rate limit`,
            exFatal: true,
          });
          toast.error('Verification rate limited');
          break;
        case RPCErrorCode.UserAlreadyLoggedIn:
          toast.error('User already logged in');
          break;
        default:
          toast.error('An error has occurred');
          ReactGA.send({
            hitType: 'exception',
            exDescription: `Error login with email: ${err.message} in ${err.stack}`,
          });
          break;
      }
    } finally {
      hideSpinner();
    }
  };

  const loginOAuth = async (provider) => {
    try {
      // Not hidding spinner to wait until the OAuth page is loaded
      showSpinner();
      setHasMetamask(false);
      setEthersProvider(new ethers.providers.Web3Provider(magic.rpcProvider));
      await magic.oauth.loginWithRedirect({
        provider,
        redirectURI: new URL('/oauth-callback', window.location.origin).href,
      });
    } catch (err) {
      console.error(err);
      ReactGA.send({
        hitType: 'exception',
        exDescription: `Error login with OAuth: ${err.message} in ${err.stack}`,
      });
      hideSpinner();
    }
  };

  const oAuthCallbackFlow = async () => {
    try {
      showSpinner();
      let provider = PROVIDER_MAGIC_EMAIL;
      const queryString = window.location.href.split('?')[1];
      const params = new URLSearchParams(queryString);

      if (params.has('provider')) {
        provider = params.get('provider');
      }

      let user = {};
      if (provider === PROVIDER_MAGIC_EMAIL) {
        await magic.auth.loginWithCredential();
        user = await magic.user.getMetadata();
      } else {
        const result = await magic.oauth.getRedirectResult();
        user = result.magic.userMetadata;
      }

      const { publicAddress } = user;

      const userDB = await getUser(publicAddress);

      window.localStorage.setItem(LOCAL_STORAGE_LAST_PROVIDER, provider);

      if (!userDB) {
        await registerUser({
          address: publicAddress,
          email: user.email,
          provider,
        });

        try {
          await checkToken(
            publicAddress,
            new ethers.providers.Web3Provider(magic.rpcProvider),
          );
        } catch (err) {
          console.error(err);
          return toast.error('error signing message');
        }

        return navigate('/onboarding', {
          state: { address: publicAddress },
        });
      }
      await checkLoginUser();

      ReactGA.event('login');
      navigate(
        window.localStorage.getItem(LOCAL_STORAGE_LOGIN_PREVIOUS_PAGE) || '/',
      );
      return true;
    } catch (err) {
      console.error(err);
      if (err.code === 'access_denied') {
        navigate(
          window.localStorage.getItem(LOCAL_STORAGE_LOGIN_PREVIOUS_PAGE) || '/',
        );
      } else {
        ReactGA.send({
          hitType: 'exception',
          exDescription: `Error while user register: ${err.message} in ${err.stack}`,
        });
        toast.error('An error has occurred');
      }
      return false;
    } finally {
      hideSpinner();
    }
  };

  const onboardingRegister = async (data) => {
    try {
      showSpinner();
      await registerUser(data);
      ReactGA.event('sign_up');
      await checkLoginUser();
      navigate(
        window.localStorage.getItem(LOCAL_STORAGE_LOGIN_PREVIOUS_PAGE) || '/',
      );
    } catch (err) {
      console.error(err);
      ReactGA.send({
        hitType: 'exception',
        exDescription: `Error onboarding new user: ${err.message} in ${err.stack}`,
      });
      toast.error('An error has occurred');
    } finally {
      hideSpinner();
    }
  };

  const loginWithMetamask = async () => {
    let error = false;
    let shouldNotReditect = false;
    try {
      const userAddress = await extensionSetup();

      if (!userAddress) {
        return toast.error('Install MetaMask To Use This Method');
      }

      setAddress(userAddress.toLowerCase());
      const user = await getUser(userAddress);
      setHasMetamask(true);

      if (!user) {
        await registerUser({
          address: userAddress,
          provider: PROVIDER_METAMASK,
        });
      }

      try {
        await checkToken(
          userAddress,
          new ethers.providers.Web3Provider(window.ethereum),
        );
      } catch (err) {
        console.error(err);
        error = true;
      }

      if (error) {
        return toast.error(
          'You should sign the message to login with MetaMask',
        );
      }
      window.localStorage.setItem(
        LOCAL_STORAGE_LAST_PROVIDER,
        PROVIDER_METAMASK,
      );
      shouldNotReditect = !user?.name;
      if (shouldNotReditect) {
        return navigate('/onboarding', {
          state: { address: userAddress, isMetamask: true },
        });
      }
      setUserName(user.name);
      setUserMetadata(user);
      setAddress(userAddress.toLowerCase());
      setIsUserLoggedIn(Boolean(userAddress));
    } catch (err) {
      console.error(err);
      setIsUserLoggedIn(false);
    } finally {
      setIsLoadingUserLogin(false);
      if (!error && !shouldNotReditect) {
        navigate(
          window.localStorage.getItem(LOCAL_STORAGE_LOGIN_PREVIOUS_PAGE) || '/',
        );
      }
    }
    return false;
  };

  const checkInvalidToken = () => {
    if (searchParams.has(QUERY_PARAMETER_INVALID_TOKEN)) {
      searchParams.delete(QUERY_PARAMETER_INVALID_TOKEN);
      setSearchParams(searchParams);

      logout(true);
    }
  };

  useEffect(() => {
    if (magic) {
      checkInvalidToken();
      checkLoginUser();
    }
  }, [magic]);

  useEffect(() => {
    const network = {
      rpcUrl: process.env.REACT_APP_NETWORK_RPC_URL,
      chainId: process.env.REACT_APP_NETWORK_CHAIN_ID,
    };
    const magicInstance = new Magic(process.env.REACT_APP_MAGIC_API_KEY, {
      network,
      extensions: [new OAuthExtension()],
    });
    setMagic(magicInstance);
    setEthersProvider(
      new ethers.providers.Web3Provider(magicInstance.rpcProvider),
    );
  }, []);

  useEffect(() => {
    // TODO: Review what to do if account changes
    const onAccountChanged = async () => {
      //  On change Address
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const userAddress = await provider.getSigner().getAddress();
      console.log('userAddress', userAddress);
      if (userAddress) {
        console.log(`Account changed: ${userAddress}`);
      }
    };
    const onChainChanged = async (chainId) => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      console.log('chainId', chainId);
      console.log('chainId type', typeof chainId);
      await switchEthereumChain(provider);
    };
    const onDisconnect = () => {
      console.log('disconnect');
    };
    if (hasMetaMask) {
      window.ethereum.on('accountsChanged', onAccountChanged);
      window.ethereum.on('chainChanged', onChainChanged);
      window.ethereum.on('disconnect', onDisconnect);
    }
    return () => {
      if (hasMetaMask) {
        window.ethereum.removeListener('accountsChanged', onAccountChanged);
        window.ethereum.removeListener('chainChanged', onChainChanged);
        window.ethereum.removeListener('disconnect', onDisconnect);
      }
    };
  }, [hasMetaMask]);

  return (
    <LoginContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        magic,
        ethersProvider,
        address,
        userName,
        isUserLoggedIn,
        isLoadingUserLogin,
        userMetadata,
        loginEmail,
        loginOAuth,
        loginWithMetamask,
        oAuthCallbackFlow,
        onboardingRegister,
        hasMetaMask,
        logout,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export { LoginProvider };
export default LoginContext;
