import { useEffect, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { PlusIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { utils } from 'ethers';
import { TrashIcon } from '@heroicons/react/24/outline';
import Toggle from '../Toggle';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import ModalNewCollection from '../ModalNewCollection';
import Input from '../Forms/Forms';
import { ButtonLink } from '../Button/Button';
import CoverMaker from '../CoverMaker';
import Tooltip from '../Tooltip/Tooltip';
import { pluralizeType } from '../../utils/utils';
import { ItemCover } from '../GridStories/GridItem';
import { TYPE_BOOK, TYPE_STORY } from '../../utils/constants';
import Heading from '../Typography';

function StoryData({
  storyHandle,
  storyData,
  address,
  openSidebar,
  error,
  setError,
  className,
}) {
  const { showSpinner, hideSpinner } = useSpinner();
  const [openModalNewCollection, setOpenModalNewCollection] = useState(false);
  const [collections, setCollections] = useState([]);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [imageFile, setImageFile] = useState(storyData.imageFile);
  const [sharedRoyalties, setSharedRoyalties] = useState(false);
  const [imageFileUrl, setImageFileUrl] = useState(
    storyData.imageFileUrl || '',
  );
  const [collectionId, setCollectionId] = useState(
    storyData.collectionId || '',
  );
  const [copies, setCopies] = useState(storyData.copies);
  const [royalties, setRoyalties] = useState(storyData.royalties);
  const [royaltiesDistribution, setRoyaltiesDistribution] = useState(
    storyData.royaltiesDistribution || [
      {
        address,
        percentage: 100,
      },
    ],
  );
  const [isSensitiveContent, setIsSensitiveContent] = useState(
    !!storyData.isSensitiveContent,
  );
  const uploadImageRef = useRef(null);
  const copiesRef = useRef(null);
  const collectionRef = useRef(null);
  const royaltiesRef = useRef(null);

  const getCollections = async () => {
    try {
      showSpinner('Loading collections...');
      const userCollections = await apiService(
        `/collection/created/${address}`,
      );
      if (userCollections.length > 0) {
        setCollections(
          // eslint-disable-next-line no-underscore-dangle
          userCollections.map((item) => ({ name: item.name, id: item._id })),
        );
      }
    } catch (err) {
      console.error(err);
      // TODO: Replace it with toast once its merged in develop
      toast.error('Error retrieving collections');
    } finally {
      hideSpinner();
    }
  };

  const cleanImage = () => {
    if (imageFileUrl) {
      URL.revokeObjectURL(imageFileUrl);
      setImageFileUrl('');
    }
    setUploadingImage(false);
    setImageFile(null);
  };

  const uploadImage = () => {
    setUploadingImage(true);
    const temp = uploadImageRef.current.files[0];
    setError({
      ...error,
      image: false,
    });

    setUploadingImage(false);
    setImageFile(temp);
    setImageFileUrl(URL.createObjectURL(temp));
  };

  const handleCollectionSelector = (e) => {
    if (e.target.value === '-1') {
      setOpenModalNewCollection(true);
    } else {
      setCollectionId(e.target.value);
    }
    if (error.collection) {
      setError({
        ...error,
        collection: false,
      });
    }
  };

  const addWallet = (e) => {
    e.preventDefault();
    setRoyaltiesDistribution([
      ...royaltiesDistribution,
      {
        address: '',
        percentage: 0,
      },
    ]);
  };

  const removeWallet = (index) => {
    const { percentage } = royaltiesDistribution[index];
    const filteredWallets = JSON.parse(
      JSON.stringify(royaltiesDistribution.filter((u, i) => i !== index)),
    );
    filteredWallets[0].percentage += percentage;
    setRoyaltiesDistribution(filteredWallets);
  };

  const editWallet = (e, index) => {
    const { value } = e.target;
    const wallets = JSON.parse(JSON.stringify(royaltiesDistribution));
    wallets[index].address = value;
    setRoyaltiesDistribution(wallets);
  };

  const editPercentage = (e, index) => {
    const reg = /^[0-9\b]+$/;
    const value = Number(e.target.value);
    if (e.target.value === '' || reg.test(e.target.value)) {
      const wallets = JSON.parse(JSON.stringify(royaltiesDistribution));
      wallets[0].percentage += wallets[index].percentage - value;
      if (wallets[0].percentage < 0) {
        return;
      }
      wallets[index].percentage = value;
      setRoyaltiesDistribution(wallets);
    } else e.target.value = value.substring(0, value.length - 1);
  };

  useEffect(() => {
    if (address) {
      getCollections(address);
      setRoyaltiesDistribution([
        {
          address,
          percentage: 100,
        },
      ]);
    }
  }, [address]);

  useEffect(() => {
    storyHandle({
      ...storyData,
      uploadingImage,
      imageFile,
      imageFileUrl,
      collectionId,
      copies,
      royalties,
      royaltiesDistribution,
      isSensitiveContent,
    });
  }, [
    uploadingImage,
    imageFile,
    imageFileUrl,
    collectionId,
    copies,
    royalties,
    royaltiesDistribution,
    isSensitiveContent,
  ]);

  useEffect(() => {
    const cleanDataUrlData = () => {
      if (imageFileUrl) {
        URL.revokeObjectURL(imageFileUrl);
      }
    };

    return cleanDataUrlData;
  }, []);

  useEffect(() => {
    if (error.collection) {
      collectionRef.current.focus();
    }
    if (error.copies) {
      copiesRef.current.focus();
    }
    if (error.royaltiesDistribution) {
      royaltiesRef.current.focus();
    }
    if (error.royaltiesPercentage) {
      royaltiesRef.current.focus();
    }
  }, [error]);

  useEffect(() => {
    if (storyData.imageFileUrl && storyData.imageFileUrl !== imageFileUrl) {
      setImageFileUrl(storyData.imageFileUrl);
      setImageFile(storyData.imageFile);
    }
  }, [storyData.imageFileUrl]);

  return (
    <>
      <div className={className}>
        {/* Story Card */}
        <div className="w-full transition-all duration-500 max-w-[300px] lg:max-w-[auto] mx-auto rel">
          <div className="justify-between items-center group-open:mb-4 group-open:mt-4 group-open:min-h-[36px] opacity-0 w-0 h-0 overflow-hidden group-open:overflow-visible group-open:w-full group-open:h-auto origin-left transition-[opacity] group-open:opacity-100 group-open:flex flex group-open:duration-300 group-open:delay-300">
            <Heading size="5">Cover</Heading>
            {/* Upload cover button */}
            <Tooltip tooltipText="Square or 4:6 (ex. 800px x 1200px)">
              {!uploadingImage && !imageFileUrl && (
                <label
                  htmlFor="cover-upload"
                  className="flex bg-gray-100 hover:bg-gray-200 font-semibold text-sm py-2 px-3 rounded-lg focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-transparent cursor-pointer"
                >
                  {/* Plus Icon */}
                  <span>Upload your cover</span>
                  <input
                    ref={uploadImageRef}
                    onInput={uploadImage}
                    id="cover-upload"
                    name="cover-upload"
                    type="file"
                    className="sr-only"
                    accept="image/png, image/jpeg, image/jpeg, image/png, image/gif"
                  />
                </label>
              )}
            </Tooltip>
          </div>
          {!uploadingImage && !imageFileUrl ? (
            <CoverMaker title={storyData.title} />
          ) : (
            <div>
              <section className="relative">
                {storyData.type === TYPE_BOOK && openSidebar ? (
                  <div className="book-container">
                    <div className="book">
                      <img alt={imageFileUrl} src={imageFileUrl} />
                    </div>
                  </div>
                ) : (
                  <ItemCover image={imageFileUrl} type={storyData.type} />
                )}

                {/* Overlay Elements */}
                <div className="z-20 absolute top-0 left-0 right-0 bottom-0 items-center w-full h-full justify-center opacity-0 transition-all group-open:block group-open:delay-300 group-open:opacity-100 group-open:duration-300">
                  {/* Close */}
                  {imageFileUrl && (
                    <button
                      type="button"
                      className="inline-flex items-center px-2 py-3 border border-transparent text-lg font-medium rounded-full text-black w-10 h-10 absolute top-3 left-3 bg-white hover:text-black focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-transparent z-20"
                      onClick={cleanImage}
                    >
                      <svg
                        className="h-7 w-7"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          d="M6 18L18 6M6 6L18 18"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  )}

                  <div className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center">
                    {uploadingImage && (
                      <div className="flex flex-col items-center px-2 py-3 mb-6 border border-transparent text-lg font-medium rounded-lg text-gray-500">
                        {/* Icon Spinner */}
                        <svg
                          role="status"
                          className="w-10 h-10 mb-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span>Uploading Cover</span>
                      </div>
                    )}
                  </div>
                  {storyData.type === TYPE_STORY && (
                    <div className="absolute left-0 right-0 bottom-0 min-h-[200px] flex flex-col justify-end">
                      <div className="z-20 relative px-6 pb-4">
                        <h2
                          className={clsx(
                            'my-4 border-none focus:ring-0 text-xl font-semibold bg-transparent text-white placeholder-white w-full break-words',
                            {
                              'opacity-75': !storyData.title,
                            },
                          )}
                        >
                          {storyData.title ? storyData.title : ''}
                        </h2>
                      </div>
                      <div className="bg-gradient-to-t from-black to-transparent h-full w-full absolute bottom-0 z-10" />
                    </div>
                  )}
                </div>
              </section>
              {error.image && (
                <p className="text-sm text-red-600">
                  You need to upload an image.
                </p>
              )}
            </div>
          )}
        </div>

        {/* General Info */}
        <div className="opacity-0 w-0 h-0 overflow-hidden group-open:overflow-visible group-open:w-full group-open:h-auto origin-left transition-[opacity] group-open:opacity-100 group-open:flex flex-col group-open:duration-300 group-open:delay-300 space-y-8">
          <div>
            {/* TODO: substitute for the Select component */}
            <div className="mt-4 relative">
              <label
                id="listbox-label"
                className="block text-sm font-medium text-gray-700 dark:text-dark-200"
              >
                {' '}
                Select a collection *
              </label>
              <select
                ref={collectionRef}
                id="collection"
                name="collection"
                className={
                  !error.collection
                    ? 'mt-1 bg-white dark:bg-dark-700 relative w-full border border-gray-300 dark:border-dark-600 dark:text-white rounded-md text-sm shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500'
                    : 'mt-1 bg-white dark:bg-dark-700 relative block w-full pl-3 pr-10 py-3 text-left cursor-default border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
                }
                value={collectionId}
                onChange={handleCollectionSelector}
              >
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <option value="" />
                {collections.map((collection) => (
                  <option
                    key={JSON.stringify(collection)}
                    value={collection.id}
                    className={
                      collection.id === collectionId
                        ? 'font-semibold block truncate'
                        : 'font-normal block truncate'
                    }
                  >
                    {collection.name}
                  </option>
                ))}
                <option value="-1" className="text-primary-600">
                  Add new collection
                </option>
              </select>
              {error.collection && (
                <div className="absolute inset-y-0 right-0 pr-8 pt-6 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {error.collection && (
              <p className="mt-2 text-sm text-red-600">
                You need to select a collection.
              </p>
            )}
          </div>

          {/* Minting Conditions */}

          <Input
            ref={copiesRef}
            value={copies}
            type="number"
            placeholder="0"
            label="How many copies?*"
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              setCopies(e.target.value);
              if (error.copies) {
                setError({
                  ...error,
                  copies: false,
                });
              }
            }}
            helpText={`Choose the amount of ${pluralizeType(
              storyData.type,
            )} that can be minted.`}
            error={error.copies}
            errorMessage="You need to select a number of copies."
          />

          <Input
            value={royalties}
            type="number"
            placeholder="0"
            label="Commission on resale"
            onWheel={(e) => e.target.blur()}
            onChange={(e) => setRoyalties(e.target.value)}
            helpText={`The percentage you get for each transaction when readers resell your ${storyData.type}.`}
          />

          {/* Royalties */}
          <Toggle
            label="Shared royalties"
            description="Share royalties with multiple wallets"
            enabled={sharedRoyalties}
            setEnabled={setSharedRoyalties}
          >
            <div className="space-y-2 mt-2">
              {royaltiesDistribution.map((wallet, index) =>
                index === 0 ? (
                  <fieldset className="flex -space-x-1" key={wallet}>
                    <Input
                      type="text"
                      name="first-name"
                      disabled
                      value={wallet.address}
                      placeholder="You"
                      className="rounded-tr-none rounded-br-none"
                    />
                    <Input
                      type="text"
                      name="first-name"
                      disabled
                      placeholder="100%"
                      width="w-[60px]"
                      value={`${wallet.percentage}%`}
                      className="rounded-tl-none rounded-bl-none"
                    />
                  </fieldset>
                ) : (
                  <div
                    className="flex -space-x-0 items-center"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`item-${index}`}
                  >
                    {/* TODO: change for the Button IconOnly component */}
                    <button
                      type="button"
                      className="inline-flex items-center px-2 pb-2 pt-3 border border-transparent text-lg font-medium rounded-lg text-gray-500 hover:text-black focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-transparent"
                      onClick={() => removeWallet(index)}
                    >
                      <TrashIcon className="w-4 h-4" />
                    </button>
                    <fieldset className="flex -space-x-1 w-full">
                      <Input
                        type="text"
                        ref={royaltiesRef}
                        name="wallet-address"
                        id="wallet-address"
                        full
                        placeholder="Wallet address"
                        value={wallet.address}
                        onChange={(e) => {
                          editWallet(e, index);
                          if (error.royaltiesDistribution) {
                            setError({
                              ...error,
                              royaltiesDistribution: false,
                            });
                          }
                        }}
                        error={
                          (error.royaltiesDistribution &&
                            !utils.isAddress(wallet.address)) ||
                          (error.royaltiesPercentage && wallet.percentage < 1)
                        }
                        errorMessage={
                          (error.royaltiesPercentage &&
                            'Percentage must be greater than 0.') ||
                          (error.royaltiesDistribution &&
                            'Invalid wallet address.')
                        }
                        className="rounded-tr-none rounded-br-none"
                      />
                      <Input
                        type="text"
                        name="percentage"
                        id="percentage"
                        placeholder="0%"
                        value={wallet.percentage}
                        onChange={(e) => {
                          editPercentage(e, index);
                          if (error.royaltiesPercentage) {
                            setError({
                              ...error,
                              royaltiesPercentage: false,
                            });
                          }
                        }}
                        onWheel={(e) => e.target.blur()}
                        width="w-[60px]"
                        className={clsx('rounded-tl-none rounded-bl-none', {
                          'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500':
                            error.royaltiesPercentage &&
                            wallet.percentage === 0,
                        })}
                        errorMessage="Percentage must be greater than 0."
                      />
                    </fieldset>
                  </div>
                ),
              )}
              <ButtonLink
                variant="link"
                size="xs"
                onClick={addWallet}
                flex
                iconLeft={<PlusIcon className="w-4 h-4" />}
                className="mt-2 block"
              >
                Add another Wallet
              </ButtonLink>
            </div>
          </Toggle>

          <Toggle
            label="Explicit & Sensitive Content"
            description="Setting your asset as explicit and sensitive content, like
            pornography and other not safe for work (NSFW) content, will
            protect users with safe search while browsing."
            enabled={isSensitiveContent}
            setEnabled={setIsSensitiveContent}
          />
        </div>
      </div>
      {/* Modal: Create Collection */}
      <ModalNewCollection
        open={openModalNewCollection}
        setOpen={setOpenModalNewCollection}
        getCollections={getCollections}
        setCollectionId={setCollectionId}
      />
    </>
  );
}

export default StoryData;
