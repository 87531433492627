import React, { useContext, useEffect, useState } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { motion, useReducedMotion } from 'framer-motion';
import toast from 'react-hot-toast';
import LoginContext from '../../context/LoginContext';
import {
  LOCAL_STORAGE_LOGIN_PREVIOUS_PAGE,
  PROVIDER_MAGIC_DISCORD,
  PROVIDER_MAGIC_EMAIL,
} from '../../utils/constants';
import ReadlWhiteLogo from '../../assets/images/ReadlWhiteLogo';
import ReadlLogo from '../../assets/images/ReadlLogo';
import ArrowIcon from '../../assets/icons/ArrowIcon';
import DiscordIcon from '../../assets/icons/DiscordIcon';
import MetamaskIcon from '../../assets/icons/MetamaskIcon';
import Heading from '../Typography';

function Login() {
  const shouldReduceMotion = useReducedMotion();
  const [email, setEmail] = useState('');
  const { loginEmail, loginOAuth, loginWithMetamask } =
    useContext(LoginContext);

  const childVariants = {
    initial: { opacity: 0, y: shouldReduceMotion ? 0 : 25 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  // TODO: Check if user introduced a valid email
  const login = async (provider) =>
    provider === PROVIDER_MAGIC_EMAIL
      ? loginEmail(email)
      : loginOAuth(provider);

  useEffect(() => {
    const previousUrl = document.referrer
      ? new URL(document.referrer).pathname
      : '/';
    window.localStorage.setItem(LOCAL_STORAGE_LOGIN_PREVIOUS_PAGE, previousUrl);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    login(PROVIDER_MAGIC_EMAIL);
  };

  const handleMetamaskLogin = async () => {
    if (!window.ethereum?.isMetaMask) {
      return toast.error('You need to install Metamask');
    }
    return loginWithMetamask();
  };

  return (
    <div className="relative bg-white dark:bg-dark-900 h-screen">
      <div className="min-h-full lg:flex h-full">
        <div className="hidden lg:block relative w-full max-w-[832px] flex-0">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://d394qrhzlf6jbr.cloudfront.net/collectibles/Pinocchio - Super Rare.jpg"
            alt="Collectible from the Genesis Collection"
          />
          <ReadlWhiteLogo className="z-10 w-20 absolute left-8 top-6" />
          <div className="lg:absolute inset-0 relative">
            <div className="relative h-full">
              <div className="absolute z-20 text-base leading-6 font-normal bottom-8 lg:bottom-16 left-8 text-white">
                Collectible by @keith
              </div>
              <a
                target="_blank"
                href="https://marketplace.readl.co/nft/collectible/edition/2"
                className="absolute z-20  text-base leading-6 font-normal bottom-8 lg:bottom-16 right-8 text-white"
                rel="noreferrer"
              >
                <ArrowTopRightOnSquareIcon className="w-6" />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-2xl flex  justify-center">
            <div className="text-base lg:max-w-md ">
              <motion.div
                initial="initial"
                animate="visible"
                variants={{
                  initial: { opacity: 0 },
                  visible: {
                    opacity: 1,
                    transition: { staggerChildren: 0.2 },
                  },
                }}
              >
                <ReadlLogo className="mx-auto h-12 w-auto lg:hidden block" />
                <motion.div variants={childVariants}>
                  <Heading>Login or sign up</Heading>
                </motion.div>

                <motion.p
                  variants={childVariants}
                  className="mt-8 text-base text-secondary-400 dark:text-dark-400 text-center lg:text-left "
                >
                  Connect with your wallet or create a non-custodial wallet with
                  your email.
                </motion.p>
              </motion.div>

              <div className="mt-6 relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-secondary-50 dark:border-dark-800" />
                </div>
              </div>
              <motion.div
                initial="initial"
                animate="visible"
                variants={{
                  initial: { opacity: 0 },
                  visible: {
                    opacity: 1,
                    transition: { staggerChildren: 0.2 },
                  },
                }}
                className="mt-12"
              >
                <motion.h4
                  variants={childVariants}
                  className="text-lg leading-8 font-semibold text-gray-900 dark:text-dark-100 text-center lg:text-left "
                >
                  Connect with your email or socials
                </motion.h4>
                <motion.p
                  variants={childVariants}
                  className="text-sm leading-5 font-normal text-secondary-400 dark:text-dark-400 lg:max-w-xs text-center lg:text-left "
                >
                  If it’s your first login we will create you a new
                  non-custodial wallet.
                </motion.p>

                <div className="mt-8 ">
                  <motion.form
                    onSubmit={handleLogin}
                    variants={childVariants}
                    className="flex"
                  >
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email-address"
                      type="email"
                      autoComplete="email"
                      required
                      className="w-full px-5 py-3 border dark:bg-dark-800 border-gray-300 dark:border-dark-600 shadow-sm placeholder-gray-400 dark:placeholder-dark-400 dark:text-white focus:ring-1 focus:ring-primary-500 focus:border-primary-500 max-w-lg rounded-full mx-auto"
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className=" rounded-full shadow mt-0 ml-3 flex-shrink-0">
                      <button
                        type="submit"
                        className="w-full flex items-center justify-center py-3 px-3 border border-transparent text-base font-medium rounded-full text-secondary-800 bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      >
                        <ArrowIcon direction="right" />
                      </button>
                    </div>
                  </motion.form>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    className="mt-6 grid grid-cols-1 gap-3"
                  >
                    <button
                      type="button"
                      onClick={() => login(PROVIDER_MAGIC_DISCORD)}
                      className="w-full inline-flex justify-center py-3 px-4 border border-secondary-50 rounded-full shadow-sm bg-white text-sm font-medium hover:bg-gray-50 dark:bg-dark-700 dark:border-dark-600 text-secondary-900 dark:text-white dark:hover:bg-dark-600"
                    >
                      <span className="sr-only">Connect with Discord</span>
                      <DiscordIcon className="w-5 h-5" />
                      <span className="ml-4 text-sm leading-5 font-medium">
                        Connect with Discord
                      </span>
                    </button>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    className="mt-6 grid grid-cols-1 gap-3"
                  >
                    <button
                      type="button"
                      onClick={handleMetamaskLogin}
                      className="w-full inline-flex justify-center py-3 px-4 border border-secondary-50 rounded-full shadow-sm bg-white text-sm font-medium hover:bg-gray-50 hover:bg-gray-50 dark:bg-dark-700 dark:border-dark-600 text-secondary-900 dark:text-white dark:hover:bg-dark-600"
                    >
                      <span className="sr-only">Connect with Metamask</span>
                      <MetamaskIcon className="w-5 h-5" />
                      <span className="ml-4 text-sm leading-5 font-medium">
                        Connect with Metamask
                      </span>
                    </button>
                  </motion.div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
