import { Tab } from '@headlessui/react';
import { motion } from 'framer-motion';
import Slider from '../Slider';
import GridItem from '../GridStories/GridItem';
import Container from '../Containers';
import Heading from '../Typography';
import TabsReadl from '../Tab/Tab';

const tabsStatus = [
  {
    id: 'Collected',
    title: 'Collected',
  },
  {
    id: 'Published',
    title: 'Published',
  },
];

export default function MostRecent({
  lastCollected,
  lastListed,
  containerMotion,
}) {
  return (
    <Container spacing="small">
      <div>
        <Heading
          size="5"
          className="uppercase text-gray-400 pb-3"
          divider="before"
          dividerColors="bg-gray-400"
        >
          Most Recent
        </Heading>

        <Tab.Group>
          <Tab.List className="mb-12" aria-label="Tabs">
            <TabsReadl
              tabs={tabsStatus}
              variant="modern"
              wrapperClasses="max-h-[44px] flex gap-8"
            />
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <motion.div
                className="bg-gray-100 dark:bg-dark-800 rounded-xl py-4"
                initial="initial"
                whileInView="visible"
                variants={containerMotion}
                viewport={{ once: true }}
              >
                <Slider
                  className="w-full"
                  classNameSlider="!px-8 !py-4 !pr-4"
                  slidesPerView={1.1}
                  navigation
                  slidesPerViewBreakpoints={{
                    xs: 1.4,
                    sm: 3.5,
                    md: 3.5,
                    lg: 3.5,
                    xl: 4.5,
                    '2xl': 4.5,
                  }}
                  items={lastCollected.map((story) => (
                    <GridItem data={story} />
                  ))}
                />
              </motion.div>
            </Tab.Panel>
            <Tab.Panel>
              <motion.div
                className="bg-gray-100 dark:bg-dark-800 rounded-xl py-4"
                initial="initial"
                whileInView="visible"
                variants={containerMotion}
                viewport={{ once: true }}
              >
                <Slider
                  className="w-full"
                  classNameSlider="!px-8 !py-4 !pr-4"
                  slidesPerView={1.1}
                  navigation
                  slidesPerViewBreakpoints={{
                    xs: 1.4,
                    sm: 3.5,
                    md: 3.5,
                    lg: 3.5,
                    xl: 4.5,
                    '2xl': 4.5,
                  }}
                  items={lastListed?.map((story) => (
                    <GridItem data={story} />
                  ))}
                />
              </motion.div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Container>
  );
}
