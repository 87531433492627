import React from 'react';

function IconTablerInstagram({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      className={className}
      stroke="currentColor"
      strokeWidth="2px"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <rect width="16" height="16" x="4" y="4" rx="4" />
      <circle cx="12" cy="12" r="3" />
      <path d="M16.5 7.5L16.5 7.501" />
    </svg>
  );
}

export default IconTablerInstagram;
