import React, { useState, useEffect } from 'react';
import { STORY_STATUS_MINTED } from '../../utils/constants';
import Modal from '../Modal';
import DestinationData from './DestinationData';
import SendConfirmation from './SendConfirmation';

function ModalSendStory({ open, setOpen, availableCopies, story, callback }) {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    quantity: 0,
    destinationWallet: '',
    confirmationCheckBox: false,
  });

  useEffect(() => {
    if (!open) {
      setStep(0);
    }
  }, [open]);

  return (
    <Modal open={open} setOpen={setOpen} allowClickToClose>
      {step === 0 && (
        <DestinationData
          setData={setData}
          setStep={setStep}
          availableCopies={availableCopies}
          storyId={story.tokenId}
          isStoryMinted={story.status === STORY_STATUS_MINTED}
          storyName={story.name}
        />
      )}
      {step === 1 && (
        <SendConfirmation
          data={data}
          setOpen={setOpen}
          storyId={story.tokenId}
          story={story}
          callback={callback}
        />
      )}
    </Modal>
  );
}

export default ModalSendStory;
