import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import ReactGA from 'react-ga4';
import Link from '../Link';
import { TYPE_BOOK, TYPE_STORY } from '../../utils/constants';
import Heading from '../Typography';
import StoryIcon from '../../assets/icons/StoryIcon';

const features = [
  {
    name: 'Story',
    description: 'Write and publish multimedia stories with the Readl editor.',
    icon: StoryIcon,
    type: TYPE_STORY,
  },
  {
    name: 'Book',
    description: 'Upload and publish your .epub file.',
    icon: BookOpenIcon,
    type: TYPE_BOOK,
  },
];

export default function ModalStartPublish({ open, setOpen }) {
  const navigate = useNavigate();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-full items-end justify-center text-center sm:items-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden bg-white dark:bg-dark-900 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:p-6 h-full overflow-y-scroll flex items-stretch justify-center">
                <div className="absolute top-0 left-3  pt-4 pr-4 block">
                  <button
                    type="button"
                    className="rounded-md bg-white dark:bg-transparent dark:text-gray-200 text-secondary-900 focus:outline-none focus:ring-2 focus:ring-secondary-900 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-auto w-7" aria-hidden="true" />
                  </button>
                </div>
                <div className="relative">
                  <div className="h-full flex items-center justify-center">
                    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-3xl lg:px-8">
                      <Heading size="4">What do you want to publish?</Heading>
                      <div className="mt-12">
                        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
                          {features.map((feature) => {
                            return (
                              <div
                                key={feature.name}
                                className="rounded-lg bg-gray-50 dark:bg-dark-800 transition duration-300 hover:ring-2 hover:ring-gray-200 dark:hover:ring-dark-600 group"
                              >
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <Link
                                  onClick={(e) => {
                                    e.preventDefault();
                                    ReactGA.event({
                                      category: 'create',
                                      action: `click_${feature.type}`,
                                      label: feature.type,
                                    });
                                    navigate(`/create/${feature.type}`);
                                  }}
                                  className="h-full"
                                >
                                  <div className="flow-root p-8">
                                    <div className="mt-6">
                                      <span className="inline-flex items-center justify-center ">
                                        <feature.icon
                                          className="h-auto w-10 group-hover:scale-125 transition text-black dark:text-dark-300"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <h3 className="mt-2 text-base font-semibold text-center dark:text-dark-100">
                                        {feature.name}
                                      </h3>
                                      <p className="text-sm mt-2 leading-normal text-center text-gray-500 dark:text-dark-300">
                                        {feature.description}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
