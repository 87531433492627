import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { utils, Contract } from 'ethers';
import toast from 'react-hot-toast';
import { DateTime } from 'luxon';
import ReactGA from 'react-ga4';
import Button from '../../components/Button';
import { signsOrder } from '../../utils/sing';
import LoginContext from '../../context/LoginContext';
import apiService from '../../services/apiService';
import { MATIC_CONTRACT_EXCHANGE_ABI } from '../../utils/constants';
import { useSpinner } from '../../context/SpinnerContext';
import Toggle from '../../components/Toggle';
import { ListBox } from '../../components/Select/Select';
import PriceConversion from '../../components/PriceConversion';
import Modal from '../../components/Modal';
import CreatedOn from '../../components/CreatedOn';
import { capitalize } from '../../utils/utils';
import DividersText from '../../components/DividersText';
import Input from '../../components/Forms/Forms';
import Spacer from '../../components/Spacer';

const expirationDefault = '2000 years';
const expirationOptions = [
  { value: 1, text: '1 day' },
  { value: 2, text: '3 days' },
  { value: 3, text: '1 week' },
  { value: 4, text: '1 month' },
  { value: 5, text: '3 months' },
  { value: 6, text: '6 months' },
  { value: 7, text: '1 year' },
];

function ModalSellStory({ tokenId, isNewStory, story, available, callback }) {
  const { ethersProvider, address, userName } = useContext(LoginContext);
  const navigate = useNavigate();
  const { showSpinner, hideSpinner } = useSpinner();
  const [open, setOpen] = useState(!!isNewStory);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(1);
  const [hasExpirationDate, setHasExpirationDate] = useState(false);
  const [expirationDate, setExpirationDate] = useState(expirationOptions[0]);

  const getHash = async (sing, address_, Order_) => {
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(
      process.env.REACT_APP_MATIC_CONTRACT_EXCHANGE,
      MATIC_CONTRACT_EXCHANGE_ABI,
      signer,
    );
    const hash = await contract.getOrderHashAndFilledAmount(Order_);
    return hash[0];
  };

  const getExpirationEpochSeconds = () => {
    const expiration = hasExpirationDate
      ? expirationDate.text
      : expirationDefault;
    const expirationDateSplitted = expiration.split(' ');

    return DateTime.now()
      .plus({ [expirationDateSplitted[1]]: expirationDateSplitted[0] })
      .toUnixInteger();
  };

  async function signDataHandle() {
    try {
      showSpinner('Please be patient.\nThis may take a couple of minutes.');

      const payload = {
        makerAddress: address,
        makerAssetAmount: `${quantity}`,
        takerAssetAmount: utils.parseEther(`${price}`).toString(),
        expirationTimeSeconds: getExpirationEpochSeconds(),
        makerAssetData: process.env.REACT_APP_MATIC_CONTRACT_PROXY_1155,
        ids: [tokenId],
        amount: [`${quantity}`],
        isleft: false,
      };

      const { order_ } = await apiService.post(`/market/orderhandle`, payload);
      const { tx } = await signsOrder(ethersProvider, order_);
      const orderHast = await getHash(tx, address, order_);
      const { makerAddress, ...orderPayload } = order_;
      orderPayload.price = utils.parseEther(`${price}`).toString();
      orderPayload.quantity = parseInt(quantity, 10);
      orderPayload.createdBy = address;
      orderPayload.createdByName = userName;
      orderPayload.tokenId = tokenId;
      orderPayload.orderSing = tx;
      orderPayload.orderHash = orderHast;

      // TODO: This DB updates should be performed in the event listener in the Backend
      await apiService.post(`/market`, orderPayload);
      ReactGA.event({
        category: 'create',
        action: 'click_list',
        label: story.type,
      });
      setOpen(false);
      // We don't put the hideSpinner in a finally block because the callback
      // function is displaying a spinner as well.
      hideSpinner();
      callback();
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
      hideSpinner();
    }
  }
  const cancel = () => {
    ReactGA.event({
      category: 'create',
      action: 'click_list_later',
      label: story.type,
    });
    if (!isNewStory) {
      return setOpen(false);
    }

    setOpen(false);
    return navigate(`/${story.type}/${tokenId}`);
  };

  return (
    <div>
      {!isNewStory && (
        <Button
          onClick={() => setOpen(true)}
          size="md"
          width="full"
          variant="secondary"
        >
          {' '}
          Sell
        </Button>
      )}
      <Modal
        open={open}
        setOpen={setOpen}
        flat
        className="px-4 py-8 m-2 xs:m-8 xs:p-10"
      >
        <h3 className="text-2xl xs:text-3xl leading-10 text-center font-bold mb-6 dark:text-dark-100">
          {isNewStory
            ? `🎉 ${capitalize(story.type)} created`
            : `Sell ${story.type}`}
        </h3>
        <div className="flex flex-col items-center justify-start">
          <div className="flex space-x-5 items-center justify-start w-full">
            <div className="flex items-center justify-center w-1/5 h-full">
              <img
                className="flex items-center justify-center h-full rounded max-w-[64px] max-h-[100px]"
                alt=""
                src={story.image}
              />
            </div>
            <div className="inline-flex flex-col space-y-2 items-start justify-start flex-1">
              <p className="w-full text-sm xs:text-base leading-snug dark:text-dark-300">
                <span>
                  {isNewStory
                    ? 'You successfully created '
                    : 'You are selling '}
                  <span className="font-semibold">{story.name}</span> by{' '}
                  <span className="font-semibold">{story.author}</span>
                </span>
              </p>
              <div className="inline-flex space-x-2 items-center justify-start">
                <CreatedOn />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col space-y-4 items-start justify-start w-full mt-6">
          <DividersText>Sell in the marketplace</DividersText>
          <div className="flex flex-col space-y-7 items-start justify-start w-full">
            <div className="flex flex-col space-y-4 items-start justify-start w-full">
              <div className="w-full">
                <label
                  htmlFor="quantity-to-sell"
                  className="block text-sm font-medium text-gray-900 dark:text-dark-300"
                >
                  Quantity
                </label>
                <span className="text-sm text-gray-500">
                  {available} available
                </span>
                <div className="flex items-center justify-between w-full">
                  <div className="mt-1 relative">
                    <Input
                      type="number"
                      name="quantity-to-sell"
                      id="quantity-to-sell"
                      placeholder={0}
                      min={0}
                      size="md"
                      value={quantity}
                      className={
                        quantity > available || quantity <= 0
                          ? 'min-w-[180px]'
                          : 'min-w-[180px]'
                      }
                      onInput={(e) => {
                        setQuantity(e.target.value);
                      }}
                    />
                    {quantity > available || quantity <= 0 ? (
                      <p
                        role="alert"
                        id="quantity-to-sell"
                        className="mt-2 text-sm text-red-600"
                      >
                        The value must be between 1 and {available}
                      </p>
                    ) : null}
                  </div>
                  <Button
                    variant="secondary"
                    onClick={() => setQuantity(available)}
                    size="sm"
                  >
                    All
                  </Button>
                </div>
              </div>
              <div className="w-full">
                <div className="flex items-center justify-between w-full">
                  <div className="relative">
                    <Input
                      type="number"
                      pattern="^[0-9]+"
                      name="price-per-unit"
                      id="price-per-unit"
                      label="Price per unit"
                      size="md"
                      placeholder={0}
                      min={0}
                      value={price}
                      className={price < 0 ? 'min-w-[180px]' : 'min-w-[180px]'}
                      onInput={(event) => {
                        setPrice(event.target.value);
                      }}
                    />
                    <span className="text-gray-500 absolute right-4 top-8">
                      MATIC
                    </span>
                    {price < 0 ? (
                      <p
                        role="alert"
                        id="quantity-to-sell"
                        className="mt-2 text-sm text-red-600"
                      >
                        Value must be greater than or equal to 0
                      </p>
                    ) : null}
                  </div>
                  <div className="flex justify-between w-full items-center">
                    <div className="ml-2 mt-6 text-gray-500">
                      <PriceConversion
                        price={utils.parseEther(`${price}` || '0')}
                      />
                    </div>
                    <Button
                      variant="secondary"
                      onClick={() => setPrice(0)}
                      size="sm"
                    >
                      Free
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <Toggle
            label="Set expiration date"
            enabled={hasExpirationDate}
            setEnabled={setHasExpirationDate}
          />
          {hasExpirationDate && (
            <>
              <Spacer size="4xs" />
              <ListBox
                label="Duration"
                options={expirationOptions}
                selectedOption={expirationDate}
                setSelectedOption={setExpirationDate}
              />
            </>
          )}
        </div>
        <div className="flex flex-col space-y-4 mt-8 justify-center">
          {/* Create collection */}
          <Button
            type="button"
            width="full"
            disabled={quantity > available || quantity <= 0 || price < 0}
            onClick={() => signDataHandle(true)}
          >
            List {capitalize(story.type)}
          </Button>
          {/* Cancel button */}
          <Button
            type="button"
            onClick={cancel}
            variant="secondary"
            width="full"
          >
            {isNewStory ? 'List it later' : 'Cancel'}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ModalSellStory;
