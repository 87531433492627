import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSearchParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { BookOpenIcon, SparklesIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import GridStories from '../../components/GridStories';
import Container from '../../components/Containers';
import apiService from '../../services/apiService';
import { useSpinner } from '../../context/SpinnerContext';
import { TYPE_BOOK, TYPE_STORY } from '../../utils/constants';
import { capitalize, pluralizeType } from '../../utils/utils';
import TagButton from '../../components/TagButton';
import TabsReadl from '../../components/Tab/Tab';
import StoryIcon from '../../assets/icons/StoryIcon';
import Heading from '../../components/Typography';
import { ButtonLink } from '../../components/Button/Button';
import TeaIcon from '../../assets/icons/TeaIcon';
import Footer from '../../components/Footer';

const tabs = [
  {
    id: '',
    title: 'All',
    icon: <SparklesIcon className="w-6 h-6 mr-2" />,
  },
  {
    id: TYPE_BOOK,
    title: capitalize(pluralizeType(TYPE_BOOK)),
    icon: <BookOpenIcon className="w-6 h-6 mr-2" />,
  },
  {
    id: TYPE_STORY,
    title: capitalize(pluralizeType(TYPE_STORY)),
    icon: <StoryIcon className="w-6 h-6 mr-2" />,
  },
];

const tabsIndexes = {
  [TYPE_BOOK]: 1,
  [TYPE_STORY]: 2,
};

function Bookstore() {
  const { showSpinner, hideSpinner } = useSpinner();
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [stories, setStories] = useState([]);
  const [tags, setTags] = useState([]);
  const [type, setType] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialSelectedTab, setInitialSelectedTab] = useState(1);

  const changeType = (selectedType) => {
    setPage(1);
    setType(selectedType);
  };

  const toggleTag = (tag) => {
    let newTags;
    if (selectedTags.includes(tag)) {
      newTags = selectedTags.filter((tagItem) => tagItem !== tag);
    } else {
      newTags = [...selectedTags, tag];
    }

    setPage(1);
    setSelectedTags(newTags);
  };

  const clearTags = () => {
    setPage(1);
    setSelectedTags([]);
  };

  const getMarket = async (paramType, paramTags, paramPage) => {
    try {
      if (!isFirstLoading) showSpinner();

      const params = [];
      if (paramType) params.push(`type=${paramType}`);
      if (paramTags.length) params.push(`tags=${paramTags.join(',')}`);
      params.push(`page=${paramPage || 1}&limit=20`);
      const { stories: resStories, totalPages: resTotalPages } =
        await apiService.get(`/market?${params.join('&')}`);

      setStories(resStories);
      setTotalPages(resTotalPages);
    } catch (err) {
      if (isFirstLoading) {
        throw err;
      }
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      if (!isFirstLoading) hideSpinner();
    }
  };

  const getNextPage = async () => {
    const newPage = page + 1;
    const params = [];
    if (type) params.push(`type=${type}`);
    if (selectedTags.length) params.push(`tags=${selectedTags.join(',')}`);
    params.push(`page=${newPage}&limit=20`);
    const { stories: resStories, totalPages: resTotalPages } =
      await apiService.get(`/market?${params.join('&')}`);

    setStories([...stories, ...resStories]);
    setPage(newPage);
    setTotalPages(resTotalPages);
  };

  const getTags = async (paramType) => {
    const responseTags = await apiService.get(`/market/tags?type=${paramType}`);
    setTags(responseTags);
  };

  const loadPageInfo = async () => {
    try {
      showSpinner();

      const paramType = searchParams.get('type') || '';
      const paramTags = searchParams.get('tags')?.split(',') || [];
      const paramPage = searchParams.get('page');
      setType(paramType);
      setSelectedTags(paramTags);
      setPage(paramPage || 1);
      setInitialSelectedTab(tabsIndexes[paramType] || 0);

      await Promise.all([
        getMarket(paramType, paramTags, paramPage),
        getTags(paramType),
      ]);
    } catch (err) {
      console.error(err);
      toast.error('An error has occurred');
    } finally {
      setIsFirstLoading(false);
      hideSpinner();
    }
  };

  useEffect(() => {
    loadPageInfo();
  }, []);

  useEffect(() => {
    if (!isFirstLoading) {
      const params = [];
      if (type) params.push(`type=${type}`);
      if (selectedTags.length) params.push(`tags=${selectedTags.join(',')}`);
      setSearchParams(params.join('&'));
      getMarket(type, selectedTags, page);
      getTags(type);
    }
  }, [type, selectedTags]);

  return (
    !isFirstLoading && (
      <>
        <Container>
          <div className="flex items-baseline justify-between pt-2 pb-2">
            <Heading>Bookstore</Heading>
          </div>
          <Tab.Group defaultIndex={initialSelectedTab}>
            <Tab.List className="mb-6" aria-label="Tabs">
              <TabsReadl
                tabs={tabs}
                tabClick={(selectedType) => changeType(selectedType)}
                wrapperClasses="max-h-[44px] flex"
              />
            </Tab.List>
          </Tab.Group>
          {tags.length > 0 && (
            <div className="relative lg:hidden">
              <ScrollContainer className="flex max-h-[34px] py-1 space-x-2">
                {tags.map((tag) => {
                  const selected = selectedTags.includes(tag.name);
                  return (
                    <TagButton
                      name={tag.name}
                      count={tag.count}
                      selected={selected}
                      selectTag={toggleTag}
                      key={tag.name}
                    />
                  );
                })}
              </ScrollContainer>
              <div className="bg-gradient-to-l from-white dark:from-dark-900 to-transparent absolute w-[10%] top-0 bottom-0 right-0" />
            </div>
          )}
          <section className="pt-6 pb-24">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-12">
              <div className="hidden lg:block lg:col-span-2">
                <div className="flex justify-between items-center">
                  <Heading size="5">Tags</Heading>
                  <ButtonLink
                    size="xs"
                    variant="link"
                    onClick={clearTags}
                    className={clsx({
                      invisible: !selectedTags.length,
                    })}
                  >
                    Clear
                  </ButtonLink>
                </div>
                <div className="relative">
                  <ul className="space-y-2 mt-4 p-0.5 pb-14 text-sm font-medium text-gray-900 max-h-[80vh] overflow-y-scroll custom-scrollbar">
                    {tags.map((tag) => {
                      const selected = selectedTags.includes(tag.name);
                      return (
                        <li key={tag.name}>
                          <TagButton
                            name={tag.name}
                            count={tag.count}
                            selected={selected}
                            selectTag={toggleTag}
                          />
                        </li>
                      );
                    })}
                  </ul>
                  <div className=" bg-gradient-to-t from-white dark:from-dark-900 to-transparent absolute left-0 bottom-0 right-0 h-14" />
                </div>
              </div>
              <div className="lg:col-span-10">
                <InfiniteScroll
                  dataLength={stories.length}
                  next={getNextPage}
                  hasMore={totalPages > page}
                  loader={
                    <TeaIcon className="flex justify-center items-center h-12" />
                  }
                >
                  <GridStories
                    className="overflow-x-hidden"
                    size="medium"
                    view={stories}
                  />
                </InfiniteScroll>
              </div>
            </div>
          </section>
        </Container>
        <Footer />
      </>
    )
  );
}

export default Bookstore;
