import { useState, useEffect, useRef } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ButtonIcon } from '../Button/Button';
import Input from '../Forms/Forms';
import Toggle from '../Toggle';
import { MB_IN_B } from '../../utils/constants';

function UnlockedImage({ editUnlockable, deleteUnlockable, content }) {
  const [fileUrl, setFileUrl] = useState('');
  const element = useRef(null);
  const inputRef = useRef(null);
  const titleRef = useRef(null);

  const addFile = () => {
    let userFile;
    if (inputRef.current?.files?.length) {
      // eslint-disable-next-line prefer-destructuring
      userFile = inputRef.current.files[0];
    } else {
      return deleteUnlockable(content.id);
    }
    editUnlockable(
      {
        file: userFile,
        size: userFile.size,
        title: userFile.name
          .replace('.png', '')
          .replace('.jpg', '')
          .replace('.jpeg', ''),
        fileName: userFile.name,
        fileType: userFile.type,
        error: {
          file: false,
          title: false,
        },
      },
      content.id,
    );
    return setFileUrl(URL.createObjectURL(userFile));
  };

  const clean = () => {
    URL.revokeObjectURL(fileUrl);
    setFileUrl('');
    deleteUnlockable(content.id);
  };

  useEffect(() => {
    if (content?.error.title) {
      titleRef.current.focus();
    }
    if (content?.error.file) {
      titleRef.current.focus();
    }
  }, [content]);

  useEffect(() => {
    if (content.file) {
      element.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [content.file]);

  useEffect(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  return (
    <div ref={element} className="bg-gray-50 dark:bg-dark-800 rounded-md">
      {/* state: noFile */}
      {!content.file ? (
        <input
          ref={inputRef}
          onInput={addFile}
          id="image-upload"
          name="image-upload"
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/gif"
          className="sr-only"
        />
      ) : (
        <div>
          <div className="bg-gray-100 dark:bg-dark-700 flex px-6 py-4 rounded-md dark:text-white">
            <div className="flex items-center w-10">
              <ButtonIcon
                icon={<TrashIcon />}
                variant="link"
                size="sm"
                onClick={clean}
              />
            </div>
            {/* File type section */}
            <div className="ml-2 flex justify-between w-full items-center">
              <div className="flex items-center">
                <div className="h-[68px] w-[68px] flex justify-center items-center mr-4">
                  <img src={fileUrl} alt="" className="h-full rounded-md" />
                </div>
                <div className="flex flex-col">
                  <span className="text-md">{content.fileName}</span>
                  <span className="text-gray-600 text-xs dark:text-dark-400">
                    {Math.round((content.size / MB_IN_B) * 100) / 100}MB
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="p-6 space-y-6">
            <Input
              ref={titleRef}
              label="Title"
              placeholder="File"
              value={content.title}
              onChange={(e) => {
                editUnlockable(
                  {
                    title: e.target.value,
                    error: {
                      title: false,
                    },
                  },
                  content.id,
                );
              }}
              error={content.error.title}
              errorMessage="You need to write a title."
            />
            <Toggle
              label="Gated"
              description="Only owners can access it."
              enabled={content.isPrivate}
              setEnabled={() =>
                editUnlockable({ isPrivate: !content.isPrivate }, content.id)
              }
            />
            <Toggle
              label="Allow download"
              description="Owners can download the file."
              enabled={content.allowDownload}
              setEnabled={() =>
                editUnlockable(
                  { allowDownload: !content.allowDownload },
                  content.id,
                )
              }
              disabled
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default UnlockedImage;
