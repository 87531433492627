import React from 'react';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import ScrollContainer from 'react-indiana-drag-scroll';

function TabsPill({
  tabs,
  vertical,
  align = 'center',
  tabClick,
  wrapperClasses,
  overlay,
}) {
  return (
    <ScrollContainer className={wrapperClasses}>
      {tabs.map((tab) => (
        <Tab
          className={({ selected }) =>
            clsx(
              'group font-semibold text-md md:text-lg transition-all duration-300 focus:outline-none active:outline-none outline-none rounded-lg mx-1',
              selected
                ? 'text-white bg-black dark:text-white'
                : 'border-transparent text-gray-500 hover:text-gray-700 dark:hover:text-gray-200 hover:border-gray-300',
              vertical
                ? 'px-8 items-center flex py-3'
                : 'inline-flex items-center px-2 md:px-6 py-5',
              { 'justify-center': vertical && align === 'center' },
            )
          }
          onClick={() => tabClick && tabClick(tab.id)}
          key={tab.id}
        >
          {tab.icon}
          {tab.title}
        </Tab>
      ))}
      {overlay && <div className="absolute" />}
    </ScrollContainer>
  );
}

export default TabsPill;
