import React from 'react';
import ReactMarkdown from 'react-markdown';
import Link from '../Link';

function LinkRenderer({ href, children }) {
  return (
    <Link href={href} target="_blank" rel="noreferrer">
      {children}
    </Link>
  );
}

function MarkdownRenderer({ className, children }) {
  return (
    <ReactMarkdown className={className} components={{ a: LinkRenderer }}>
      {children}
    </ReactMarkdown>
  );
}

export default MarkdownRenderer;
