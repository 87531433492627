import Container from '../../components/Containers';
import Footer from '../../components/Footer';
import Heading from '../../components/Typography';
import { Text } from '../../components/Typography/Heading';

export default function TermsConditions() {
  return (
    <div>
      <Container width="md" className="prose">
        <Heading size="1">
          <button type="button">Terms and Conditions</button>
        </Heading>
        <div>
          <Heading size="4">1. Scope and application</Heading>
          <Text>
            Readl is a blockchain network to reshape the book publishing
            industry by returning ownership back to book authors, publishers,
            and readers through NFTs. We build tools for publishers, writers,
            artists, and contributors to create NFT book editions and
            collectibles providing a whole new reading experience.&nbsp;
          </Text>
          <Text>
            Readl has a marketplace and a publishing platform, where authors and
            publishers can create NFT written content editions to sell them into
            the marketplace at the price defined by the author or publisher.
            Also, artists can create NFT content to attach into the NFT document
            and visualize it while reading. The first time a NFT asset is sold,
            2% of the sales prices defined by the author or publisher is for
            Readl and the 98% for the creator. If this digital asset is sold in
            a second hand market, 2% of the sale price is for Readl, X% (to be
            defined before making the digital asset available in any second hand
            market) is for the publisher and the rest for the owner.&nbsp;
          </Text>
          <Text>
            These Terms of Use (these &ldquo;Terms&rdquo;) govern your access to
            and use of the Readl website; including without limitation the
            creation, purchase, sale, exchange, or modification of certain
            digital assets; our online and/or mobile services, and software
            provided on or in connection with those services (collectively, the
            &ldquo;Service&rdquo;).
          </Text>
          <Text>
            This Service also allows you to sell and purchase Crypto Assets
            <strong>.</strong> You may only participate in the Sale or Purchase
            by linking your digital wallets on supported bridge extensions such
            as https://metamask.io/. Metamask or any other EVM compatible
            wallet, is an electronic wallet, which allows you to purchase,
            store, and engage in transactions using&nbsp;
            <strong>
              moonriver/moonbeam (https://moonbeam.network/)&nbsp;
            </strong>
            cryptocurrency. Before putting your unique digital asset on Sale or
            placing an offer to purchase a unique digital asset from another
            user, we will ask you to download a supported electronic wallet
            extension, and connect and unlock your digital wallets with that
            extension. Once you submit an order to sell or purchase a unique
            digital asset, your order is passed on to the applicable extension,
            which completes the transaction on your behalf.&nbsp;
          </Text>
          <Text>
            &ldquo;Crypto Assets&rdquo; refers to unique non-fungible tokens
            (NFTs), implemented on the Moonbeam blockchain using smart contracts
            .&nbsp;
          </Text>
          <Text>
            All transactions initiated through our service are managed and run
            by third-party electronic wallet extensions, and by using our
            services you agree to the terms of service and privacy policy for
            the applicable extensions for Metamask, those terms are available at
            &nbsp;
            <a href="https://metamask.io/terms.html">
              https://metamask.io/terms.html
            </a>{' '}
            and&nbsp;
            <a href="https://metamask.io/terms.html">
              https://metamask.io/terms.html
            </a>
            &nbsp;
          </Text>
          <Text>
            Readl is a platform. Readl is not a broker, financial institution,
            or creditor. Readl anables transactions between the buyer and seller
            but is not a party to any agreement between the buyer and seller of
            crypto assets or between any users.
          </Text>
          <Text>
            You have full responsibility for verifying the identity, legitimacy,
            and authenticity of assets you purchase on Readl. Notwithstanding
            indicators and messages that suggest verification, Readl makes no
            claims about the identity, legitimacy, or authenticity of assets on
            the platform.
          </Text>
          <Text>
            Readl reserves the right to modify or change these Terms and
            Conditions to adapt them to additional services or to the applicable
            laws and regulations at any time and in our sole discretion. If we
            make changes to these Terms, we will provide notice of such changes,
            such as by sending an email notification, providing notice through
            the Service or updating the &ldquo;Last Updated&rdquo; date at the
            beginning of these Terms. By continuing to access or use the
            Service, you confirm your acceptance of the revised Terms and all of
            the terms incorporated therein by reference. We encourage you to
            review the Terms frequently to ensure that you understand the terms
            and conditions that apply when you access or use the Service. If you
            do not agree to the revised Terms, you may not access or use the
            Service. Those additional terms and conditions, which are available
            with the relevant services, then become part of your agreement with
            us if you use those services.
          </Text>
          <Text>
            These terms of use are important and affect your legal rights, so
            please read them carefully.&nbsp;
          </Text>
          <Text>
            By clicking on the &ldquo;I accept&rdquo; button, completing the
            account registration process, using our services and/or purchasing
            crypto assets, you agree to be bound by these terms and all of the
            terms incorporated herein by reference. If you do not agree to these
            terms, you may not access or use the service or purchase the crypto
            assets.
          </Text>
        </div>
        <div>
          <Heading size="4">
            2. Ownership of the platform intellectual property
          </Heading>
          <Text>
            Please refer to our Privacy Policy
            (https://readl.co/privacy-policy-for-readl-association/) for
            information about how we collect, use and share personal information
            about you.
          </Text>
          <ol>
            <li>
              Unless otherwise indicated in writing by us, the Service and all
              content and other materials contained therein, including, without
              limitation, the Readl logo and all designs, text, graphics,
              pictures, information, data, software, sound files, other files
              and the selection and arrangement thereof (collectively,
              &ldquo;Content&rdquo;) are the proprietary property of Readl or
              our affiliates, licensors or users, as applicable.
            </li>
            <li>
              Notwithstanding anything to the contrary in these Terms, the
              Service and Content may include software components provided by
              Readl or its affiliates or a third party that are subject to
              separate license terms, in which case those license terms will
              govern such software components.
            </li>
            <li>
              The Readl logo and any Readl product or service names, logos or
              slogans that may appear on the Service or Service are trademarks
              of Readl or our affiliates and may not be copied, imitated or
              used, in whole or in part, without our prior written permission.
              You may not use any metatags or other &ldquo;hidden text&rdquo;
              utilizing &ldquo;Readl&rdquo; or any other name, trademark or
              product or service name of Readl or our affiliates without our
              prior written permission. In addition, the look and feel of the
              Service and Content, including, without limitation, all page
              headers, custom graphics, button icons and scripts, constitute the
              service mark, trademark or trade dress of Readl and may not be
              copied, imitated or used, in whole or in part, without our prior
              written permission. All other trademarks, registered trademarks,
              product names and Readl names or logos mentioned on the Service
              are the property of their respective owners and may not be copied,
              imitated or used, in whole or in part, without the permission of
              the applicable trademark holder. Reference to any products,
              services, processes or other information by name, trademark,
              manufacturer, supplier or otherwise does not constitute or imply
              endorsement, sponsorship or recommendation by Readl.
            </li>
          </ol>
        </div>
        <div>
          <Heading size="4">3. Third party services</Heading>
          <Text>
            The Service may contain links to third-party websites
            (&ldquo;Third-Party Websites&rdquo;&nbsp;
            <a href="https://transak.com/">https://transak.com/</a>,&nbsp;
            <a href="https://metamask.io/">https://metamask.io/</a>{' '}
            <a href="https://moonbeam.network/">https://moonbeam.network/</a> )
            and applications (&ldquo;Third-Party Applications&rdquo;). When you
            click on a link to a Third-Party Website or Third-Party Application,
            we will not warn you that you have left our Service and are subject
            to the Terms and conditions (including privacy policies) of another
            website or destination. Such Third-Party Websites and Third-Party
            Applications are not under the control of Readl. Readl is not
            responsible for any Third-Party Websites or Third-Party
            Applications. Readl provides these Third-Party Websites and
            Third-Party Applications only as a convenience and does not review,
            approve, monitor, endorse, warrant, or make any representations with
            respect to Third-Party Websites or Third-Party Applications, or
            their products or services. You use all links in Third-Party
            Websites, and Third-Party Applications at your own risk. When you
            leave our Service, our Terms and policies no longer govern. You
            should review all applicable agreements and policies, including
            privacy and data gathering practices, of any Third-Party Websites or
            Third-Party Applications, and should make whatever investigation you
            feel necessary or appropriate before proceeding with any transaction
            with any third party.
          </Text>
          <Text>Services</Text>
          <ul>
            <li>IPFS, P&icirc;nata</li>
            <li>Moonbeam</li>
            <li>Transak</li>
            <li>Metamask</li>
            <li>Amazon Web Services</li>
          </ul>
        </div>
        <div>
          <Heading size="4">4. User conduct</Heading>
          <Text>
            You agree that you will not violate any law, contract, intellectual
            property or other third party right, and that you are solely
            responsible for your conduct, while accessing or using the Service
            or participating in a sale. You agree that you will abide by these
            Terms and will not:
          </Text>
          <ol>
            <li>Provide false or misleading information to Readl;</li>
            <li>
              Use or attempt to use another user&rsquo;s Account without
              authorization from such user and Readl;
            </li>
            <li>Create or list counterfeit items;</li>
            <li>Pose as another person or create a misleading username;</li>
            <li>
              Use the Service in any manner that could interfere with, disrupt,
              negatively affect or inhibit other users from fully enjoying the
              Service, or that could damage, disable, overburden or impair the
              functioning of the Service in any manner;
            </li>
            <li>
              Develop, utilize, or disseminate any software in any manner, that
              could damage, harm, or impair the Service;
            </li>
            <li>
              Use data collected from our Service to contact individuals,
              companies, or other persons or entities;
            </li>
            <li>
              Use data collected from our Service for any direct marketing
              activity (including without limitation, email marketing, SMS
              marketing, telemarketing, and direct marketing);
            </li>
            <li>
              Bypass or ignore instructions that control all automated access to
              the Service;
            </li>
            <li>
              Use the Service for any illegal or unauthorized purpose, or engage
              in, encourage or promote any activity that violates these Terms;
            </li>
            <li>
              Use the Platform to carry out any illegal activities, including
              but not limited to money laundering, terrorist financing or
              deliberately engaging in activities designed to adversely affect
              the performance of the Ethereum Platform, or the Service;
            </li>
            <li>Place misleading bids or offers;</li>
            <li>
              Spam listings for the purpose of causing a listing to appear at
              the top of the search results;
            </li>
          </ol>
          <Text>
            Readl facilitates trade among a diverse community of buyers and
            sellers. Openness is one of our most prized values, and we&rsquo;re
            committed to providing a platform for the exchange of a wide range
            of content, including controversial content. These policies ensure
            that our marketplace offers the widest selection of assets possible
            while promoting trust and respect, as well as adherence to the law.
          </Text>
          <Text>
            Assets, listings, smart contracts, and collections that Readl deems
            inappropriate, disruptive, or illegal are prohibited on Readl. Readl
            reserves the rights to determine the appropriateness of listings on
            its site and to remove any listing at any time. If you create or
            offer an asset, listing, smart contract, or collection in violation
            of these policies, we will take corrective actions, as appropriate,
            including but not limited to removing the asset, listing, smart
            contract, or collection, deleting your Readl account, and
            permanently withholding referral payments and developer revenue
            sharing fees. Readl cannot destroy or impound your assets or smart
            contracts, but we reserve the right to destroy inappropriate
            metadata stored on our servers.
          </Text>
          <Text>
            Assets, listings, smart contracts, and collections that include
            metadata that violates international or Swiss intellectual property
            laws, promotes suicide or self-harm, incites hate or violence
            against others, degrades or doxes another individual, depicts minors
            in sexually suggestive situations, or is otherwise illegal will be
            removed.
          </Text>
          <Text>
            Assets, listings, smart contracts, and collections created or used
            primarily or substantially for any illegal purpose or for the
            purpose of raising funds for the known terrorist organizations
            listed on&nbsp;
            <a href="https://www.state.gov/foreign-terrorist-organizations/">
              https://www.state.gov/foreign-terrorist-organizations/
            </a>{' '}
            are prohibited on Readl and will be removed.
          </Text>
          <Text>
            The sale of stolen assets, converted assets, fraudulently obtained
            assets, assets taken without authorization, and other illegally
            obtained assets on Readl is prohibited. Listing illegally obtained
            assets may result in your listings being cancelled, your assets
            being hidden, or your account being suspended.
          </Text>
          <Text>
            If you have reason to believe that an asset listed on Readl was
            illegally obtained, please contact us immediately at&nbsp;
            <a href="mailto:legal@readl.co">legal@readl.co</a> Include the
            asset&rsquo;s ID, the asset&rsquo;s contract address, and evidence
            demonstrating that the asset was illegally obtained. Readl will
            review your claim and reply within 72 hours. If we determine from
            the evidence that the asset is stolen, we will hide it or disable
            trading of it.
          </Text>
          <Text>
            We require all users to be at least 18 years old. If you are under
            18, you may use a parent or guardian&rsquo;s Readl account, but only
            with the involvement of the account holder. However, the account
            holder is responsible for everything done with that account.
          </Text>
          <Text>
            If you become aware of the creation, listing, or buying of assets in
            violation of any of the terms specified in this section, you shall
            contact us at &nbsp;
            <a href="mailto:legal@readl.co">legal@readl.co</a>&nbsp; to report
            it.
          </Text>
        </div>
        <div>
          <Heading size="4">5. User information and copyright</Heading>
          <Text>
            You are solely responsible for your use of the Services and for any
            User Information you provide, including compliance with applicable
            laws, rules, and regulations. We take no responsibility for the User
            Information posted or listed via the Services.
          </Text>
          <Text>
            You retain your rights to any User Information you submit, post, or
            display using the Services.
          </Text>
          <Text>
            You represent and warrant that you have, or have obtained, all
            rights, licenses, consents, permissions, power and/or authority
            necessary to grant the rights granted herein for any User
            Information that you submit, post or display on or through the
            Services. You agree that such User Information will not contain
            material subject to copyright or other proprietary rights, unless
            you have necessary permission or are otherwise legally entitled to
            post the material and to grant Readl the license described above.
          </Text>
          <Text>
            Readl reserves the right to remove content without prior notice.
            Readl will take down works in response to formal infringement claims
            and will terminate a user&rsquo;s access to the Services if the user
            is determined to be a repeat infringer.
          </Text>
          <Text>
            If you believe that your content has been copied in a way that
            constitutes a copyright infringement, please report this by
            contacting our designated copyright agent at:
          </Text>
          <Text>Address: &nbsp;M&uuml;hlegasse 18, 6340 Baar Switzerland</Text>
          <Text>
            Email:&nbsp;<a href="mailto:legal@readl.co">legal@readl.co</a>&nbsp;
          </Text>
          <Text>
            If you believe that your likeness has been used in a way that
            violates your personality rights, please report this by contacting
            our designated personality rights agent at:
          </Text>
          <Text>Address M&uuml;hlegasse 18, 6340 Baar Switzerland</Text>
          <Text>
            Email: &nbsp;<a href="mailto:legal@readl.co">legal@readl.co</a>
            &nbsp;
          </Text>
        </div>
        <div>
          <Heading size="4">6. User content management and copyright</Heading>
          <Text>
            Readl is a platform that enables the creation, storage, publication,
            and sale of content. &nbsp;Readl provides the technology to perform
            these actions but is not responsible for its use. Readl advice to
            observe the following content management guidelines:
          </Text>
          <ol>
            <li>
              The author who creates his work in Readl has the right to
              reproduce it. He/she can create an unlimited number of editions
              and units in each edition. If the work comes from a publisher, the
              reproduction of the work must comply with the terms already
              established in the author-publisher contract. If the publisher
              uses Readl without being under author-publisher agreement, Readl
              is not responsible.
            </li>
            <li>
              Distribution right: the creator of the work allows users to
              resell, temporarily rent or give away (with minimal royalty and
              transaction costs) the book (with a commission set by the creator
              as a royalty).
            </li>
            <li>
              Display right: users cannot display publicly the author&rsquo;s
              work without his approval.
            </li>
            <li>
              The author/publisher has the right to market his/her work and
              republish it as many times as he/she wishes, as long as he/she
              complies with any prior contracts that may exist.
            </li>
          </ol>
        </div>
        <div>
          <Heading size="4">
            7. Account registration and communication preferences
          </Heading>
          <Text>
            If you wish to participate in a sale of Crypto Assets (as a
            copyright owner or as a buyer), you will need to register for an
            account on the Service (&ldquo;Account&rdquo;). By creating an
            Account, you agree to (a) provide accurate, current and complete
            Account information about yourself, (b) maintain and promptly update
            from time to time as necessary your Account information, (c)
            maintain the security of your password and accept all risks of
            unauthorized access to your Account and the information you provide
            to us, and (d) immediately notify us if you discover or otherwise
            suspect any security breaches related to the Service, or your
            Account. Readl will block multiple accounts of the same user. Also,
            you agree that you will not:
          </Text>
          <ol>
            <li>
              create another account if we&rsquo;ve disabled one you had unless
              you have our prior written permission;
            </li>
            <li>
              buy, sell, rent or lease access to your Account or username unless
              you have prior written permission;
            </li>
            <li>share your Account password with anyone; or</li>
            <li>
              log in or try to log in to access the Service through unauthorized
              third party applications or clients.
            </li>
            <li>
              Readl may require you to provide additional information and
              documents at the request of any competent authority or in case of
              application of any applicable law or regulation, including laws
              related to anti-laundering (legalization) of incomes obtained by
              criminal means, or for counteracting financing of terrorism. Readl
              may also require you to provide additional information and
              documents in cases where it has reasons to believe that:
            </li>
          </ol>
          <ul>
            <li>
              Your Account is being used for money laundering or for any other
              illegal activity;
            </li>
            <li>
              You have concealed or reported false identification information
              and other details; or
            </li>
            <li>
              Transactions realized through your Account caused a breach of
              these Terms.
            </li>
          </ul>
          <Text>
            In such cases, Readl, at its sole discretion, may pause or cancel
            your Auction transactions until such additional information and
            documents are reviewed by Readl and accepted as compliant with the
            requirements of applicable law. If you do not provide complete and
            accurate information and documents in response to such a request,
            Readl may refuse to provide the Content to you.
          </Text>
          <ol start="6">
            <li>
              By creating an Account, you consent to the possible reception of
              electronic communications from Readl (e.g., via email or by
              posting notices to the Service). These communications may include
              notices about your Account (e.g., password changes and other
              transactional information) and are part of your relationship with
              us. You agree that any notices, agreements, disclosures or other
              communications that we send to you electronically will comply with
              any legal communication requirements including, but not limited
              to, that such communications be in writing. You should maintain
              copies of electronic communications from us by printing a paper
              copy or saving an electronic copy. We may also send you
              promotional communications via email, including, but not limited
              to, newsletters, special offers, surveys and other news and
              information we think will be of interest to you. You may opt out
              of receiving these promotional emails at any time by following the
              unsubscribe instructions provided therein.
            </li>
            <li>
              By submitting personal data through our Site or Services, you
              agree to the terms of our Privacy Policy and you expressly consent
              to the collection, use and disclosure of your personal data in
              accordance with the Privacy Policy.
            </li>
            <li>
              You must provide all equipment and software necessary to connect
              to the Service and services, including but not limited to, a
              mobile device that is suitable to connect with and use Service and
              services, in cases where the Service offers a mobile component.
              You are solely responsible for any fees, including Internet
              connection or mobile fees, that you incur when accessing the
              Service or services.
            </li>
            <li>
              Notwithstanding anything to the contrary in these Terms, the
              Service and Content may include software components provided by
              Readl or its affiliates or a third party that are subject to
              separate license terms, in which case those license terms will
              govern such software components. For example, when you click to
              get more details about any of the Crypto Assets sold through our
              marketplace, you will notice a third party link to the website
              from which such Crypto Asset originated. Such websites may include
              license terms governing the use of such Crypto Asset. In the event
              you purchase such Crypto Asset through our marketplace, you are
              required to comply with such terms.
            </li>
          </ol>
        </div>
        <div>
          <Heading size="4">
            8. License to access and use our service and content
          </Heading>
          <Text>
            When you create an Account and accept this Terms of Use you are
            hereby granted a limited, nonexclusive, non-transferable, no
            sublicensable, and personal license to access and use the Service
            and Content. Such license is subject to these Terms and does not
            include, if you are not a copyrights owner, any right to&nbsp;
          </Text>
          <ol>
            <li>
              sell, resell or use commercially the Service or Content,&nbsp;
            </li>
            <li>
              distribute, publicly perform or publicly display any
              Content,&nbsp;
            </li>
            <li>
              modify or otherwise make any derivative uses of the Service or
              Content, or any portion thereof,&nbsp;
            </li>
            <li>
              use any data mining, robots or similar data gathering or
              extraction methods,&nbsp;
            </li>
            <li>
              download (other than page caching) any portion of the Service or
              Content, except as expressly permitted by us, and&nbsp;
            </li>
            <li>
              use the Service or Content other than for their intended purposes.
            </li>
          </ol>
        </div>
        <div>
          <Heading size="4">
            9. Fees, commissions, royalties, taxes and other charges
          </Heading>
          <Heading size="5">9.1. Fees</Heading>
          <ol className="">
            <li>
              Fees for content providers
              <ol>
                <li>
                  Authors (Fees for books)
                  <ol>
                    <li>2% of the sale price in the case of the first sale</li>
                  </ol>
                </li>
                <li>
                  <Text>
                    Artists and other collaborators (Fees for illustrations)
                  </Text>
                  <ul>
                    <li>2% of the sale price in the case of the first sale</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              Fees for buyers &nbsp;&nbsp;
              <ul>
                <li>
                  There are NO fees for buyers. They only pay the price set by
                  the owner of the NFT
                </li>
              </ul>
            </li>
            <li>
              <Text>
                Commissions on secondary sales of Books and Collectibles,
                established by and payable to the creators of such Books and
                Collectibles;
              </Text>
              <ul>
                <li>
                  Creators define X% of commission in the sale price in the case
                  the NFT is sold in Readl marketplace as a second hand item
                  between users.
                </li>
              </ul>
            </li>
            <li>
              <Text>
                Gas: fees paid to miners through the User&rsquo;s independent
                wallet applications as settlement of the User&rsquo;s mining
                transactions. There are necessary costs in each transaction but
                they are managed externally through Metamask. Readl does not
                intervene in them, nor does it collect or collect payments on
                behalf of Metamask.
              </Text>
            </li>
          </ol>
          <Heading size="5">9.2. Forms of payment</Heading>
          <Text>
            Fees may be paid solely in the manner determined in the sole
            discretion of Readl On the date of initial publication of these
            Terms, all fees will be paid in the cryptocurrency used by Readl.
          </Text>

          <Heading size="5">9.3. Finality; No refunds.</Heading>
          <Text>
            All transactions involving the Offering are final. All Fees relating
            are non-refundable except at the sole discretion of Readl (for
            service fees and other fees within its control) or applicable third
            parties (Books and Collectibles creators, Ethereum miners, etc.).
          </Text>

          <Heading size="5">
            9.4. You are responsible for your own taxes
          </Heading>
          <Text>
            You are responsible to pay any and all sales, use, value-added and
            other taxes, duties, and assessments now or hereafter claimed or
            imposed by any governmental authority, associated with your use of
            the READL Platform (including, without limitation, any taxes that
            may become payable as the result of your ownership, purchase,
            transfer, sale, or creation of any NFTs). You are solely responsible
            for determining what, if any, taxes apply to your transactions
            through the READL Platform. You acknowledge and agree that READL is
            not responsible for determining the taxes that apply to such
            transactions.
          </Text>
          <Text className="text-semibold">
            Notice that increases in Digital Asset Prices and Transaction Volume
            has Attracted Tax Authority
          </Text>
          <Text>
            As the laws governing digital assets are still evolving, the tax
            treatment of digital assets in various jurisdictions is subject to
            change. As there is considerable uncertainty over the taxation of
            digital assets, READL cannot guarantee that digital assets like your
            NFTs will not be subject to further taxation in the future,
            including but not limited to additional taxes and increased tax
            rate. These events could reduce the economic return and increase the
            holding costs of digital assets, which could materially and
            adversely affect your NFTs.
          </Text>
        </div>
        <div>
          <Heading size="4">
            10. Risks, Disclaimers and limitations of liability
          </Heading>
          <Heading size="5">
            10.1. No consequential, incidental or punitive damages
          </Heading>
          <Text>
            Notwithstanding anything to the contrary contained in these Terms,
            Readl shall not be liable to any person, whether in contract, tort
            (including pursuant to any cause of action alleging negligence),
            warranty or otherwise, for special, incidental, consequential,
            indirect, punitive or exemplary damages (including but not limited
            to lost data, lost profits or savings, loss of business or other
            economic loss) arising out of or related to these Terms, whether or
            not Readl has been advised or knew of the possibility of such
            damages, and regardless of the nature of the cause of action or
            theory asserted.
          </Text>
          <Heading size="5">10.2. Limitation of liability</Heading>
          <Text>
            Readl’s liability for damages to each User shall in all cases be
            limited to, and under no circumstances shall exceed, Readl’s service
            fees actually received by Readl from such User.
          </Text>
          <Heading size="5">
            10.3. No responsibility for books and collectibles; No guaranteee of
            uniqueness or IP.
          </Heading>
          <Text>
            Readl has no responsibility for the Books and Collectibles created
            or traded by Users on the Readl Platform.
          </Text>
          <Text>
            Readl does not investigate and cannot guarantee or warrant the
            authenticity, originality, uniqueness, marketability, legality or
            value of any Collectible created or traded by Users on the Readl
            Platform.
          </Text>
          <Heading size="5">10.4. No professional advice or liability</Heading>
          <Text>
            All information provided by or on behalf of Readl is for
            informational purposes only and should not be construed as
            professional, accounting or legal advice. Users should seek
            independent professional advice from persons licensed and qualified
            in the area for which such advice would be appropriate.
          </Text>
          <Heading size="5">
            10.5. Certain uses and risks of blockchain technology and cloud
          </Heading>
          <Text>
            Readl has no responsibility for the issues that can happen in any
            underlying technology such as any Blockchain or cloud services.
          </Text>
          <Heading size="5">10.6. Legal limitations on disclaimers</Heading>
          <Text>
            Some jurisdictions do not allow the exclusion of certain warranties
            or the limitation or exclusion of certain liabilities and damages.
            Accordingly, some of the disclaimers and limitations set forth in
            these Terms may not apply in full to specific Users. The disclaimers
            and limitations of liability provided in these terms shall apply to
            the fullest extent permitted by applicable law.
          </Text>
        </div>
        <div>
          <Heading size="4">11. Governing law; Dispute resolution.</Heading>
          <Heading size="5">11.1. Governing law</Heading>
          <Text>
            These Terms shall be governed by and construed and interpreted in
            accordance with the laws of Switzerland, as to all matters,
            including matters of validity, construction, effect, enforceability,
            performance and remedies. Although the Offerings may be available in
            other jurisdictions, each user hereby acknowledges and agrees that
            such availability shall not be deemed to give rise to general or
            specific personal jurisdiction over Readl in any forum outside
            Switzerland.
          </Text>
          <Heading size="5">11.2. Settlement negotiations</Heading>
          <Text>
            If a User has a potential legal dispute, claim or cause of action
            against Readl, the User shall first (prior to initiating any
            litigation proceedings) contact Readl by sending an email to
            legal@readl.co. describing the nature of the potential dispute,
            claim or cause of action and providing all relevant documentation
            and evidence thereof. If so elected by Readl, User shall use
            commercially reasonable efforts to negotiate a settlement of any
            such legal dispute, claim or cause of action within 60 days of the
            delivery of such email. Any such dispute, claim or cause of action
            that is not finally resolved by a binding, written settlement
            agreement within such 60 days shall be brought and resolved
            exclusively in accordance with the following provisions of this
            Section 10.
          </Text>
        </div>
        <div>
          <Heading size="4">12. Miscellaneous</Heading>
          <Heading size="5">12.1. Headings</Heading>
          <Text>
            The headings and captions contained in these Terms are for
            convenience of reference only, shall not be deemed to be a part of
            these Terms and shall not be referred to in connection with the
            construction or interpretation of these Terms.
          </Text>
          <Heading size="5">12.2. Successors and assigns</Heading>
          <Text>
            These Terms shall inure to the benefit of Readl, the Users, and
            their respective permitted successors, permitted transferees and
            permitted delegates and shall be binding upon all of the foregoing
            persons and any person who may otherwise succeed to any right,
            obligation or liability under these Terms by operation of law or
            otherwise. A User shall not share or provide a copy of, or transfer
            to, any person or the private key associated without notifying such
            person that such person shall be bound by and become a party to
            these Terms by virtue of thereof (or if the transferor has a
            reasonable belief that the transferee is aware of these Terms).
          </Text>
          <Heading size="5">12.3. Severability</Heading>
          <Text>
            In the event that any provision of these Terms, or the application
            of any such provision to any person or set of circumstances, shall
            be determined by a court of competent jurisdiction to be invalid,
            unlawful, void or unenforceable to any extent: (a) the remainder of
            these Terms, shall not be impaired or otherwise affected and shall
            continue to be valid and enforceable to the fullest extent permitted
            by law; and (b) Readl shall have the right to modify these Terms in
            order that the transactions contemplated hereby be consumed as
            originally contemplated to the fullest extent possible.
          </Text>
          <Heading size="5">12.4. Force majeure</Heading>
          <Text>
            Readl shall not incur any liability or penalty for not performing
            any act or fulfilling any duty or obligation hereunder or in
            connection with the matters contemplated hereby (including any
            present or future law or regulation or any act of any governmental
            authority, any act of God or war or terrorism, any epidemic or
            pandemic, or the unavailability, disruption or malfunction of the
            Internet, the World Wide Web or any other electronic network, or
            blockchain, or hack, or denial-of-service), it being understood that
            Readl shall use commercially reasonable efforts, consistent with
            accepted practices in the industries in which Readl operates, as
            applicable, to resume performance as soon as reasonably practicable
            under the circumstances.
          </Text>
          <Heading size="5">12.5. Amendments and modifications</Heading>
          <Text>
            These Terms may only be amended, modified, altered or supplemented
            by or with the written consent of Readl. Readl reserves, the right,
            in its sole and absolute discretion, to amend, modify, alter or
            supplement these Terms from time to time.
          </Text>
          <Text>
            The most current version of these Terms will be posted on Readl’s
            website. Any changes or modifications will be effective immediately
            upon the modified Agreement being posted to Readl’s website. A User
            shall be responsible for reviewing and becoming familiar with any
            such modifications. A User hereby waive any right a User may have to
            receive specific notice of such changes or modifications. Use of the
            Offerings by a User after any modification of these Terms
            constitutes a User acceptance of the modified terms and conditions.
          </Text>
          <Heading size="5">12.6. Entire agreement</Heading>
          <Text>
            These Terms constitute the entire agreement between the parties
            relating to the subject matter hereof and supersede all prior or
            contemporaneous agreements and understandings, both written and
            oral, between the parties with respect to the subject matter hereof.
          </Text>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
