import { useState } from 'react';
import {
  ArrowPathIcon,
  ArrowsRightLeftIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';

function CoverChangeButton({ children, onClick, style }) {
  return (
    <button
      type="button"
      onClick={onClick}
      style={style}
      className="h-10 w-10 bg-white shadow-xl rounded-full flex items-center justify-center hover:scale-110 transition-all duration-200"
    >
      {children}
    </button>
  );
}

function CoverMaker({ title }) {
  const Colors = [
    '000',
    'D3D8DC',
    '091824',
    '4F46E5',
    'DC2626',
    'EA580C',
    '059669',
    'F59E0B',
    '0D9488',
    '0891B2',
    '2563EB',
    '7C3AED',
    'DB2777',
    'E11D48',
  ];
  const Patterns = [
    '/patterns/pattern01.png',
    '/patterns/pattern02.png',
    '/patterns/pattern03.png',
    '/patterns/pattern05.png',
    '/patterns/pattern06.png',
    '/patterns/pattern07.png',
    '/patterns/pattern08.png',
    '/patterns/pattern09.png',
    '/patterns/pattern10.png',
    '/patterns/pattern11.png',
    '/patterns/pattern12.png',
    '/patterns/pattern13.png',
    '/patterns/pattern14.png',
    '/patterns/pattern15.png',
    '/patterns/pattern16.png',
    '/patterns/pattern17.png',
    '/patterns/pattern18.png',
    '/patterns/pattern19.png',
    '/patterns/pattern20.png',
    '/patterns/pattern21.png',
    '/patterns/pattern22.png',
    '/patterns/pattern23.png',
    '/patterns/pattern24.png',
    '/patterns/pattern25.png',
    '/patterns/pattern26.png',
    '/patterns/pattern27.png',
    '/patterns/pattern28.png',
  ];
  const Fonts = [
    "'LEMON MILK'",
    'Creepster',
    'Cocogoose',
    "'Gloria Hallelujah'",
    "'DS-Digital'",
    "'Akira Expanded'",
    "'Syne Mono'",
    'Limelight',
    "'Storm Gust'",
  ];
  const Positions = ['top', 'center', 'top-left'];

  const [colorIndex, setColorIndex] = useState(
    Math.floor(Math.random() * Colors.length),
  );
  const [patternIndex, setPatternIndex] = useState(
    Math.floor(Math.random() * Patterns.length),
  );
  const [fontIndex, setFontIndex] = useState(
    Math.floor(Math.random() * Fonts.length),
  );
  const [positionIndex, setPositionIndex] = useState(
    Math.floor(Math.random() * Positions.length),
  );

  const changeColor = (e) => {
    e.preventDefault();
    setColorIndex(colorIndex + 1 === Colors.length ? 0 : colorIndex + 1);
  };

  const changePattern = (e) => {
    e.preventDefault();
    setPatternIndex(
      patternIndex + 1 === Patterns.length ? 0 : patternIndex + 1,
    );
  };

  const changeFont = (e) => {
    e.preventDefault();
    setFontIndex(fontIndex + 1 === Fonts.length ? 0 : fontIndex + 1);
  };

  const changePosition = (e) => {
    e.preventDefault();
    setPositionIndex(
      positionIndex + 1 === Positions.length ? 0 : positionIndex + 1,
    );
  };

  const setRandom = () => {
    setColorIndex(Math.floor(Math.random() * Colors.length));
    setPatternIndex(Math.floor(Math.random() * Patterns.length));
    setFontIndex(Math.floor(Math.random() * Fonts.length));
    setPositionIndex(Math.floor(Math.random() * Positions.length));
  };

  return (
    <div className="relative">
      <div
        id="cover-maker"
        className="aspect-[4/6] relative rounded-lg overflow-hidden transition-color duration-150"
        style={{
          backgroundColor: `#${Colors[colorIndex]}`,
        }}
      >
        <div
          className={clsx('absolute bottom-0 top-0 left-0 right-0 flex p-8', {
            'items-center text-center': positionIndex === 0,
            'items-start text-right': positionIndex === 1,
            'items-start text-left': positionIndex === 2,
          })}
        >
          <div
            className="absolute top-0 left-0 right-0 bottom-0 opacity-40 bg-[length:auto_110%] bg-center"
            style={{ backgroundImage: `url(${Patterns[patternIndex]})` }}
          />
          <h3
            className={clsx(
              'text-white font-semibold text-whitebreak-words leading-tight w-full z-10 break-words opacity-0 h-0 overflow-hidden group-open:overflow-visible group-open:w-full group-open:h-auto origin-left transition-[opacity] group-open:opacity-100 group-open:flex flex group-open:duration-300 group-open:delay-300',
              {
                'text-5xl': title.length < 18,
                'text-4xl': title.length >= 18 && title.length < 43,
                'text-3xl': title.length >= 43,
              },
            )}
            style={{
              fontFamily: Fonts[fontIndex],
            }}
          >
            {title}
          </h3>
        </div>
      </div>
      <div className="absolute top-8 -left-5 flex-col space-y-2 opacity-0 w-0 h-0 overflow-hidden group-open:overflow-visible group-open:w-full group-open:h-auto origin-left transition-[opacity] group-open:opacity-100 group-open:flex flex group-open:duration-300 group-open:delay-300">
        <CoverChangeButton
          onClick={changeColor}
          style={{
            backgroundColor: `#${Colors[colorIndex]}`,
          }}
        />
        <CoverChangeButton onClick={changePattern}>
          <SparklesIcon className="h-5 w-5" />
        </CoverChangeButton>
        <CoverChangeButton onClick={changeFont}>
          <span>a</span>
        </CoverChangeButton>
        <CoverChangeButton onClick={changePosition}>
          <ArrowsRightLeftIcon className="h-5 w-5" />
        </CoverChangeButton>
        <CoverChangeButton onClick={setRandom}>
          <ArrowPathIcon className="h-5 w-5" />
        </CoverChangeButton>
      </div>
    </div>
  );
}

export default CoverMaker;
