import React from 'react';

function ReadlLogo({ className }) {
  return (
    <svg
      width="84"
      height="32"
      viewBox="0 0 84 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)" className="fill-black dark:fill-white">
        <path d="M23.9723 12.1326C23.6793 8.62382 21.4246 4.70179 16.0511 3.68059C14.1113 3.31259 12.0115 2.96087 10.4065 2.67779C10.1361 2.62952 9.85746 2.65922 9.60327 2.76342L3.73933 5.07333L3.70748 5.08607L3.68625 5.09456C3.49843 5.17236 3.32463 5.28042 3.17176 5.41444C2.71789 5.81027 2.4912 6.33137 2.49167 6.97773V27.1363L7.09167 27.9481V19.1982L12.0186 20.07C13.2722 20.2913 14.1365 20.6815 14.6116 21.2406C15.0433 21.7916 15.3028 22.7878 15.3901 24.2291C15.4205 25.8016 15.4608 28.0677 15.5146 29.3762L20.2873 30.2693V24.2051C20.2873 21.0205 18.2201 19.4317 17.2697 18.9186C18.3496 18.8059 19.192 18.3279 19.7969 17.4848C20.3588 16.7191 20.6397 15.6236 20.6397 14.1983C20.6397 11.9946 20.0564 10.2713 18.8896 9.02862C17.6365 7.77034 15.8003 6.92795 13.3809 6.50145L7.73708 5.50644L10.335 4.48311L14.1474 5.15967C16.8366 5.63311 18.7183 6.42785 20.1571 7.91542C21.61 9.41785 22.2094 11.4617 22.2094 13.3993C22.2094 16.2082 21.627 18.0659 20.2908 18.9399C21.2993 19.6596 21.8824 21.3227 21.8824 23.1287V29.7937L23.7769 28.9027C23.7656 28.3578 23.7769 23.2907 23.7769 22.3877C23.7769 20.581 23.3743 19.3588 22.7791 18.5995C23.6913 17.5931 24.2369 15.2931 23.9723 12.1326ZM15.1969 11.4879C15.7158 12.054 15.9753 12.8344 15.9753 13.8289C15.9753 14.8234 15.7158 15.534 15.1969 15.9605C14.5486 16.3214 13.6628 16.4028 12.5395 16.2046L7.09521 15.2443V9.28197L12.7985 10.2876C13.878 10.4782 14.6767 10.8783 15.1947 11.4879H15.1969Z" />
        <path d="M33.2855 13.7852C28.9686 13.7852 25.4549 17.3236 25.4549 21.671C25.4573 23.747 26.2831 25.7374 27.7511 27.2054C29.2191 28.6734 31.2094 29.4992 33.2855 29.5016C35.3427 29.5016 37.153 28.6842 38.5224 27.1372L38.6703 26.9695L35.0101 24.8867L34.9188 24.9426C34.4279 25.2424 33.8613 25.395 33.2862 25.3821C31.9762 25.3821 30.7385 24.4487 30.1823 23.4713H40.4756L40.5089 23.3298C40.6242 22.8394 40.678 22.0496 40.678 21.671C40.678 19.5479 39.9427 17.577 38.6087 16.1128C37.2401 14.6117 35.3477 13.7852 33.2855 13.7852ZM30.1207 19.8989C30.6557 18.5932 31.7448 17.9046 33.2855 17.9046C34.5905 17.9046 35.6272 18.6449 36.1021 19.8989H30.1207Z" />
        <path d="M50.2474 13.7852C48.1633 13.7852 46.1645 14.6131 44.6908 16.0868C43.2171 17.5605 42.3892 19.5592 42.3892 21.6434C42.3892 23.7275 43.2171 25.7263 44.6908 27.2C46.1645 28.6737 48.1633 29.5016 50.2474 29.5016C51.6204 29.5016 52.7838 29.2327 53.8029 28.6792H57.7221V14.6075H53.8029C52.7838 14.0548 51.6204 13.7852 50.2474 13.7852ZM50.2474 25.3821C48.2008 25.3821 46.5363 23.7049 46.5363 21.6434C46.5363 19.5819 48.2008 17.9046 50.2474 17.9046C51.9551 17.9046 53.684 19.1891 53.684 21.6434C53.684 24.0976 51.9551 25.3821 50.2474 25.3821Z" />
        <path d="M70.9064 14.5431C69.8626 14.0326 68.7139 13.7731 67.552 13.7852C65.4579 13.7726 63.4428 14.5837 61.9414 16.0434C61.2001 16.7636 60.6118 17.6259 60.2115 18.5788C59.8112 19.5316 59.6072 20.5554 59.6116 21.5889C59.6116 25.9518 63.1735 29.5016 67.552 29.5016C68.9433 29.5016 70.1159 29.2327 71.135 28.6793H75.0535V6.62402H70.9064V14.5431ZM67.552 25.3545C67.0531 25.3614 66.5578 25.269 66.0949 25.0828C65.632 24.8966 65.2107 24.6203 64.8554 24.2699C64.5002 23.9195 64.2182 23.5021 64.0256 23.0418C63.8331 22.5815 63.734 22.0875 63.734 21.5885C63.734 21.0896 63.8331 20.5956 64.0256 20.1353C64.2182 19.675 64.5002 19.2575 64.8554 18.9071C65.2107 18.5568 65.632 18.2804 66.0949 18.0943C66.5578 17.9081 67.0531 17.8157 67.552 17.8225C69.2738 17.8225 71.0197 19.1162 71.0197 21.5889C71.0197 24.0616 69.2738 25.3545 67.552 25.3545Z" />
        <path d="M81.5084 6.62549H77.3337V28.6793H81.5084V6.62549Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="84" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default ReadlLogo;
