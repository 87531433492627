import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Badge from '../Badge';
import apiService from '../../services/apiService';

const MAX_TAGS = 10;

function TagInput({ tags, setTags }) {
  const [mostUsedTags, setMostUsedTags] = useState([]);

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (tag) => {
    if (tags.includes(tag)) return;
    if (tag.trim() !== '') {
      setTags([...tags, tag.trim()]);
    }
  };

  const handleInputChange = (event) => {
    const text = event.target.value;
    if (
      text.charAt(text.length - 1) === ' ' ||
      text.charAt(text.length - 1) === ','
    ) {
      addTags(text.slice(0, -1));
      /* eslint-disable-next-line no-param-reassign */
      event.target.value = '';
    } else if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      addTags(text);
      /* eslint-disable-next-line no-param-reassign */
      event.target.value = '';
    }
  };

  const getMostUsedTags = async () => {
    const response = await apiService.get('general/most-used-tags');
    setMostUsedTags(response);
  };

  useEffect(() => {
    getMostUsedTags();
  }, []);

  return (
    <div className="mt-3">
      <div className="flex flex-wrap gap-1 items-center">
        {tags.map((tag, index) => (
          <Badge
            variant="gray"
            key={tag}
            remove
            size="md"
            onClick={() => removeTags(index)}
          >
            {tag}
          </Badge>
        ))}
        <div className={clsx('flex')}>
          <input
            onKeyUp={handleInputChange}
            type="text"
            className={clsx(
              'bg-white dark:bg-dark-900 text-secondary-700 dark:text-dark-200 dark:placeholder-dark-400 text-xs px-1 py-1 border-none focus:outline-none focus:ring-0',
              {
                'inline-flex items-center rounded-full font-medium':
                  tags.length > 0,
                'inline-flex': tags.length >= MAX_TAGS,
                'rounded-full': tags.length < MAX_TAGS,
              },
            )}
            placeholder="Click to add tags"
            disabled={tags.length >= MAX_TAGS}
          />
        </div>
      </div>
      <div className="mt-2 text-sm text-gray-500 lg:col-span-7 flex hidden">
        Most used tags:{' '}
        <ul className="ml-2 space-x-2 flex flex-row">
          {mostUsedTags.map((i) =>
            tags.includes(i) || tags.length >= MAX_TAGS ? null : (
              <button
                type="button"
                key={i}
                onClick={() => setTags((tag) => [...tag, i])}
              >
                <Badge variant="gray">{i}</Badge>
              </button>
            ),
          )}
        </ul>
      </div>
    </div>
  );
}

export default TagInput;
