import React from 'react';

function CardQuantity({ className = '' }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 16.5467H18.2411C19.2537 16.5467 20.0745 15.7259 20.0745 14.7134V7.28841C20.0745 6.27589 19.2537 5.45508 18.2411 5.45508H17"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M13 4.44531H15.2148C16.2274 4.44531 17.0482 5.26612 17.0482 6.27865V15.7203C17.0482 16.7328 16.2274 17.5536 15.2148 17.5536H13"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <rect
        x="2.45703"
        y="3.35156"
        width="10.5"
        height="15.5"
        rx="1.83333"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CardQuantity;
