import { ChevronDownIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { useState, useEffect, useRef } from 'react';

export default function CollapseSection({ children, onclick }) {
  const [showText, setShowText] = useState(false);
  const toggleText = () => setShowText(!showText);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  return (
    <div
      ref={ref}
      className={clsx(
        'text-gray-500 transition-all duration-1000 mt-2 w-full overflow-hidden relative mb-8',
        {
          'max-h-72': !showText,
          'max-h-full': showText,
        },
      )}
      aria-hidden
    >
      {children}
      {!showText && height > 270 && (
        <div className="bg-gradient-to-t from-white dark:from-dark-900 via-[256, 256, 256, 0.5] w-full absolute left-0 right-0 bottom-0 h-24 flex items-end">
          <div
            className="mx-auto cursor-pointer hover:-translate-y-4 transition-all duration-500 hover:bg-gray-50 rounded-full p-2 -mb-4"
            onClick={onclick || toggleText}
            aria-hidden="true"
          >
            <ChevronDownIcon className="w-8 h-8" />
          </div>
        </div>
      )}
    </div>
  );
}
