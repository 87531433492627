import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ArrowLeftIcon, CheckIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import LoginContext from '../../context/LoginContext';
import { LinkButton } from '../Button/Button';

export default function HeaderEditProfile({ editProfile, description }) {
  const { address } = useContext(LoginContext);
  const navigate = useNavigate();

  return (
    <Disclosure
      as="nav"
      className="bg-white dark:bg-dark-800 fixed w-full top-0 pt-2 z-20"
    >
      {() => (
        <>
          {/* Navbar for general pages and collections */}
          <div className="container mx-auto px-2">
            <div className="relative flex justify-between h-16">
              <div className="flex flex-1 self-center">
                <button
                  type="button"
                  className="hover:text-primary-600 focus:outline-primary-500"
                  onClick={() => navigate(-1)}
                >
                  <ArrowLeftIcon className="h-8 dark:text-dark-200" />
                </button>
              </div>

              <div className="flex-1 flex items-center justify-center">
                <h3 className="text-lg leading-6 text-black-900 dark:text-dark-200 font-bold lg:text-xl">
                  Edit Profile
                </h3>
              </div>

              {address ? (
                <div className="flex flex-1 self-center justify-end space-x-2">
                  <button
                    type="button"
                    className={clsx(
                      'py-1 px-1 lg:pl-2 lg:pr-3 flex bg-primary-500 rounded-full lg:rounded-lg font-semibold text-sm lg:text-base',
                      { 'bg-gray-100': description.length > 200 },
                    )}
                    onClick={editProfile}
                    disabled={description.length > 200}
                  >
                    <CheckIcon className="h-8 lg:mr-2" />
                    <span className="hidden lg:flex leading-8">Save</span>
                  </button>
                </div>
              ) : (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <LinkButton underlined onClick={() => navigate('/login')}>
                    Login/Signup
                  </LinkButton>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
