export const LOCAL_STORAGE_LOGIN_PREVIOUS_PAGE = 'slpp';
export const LOCAL_STORAGE_JWT = 'sjwt';
export const LOCAL_STORAGE_LAST_PROVIDER = 'slp';
export const PROVIDER_MAGIC_EMAIL = 'email';
export const PROVIDER_MAGIC_DISCORD = 'discord';
export const PROVIDER_METAMASK = 'metamask';
export const GRID_ITEM_SIZE_FULL = 'full';
export const GRID_ITEM_SIZE_NARROW = 'narrow';
export const GRID_ITEM_SIZE_MEDIUM = 'medium';
export const QUERY_PARAMETER_INVALID_TOKEN = 'invalidToken';
export const CONTENT_TYPE_EPUB = 'application/epub+zip';
export const TYPE_STORY = 'story';
export const TYPE_BOOK = 'book';
export const UNLOCKABLE_TYPE_AUDIO = 'audio';
export const UNLOCKABLE_TYPE_IMAGE = 'image';
export const UNLOCKABLE_TYPE_FILE = 'file';
export const UNLOCKABLE_TYPE_LINK = 'link';
export const STORY_STATUS_MINTED = 'MINTED';
export const MB_IN_B = 1048576;
export const READER_THEME_LIGHT = 'light';
export const READER_THEME_DARK = 'dark';
export const READER_THEME_SEPIA = 'sepia';
export const READER_THEME_GREY = 'grey';
export const READER_THEMES = {
  [READER_THEME_LIGHT]: {
    backgroundColor: '#FFFFFF',
    fontColor: '#111827',
    secondaryColor: '#6B7280',
  },
  [READER_THEME_DARK]: {
    backgroundColor: '#000000',
    fontColor: '#E5E7EB',
    secondaryColor: '#6B7280',
  },
  [READER_THEME_SEPIA]: {
    backgroundColor: '#FBF5EE',
    fontColor: '#1F2937',
    secondaryColor: '#6B7280',
  },
  [READER_THEME_GREY]: {
    backgroundColor: '#374151',
    fontColor: '#FFFFFF',
    secondaryColor: '#B2BAC1',
  },
};
export const READER_FONTS = [
  {
    family: 'Gelasio',
    provider: 'google',
  },
  {
    family: 'Bitter',
    provider: 'google',
  },
  {
    family: 'Open Sans',
    provider: 'google',
  },
  {
    family: 'Lato',
    provider: 'google',
  },
  {
    family: 'Roboto Mono',
    provider: 'google',
  },
  {
    family: 'Open Dyslexic',
    provider: 'custom',
    url: 'https://assets.readl.co/fonts.css',
  },
];
export const PREVIEW_FONT = 'Gelasio';
export const READER_DEFAULT_CONFIG = {
  theme: READER_THEME_LIGHT,
  fontFamily: READER_FONTS[0].family,
  fontSize: 100,
  lineHeight: 130,
};

export const MATIC_CONTRACT_EXCHANGE_ABI = [
  {
    inputs: [
      {
        internalType: 'string',
        name: 'name',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'version',
        type: 'string',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'makerAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'feeRecipientAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'makerAssetData',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'takerAssetData',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'senderAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'orderHash',
        type: 'bytes32',
      },
    ],
    name: 'Cancel',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'makerAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'feeRecipientAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'makerAssetData',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'takerAssetData',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'makerFeeAssetData',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'takerFeeAssetData',
        type: 'bytes',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'orderHash',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'takerAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'senderAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'makerAssetFilledAmount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'makerAssetPaid',
        type: 'uint256',
      },
    ],
    name: 'Fill',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order',
        name: 'order',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum LibOrder.OrderStatus',
            name: 'orderStatus',
            type: 'uint8',
          },
          {
            internalType: 'bytes32',
            name: 'orderHash',
            type: 'bytes32',
          },
          {
            internalType: 'uint256',
            name: 'orderTakerAssetFilledAmount',
            type: 'uint256',
          },
        ],
        internalType: 'struct LibOrder.OrderInfo',
        name: 'orderInfo',
        type: 'tuple',
      },
      {
        internalType: 'address',
        name: 'takerAddress',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
    ],
    name: '_assertFillableOrder',
    outputs: [],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order[]',
        name: 'orders',
        type: 'tuple[]',
      },
    ],
    name: 'batchCancelOrders',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'cancelOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    name: 'cancelled',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'currentContextAddress',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'exchangeHelpler',
    outputs: [
      {
        internalType: 'contract ExchangeHelpler',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    name: 'filled',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'assetProxyId',
        type: 'bytes4',
      },
    ],
    name: 'getAssetProxy',
    outputs: [
      {
        internalType: 'address',
        name: 'assetProxy',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getChainId',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'orderHash',
        type: 'bytes32',
      },
    ],
    name: 'getFilled',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'getOrderHashAndFilledAmount',
    outputs: [
      {
        internalType: 'bytes32',
        name: 'orderHash',
        type: 'bytes32',
      },
      {
        internalType: 'uint256',
        name: 'orderTakerAssetFilledAmount',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'getOrderInfo',
    outputs: [
      {
        components: [
          {
            internalType: 'enum LibOrder.OrderStatus',
            name: 'orderStatus',
            type: 'uint8',
          },
          {
            internalType: 'bytes32',
            name: 'orderHash',
            type: 'bytes32',
          },
          {
            internalType: 'uint256',
            name: 'orderTakerAssetFilledAmount',
            type: 'uint256',
          },
        ],
        internalType: 'struct LibOrder.OrderInfo',
        name: 'orderInfo',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'getOrderInfo2',
    outputs: [
      {
        components: [
          {
            internalType: 'enum LibOrder.OrderStatus',
            name: 'orderStatus',
            type: 'uint8',
          },
          {
            internalType: 'bytes32',
            name: 'orderHash',
            type: 'bytes32',
          },
          {
            internalType: 'uint256',
            name: 'orderTakerAssetFilledAmount',
            type: 'uint256',
          },
        ],
        internalType: 'struct LibOrder.OrderInfo',
        name: 'orderInfo',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'orderHash',
        type: 'bytes32',
      },
    ],
    name: 'isCanceled',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order',
        name: 'leftOrder',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order',
        name: 'rightOrder',
        type: 'tuple',
      },
      {
        internalType: 'bytes',
        name: 'leftSignature',
        type: 'bytes',
      },
      {
        internalType: 'bytes',
        name: 'rightSignature',
        type: 'bytes',
      },
    ],
    name: 'matchOrders',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'orderEpoch',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'assetProxy',
        type: 'address',
      },
    ],
    name: 'registerAssetProxy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        internalType: 'address',
        name: 'signer',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order',
        name: 'order',
        type: 'tuple',
      },
    ],
    name: 'verify',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order',
        name: 'leftOrder',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'makerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'takerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'feeRecipientAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'senderAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'makerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerAssetAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'makerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'takerFee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'expirationTimeSeconds',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'salt',
            type: 'uint256',
          },
          {
            internalType: 'bytes',
            name: 'makerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'makerFeeAssetData',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'takerFeeAssetData',
            type: 'bytes',
          },
        ],
        internalType: 'struct LibOrder.Order',
        name: 'rightOrder',
        type: 'tuple',
      },
      {
        internalType: 'bytes',
        name: 'leftSignature',
        type: 'bytes',
      },
      {
        internalType: 'bytes',
        name: 'rightSignature',
        type: 'bytes',
      },
    ],
    name: 'verify2',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

export const MATIC_CONTRACT_NFT_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'approved',
        type: 'bool',
      },
    ],
    name: 'ApprovalForAll',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]',
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'values',
        type: 'uint256[]',
      },
    ],
    name: 'TransferBatch',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'TransferSingle',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'string',
        name: 'value',
        type: 'string',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'URI',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'accounts',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]',
      },
    ],
    name: 'balanceOfBatch',
    outputs: [
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
    ],
    name: 'isApprovedForAll',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: 'amounts',
        type: 'uint256[]',
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes',
      },
    ],
    name: 'safeBatchTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes',
      },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'approved',
        type: 'bool',
      },
    ],
    name: 'setApprovalForAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId',
        type: 'bytes4',
      },
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_id',
        type: 'uint256',
      },
    ],
    name: 'totalSupply',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
