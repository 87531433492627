import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Container from '../../components/Containers';
import DisclosureReadl from '../../components/Disclosure/Disclosure';
import Footer from '../../components/Footer';
import Heading from '../../components/Typography';
import StartPublish from '../../components/StartPublish';
import { Text } from '../../components/Typography/Heading';
import UseCase from './UseCase';
import Roadmap from './Roadmap';
import JoinDiscord from '../../components/JoinDiscord/JoinDiscord';
import FooterCTA from '../../components/FooterCTA/FooterCTA';
import EmailCTA from './EmailCTA';
import {
  containerMotion,
  childVariantsAlways,
  childVariants,
} from '../../components/MotionAnimations/motion-variants';
import Features from './Features';
import Spacer from '../../components/Spacer';
import { Button } from '../../components/Button/Button';
import Parallax from '../../components/parallax/parallax';
import apiService from '../../services/apiService';

const blockchainCreators = [
  {
    title: 'Guarantee Lifetime Royalties',
    text: `Earn lifetime royalties from every sale on Readl Bookstore and the secondary market by publishing on the blockchain and securing your rights.`,
  },
  {
    title: 'Publish your work for free and enjoy low fees',
    text: `Forget about intermediaries, gatekeepers, and high commission rates. Readl only keeps 2% of the commission on your sales.`,
  },
  {
    title: 'Decentralized storage',
    text: `All your NFT files are stored in IPFS - a perpetual and decentralized stored solution. This means that they will always be accessible even if Readl doesn't exist anymore.`,
  },
  {
    title: 'Proof of ownership',
    text: `The blockchain provides an unchangeable record of ownership to prove authorship of a work, which is useful in disputes over ownership and copyright.`,
  },
  {
    title: 'Be a pioneer in web3 publishing',
    text: `Be at the forefront of technological innovation and help shape the future of the publishing industry.`,
  },
  {
    title: 'Collaboration made easy',
    text: `Split royalties among collaborators easily and automatically and ensure that everyone gets paid fairly for their work.`,
  },
  {
    title: 'Make it scarce or abundant',
    text: `Not every NFT needs to be unique. Adjust the number copies to your marketing strategy and multiple editions for your books and stories.`,
  },
];

const useCases = [
  {
    title: 'Flash fiction, serialized content or poetry',
    description:
      'Bring your imagination to life with a multimedia platform that encourages creative expression. Share your flash fiction, serialized content, and poetry with the world and showcase your writing talent.',
    cover: '/images/landing/usecase1.jpg',
    itemTitle: 'The ICGNU Odyssey Vol. 1',
    itemDescription:
      'A gripping odyssey that intertwines a diverse cast of 777 characters. In a world where each character is grappling with their own challenges, a mysterious recognition ignites a journey of collaboration and self-discovery.',
    itemLink: 'https://readl.co/collection/63dca5ca146601e7b2d20b6c',
    useList: [
      {
        text: '📚 Publish poems, stories, books, and comics in the same collection',
      },
      {
        text: '📑 Publish your book divided into multiple stories',
      },
      {
        text: '🤝 Collaborate and automatically split royalties with unlimited contributors',
      },
    ],
  },
  {
    title: 'Create a story for your NFT project or character',
    description: `We've got so many NFT avatars that are just begging to have stories told about them! Create an official narrative for your project and give your holders an entire universe to explore.`,
    cover: '/images/landing/usecase2.jpg',
    itemTitle: 'Web3 Tales',
    itemDescription:
      'This is the collection of stories about the lives, adventures, and sometimes deaths, of the characters who inhabited the worlds of your favorite NFTs collections. Each story is a unique 1/1 edition.',
    itemLink: 'https://readl.co/collection/63a32863d2b5e2d241903f8a',
    useList: [
      {
        text: '🙋‍♀️ Collaborate with your holders and writers to create plots for the characters',
      },
      {
        text: '📈 Increase the NFTs value by giving them an official backstory',
      },
      {
        text: '🎁 Reward your holders by sending them stories and books',
      },
    ],
  },
  {
    title: 'Create your books universe',
    description: `Bring your characters to life and provide unique content for your audience. Showcase the untold stories and behind-the-scenes details that bring your writing to life. Let your readers in on the creative process and offer exclusive insights that deepen their connection to your work.`,
    cover: '/images/landing/usecase3.jpg',
    itemTitle: 'Do you dare?',
    itemDescription:
      'A compilation of 10 short stories from the twisted mind of James D Armstrong. Some familiar concepts as well as some new ones.',
    itemLink:
      'https://readl.co/book/65525964018092524969961565155091778960598739047904341779609385365252989780168',
    useList: [
      {
        text: '🦸 Write backstories for your book characters',
      },
      {
        text: '⌛️ Imagine alternative timelines and stories of your books',
      },
      {
        text: '🔀 Explore different endings',
      },
      {
        text: '💥 Tell your audience what inspired you to write it',
      },
    ],
  },
  {
    title: 'Offer exclusive content to your readership',
    description: `With Readl, you can lock books, audios, images and any other kind of files only to collectors. Why not reward your collectors by offering them exclusive content if they collect? It's a great way to show your appreciation!`,
    cover: '/images/landing/usecase4.jpg',
    itemTitle: 'The Legend Of Cloud Niners?',
    itemDescription:
      'More than a traditional NFT, Cloud Niners is a multi-platform expedition released through NFTs, videos, photos, cryptic clues, and much more.',
    itemLink:
      'https://readl.co/story/45971232427478957730698653216425312320970941240266493570576412148976884645928',
    useList: [
      {
        text: '🔏 Enhance reader engagement by providing access to exclusive content through NFT unlockables',
      },
      {
        text: '🎨 Collaborate with artists to create unique artworks that owners can download',
      },
      {
        text: '💚 Offer free-to-access content but exclusive perks to incentivize collecting',
      },
    ],
  },
];

const steps = [
  {
    title: '1. Sign up and create your profile',
    text: 'Easily create your profile with your wallet, email or Discord.',
    img: '/images/landing/step-1.png',
  },
  {
    title: '2. Publish your book or story',
    text: 'Write your story with our easy to use editor or upload your EPUB file.',
    img: '/images/landing/step-2.png',
  },
  {
    title: '3. Sell on the marketplace',
    text: 'As soon as you publish you can list it and start selling it your readers',
    img: '/images/landing/step-3.png',
  },
];

function Step({ title, text, img }) {
  return (
    <Parallax>
      <div className="bg-gray-100 dark:bg-dark-800 text-center p-4 pt-8 md:px-6 xl:px-8 pb-28 rounded-xl space-y-3 relative mb-24 max-w-md mx-auto">
        <Heading size="5">{title}</Heading>
        <Text className="text-gray-600">{text}</Text>
        <Parallax>
          <img
            src={img}
            alt="Step 1"
            className="absolute left-0 right-0 top-0 max-w-sm mx-auto w-full filter drop-shadow-2xl"
          />
        </Parallax>
      </div>
    </Parallax>
  );
}

export default function ForCreators() {
  const [faqs, setFaqs] = useState([]);

  const getFaqs = async () => {
    const response = await apiService.get('/general/faqs/forAuthors');
    setFaqs(response);
  };

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <div>
      <div>
        <div className="fixed max-w-2xl md:bg-white md:dark:bg-dark-800 md:shadow-2xl bottom-4 left-0 right-0 mx-4 md:mx-auto rounded-full flex justify-between items-center px-6 md:pl-10 py-4 z-30">
          <Text className="hidden md:flex font-medium">
            Start publishing your literary NFTs for free
          </Text>
          <StartPublish className="flex w-full md:w-auto md:block">
            <Button
              className="shadow-[0_5px_60px_-5px] shadow-primary-500 inline-block border-primary-300 border rounded-full hover:scale-105 transition duration-300 w-full md:max-w-[160px]"
              rounded="full"
              width="full"
            >
              Get started
            </Button>
          </StartPublish>
        </div>
        {/* Hero Section */}
        <Container flex className="min-h-[80vh] justify-center" spacing="large">
          <motion.div
            variants={containerMotion}
            initial="initial"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid lg:grid-cols-2 items-center"
          >
            <motion.div
              variants={childVariants}
              className="lg:min-h-[80vh] flex items-center justify-center"
            >
              <img
                src="/images/landing/authors-hero.png"
                alt="Readl for creators"
                className="max-h-[400px] w-full object-cover lg:max-h-[650px] rounded-xl"
              />
            </motion.div>
            <Parallax className="lg:order-first">
              <motion.div variants={childVariants} className="lg:pr-24 mt-12">
                <div className="max-w-2xl">
                  <Heading
                    size="5"
                    className="uppercase before:inline-block before:w-8 before:bg-black dark:before:bg-dark-200 before:h-1 before:my-1 before:mr-3"
                  >
                    Readl for authors
                  </Heading>
                  <Heading size="1">Turn your readers into fans</Heading>
                  <Text size="2xl">
                    Experience, collect and trade digital stories while
                    supporting your favourite authors
                  </Text>
                </div>
              </motion.div>
            </Parallax>
          </motion.div>
        </Container>

        {/* How to get started */}
        <Container
          className="px-4"
          variants={containerMotion}
          initial="initial"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <div className="mx-auto max-w-2xl text-center my-20 space-y-2">
            <Heading>How to get started</Heading>
            <Text size="xl">
              Getting started on web3 doesn’t need to be complicated.
            </Text>
          </div>
          <div className="grid lg:grid-cols-3 gap-12">
            {steps.map((step) => (
              <Step title={step.title} text={step.text} img={step.img} />
            ))}
          </div>
        </Container>

        {/* Blockchain Pros */}
        <Container
          className="bg-black lg:rounded-3xl text-white py-16 lg:py-28 bg-[url('/public/images/landing/pattern-2.png')]"
          spacing="large"
          variants={containerMotion}
          initial="initial"
          whileInView="visible"
          viewport={{ once: true }}
          margin="flat-mobile"
        >
          <Parallax>
            <motion.div
              className="grid xl:grid-cols-2 xl:space-x-32 p-4 md:p-8 lg:p-12 mb-8"
              variants={childVariantsAlways}
            >
              <Heading size="1" className="text-holo xl:text-[58px]">
                What blockchain can do for authors
              </Heading>
              <Text size="2xl">
                NFTs aren’t just expensive images. They can be a lot more and
                authors can already start taking advantage of what it offers.
              </Text>
            </motion.div>
          </Parallax>
          <div className="grid md:grid-cols-3 xl:grid-cols-4 p-4 lg:p-12 gap-4 lg:gap-8">
            {blockchainCreators.map((text) => (
              <Parallax className="flex">
                <motion.div
                  className="space-y-4 bg-[#1A1A1A] rounded-xl p-6"
                  variants={childVariantsAlways}
                >
                  <Heading size="5" className="text-holo">
                    {text.title}
                  </Heading>
                  <Text>{text.text}</Text>
                </motion.div>
              </Parallax>
            ))}
            <Parallax>
              <motion.div
                className="flex items-center p-8"
                variants={childVariantsAlways}
              >
                <Text size="xl" className="text-holo font-bold">
                  Still a lot more do discover
                </Text>
              </motion.div>
            </Parallax>
          </div>
        </Container>

        {/* Features */}
        <Features />

        {/* EmailCTA */}
        <EmailCTA />

        {/* Roadmap */}
        <Roadmap />

        {/* Use Cases */}
        <Container
          variants={containerMotion}
          initial="initial"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <div className="max-w-3xl mx-auto text-center">
            <Heading size="1">Use cases and inspiration</Heading>
            <Text size="lg">Learn what you can do with Readl</Text>
          </div>
        </Container>
        {useCases.map((useCase, index) => (
          <div key={useCase.title}>
            <UseCase
              useCase={useCase}
              className={index % 2 !== 0 && 'lg:flex-row-reverse '}
            />
          </div>
        ))}

        <Spacer size="sm" />

        {/* FooterCTA */}
        <FooterCTA />

        {/* FAQs */}
        <Container
          width="sm"
          variants={containerMotion}
          initial="initial"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <Heading size="3" className="text-center mb-4">
            FAQs
          </Heading>
          {faqs.map((faq) => (
            <DisclosureReadl key={faq.question} item={faq} />
          ))}
          <JoinDiscord />
        </Container>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
}
