import { useState, useEffect } from 'react';
import { ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
// eslint-disable-next-line camelcase
import codes, { by639_1, by639_2T } from 'iso-language-codes';
import { ButtonIcon, Button } from '../Button/Button';
import Input from '../Forms/Forms';
import Toggle from '../Toggle';
import Spinner from '../Spinner/Spinner';
import { CONTENT_TYPE_EPUB, MB_IN_B } from '../../utils/constants';
import { isFileTooLarge } from '../../utils/utils';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import ModalBookReader from '../ModalBookReader';
import { ListBox } from '../Select/Select';

function UploadFile({ storyData, storyHandle, generateEpub }) {
  const { showSpinner, hideSpinner } = useSpinner();
  const [file, setFile] = useState(storyData.epub || null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: '',
    text: '',
  });
  const [isPrivate, setIsPrivate] = useState(storyData.isPrivate || true);
  const [allowDownload, setAllowDownload] = useState(storyData.allowDownload);
  const [displayPreview, setDisplayPreview] = useState(false);
  const [epubToPreview, setEpubToPreview] = useState();

  const parseEpub = async (epub) => {
    try {
      showSpinner('Getting epub info...');
      setIsUploadingFile(true);
      if (isFileTooLarge(epub.size, 'book')) {
        setIsUploadingFile(false);
        hideSpinner();
        return;
      }
      const formData = new FormData();
      formData.append('epub', epub);
      const [responseMetadata, responseCover] = await Promise.all([
        apiService.post('/epub/get-metadata', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        apiService.post('/epub/get-cover', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob',
        }),
      ]);

      storyHandle({
        ...storyData,
        imageFile: responseCover,
        imageFileUrl: URL.createObjectURL(responseCover),
        epubInfo: {
          title: responseMetadata.title || '',
          author: responseMetadata.author || '',
          isbn: responseMetadata.isbn || '',
          description: responseMetadata.comments || '',
        },
      });
      setFile(epub);
      setIsUploadingFile(false);
      // eslint-disable-next-line camelcase
      const codeLanguage = by639_2T[responseMetadata.languages];
      if (codeLanguage) {
        const { name: text, iso639_1: value } = codeLanguage;
        setSelectedLanguage({ text, value });
      }
    } catch (err) {
      toast.error('Error uploading epub');
      console.error(err);
    } finally {
      setIsUploadingFile(false);
      hideSpinner();
    }
  };

  const handleClick = (event) => {
    const typesToFunction = {
      [CONTENT_TYPE_EPUB]: parseEpub,
    };
    const userFile = event.target.files[0];
    const func = typesToFunction[userFile.type];
    if (!func) {
      return toast.error('File type not supported');
    }
    return func(userFile);
  };

  const cleanData = () => {
    setFile(null);
    setIsUploadingFile(false);
    storyHandle({
      ...storyData,
      imageFile: null,
      imageFileUrl: null,
      epubInfo: null,
    });
  };

  const previewOnClick = () => {
    const generatedEpub = generateEpub();
    setEpubToPreview(generatedEpub);
    setDisplayPreview(true);
  };

  useEffect(() => {
    storyHandle({
      ...storyData,
      isPrivate,
      allowDownload,
      epub: file,
      epubInfo: {
        ...storyData.epubInfo,
        // eslint-disable-next-line camelcase
        language: by639_1[selectedLanguage.value]?.iso639_1,
      },
    });
  }, [file, selectedLanguage, isPrivate, allowDownload]);

  useEffect(() => {
    const languages = codes
      .map((code) => ({
        value: code.iso639_1,
        text: code.name,
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1));
    setLanguagesOptions(languages);
  }, []);

  return (
    <>
      <div className="bg-gray-50 dark:bg-dark-800 rounded-md">
        {/* state: noFile */}
        {!file && (
          <div className="flex p-6 justify-between">
            <div className="flex flex-col gap-1">
              <span className="font-semibold dark:text-dark-100">
                Upload ePub
              </span>
              <span className="text-gray-600 dark:text-dark-400 text-sm">
                Upload your .epub book file
              </span>
            </div>
            <div>
              {!isUploadingFile && (
                <Button
                  variant="secondary"
                  size="sm"
                  flex
                  iconLeft={<ArrowUpTrayIcon />}
                  onClick={(e) => {
                    e.target.childNodes[1]?.click();
                  }}
                >
                  <input
                    onInput={handleClick}
                    id="epub-upload"
                    name="epub-upload"
                    type="file"
                    className="sr-only"
                    accept={CONTENT_TYPE_EPUB}
                  />
                  Upload
                </Button>
              )}
              {isUploadingFile && (
                <Button
                  variant="secondary"
                  size="sm"
                  flex
                  iconLeft={<Spinner />}
                >
                  Uploading
                </Button>
              )}
            </div>
          </div>
        )}
        {/* state: hasFile */}
        {file && !isUploadingFile && (
          <div className="bg-gray-50 dark:bg-dark-700 flex px-6 py-4 rounded-md">
            <div className="flex items-center w-10">
              <ButtonIcon
                icon={<TrashIcon />}
                variant="link"
                size="sm"
                onClick={cleanData}
              />
            </div>
            {/* File type section */}
            <div className="flex justify-between w-full items-center">
              <div className="flex items-center">
                <div className="h-[68px] w-[68px] flex justify-center items-center mr-4">
                  <img
                    src={storyData.imageFileUrl}
                    alt=""
                    className="h-full rounded-md"
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-md dark:text-dark-200">
                    {file.name}
                  </span>
                  <span className="text-gray-600 dark:text-dark-400 text-xs">
                    {Math.round(file.size / MB_IN_B)}MB
                  </span>
                </div>
              </div>
              <div>
                <Button
                  variant="secondary"
                  size="sm"
                  flex
                  onClick={previewOnClick}
                >
                  Preview
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="p-6 space-y-6 border-t dark:border-dark-700">
          <Input
            label="Book Title"
            placeholder="Alice in Wonderland"
            value={storyData.epubInfo?.title ?? ''}
            onChange={(e) => {
              storyHandle({
                ...storyData,
                epubInfo: { ...storyData.epubInfo, title: e.target.value },
              });
            }}
          />
          <Input
            label="Author"
            placeholder="John Doe"
            value={storyData.epubInfo?.author ?? ''}
            onChange={(e) => {
              storyHandle({
                ...storyData,
                epubInfo: { ...storyData.epubInfo, author: e.target.value },
              });
            }}
          />
          <ListBox
            label="Language"
            options={languagesOptions}
            selectedOption={selectedLanguage}
            setSelectedOption={setSelectedLanguage}
            placeholder="Language..."
          />
          <Input
            label="ISBN"
            placeholder="000-0-00-000000-0"
            value={storyData.epubInfo?.isbn ?? ''}
            onChange={(e) => {
              storyHandle({
                ...storyData,
                epubInfo: { ...storyData.epubInfo, isbn: e.target.value },
              });
            }}
          />
          <Toggle
            label="Gated"
            description="Only owners can read it."
            enabled={isPrivate}
            setEnabled={setIsPrivate}
          />
          <Toggle
            label="Allow download"
            description="Owners can download the file."
            enabled={allowDownload}
            setEnabled={setAllowDownload}
          />
        </div>
      </div>
      <ModalBookReader
        open={displayPreview}
        setOpen={setDisplayPreview}
        file={epubToPreview}
        title={storyData.epubInfo?.title}
      />
    </>
  );
}

export default UploadFile;
