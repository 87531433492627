import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Disclosure, Menu } from '@headlessui/react';
import { Bars3Icon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import LoginContext from '../../context/LoginContext';
import { LinkButton, ButtonIcon, Button, ButtonLink } from '../Button/Button';
import { getIpfsUrl } from '../../services/ipfsService';
import Link from '../Link';
import AvatarWithInfo from '../AvatarWithInfo';
import SlideOverProfile from './SlideOverProfile';
import Spinner from '../Spinner/Spinner';
import Dropdown from '../Dropdown';
import StartPublish from '../StartPublish';
import Container from '../Containers';
import ReadlLogo from '../../assets/images/ReadlLogo';
import ButtonBack from '../ButtonBack/ButtonBack';
import LogoReadlCircle from '../../assets/icons/LogoReadlCircle';
import { pathToPageName } from '../../utils/utils';
import { useTheme } from '../../context/ThemeContext';

const pathnamesWithoutStory = ['/create'];
const pathnameWithoutNewStory = ['/create'];

export function LinkMenu({ title, to, splitLocation, className, target }) {
  return (
    <div className="sm:space-x-8">
      {/* Current: "border-primary-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
      <Link
        to={to}
        target={target}
        className={
          (className,
          splitLocation !== '' && splitLocation === to.split('/')[1]
            ? 'border-primary-500 text-gray-900 dark:text-white inline-flex items-center px-1 pt-1 border-b-[3px] font-medium'
            : 'text-gray-600 dark:text-dark-300 inline-flex items-center px-1 pt-1 font-medium hover:scale-110 hover:text-black transition')
        }
      >
        {title}
      </Link>
    </div>
  );
}

function LinkMenuMobile({ title, to, splitLocation, className, target }) {
  return (
    <Disclosure.Button
      as={Link}
      href={to}
      target={target}
      className={
        (className,
        splitLocation !== '' && splitLocation === to.split('/')[1]
          ? 'bg-gray-50 border-primary-500 text-black-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
          : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium')
      }
    >
      {title}
    </Disclosure.Button>
  );
}

export default function Header({
  createStory,
  fixed = true,
  dropdownActions = [],
}) {
  const {
    address,
    userName,
    userMetadata,
    isLoadingUserLogin,
    isUserLoggedIn,
  } = useContext(LoginContext);
  const { theme, changeTheme } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    changeTheme(theme === 'dark' ? 'light' : 'dark');
  };

  return (
    <Disclosure
      as="nav"
      className={clsx(
        'bg-white w-full top-0 pt-2 z-50 dark:bg-dark-900 transition-[background-color] duration-200',
        {
          fixed: fixed === true,
        },
      )}
    >
      {({ open }) => (
        <>
          {/* Navbar for general pages and collections */}
          <Container spacing="none">
            <div className="relative flex justify-between h-16">
              {!pathnamesWithoutStory.filter((pathName) =>
                location.pathname.startsWith(pathName),
              ).length ? (
                <>
                  <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex-1 flex items-center justify-start">
                    <Link
                      to="/"
                      className="flex-shrink-0 hidden md:flex items-center ml-12 md:ml-0"
                    >
                      <ReadlLogo />
                    </Link>
                    <Link
                      to="/"
                      className="flex-shrink-0 flex md:hidden items-center ml-12 md:ml-0"
                    >
                      <LogoReadlCircle className="h-10 w-10" />
                    </Link>
                    <div className="hidden md:flex items-center justify-start ml-6 gap-2 lg:gap-6">
                      <LinkMenu
                        title="Discover"
                        to="/discover"
                        splitLocation={splitLocation[1]}
                      />
                      <LinkMenu
                        title="Bookstore"
                        to="/bookstore"
                        splitLocation={splitLocation[1]}
                      />
                      {isUserLoggedIn && (
                        <LinkMenu
                          title="For Authors"
                          to="/authors"
                          splitLocation={splitLocation[1]}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex flex-1 self-center">
                  <ButtonBack />
                </div>
              )}
              {isLoadingUserLogin === true && (
                <div className="flex text-center self-center">
                  <div className="h-6 w-6">
                    <Spinner size="xs" />
                  </div>
                  <p className="ml-2 mr-2 lg:block hidden text-secondary-600 dark:text-dark-300">
                    Checking login
                  </p>
                </div>
              )}

              {pathnameWithoutNewStory.filter((pathName) =>
                location.pathname.startsWith(pathName),
              ).length > 0 && (
                <div className="flex-[2] text-center self-center dark:text-dark-100">
                  <h6 className="text-center font-semibold text-lg">Create</h6>
                </div>
              )}
              {isUserLoggedIn &&
                pathnameWithoutNewStory.filter((pathName) =>
                  location.pathname.startsWith(pathName),
                ).length === 0 && (
                  <div className="items-center mr-3 sm:flex hidden sm:hidden">
                    <Link
                      to={`/${address}`}
                      className={
                        splitLocation[1] === address
                          ? 'border-primary-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-[3px] font-medium'
                          : 'text-gray-600 inline-flex items-center px-1 pt-1  font-medium'
                      }
                    >
                      Bookshelf
                    </Link>
                  </div>
                )}
              {!location.pathname.startsWith('/create') && (
                <div className="my-4 mr-3 pt-0.5">
                  <input
                    id="toggle"
                    className="toggle"
                    type="checkbox"
                    checked={theme === 'dark'}
                    onClick={handleThemeSwitch}
                  />
                </div>
              )}
              {/* eslint-disable-next-line no-nested-ternary */}
              {pathnameWithoutNewStory.filter((pathName) =>
                location.pathname.startsWith(pathName),
              ).length > 0 ? (
                <div className="flex flex-1 self-center justify-end space-x-2">
                  <div className="mt-2 mr-4 pt-0.5">
                    <input
                      id="toggle"
                      className="toggle"
                      type="checkbox"
                      checked={theme === 'dark'}
                      onClick={handleThemeSwitch}
                    />
                  </div>
                  <button
                    type="button"
                    className="py-1 px-1 lg:pl-2 lg:pr-3 flex bg-primary-500 rounded-full lg:rounded-lg font-semibold text-sm lg:text-base"
                    onClick={createStory}
                  >
                    <CheckIcon className="h-8 lg:mr-2" />
                    <span className="hidden lg:flex leading-8">Publish</span>
                  </button>
                </div>
              ) : (
                address && (
                  <div className="inset-y-0 -right-3 xs:right-0 flex items-center sm:static sm:inset-auto space-x-1 sm:space-x-3">
                    <StartPublish
                      gaLabel={`header_${pathToPageName(pathname)}`}
                    >
                      <Button
                        variant="primary"
                        iconLeft={<PlusIcon />}
                        size="sm"
                        flex
                        className="hidden md:flex"
                      >
                        Create
                      </Button>
                      <ButtonIcon
                        variant="primary"
                        icon={<PlusIcon />}
                        size="xs"
                        rounded="full"
                        flex
                        className="md:hidden"
                      />
                    </StartPublish>
                    <Link
                      to="/create"
                      className="py-0 px-0 sm:pl-2 sm:pr-3 flex bg-primary-500 rounded-full sm:rounded-lg font-semibold text-base items-center hidden"
                    >
                      <PlusIcon className="h-10 p-2 sm:mr-2" />
                      <span className="hidden sm:flex">Create</span>
                    </Link>
                    {/* Profile dropdown */}

                    <AvatarWithInfo
                      // TODO
                      name={userName}
                      // userName="18 stories · 9 books"
                      active
                      responsive
                      avatarSize="sm"
                      img={
                        userMetadata?.avatar && getIpfsUrl(userMetadata.avatar)
                      }
                      link={`/${address}`}
                    />
                    <Menu as="div" className="relative">
                      <SlideOverProfile />
                    </Menu>

                    {/* More dropdown */}
                    {dropdownActions.length > 0 && (
                      <div className="lg:hidden">
                        <Dropdown items={dropdownActions} />
                      </div>
                    )}
                  </div>
                )
              )}
              {!isLoadingUserLogin === true && !address && (
                <div className="right-0 flex items-center pr-2 sm:ml-6 sm:pr-0">
                  <div className="sm:hidden">
                    <ButtonLink size="xs" to="/authors">
                      Authors
                    </ButtonLink>
                  </div>
                  <div className="hidden sm:flex md:hidden">
                    <ButtonLink size="sm" to="/authors">
                      For Authors
                    </ButtonLink>
                  </div>
                  <div className="hidden md:flex">
                    <ButtonLink to="/authors">For Authors</ButtonLink>
                  </div>
                </div>
              )}
              {!isLoadingUserLogin === true && !address && (
                <div className="right-0 flex items-center pr-2 sm:ml-6 sm:pr-0">
                  <LinkButton
                    underlined
                    onClick={() => navigate('/login')}
                    className="text-sm md:text-base dark:text-white"
                  >
                    Login/Signup
                  </LinkButton>
                </div>
              )}

              {/* When signed in */}
            </div>
          </Container>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-4 space-y-1 shadow-lg z-20">
              <LinkMenuMobile
                title="Discover"
                to="/discover"
                splitLocation={splitLocation[1]}
              />
              <LinkMenuMobile
                title="Bookstore"
                to="/bookstore"
                splitLocation={splitLocation[1]}
              />
              <LinkMenuMobile
                title="For authors"
                to="/authors"
                splitLocation={splitLocation[1]}
              />
              <LinkMenuMobile
                title="About"
                to="/about-us"
                splitLocation={splitLocation[1]}
              />
              {isUserLoggedIn && (
                <LinkMenuMobile
                  title="Bookshelf"
                  to={`/${address}`}
                  splitLocation={splitLocation[1]}
                />
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
