import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { motion, useReducedMotion } from 'framer-motion';
import { useSpinner } from '../../context/SpinnerContext';
import apiService from '../../services/apiService';
import Slider from '../../components/Slider';
import GridItem from '../../components/GridStories/GridItem';
import Footer from '../../components/Footer';
import { ButtonLink } from '../../components/Button/Button';
import Container from '../../components/Containers';
import Heading from '../../components/Typography';
import { Text } from '../../components/Typography/Heading';
import { childVariantsAlways } from '../../components/MotionAnimations/motion-variants';
import Parallax from '../../components/parallax/parallax';
import { FeatureHighlight } from '../ForAuthors/Features';
import Link from '../../components/Link';
import MostRecent from '../../components/MostRecent/MostRecent';

const blogArticles = [
  {
    link: 'https://medium.com/readl-web3-storytelling/how-to-market-your-writing-project-in-the-web3-331ee1123566',
    tag: 'Web 3',
    title: 'How To Market Your Writing Project In Web3',
    text: `As an author, you decide to take the plunge and publish your next novel, short story, poem, or comic on the blockchain. You know all the potential benefits of publishing on Web3, and you’ve seen how some authors have sold out in just a few hours.`,
  },
  {
    link: 'https://medium.com/readl-web3-storytelling/how-authors-can-create-a-strong-community-on-web3-1c527788d580',
    tag: 'Community',
    title: 'How Authors Can Create a Strong Community on Web3',
    text: `As the world transitions to a more decentralized environment and blockchain-based technologies become increasingly popular, it’s essential to update your strategy for building a community.
    `,
  },
  {
    link: 'https://medium.com/readl-web3-storytelling/7-reasons-to-buy-books-on-the-blockchain-f67f60679e00',
    tag: 'NFT Books',
    title: '7 Reasons to Buy Books on the Blockchain',
    text: `Reading books on the blockchain is a relatively new concept that is gaining popularity as more and more people are discovering the benefits of owning a digital book that is stored on a decentralized network.`,
  },
];

function BlogArticle({ title, tag, text, link }) {
  return (
    <Link href={link} target="_blank">
      <div className="space-y-4 hover:bg-gray-100 dark:hover:bg-dark-800 p-4 lg:p-8 rounded-2xl transition duration-300">
        <div className="space-y-2">
          <Heading
            size="5"
            divider="before"
            dividerColor="bg-gray-300"
            className="text-gray-400"
          >
            {tag}
          </Heading>
          <Heading size="4">{title}</Heading>
        </div>
        <Text className="line-clamp-4 text-gray-600">{text}</Text>
      </div>
    </Link>
  );
}

function Landing() {
  const { showSpinner, hideSpinner } = useSpinner();
  const [content, setContent] = useState({
    collections: [],
    stories: [],
    lastCollected: [],
    hotTagsStories: {},
    extras: {},
  });
  const shouldReduceMotion = useReducedMotion();
  const containerMotion = {
    initial: {
      opacity: 0,
      y: shouldReduceMotion ? 0 : 80,
      transition: { duration: 0 },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, staggerChildren: 0.5 },
    },
  };

  const childVariants = {
    initial: { opacity: 0, y: shouldReduceMotion ? 0 : 25 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.2, duration: 1 } },
  };

  const blockchainCreators = [
    {
      title: '👩‍💻 Support authors directly',
      text: `With no intermediaries involved, authors earn a fairer share of the profits.`,
    },
    {
      title: '🤝 Read and resell',
      text: `Discover a new level of ownership where you can resell or gift when you're done.`,
    },
    {
      title: '📱 Read from anywhere',
      text: `Seamlessly switch between laptop or mobile devices and enjoy your stories and books.`,
    },
    {
      title: '🔑 Login with Metamask or email',
      text: `Whether you're new or an expert in Web3, Readl has you covered. Easily login with your email, Discord or wallet.`,
    },
    {
      title: '🤘 Free transactions for free items',
      text: `Collect free literary NFTs without needing to cover the gas fees. We do it for you!`,
    },
    {
      title: '💳 Buy with credit card',
      text: `Purchase your desired books and stories securely with your credit card.`,
    },
  ];

  const getContent = async () => {
    try {
      showSpinner();
      const dataContent = await apiService.get('/general/homepage');
      setContent(dataContent);
    } catch (err) {
      console.error(err);
      toast.error('Error retrieving home page');
    } finally {
      hideSpinner();
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <div>
      <Container
        className="bg-[url('/public/images/landing/home-hero.jpg')] min-h-[80vh] lg:rounded-3xl items-center bg-opacity-40 px-4 bg-infinite-zoom relative"
        flex
        margin="flat-mobile"
      >
        <div className="absolute bg-black bg-opacity-40 inset-0 z-0 lg:rounded-3xl" />
        <div className="max-w-3xl mx-auto text-center text-white space-y-6 z-10">
          <Heading size="1">Enter a new era of storytelling</Heading>
          <Text size="2xl">
            Experience, collect and trade digital stories while supporting your
            favourite authors
          </Text>
          <div className="flex flex-wrap gap-4 justify-center items-center">
            <ButtonLink to="/authors" size="lg">
              Readl for authors
            </ButtonLink>
            <ButtonLink to="/discover" size="lg" variant="secondary">
              Discover
            </ButtonLink>
          </div>
        </div>
      </Container>
      <MostRecent
        lastCollected={content.lastCollected}
        lastListed={content.lastListed}
        containerMotion={containerMotion}
      />
      <Container
        className="bg-black lg:rounded-3xl text-white py-16 lg:py-28 bg-[url('/public/images/landing/pattern-2.png')]"
        spacing="xl"
        variants={containerMotion}
        initial="initial"
        animate="visible"
        margin="flat-mobile"
      >
        <motion.div
          className="grid xl:grid-cols-2 xl:space-x-8 p-4 sm:p-8 lg:p-12 mb-8"
          variants={childVariantsAlways}
        >
          <div>
            <Parallax className="xl:pr-16 mb-8 xl:mb-0">
              <img
                src="/images/landing/reader-readers.png"
                className="w-full"
                alt="Collect and Reader"
              />
            </Parallax>
          </div>
          <div>
            <Parallax className="sm:text-center xl:text-left">
              <Heading size="1">📖 💎</Heading>
              <Heading size="1" gradient>
                Read and Collect
              </Heading>
              <Text size="2xl">
                Unlock the power of Literary NFTs and build your collection
                today
              </Text>
              <ButtonLink to="/discover" variant="secondary" className="mt-6">
                Discover
              </ButtonLink>
            </Parallax>
          </div>
        </motion.div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 p-4 lg:p-12 gap-4 lg:gap-8">
          {blockchainCreators.map((text) => (
            <Parallax>
              <motion.div
                className="space-y-4 bg-[#1A1A1A] rounded-xl p-6"
                variants={childVariantsAlways}
              >
                <Heading size="5">{text.title}</Heading>
                <Text>{text.text}</Text>
              </motion.div>
            </Parallax>
          ))}
        </div>
        <div className="px-4 mt-16 pb-8">
          <Parallax>
            <div className="max-w-4xl mx-auto text-center space-y-4">
              <Heading size="2">
                Get started with a{' '}
                <span className="text-primary-500">free</span> NFT book
              </Heading>
              <Text size="xl">
                Experience what if feels like to own your books and stories.
              </Text>
            </div>
          </Parallax>
          <Parallax>
            <motion.div
              className="bg-[#141414] rounded-xl py-4 mt-16"
              initial="initial"
              animate="visible"
              variants={containerMotion}
              viewport={{ once: true }}
            >
              <Slider
                className="w-full"
                classNameSlider="!px-8 !py-4 !pr-4"
                slidesPerView={1.1}
                navigation
                slidesPerViewBreakpoints={{
                  xs: 1.4,
                  sm: 2.5,
                  md: 2.5,
                  lg: 3.5,
                  xl: 4.5,
                  '2xl': 4.5,
                }}
                items={content.theLibrary?.stories.map((story) => (
                  <GridItem data={story} />
                ))}
              />
            </motion.div>
          </Parallax>
          <Parallax className="text-center -mt-12" offset={-50}>
            <ButtonLink
              to={`/collection/${content.theLibrary?.collectionId}`}
              size="lg"
              className="shadow-[0_5px_60px_-5px] shadow-primary-500 inline-block border-primary-300 border rounded-lg hover:scale-105 transition duration-300"
            >
              Get a free NFT book
            </ButtonLink>
          </Parallax>
        </div>
      </Container>
      <Container
        variants={containerMotion}
        initial="initial"
        whileInView="visible"
        viewport={{ once: true }}
        className="z-10 relative"
        spacing="xl"
      >
        <Parallax variants={childVariants}>
          <div className="max-w-2xl text-center mx-auto space-y-4">
            <Heading size="5" className="uppercase">
              Readl for <span className="text-primary-500">authors</span>
            </Heading>
            <Heading size="1">Publish your literary content as NFTs</Heading>
            <Text size="xl">
              We&apos;re reimagining the publishing model and helping authors
              grow their readership by making it easy to manage, distribute and
              monetize their content.
            </Text>
          </div>
        </Parallax>
      </Container>
      <Container
        className="bg-black lg:rounded-3xl text-white p-4 lg:px-20 lg:pt-32 z-0 pb-24 lg:pb-12 bg-[url('/public/images/landing/pattern-2.png')]"
        spacing="large"
        variants={containerMotion}
        initial="initial"
        animate="visible"
        viewport={{ once: true }}
        margin="flat-mobile"
      >
        <div className="grid xl:grid-cols-2">
          <div className="-mt-32 xl:-mt-64 xl:-mb-64 xl:order-last">
            <Parallax offset={-50}>
              <img
                src="/images/landing/authors-house.png"
                alt="Authors house"
                className="xl:scale-[140%] max-w-2xl mx-auto w-full"
              />
            </Parallax>
          </div>
          <div>
            <Parallax className="flex items-start flex-wrap xl:flex-col gap-3">
              <FeatureHighlight
                icon="💎"
                text="Free and only 2% sales fee"
                dark
              />
              <FeatureHighlight
                icon="🎁"
                text="Add unlockable content to your NFTs"
                dark
              />
              <FeatureHighlight
                icon="📖"
                text="Create NFT books from epubs"
                dark
              />
              <FeatureHighlight
                icon="🔐"
                text="Write short stories, poems and more with gated content"
                dark
              />
            </Parallax>
            <Parallax className="text-center xl:text-left">
              <ButtonLink
                to="/authors"
                size="lg"
                className="shadow-[0_5px_60px_-5px] shadow-primary-500 inline-block border-primary-300 border rounded-lg hover:scale-105 transition duration-300 xl:mt-12"
              >
                Readl for authors
              </ButtonLink>
            </Parallax>
          </div>
        </div>
      </Container>
      <Container>
        <div className="grid lg:grid-cols-2 gap-6 lg:gap-12 lg:px-12">
          <div className="sm:p-8">
            <Heading>✍️</Heading>
            <Heading>Handpicked articles on web 3 writing</Heading>
          </div>
          {blogArticles.map((article) => (
            <BlogArticle
              title={article.title}
              tag={article.tag}
              link={article.link}
              text={article.text}
            />
          ))}
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default Landing;
