import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import Link from '../Link';
import { ButtonLink } from '../Button/Button';

function Dropdown({ items, variantButton = 'link' }) {
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button
          as={ButtonLink}
          variant={variantButton}
          size="sm"
          rounded="full"
          icon={<EllipsisVerticalIcon />}
        />
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {items.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <div>
                  {item.href && (
                    <Link
                      to={item.href}
                      className={`${
                        active && 'bg-gray-100'
                      } group flex w-full items-center px-2 py-2 text-sm text-gray-700`}
                    >
                      {item.name}
                    </Link>
                  )}
                  {item.callback && (
                    <button
                      type="button"
                      className={`${
                        active && 'bg-gray-100'
                      } group flex w-full items-center px-2 py-2 text-sm text-gray-700`}
                      onClick={item.callback}
                    >
                      {item.name}
                    </button>
                  )}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default Dropdown;
