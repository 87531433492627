import { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  ArrowDownTrayIcon,
  BookOpenIcon,
  DocumentArrowDownIcon,
  LinkIcon,
  LockClosedIcon,
  PhotoIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/24/outline';
import axios from 'axios';
import {
  MB_IN_B,
  TYPE_BOOK,
  UNLOCKABLE_TYPE_AUDIO,
  UNLOCKABLE_TYPE_FILE,
  UNLOCKABLE_TYPE_IMAGE,
  UNLOCKABLE_TYPE_LINK,
} from '../../utils/constants';
import Heading from '../Typography';
import { ButtonLink } from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';

export default function Unlocks({ item, isOwner }) {
  const [allowDownload, setAllowDownload] = useState(false);
  const [file, setFile] = useState('');

  useEffect(() => {
    const func = async () => {
      if (item.type !== UNLOCKABLE_TYPE_LINK) {
        setFile(item.file);
      } else {
        const { data } = await axios.get(item.file);
        setFile(data.link);
      }
    };
    func();
  }, [item]);

  useEffect(() => {
    if (item) {
      // TODO: The logic to allow download should be the same as the logic to allow read
      // Check if we can have this logic in only one place
      setAllowDownload(item.allowDownload && (isOwner || !item.isPrivate));
    }
  }, [item, isOwner]);

  const download = () => {
    if (allowDownload) {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', `${item.name}.epub`);
      // const href = URL.createObjectURL(getIpfsUrl(item.contentHash));
      aElement.href = item.file;
      // aElement.setAttribute('href', href);
      aElement.setAttribute('target', '_blank');
      aElement.click();
      // URL.revokeObjectURL(href);
    }
  };

  return (
    <div className="p-2 px-4 mb-2 border border-gray-200 dark:border-dark-700 rounded-xl hover:shadow-xl duration-500">
      <div
        className={clsx('flex  space-x-4 rounded-xl items-center mb-2 pt-1')}
      >
        {item.type === TYPE_BOOK && item.image && (
          <div className="w-16 min-w-fit -ml-2">
            <img
              alt={item.name}
              src={item.image}
              className="rounded-md max-h-[90px] mx-auto"
            />
          </div>
        )}
        <div className="flex flex-col sm:flex-row w-full sm:items-center pt-1">
          <div className="w-full">
            {/* TODO: We should abstract this badge logic in a component */}
            <div className="flex text-sm capitalize items-center dark:text-dark-400">
              {item.type === TYPE_BOOK && (
                <BookOpenIcon className="w-4 h-4 mr-1" />
              )}
              {item?.type === UNLOCKABLE_TYPE_AUDIO && (
                <SpeakerWaveIcon className="w-4 h-4 mr-1" />
              )}
              {item?.type === UNLOCKABLE_TYPE_FILE && (
                <DocumentArrowDownIcon className="w-4 h-4 mr-1" />
              )}
              {item?.type === UNLOCKABLE_TYPE_IMAGE && (
                <PhotoIcon className="w-4 h-4 mr-1" />
              )}
              {item?.type === UNLOCKABLE_TYPE_LINK && (
                <LinkIcon className="w-4 h-4 mr-1" />
              )}
              <span>{item.type}</span>
            </div>
            <Heading size="6" className="mt-2 mb-1 break-words line-clamp-2">
              {item.title || item.name}
            </Heading>
            <ul className="text-xs text-gray-500 flex list-disc space-x-4 leading-5 marker:text-gray-300 dark:marker:text-dark-500 dark:text-dark-400 flex-wrap mb-1 sm:mb-0">
              {item.author && (
                <li className="first:list-none !mr-2">{item.author}</li>
              )}
              {item.allowDownload ? (
                <li className="first:list-none !mr-2">Downloadable</li>
              ) : (
                <li className="first:list-none !mr-2">Access Online</li>
              )}
              {item?.type === TYPE_BOOK && (
                <li className="first:list-none !mr-2">EPUB</li>
              )}
              {item?.type === UNLOCKABLE_TYPE_AUDIO && (
                <li className="first:list-none !mr-2">MP3</li>
              )}
              {item?.type === UNLOCKABLE_TYPE_IMAGE && (
                <li className="first:list-none !mr-2">PNG</li>
              )}
              {item?.type === UNLOCKABLE_TYPE_FILE && (
                <li className="first:list-none !mr-2">ZIP</li>
              )}
              {item?.size && (
                <li className="first:list-none !mr-2">
                  {Math.round(item.size / MB_IN_B)}MB
                </li>
              )}
            </ul>
          </div>
          {!isOwner && item.isPrivate && (
            <div className="flex w-full sm:w-fit">
              <div className="flex items-center w-full p-2 sm:p-0 bg-gray-100 rounded-full">
                <Tooltip tooltipText="Collect to unlock">
                  <LockClosedIcon className="w-4 h-4 sm:p-3 sm:w-11 sm:h-11" />
                </Tooltip>
                <span className="sm:hidden pl-2 text-xs">
                  Collect to unlock
                </span>
              </div>
            </div>
          )}
          {(isOwner || !item.isPrivate) &&
            (allowDownload || item.type === UNLOCKABLE_TYPE_LINK) && (
              <div className="flex space-x-2 w-fit">
                {item.type === UNLOCKABLE_TYPE_LINK ? (
                  <ButtonLink
                    size="sm"
                    variant="secondary"
                    to={file}
                    target="_blank"
                    flex
                  >
                    Visit
                  </ButtonLink>
                ) : (
                  <ButtonLink
                    size="sm"
                    variant="secondary"
                    onClick={download}
                    flex
                    iconLeft={<ArrowDownTrayIcon />}
                  >
                    Download
                  </ButtonLink>
                )}
              </div>
            )}
        </div>
      </div>

      {item?.type === UNLOCKABLE_TYPE_AUDIO && (isOwner || !item.isPrivate) && (
        <div>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio
            controls
            controlsList="nodownload noplaybackrate"
            className="w-full"
          >
            <source src={item.file} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  );
}
