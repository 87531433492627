import { Tab } from '@headlessui/react';
import Countdown from 'react-countdown';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import Container from '../../components/Containers';
import Heading from '../../components/Typography';
import Button from '../../components/Button';
import { Text } from '../../components/Typography/Heading';
import DisclosureReadl from '../../components/Disclosure/Disclosure';
import TabsPill from '../../components/Tab/TabPill';
import IconTablerTwitter from '../../assets/icons/IconTablerTwitter';
import MailchimpForm from '../../components/MailchimpForm/MailchimpForm';
import Slider from '../../components/Slider';
import GridItem from '../../components/GridStories/GridItem';
import GridItemReveal from '../../components/GridStories/GridItemReveal';

const countdownRenderer = ({ days, hours, minutes, seconds }) => (
  <div className="text-4xl font-bold flex space-x-4 justify-center">
    <div>
      {days}
      <span className="text-lg">D</span>
    </div>
    <div>
      {hours}
      <span className="text-lg">H</span>
    </div>
    <div>
      {minutes}
      <span className="text-lg">MIN</span>
    </div>
    <div className="w-20">
      {seconds}
      <span className="text-lg">S</span>
    </div>
  </div>
);

function FlowerGirls({
  revealDate,
  isRevealed,
  checkIsRevealed,
  stories,
  twitterLink,
  formLink,
  formDateEnabled,
}) {
  const statsText = [
    {
      stat: '10',
      text: `plots by holders`,
      key: 1,
    },
    {
      stat: '10',
      text: `stories by authors`,
      key: 2,
    },
    {
      stat: '100',
      text: `total NFTs`,
      key: 3,
    },
  ];

  const timelineText = [
    {
      date: '9 - 25 Feb',
      title: `💡 Call for plot ideas`,
      text: 'Calling all FG holders to submit their plot. To all others: join the FG story craze and cast your vote. Let the best 10 tales win!',
      key: 1,
    },
    {
      date: '27 Feb - 10 March',
      title: `✍️ Call for authors`,
      text: 'Calling all writers! Become a Flower Girl Story Creator: apply to bring one of the most voted plots to life!',
      key: 2,
    },
    {
      date: '25 March',
      title: `📥 Stories delivery`,
      text: 'Time for the selected authors to pick up their pen and craft compelling tales in the world of Flower Girls!',
      key: 3,
    },
    {
      date: '3 April',
      title: `💡 Stories publication`,
      text: 'Grab a coffee, tea, or wine, find a cozy spot, and begin collecting and reading the ten stories written by the community.',
      key: 4,
    },
  ];

  const tabsFaqs = [
    {
      id: 'Collection',
      title: 'Collection',
      component: 'Collection',
    },
    {
      id: 'Readl',
      title: 'Readl',
      component: 'Readl',
    },
  ];

  const faqsCollection = [
    {
      question: 'I’m a holder, how do I enter?',
      answer: `Flower Girls holders can enter by tweeting an image of the Flower Girl that inspired your backstory, alongside your backstory / plot idea. Please tag @flowergirlsnft @readl_co in your tweet, and use the hashtag #FlowerGirlStories. One entry per person.`,
    },
    {
      question: 'I’m a holder and an author, can I write my own story?',
      answer:
        'Yes you can! Please fill in the form and specify you’re the owner in the comment section.',
    },
    {
      question: 'What criteria must the stories meet?',
      answer:
        'The story must follow the plot the author has been matched with. Additionally, Flower Girl and Readl teams will review every story before publication and will have right to discard any story that do not respect Readl’s Terms&Conditions and Flower Girls community guidelines.',
    },
    {
      question: 'How are authors selected?',
      answer:
        'Eligible authors will be chosen and paired by the Flower Girls and Readl team based on the storytelling skills and creativity they demonstrate in their application.',
    },
  ];

  const faqsText = [
    {
      question: 'What is an NFT?',
      answer: `A non-fungible token (NFT) is a unique digital identifier that cannot be copied, substituted, or subdivided, that is recorded in a blockchain, and that is used to certify authenticity and ownership.\nThe ownership of an NFT is recorded in the blockchain and can be transferred by the owner, allowing NFTs to be sold and traded.\nNFTs can be created by anybody, and require few or no coding skills to create. NFTs typically contain references to digital files such as photos, videos, and audio. Because NFTs are uniquely identifiable assets, they differ from cryptocurrencies, which are fungible`,
    },
    {
      question: 'In which network is Readl built',
      answer:
        'Readl is built on Polygon, a layer 2 built on top of Ethereum. We decided to go for Polygon because the very low fees for transactions and also because of their pledge to be environmentally friendly.',
    },
    {
      question: 'Does Readl have fees?',
      answer: `It's free to publish on Readl.\nReadl only keeps a 2% commission fee on every transaction, so only when the NFTs are sold.`,
    },
    {
      question: 'What can I publish on Readl',
      answer: `On Readl you can publish literary content such as books, comics, short stories, poems, magazines, etc.\nReadl supports multiple file formats: .ePub (recommended for long written content), .jpg, .png and .gif.\nUpcoming supported formats are: .mp3, .mp4.`,
    },
    {
      question: 'How do I create an account on Readl',
      answer: `To sign up for a Readl account, you can either link your Metamask or create an account with your email address or Discord username. In this case, a wallet will be automatically created.`,
    },
  ];

  const containerMotion = {
    initial: { opacity: 0, transition: { duration: 1 } },
    visible: {
      opacity: 1,
      transition: { duration: 0.5, staggerChildren: 0.3 },
    },
  };

  const handleJoinOnTwitter = () => {
    window.open(twitterLink, '_blank');
  };

  const handleFormOpens = () => {
    window.open(formLink, '_blank');
  };

  return (
    <>
      {/* Flower Girls Section */}
      <Container spacing="large">
        <div className="sm:w-8/12 lg:w-1/2 mx-auto text-center space-y-4">
          <span className="block w-24 bg-gray-200 h-0.5 mx-auto my-12" />
          <Heading size="2" className="max-w-md mx-auto">
            Enter the world of Flower Girls
          </Heading>
          <Text size="lg">
            <p className="mb-4">
              Welcome to an exciting world of adventure and imagination… the
              stories behind Flower Girls!
            </p>
            <p className="mb-4">
              <strong>#FlowerGirlStories</strong> is a unique collaboration
              between the creative minds of Flower Girls holders across the
              globe and the Readl community of storytellers.
            </p>
            <p>
              We invite all FG holders to share their favorite character&lsquo;s
              backstory and plot ideas, by tweeting a picture of the FG that
              they own along with a brief summary of her backstory. Tag{' '}
              <strong>@flowergirlsnft</strong> and Readl, and use the hashtag{' '}
              <strong>#FlowerGirlStories</strong> for your entry to be counted.
            </p>
          </Text>
          <div className="py-8">
            <div className="grid xs:grid-cols-3 gap-4">
              {statsText.map((text) => (
                <div key={text.key}>
                  <span className="font-bold text-5xl">{text.stat}</span>
                  <p className="text-lg">{text.text}</p>
                </div>
              ))}
            </div>
          </div>
          <Text size="lg">
            <p className="mb-4">
              As determined by likes, the ten most popular entries will be
              selected and transformed into full-fledged stories by the talented
              community of writers at Readl.
            </p>
            <p className="mb-4">
              Ten stories will be chosen and sold as official Flower Girls
              stories on Readl, adding even more depth and character to this
              already captivating world.
            </p>
            <p>
              Unleash your imagination and get ready to share your story! Join
              the #FlowerGirlStories movement today.
            </p>
          </Text>
        </div>
        <div className="flex justify-center mt-8">
          <Button
            variant="dark"
            flex
            iconLeft={<IconTablerTwitter />}
            onClick={handleJoinOnTwitter}
          >
            Join on Twitter
          </Button>
        </div>
      </Container>
      <Container spacing="large">
        <Heading size="3" className="text-center mb-8">
          Timeline
        </Heading>
        <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-6 relative">
          {timelineText.map((text) => (
            <div
              className="text-center bg-[#FAEFF8] p-8 rounded-xl space-y-2"
              key={text.key}
            >
              <span className="font-bold text-[#77476E]">{text.date}</span>
              <Heading size="5">{text.title}</Heading>
              <p>{text.text}</p>
            </div>
          ))}

          <img
            src="/images/flower-girls/butterfly-FG.png"
            alt="butterfly"
            className="absolute xl:left-[20%] rotate-[-80deg] xl:-top-24 -top-32 scale-75 md:scale-100 -z-10 md:z-0"
          />
        </div>
      </Container>
      <Container>
        <div className="grid xl:grid-cols-2">
          <div className="space-y-6 pb-12 sm:p-16 prose text-black mx-auto xl:mx-0 order-last">
            <Heading size="3" className="m-0">
              Are you a Flower Girls holder?
            </Heading>
            <Text size="lg">
              <p>
                FG holders, now’s your time to get creative! Kickstarting 3rd
                Feb 2023, we’re looking for plot ideas and backstories of your
                favorite Flower Girl… celebrating #NationalStoryTellingWeek with
                Readl!
              </p>
              <div className="px-6 pt-1 pb-4 bg-[#FAEFF8] rounded-lg text-lg">
                <p className="mb-2">
                  <strong>To enter:</strong> Tweet @flowergirlsnft & @readl_co
                  with a summary of your Flower Girl’s backstory / your plot
                  idea for a story about her, attach an image of the Flower Girl
                  who’s inspired you to write about her and include the hashtag{' '}
                  <strong>#FlowerGirlStories</strong>.
                </p>
                <Button
                  variant="dark"
                  flex
                  iconLeft={<IconTablerTwitter />}
                  className="pt-4"
                  size="sm"
                  onClick={handleJoinOnTwitter}
                >
                  Join on Twitter
                </Button>
              </div>
            </Text>
            <Text size="lg">
              <p>
                Be sure to follow both Twitter accounts, so you don’t miss out
                on any updates.
              </p>
              <p>
                <strong>
                  The 10 winning stories will be published via Readl on April
                  3rd.
                </strong>
              </p>
              <p>
                The stories will be viewable by everyone once published, but you
                can also purchase them on Readl to hold a piece of the story
                yourself and support the holder / charity. Any sales royalties
                will be split between you, a charity of your choice and the
                author who wrote your #FlowerGirlStory
              </p>
            </Text>
          </div>
          <div className="self-center xl:order-last">
            <div>
              <img
                src="https://readl.mypinata.cloud/ipfs/QmUaL5zLMuDvuzwv1AnLX77JNRtbnnAtfLK66nKzma6see"
                alt="Flower Girls Collection"
                className="w-full rounded-2xl mx-auto mb-8 sm:mb-0 max-w-xl xl:max-w-none"
              />
            </div>
          </div>
        </div>
      </Container>
      <Container spacing="large">
        <div className="bg-[url('/public/images/flower-girls/fg-bg.jpg')] bg-no-repeat bg-cover rounded-xl min-h-[400px] md:min-h-[800px]" />
        <div className="bg-white px-6 py-10 sm:p-20 rounded-xl space-y-6 max-w-4xl mx-auto xl:mr-24 xl:ml-auto -mt-[40px] md:-mt-[540px] shadow-2xl relative">
          <Heading size="3">Join as storyteller</Heading>
          <Text size="lg">
            <p>
              Are you an author seeking an exciting writing adventure? Join this
              unique initiative and showcase your talent to the Flower Girls
              community and the broader Web3 world.
            </p>
          </Text>
          <Text size="lg">
            <ul className="list-disc ml-4 space-y-2 md:space-y-4 md:columns-2 text-base gap-14">
              <li>
                Let us know which story/stories you want to tell by filling in{' '}
                <strong>this</strong> form below (the form will open on February
                27th)
              </li>
              <li>
                We&rsquo;ll review the application and contact you on Twitter
                before March 10th.
              </li>
              <li>You&rsquo;ll have till March 25th to submit your story.</li>
              <li>
                The story must follow the plot the author has been matched with.
              </li>
              <li>The stories will be published on April 3rd.</li>
              <li>
                The Flower Girls team will publish and list the stories, and
                distribute the royalties as indicated below:
                <ul className="list-disc ml-6 space-y-2 mt-2">
                  <li>70% to the author</li>
                  <li>10% to the NFT holder</li>
                  <li>20% to a NFT holder&rsquo;s charity of choice</li>
                </ul>
              </li>
            </ul>
          </Text>
          <div className="p-8 bg-[#FAEFF8] rounded-lg text-[18px] leading-8">
            🖼️ The ten authors taking part will also receive a{' '}
            <strong>Flower Girl Special Edition</strong> as a reward!
          </div>
          <Button
            variant="dark"
            flex
            className={clsx('pt-4', {
              'opacity-50': DateTime.now() < DateTime.fromISO(formDateEnabled),
            })}
            disabled={DateTime.now() < DateTime.fromISO(formDateEnabled)}
            onClick={handleFormOpens}
          >
            {DateTime.now() < DateTime.fromISO(formDateEnabled)
              ? `Form opens on ${DateTime.fromISO(formDateEnabled).toFormat(
                  'd',
                )}th Feb`
              : 'Join as storyteller'}
          </Button>
          <img
            src="/images/flower-girls/bird-FG.png"
            alt="fg-bird"
            className="absolute right-0 lg:-right-12 -top-24 md:-top-12"
          />
        </div>
      </Container>
      <Container spacing="large">
        <div className="mx-auto xl:w-3/12 text-center space-y-4 mb-8">
          <Heading>Winning Plots</Heading>
          <Text size="lg" className="text-gray-600">
            Most voted plots on Twitter will be announced on{' '}
            {DateTime.fromISO(formDateEnabled).toFormat('d')} Feb.
          </Text>
        </div>
        <Slider
          className="w-full"
          classNameSlider="!px-8 !py-4 !pr-4"
          slidesPerView={1.1}
          navigation
          slidesPerViewBreakpoints={{
            xs: 1.4,
            sm: 2.5,
            md: 2.5,
            lg: 3.5,
            xl: 4.5,
            '2xl': 4.5,
          }}
          items={stories.map((story) =>
            isRevealed ? (
              <GridItem data={story} key={story.name} />
            ) : (
              <GridItemReveal data={story} key={story.name} />
            ),
          )}
        />
      </Container>
      <Container>
        <div className="grid lg:grid-cols-12">
          <div className="bg-[#FAEFF8] px-6 py-10 sm:p-16 rounded-xl space-y-6 md:col-span-6 xl:col-span-5 relative">
            <Heading size="3">Write your Flower Girl plot</Heading>
            <Text size="lg">
              Join the conversation on Twitter and vote for your favourites.
            </Text>
            <Button
              variant="dark"
              flex
              iconLeft={<IconTablerTwitter />}
              onClick={handleJoinOnTwitter}
            >
              Join on Twitter
            </Button>
            <img
              src="/images/flower-girls/butterfly2-FG.png"
              alt="fg-butterfly"
              className="absolute lg:-right-32 lg:top-16 right-0 -top-32"
            />
          </div>
          <div className="md:col-span-6 xl:col-span-6 pt-32 pb-20 xl:p-16 xl:col-start-8 text-center self-center">
            {!isRevealed && (
              <>
                <span className="text-gray-500 font-bold uppercase text-sm block">
                  Collection will be revealed in
                </span>
                <Countdown
                  date={revealDate}
                  renderer={countdownRenderer}
                  onComplete={() => {
                    setTimeout(checkIsRevealed, 1000);
                  }}
                />
                <div
                  id="mailchimpform"
                  className="flower-girls relative max-w-[425px] mx-auto"
                >
                  <MailchimpForm />
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
      <Container
        width="sm"
        variants={containerMotion}
        initial="initial"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <Heading size="3" className="text-center mb-6">
          FAQs
        </Heading>

        <Tab.Group>
          <Tab.List className="mb-8 text-center" aria-label="Tabs">
            <TabsPill
              tabs={tabsFaqs}
              wrapperClasses="max-h-[44px] flex justify-center"
            />
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              {faqsCollection.map((text) => (
                <DisclosureReadl key={text.question} item={text} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {faqsText.map((text) => (
                <DisclosureReadl key={text.question} item={text} />
              ))}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Container>
    </>
  );
}

export default FlowerGirls;
