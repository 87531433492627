import React, { useContext, useState, useEffect } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { useLocation } from 'react-router-dom';
import ReadlLogo from '../../assets/images/ReadlLogo';
import ReadlWhiteLogo from '../../assets/images/ReadlWhiteLogo';
import LoginContext from '../../context/LoginContext';
import { Input } from '../FormElements';
import Button from '../Button';
import ModalTermsOfService from '../ModalTermsOfService';

export default function OnBoarding() {
  const location = useLocation();
  const { onboardingRegister } = useContext(LoginContext);
  const [checkedTerm, setCheckedTerm] = useState(true);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
  });
  const [communicationsCheck, setCommunicationsCheck] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [selectedGoal, setSelectedGoal] = useState('');
  const optionsGoal = [
    { value: '', text: 'What`s your main goal?', disabled: true },
    { value: 'read-and-discover', text: 'Read and discover' },
    { value: 'create-and-sell', text: 'Create and sell' },
  ];

  const [openModalTermsOfService, setOpenModalTermsOfService] = useState(false);
  const { address, isMetamask } = location.state;

  const canBeSubmitted = () => {
    const isValid =
      formValues.name?.trim().length &&
      !checkedTerm &&
      selectedGoal &&
      ((isMetamask && formValues.email?.trim().length) || !isMetamask);

    if (isValid) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  };

  useEffect(() => canBeSubmitted());

  const handleChange = (e) => {
    setSelectedGoal(e.target.value);
  };

  const register = async (e) => {
    e.preventDefault();
    const data = {
      address,
      name: formValues.name,
      communications: communicationsCheck,
      goal: selectedGoal,
    };

    if (isMetamask) {
      data.email = formValues.email;
    }

    await onboardingRegister(data);
  };

  return (
    <>
      <ModalTermsOfService
        open={openModalTermsOfService}
        setOpen={setOpenModalTermsOfService}
      />
      <div className="relative bg-white h-screen">
        <div className="min-h-full lg:flex h-full">
          <div className="hidden lg:block relative w-full max-w-[832px] flex-0">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://d394qrhzlf6jbr.cloudfront.net/collectibles/The Strange Case of Dr. Jekyll and Mr. Hyde Collectible 1.png"
              alt="Collectible from the Genesis Collection"
            />
            <ReadlWhiteLogo className="z-10 w-20 absolute left-8 top-6" />
            <div className="lg:absolute inset-0 relative">
              <div className="relative h-full">
                <div className="absolute z-20 text-base leading-6 font-normal bottom-8 lg:bottom-16 left-8 text-white">
                  Collectible by @keith
                </div>
                <a
                  target="_blank"
                  href="https://marketplace.readl.co/nft/collectible/edition/9"
                  className="absolute z-20  text-base leading-6 font-normal bottom-8 lg:bottom-16 right-8 text-white"
                  rel="noreferrer"
                >
                  <ArrowTopRightOnSquareIcon className="w-6" />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-2xl flex  justify-center">
              <div className="text-base lg:max-w-2xl">
                <ReadlLogo className="mx-auto h-12 w-auto lg:hidden block" />
                <h3 className="mt-12 lg:mt-2 text-4xl lg:text-6xl leading-tight font-medium tracking-tight text-gray-900 font-serif lg:max-w-lg lg:text-left text-center">
                  Complete your profile
                </h3>

                <p className="mt-8 text-base text-secondary-400 lg:text-left text-center">
                  We just need to know how we can greet you.
                </p>
                <form
                  onChange={(event) => {
                    const form = event.currentTarget;
                    setFormValues({
                      name: form.name.value,
                      email: form.email?.value,
                    });
                  }}
                  onSubmit={register}
                >
                  <div className="mt-12  lg:max-w-sm">
                    <div className="rounded-md shadow-sm -space-y-px w-full">
                      <Input
                        autoFocus
                        id="name"
                        type="text"
                        name="name"
                        className="mt-3 mb-4 py-4 focus:ring-primary-500 focus:border-primary-500"
                        placeholder="Enter your username"
                        defaultValue={formValues.name}
                      />
                    </div>
                  </div>
                  {isMetamask && (
                    <div className="mt-6  lg:max-w-sm">
                      <div className="rounded-md shadow-sm -space-y-px w-full">
                        <Input
                          id="email"
                          type="text"
                          name="email"
                          className="mt-3 mb-4 py-4 focus:ring-primary-500 focus:border-primary-500"
                          placeholder="Enter your email"
                          defaultValue={formValues.email}
                        />
                      </div>
                    </div>
                  )}
                  <div className="mt-6  lg:max-w-sm">
                    <select
                      id="goal"
                      name="goal"
                      className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md mt-2 mt-3 mb-4 py-4 focus:ring-primary-500 focus:border-primary-500"
                      value={selectedGoal}
                      onChange={handleChange}
                    >
                      {optionsGoal.map((option) => (
                        <option
                          disabled={option.disabled}
                          key={option.value}
                          value={option.value}
                        >
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="relative flex items-start mt-5">
                    <div className="flex items-center h-5">
                      <input
                        id="TermsOfService"
                        aria-describedby="TermsOfService-description"
                        name="TermsOfService"
                        type="checkbox"
                        onChange={() => setCheckedTerm(!checkedTerm)}
                        className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="TermsOfService"
                        className="font-medium text-sm leading-5 text-gray-700"
                      >
                        I accept the{' '}
                        <button
                          type="button"
                          onClick={() => setOpenModalTermsOfService(true)}
                          target="_blank"
                          className="border-b-2 border-primary-500"
                        >
                          Readl Terms of Service
                        </button>
                      </label>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-3">
                    <div className="flex items-center h-5">
                      <input
                        id="comunicationsReadl"
                        value={communicationsCheck}
                        aria-describedby="candidates-description"
                        name="comunicationsReadl"
                        type="checkbox"
                        className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                        onChange={(e) =>
                          setCommunicationsCheck(e.target.checked)
                        }
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="comunicationsReadl"
                        className="font-medium text-sm leading-5 text-gray-700"
                      >
                        I accept to occasionally receive communications from
                        Readl (optional)
                      </label>
                    </div>
                  </div>
                  <div className="lg:mt-6 mt-12 lg:flex">
                    <Button
                      type="submit"
                      variant="marketplace-primary"
                      size="none"
                      flex
                      disabled={buttonStatus}
                    >
                      Create account
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
