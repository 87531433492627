import * as React from 'react';
import IconTablerDiscord from '../../assets/icons/IconTablerDiscord';
import IconTablerTwitter from '../../assets/icons/IconTablerTwitter';
import IconTablerGlobe from '../../assets/icons/IconTablerGlobe';
import IconTablerTiktok from '../../assets/icons/IconTablerTikTok';
import IconTablerInstagram from '../../assets/icons/IconTablerInstagram';

function SocialLink({ icon: Icon, href }) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className="group -m-1 p-1 hover:bg-gray-100 dark:text-dark-300 dark:hover:bg-dark-700 dark:hover:text-primary-500 rounded-full transition-all"
      href={href}
    >
      <Icon className="h-6 w-6 " />
    </a>
  );
}
export default function SocialLinks({ items }) {
  return (
    <div className="flex gap-2 items-center mt-1 last:!mr-4">
      {items?.socialTwitter && (
        <SocialLink
          icon={IconTablerTwitter}
          href={`https://twitter.com/${items?.socialTwitter}`}
        />
      )}
      {items?.socialInstagram && (
        <SocialLink
          icon={IconTablerInstagram}
          href={`https://www.instagram.com/${items?.socialInstagram}`}
        />
      )}
      {items?.socialDiscord && (
        <SocialLink
          icon={IconTablerDiscord}
          href={`https://discord.gg/${items?.socialDiscord}`}
        />
      )}
      {items?.socialTiktok && (
        <SocialLink
          icon={IconTablerTiktok}
          href={`https://www.tiktok.com/@${items?.socialTiktok}`}
        />
      )}
      {items?.website && (
        <SocialLink icon={IconTablerGlobe} href={`https://${items?.website}`} />
      )}
    </div>
  );
}
