import { Disclosure } from '@headlessui/react';
import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Text } from '../Typography/Heading';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function DisclosureReadl({ item }) {
  return (
    <Disclosure
      className="border-b border-b-gray-200 dark:border-b-dark-700 py-4"
      as="div"
      key={item.question}
    >
      {({ open }) => (
        <>
          <dt className="">
            <Disclosure.Button className=" w-full">
              <div className="text-lg font-semibold cursor-pointer flex justify-between items-center pb-1 text-left dark:text-dark-100">
                <span> {item.question}</span>
                <ChevronDownIcon
                  className={classNames(
                    open ? '-rotate-180' : 'rotate-0',
                    'h-6 w-6 transform',
                  )}
                  aria-hidden="true"
                />
              </div>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="collapse-content py-2">
            <Text className="text-gray-500 dark:!text-dark-200">
              {item.answer}
            </Text>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default DisclosureReadl;
