import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/outline';
import apiService from '../../services/apiService';
import { useSpinner } from '../../context/SpinnerContext';
import Modal from '../Modal/Modal';

export default function ModalMainGoal() {
  const [openModalGoal, setOpenModalGoal] = useState(true);
  const { showSpinner, hideSpinner } = useSpinner();

  const onClickGoal = async (goal) => {
    showSpinner();
    try {
      apiService.put('/user/goal', { goal });
      setOpenModalGoal(false);
    } catch (error) {
      console.log(error);
    } finally {
      hideSpinner();
    }
  };

  return (
    <Modal size="xl" open={openModalGoal} setOpen={setOpenModalGoal}>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center ">
          <UserIcon
            className="h-12 w-12 text-secondary-900 dark:text-dark-300"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900 dark:text-dark-100"
          >
            Whats your main goal with Readl?
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500 dark:text-dark-300">
              Help us tailor your experience.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 grid grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="button"
          onClick={() => onClickGoal('create-and-sell')}
          className="rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 border border-gray-300 bg-white transition duration-300 hover:ring-2 hover:ring-gray-200 group dark:border-dark-600 dark:text-white dark:bg-dark-800"
        >
          <div className="h-full">
            <div className="flow-root py-4">
              <div className="">
                <h3 className="mt-2 text-base font-semibold text-center">
                  Author
                </h3>
                <p className="text-sm mt-2 leading-normal text-center text-gray-500">
                  Publish and sell
                </p>
              </div>
            </div>
          </div>
        </button>
        <button
          type="button"
          onClick={() => onClickGoal('read-and-discover')}
          className="rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 lg:mt-0 mt-4 border border-gray-300 bg-white transition duration-300 hover:ring-2 hover:ring-gray-200 group dark:border-dark-600 dark:text-white dark:bg-dark-800"
        >
          <div className="h-full">
            <div className="flow-root py-4">
              <div className="">
                <h3 className="mt-2 text-base font-semibold text-center">
                  Reader
                </h3>
                <p className="text-sm mt-2 leading-normal text-center text-gray-500">
                  Collect and read
                </p>
              </div>
            </div>
          </div>
        </button>
      </div>
    </Modal>
  );
}
